
import { EditFilled } from '@ant-design/icons';
import { Typography, Form, Button } from 'antd';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { safeGet } from 'helpers/getters';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import SettingsLayout from '../SettingsLayout';
import { FormProps } from './macroscop-forms';

const Macroscop = ({ history }) => {
  const currentUser = useCurrentUser();
  useEffect(() => {
    currentUser.updateMacroscopData();
  }, []);

  const [macroscop, setMacroscop] = useState({});

  useEffect(() => {
    setMacroscop(currentUser.macroscop);
  }, [currentUser.macroscop]);

  const formOpener = useFormOpener();


  const handleOpenEditForm = useCallback(() => {
    formOpener.open({
      title: t('macrosConnSett'),
      submitText: currentUser.macroscop.loading
        ? t('loading') + '...'
        : t('save'),
      component: (
        <FormProps
          macroscop={macroscop}
          isFormOpened={true}
          setMacroscop={setMacroscop}
        />
      ),
      onCancel: () => {
        formOpener.close();
      },
      onSubmit: async (_, val) => {
        currentUser.setMacroscopData(val);
        formOpener.close();
      },
    });
  }, [currentUser.macroscop.loading, macroscop, setMacroscop]);

  return (
    <SettingsLayout history={history}>
      <Typography.Title className="m-config-title">
        {t('macrosConnSett')}
      </Typography.Title>
      <Form
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 20 }}
        className="m-config-wrapper"
      >
        {<FormProps macroscop={macroscop} setMacroscop={setMacroscop} />}

        <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
          <Button
            type="primary"
            icon={<EditFilled />}
            disabled={currentUser.macroscop.loading}
            onClick={handleOpenEditForm}
          >
            {currentUser.macroscop.loading ? t('loading') + '...' : t('edit')}
          </Button>
        </Form.Item>
      </Form>
    </SettingsLayout>
  );
};

export default Macroscop;
