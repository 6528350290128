import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import SearchForm from './SearchForm';

import '../index.css';
import SearchResult from './SearchResult';
import { useObjectTypeOptions } from '../../../consts/options';

function SearchPopoverContent({ setVisible,visible }) {
  const [{ id: employees }] = useObjectTypeOptions();
  const [objectType, setObjectType] = useState(employees);

  return (
    <div className="search-popover__content">
      <CloseOutlined
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          color: '#25ACE3',
        }}
        onClick={() => {
          setVisible(false);
        }}
      />
      <SearchForm objectType={objectType} setObjectType={setObjectType} />
      <div className="search-result">
        <SearchResult objectType={objectType} visible={visible} />
      </div>
    </div>
  );
}

export default SearchPopoverContent;
