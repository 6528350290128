import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import { Button, Modal, Pagination, Table } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import { getListRequest as getEmployees } from '../../../store/actions/employees';
import { initForm } from '../../../store/actions/forms';
import {
  askToDelete,
  deleteRequest,
  getEventTypeRequest,
  getListRequest,
  setIsCreateFormOpen,
  setIsEditFormOpen
} from '../../../store/actions/groups';
import { selectConfigList as selectEmployees } from '../../../store/selectors/employees';
import { selectFormValues } from '../../../store/selectors/forms';
import {
  selectConfigList,
  selectConfigListCount,
  selectConfigListFetching,
  selectConfigTableData,
  selectCreationFetching,
  selectDeletingFetching,
  selectEditingFetching,
  selectIsCreateFormOpen,
  selectIsEditFormOpen,
  selectNominatedToRemoveItem
} from '../../../store/selectors/groups';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import useConfig from './form/config';
import { CREATE_FORM, EDIT_FORM } from './form/constants';
import { GroupForm } from './form/group.modal';

const GroupsConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variabels
  // ------------------------------------------------------------------------------
  const formOpener = useFormOpener();
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const config = useConfig()
  const { query, setQuery } = useQuery();
  const count = useSelector(selectConfigListCount);
  const isCreateFormOpen = useSelector(selectIsCreateFormOpen);
  const isEditFormOpen = useSelector(selectIsEditFormOpen);
  const nominatedToRemoving = useSelector(selectNominatedToRemoveItem);
  const list = useSelector(selectConfigList);
  const employees = useSelector(selectEmployees);
  const loading = useSelector(selectConfigListFetching);
  const createFormValues = useSelector(selectFormValues(CREATE_FORM));
  const editFormValues = useSelector(selectFormValues(EDIT_FORM));

  const creationLoading = useSelector(selectCreationFetching);
  const editingLoading = useSelector(selectEditingFetching);
  const deletingLoading = useSelector(selectDeletingFetching);

  const columns = [
    {
      title: t('title'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('responsible'),
      dataIndex: 'responsible',
      key: 'responsible',
      render: (responsible) => {
        const employee = employees.find(({ id }) => id === responsible);
        return employee ? `${employee.name} ${employee.surname}` : t("notSpecified");
      },
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => {
              formOpener.open({
                title: t('editGroup'),
                submitText: t('edit'),
                component: (
                  <GroupForm  onCreate={() => {
                    loadData()
                    formOpener.close()
                  }} groupId={instance.id} />
                ),
              });
            }}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={askToRemoveNotification(instance.id)}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  const data = useSelector(selectConfigTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(
    () => {
      loadData();
      dispatch(getEventTypeRequest());
      dispatch(initForm({ form: CREATE_FORM, config }));
      dispatch(initForm({ form: EDIT_FORM, config }));

      return () => {
        dispatch(setIsCreateFormOpen(false));
        dispatch(setIsEditFormOpen(false));
        dispatch(askToDelete(null));
      };
    },
    // Need to call this only once at render
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [query]
  );

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  function loadData() {
    dispatch(
      getListRequest({
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
        search: query.search || '',
      })
    );
  }

  const handleRemoveNotification = (id) => (e) => {
    e.preventDefault();
    dispatch(deleteRequest({ id }));
  };

  const askToRemoveNotification = (id) => (e) => {
    e.preventDefault();
    dispatch(askToDelete(id));
  };

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div className="item">
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('searchByColumns')}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(event) => {
                  setQuery({
                    page: 1,
                    size: MIN_PAGINATION_ITEMS,
                    search: event.target.value,
                  });
                }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              formOpener.open({
                title: t('addGroup'),
                submitText: t('add'),
                component: <GroupForm onCreate={() => {
                  loadData()
                  formOpener.close()
                }} />,
              });
            }}
          >
           {t('addGroup')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="groupsTable"
          loading={loading}
        />
        <Pagination
          style={{ marginTop: '1rem' }}
          {...{
            total: count,
            current: query.page || 1,
            pageSize: query.size || MIN_PAGINATION_ITEMS,
            onChange: (page, size) => setQuery({ ...query, page, size }),
            hideOnSinglePage: true,
            showSizeChanger: true,
            position: ['bottomLeft'],
            defaultCurrent: query.page,
          }}
        />
      </div>

      <Modal
        visible={Boolean(nominatedToRemoving)}
        onCancel={() => dispatch(askToDelete(null))}
        title={t('confirmAction')}
        okText={deletingLoading ? t('loading') + '...' : t('delete')}
        okButtonProps={{ color: 'red', loading: deletingLoading }}
        cancelText={t('not')}
        onOk={handleRemoveNotification(nominatedToRemoving)}
      >
        <p>{t('sureWantToDeleteGroup')}</p>
      </Modal>
    </SettingsLayout>
  );
};

export default GroupsConfiguration;
