import { ReactComponent as CameraLogoSvg } from 'assets/icons/camera-large.svg';
import { useQuery } from 'hooks/query';
import { mapStore } from 'model/MapStore';
import { useMemo } from 'react';
import './index.css';

export const CameraLogo = ({ inCameraArea }) => {
  const { setQuery } = useQuery();
  const props = useMemo(() => {
    if (inCameraArea) {
      return {
        className: 'activeCamera',
        onClick: () => {
          mapStore.openCamera();
          setQuery({ showKeeperCamera: 'true' });
        },
      };
    }
    return {
      className: 'disabledCamera',
      onClick: () => {},
    };
  }, [inCameraArea]);

  return (
    <span {...props}>
      <CameraLogoSvg />
    </span>
  );
};
