import {
  BellOutlined,
  CheckCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { Space, Typography, notification } from 'antd';
import { SHOW_MAP } from 'consts/urls';
import moment from 'moment';
import { eventTypeNameMapped } from 'pages/Settings/Reactions/utils';

export const successNotification = (message) => {
  notification.success({
    message,
    className: 'success',
    icon: <CheckCircleOutlined />,
  });
};

export const errorNotification = (message) => {
  notification.error({
    message,
    className: 'error',
    icon: <ExclamationCircleFilled />,
  });
};

export const eventNotification = ({ item, onCloseClick, t }) => {
  notification.open({
    placement: 'bottomRight',
    icon: <BellOutlined style={{ color: 'rgb(0, 162, 232)' }} />,
    onClose: onCloseClick,
    message: (
      <Typography.Text>{eventTypeNameMapped(t)[item.type]}</Typography.Text>
    ),
    duration: 4.5,
    description: (
      <Space direction={'vertical'} style={{ padding: 5 }}>
        {item.time && (
          <Typography.Text>
            {t('time')}: {moment(item.time).format('DD.MM.YYYY HH:mm')}
          </Typography.Text>
        )}
        {item.owner && (
          <Typography.Text>
            {t('fullName')}: {item.owner}
          </Typography.Text>
        )}
        {item.links?.map && (
          <Typography.Text>
            {t('map')}: {item.links?.map?.label}
          </Typography.Text>
        )}
        {item.params?.area_name && (
          <Typography.Text>
            {t('zone')}: {item.params?.area_name}
          </Typography.Text>
        )}
        {item.associated_layouts && item.associated_layouts.length !== 0 && (
          <Typography.Text
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
              const { x, y, z } = item.params ?? {};
              if (item.associated_layouts[0].id && x && y && z) {
                const path =
                  SHOW_MAP +
                  item.associated_layouts[0].id +
                  `/point/${(+x).toFixed(2)}/${(+y).toFixed(2)}/${(+z).toFixed(
                    2
                  )}/${item.id}`;
                window.open(path, '_blank');
              }
            }}
          >
            Карта:&nbsp;{item.associated_layouts[0].name}
          </Typography.Text>
        )}
      </Space>
    ),
  });
};

export const filterBlockingNotifications = (payload) =>
  payload?.filter((item) => !!item.showBlockingPopup);
export const filterBottomRightNotifications = (payload) =>
  payload?.filter((item) => !!item.showPopup);
export const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
