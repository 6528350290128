import axios from 'axios';
import { TOKEN } from '../consts/storageKeys';
import clearAuth from './clearAuth';
import { getStorageItem } from './storageHelpers';

const client = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const request = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_API_BASE_URL,
});

request.interceptors.request.use(
  (config) => {
    const token = getStorageItem(TOKEN);

    if (token && token?.access) {
      config.headers.Authorization = `Bearer ${token.access}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error

    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      clearAuth();
    }

    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      clearAuth();
    }
    return Promise.reject(error);
  }
);

export { request };

export default client;
