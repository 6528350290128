import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  CATEGORIES_REQUEST,
  CATEGORY_BY_ID_REQUEST,
  UPDATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  setCategoriesError,
  setCategoriesLoading,
  setCategoriesData,
  categoryByIdSuccess,
  categoryByIdFailure,
  updateCategoryFailure,
  updateCategorySuccess,
  createCategorySuccess,
  createCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
  openCreateModal,
  openConfirmModal,
  categoriesRequest,
} from '../actions/categories';
import { request } from '../../helpers/client';
// import { DEFAULT_PAGE_SIZE } from 'components/Pagination/constants';

function* getCategories(action) {
  // const { page = 1, size = DEFAULT_PAGE_SIZE } = action.payload;
  try {
    yield put(setCategoriesLoading(true));
    const response = yield call(request.get, '/layouts/categories/',
    //  {
    //   params: { page, size },
    // }
    );

    yield put(setCategoriesData(response.data));
  } catch (err) {
    yield put(setCategoriesError(err));
  } finally {
    yield put(setCategoriesLoading(false));
  }
}

function* getCategoryById(action) {
  const { id } = action.payload;

  try {
    const response = yield call(request.get, `/layouts/categories/${id}/`);

    yield put(categoryByIdSuccess(response.data));
  } catch (err) {
    yield put(categoryByIdFailure(err));
  }
}

function* updateCategory(action) {
  const { formData, id } = action.payload;

  try {
    const response = yield call(
      request.put,
      `/layouts/categories/${id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(updateCategorySuccess(response.data));
  } catch (err) {
    yield put(updateCategoryFailure(err));
  }
}

function* createCategory(action) {
  const { formData } = action.payload;
  try {
    const response = yield call(request.post, '/layouts/categories/', formData);
    yield put(createCategorySuccess(response.data));
    yield put(openCreateModal(false));
    yield put(categoriesRequest());
  } catch (err) {
    yield put(createCategoryFailure(err));
  }
}

function* deleteCategory(action) {
  const { id } = action.payload;
  try {
    const response = yield call(request.delete, `/layouts/categories/${id}/`);
    yield put(deleteCategorySuccess(response.data));
    yield put(openConfirmModal(false));
    yield put(categoriesRequest());
  } catch (err) {
    yield put(deleteCategoryFailure(err));
  }
}

function* Saga() {
  yield all([
    takeLatest(CATEGORIES_REQUEST, getCategories),
    takeLatest(CATEGORY_BY_ID_REQUEST, getCategoryById),
    takeLatest(UPDATE_CATEGORY_REQUEST, updateCategory),
    takeLatest(CREATE_CATEGORY_REQUEST, createCategory),
    takeLatest(DELETE_CATEGORY_REQUEST, deleteCategory),
  ]);
}

export default Saga;
