export const STATE_KEY = '@GROUPS-STAFFS';

export const GET_LIST_REQUEST = `${STATE_KEY}_GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${STATE_KEY}_GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${STATE_KEY}_GET_LIST_FAILURE`;

export const CREATION_REQUEST = `${STATE_KEY}_CREATION_REQUEST`;
export const CREATION_SUCCESS = `${STATE_KEY}_CREATION_SUCCESS`;
export const CREATION_FAILURE = `${STATE_KEY}_CREATION_FAILURE`;

export const DELETE_REQUEST = `${STATE_KEY}_DELETE_REQUEST`;
export const DELETE_SUCCESS = `${STATE_KEY}_DELETE_SUCCESS`;
export const DELETE_FAILURE = `${STATE_KEY}_DELETE_FAILURE`;

export const getListRequest = (payload = {}) => ({
  type: GET_LIST_REQUEST,
  payload,
});
export const getListSuccess = (payload) => ({
  type: GET_LIST_SUCCESS,
  payload,
});
export const getListFailure = (payload) => ({
  type: GET_LIST_FAILURE,
  payload,
});

export const createRequest = (payload) => ({
  type: CREATION_REQUEST,
  payload,
});
export const createSuccess = (payload) => ({
  type: CREATION_SUCCESS,
  payload,
});
export const createFailure = (payload) => ({
  type: CREATION_FAILURE,
  payload,
});

export const deleteRequest = (payload) => ({
  type: DELETE_REQUEST,
  payload,
});
export const deleteSuccess = (payload) => ({
  type: DELETE_SUCCESS,
  payload,
});
export const deleteFailure = (payload) => ({
  type: DELETE_FAILURE,
  payload,
});
