// @ts-nocheck
import { Button, Layout, Menu, Pagination, Table, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DeleteOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { successNotification } from 'helpers/notificationHelpers';
import { useQuery } from 'hooks/query';
import { MIN_PAGINATION_ITEMS } from 'pages/Settings/consts';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { getCreateMapAreaPath, getEditMapAreaPath } from '../../consts/routes';
import { mapStore } from '../../model/MapStore';
import { deleteArea } from '../../services/areas';
import './styles.scss';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

export const Areas = ({ history, children }) => {
  const { mapId } = useParams();
  const { t } = useTranslation();
  const { query, setQuery } = useQuery();
  const formOpener = useFormOpener();
  const [areas, setAreas] = useState();
  const mapName = useMemo(
    () => (mapId && mapStore.maps[mapId]?.data?.name) || '',
    [mapId]
  );

  useEffect(() => {
    mapStore.fetchAreas();
  }, []);

  useEffect(() => {
    if (mapId) {
      mapStore.setMapId(mapId);
    }
  }, [mapId]);

  useEffect(() => {
    const setZones = (areas) => {
      if (areas) {
        setAreas(
          Object.values(areas).map((area) => ({
            ...area,
            enabled: true,
          }))
        );
      }
    };
    const subscription = mapStore.currentMapAreas$.subscribe((areas) => {
      setZones(areas);
    });
    if (mapId) {
      setZones(mapStore.maps[mapId]?.areas);
    }
    return () => {
      subscription?.unsubscribe();
    };
  }, [mapId]);

  const remove = useCallback(
    (id) => {
      deleteArea(id)
        .then(() => mapStore.fetchAreas())
        .then(() => {
          const areas = mapStore.maps[mapId]?.areas;
          let data = [];
          if (areas) {
            data = Object.values(areas)
              .map((area) => {
                return {
                  ...area,
                  enabled: true,
                };
              })
              .filter((area) => area.id !== id);
          }
          setAreas(data);
          successNotification(t('zoneDeleted'));
        });
    },
    [mapId, areas]
  );

  const columns = useMemo(
    () => [
      {
        title: t('title'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('type'),
        dataIndex: 'type',
        key: 'type',
        render: (type) => t(type),
      },
      {
        title: t('actions'),
        dataIndex: 'layout',
        key: 'layout',
        render: (_, data) => {
          return (
            <div className="actions-wrapper">
              <Tooltip title={t('editZone')}>
                <NavLink to={getEditMapAreaPath(data.layout, data.id)} exact>
                  <EditFilled style={{ fontSize: 20 }} />
                </NavLink>
              </Tooltip>
              <Tooltip title={t('deleteZone')}>
                <DeleteOutlined
                  onClick={() =>
                    formOpener.open({
                      title: t('sureWantDeleteZone'),
                      submitText: t('delete'),
                      onSubmit: () => {
                        remove(data.id);
                        formOpener.close();
                      },
                    })
                  }
                  style={{ fontSize: 20 }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [remove, t]
  );

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <Layout
          className="site-layout-background"
          style={{ padding: '24px 0' }}
        >
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            <div className="table-wrapper">
              <div className="table-header">
                <div className="title">{mapName}</div>
                <div className="btn-create-wrapper">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => history.push(getCreateMapAreaPath(mapId))}
                  >
                    {t('addZone')}
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={areas || []}
                pagination={false}
                loading={false}
              />
              <Pagination
                style={{ padding: '1rem' }}
                total={areas?.length}
                current={Number(query.page) || 1}
                pageSize={query.size || MIN_PAGINATION_ITEMS}
                onChange={(page, size) => {
                  setQuery({ ...query, page, size });
                }}
                hideOnSinglePage={true}
                showSizeChanger={true}
                defaultCurrent={Number(query.page)}
              />
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};
