import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest as getEmployeesListRequest } from 'store/actions/employees';
import { getListRequest as getGroupsRequest } from 'store/actions/groups';
import { getListRequest as getUsersListRequest } from 'store/actions/users';

import { useReportFilterParams } from '../report-page.hook';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

export function ReportsSos({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const reportsEventColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (/** @type {moment.MomentInput} */ created_at) =>
        moment(created_at).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'from_user',
      dataIndex: 'from_user',
      title: t('report.filter.sender'),
      render: (from_user) =>
        from_user
          ? `${from_user.last_name ?? ''} ${from_user.username ?? ''}`
          : '',
    },

    {
      key: 'sos_type',
      dataIndex: 'sos_type',
      title: t('report.filter.sosType'),
      render: (sos_type) => {
        if (!sos_type) return '';
        return t(`report.filter.${sos_type}`);
      },
    },
    {
      key: 'to_group',
      dataIndex: 'to_group',
      title: t('groupName'),
      render: (to_group) =>
        to_group ? `${to_group ? to_group.name ?? '' : ''}` : '',
    },
    {
      key: 'to_employee',
      dataIndex: 'to_employee',
      title: t('receiver'),
      render: (to_employee) =>
        to_employee
          ? `${to_employee.username ?? ''} ${to_employee.surname ?? ''}`
          : t('receiverNotSpecified'),
    },
  ];

  const dispatch = useDispatch();
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getUsersListRequest({
        size: 1000,
      })
    );
    dispatch(
      getEmployeesListRequest({
        size: 1000,
      })
    );
    dispatch(
      getGroupsRequest({
        size: 1000,
      })
    );
  }, []);

  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            {/* <div className="filter-params">
              <ReportSelectUsers
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('report.filter.sender')}
                paramName={'from_user'}
              />
              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('report.filter.receiver')}
                paramName={'to_employee'}
              />

              <ReportSelectSosType
                setTempFilterParams={(type) => {
                  console.log(type);
                  setFilterParams(type);
                }}
                tempFilterParams={filterParams}
                loading={loading}
              />

              {filterParams.sos_type === 'group' && (
                <ReportSelectGroups
                  setTempFilterParams={setFilterParams}
                  tempFilterParams={filterParams}
                  loading={loading}
                  placeholder={t('report.filter.group')}
                  paramName={'to_group'}
                />
              )}
              
              <ReportFilterBoolean
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={"for_today"}
                localizationKey={'report.filter.forToday'}
              />
            </div> */}
          </div>
          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}
      <ReportTable
        columns={reportsEventColumns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}
