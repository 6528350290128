import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const EMPLOYEES = 'employees';
export const TRANSPORT = 'transports';

export const USERS = 'base_users';
export const ITEMS = 'keepers-items';
export const AREAS = 'layouts/zones';
export const ANCHORS = 'anchors';

export const keysCollections = {
  [EMPLOYEES]: [
    'lastname',
    'name',
    'secondname',
    'organization',
    'descr',
    'labels',
    'tag.id',
  ],
  [ITEMS]: ['name', 'descr', 'labels', 'tag.id'],
  [AREAS]: ['name', 'descr', 'labels', 'map.name'],
  [ANCHORS]: ['sn', 'ip_address', 'status'],
  [USERS]: ['login', 'descr', 'role.name'],
};
export const NOT_DELETED = 'deleted==false';

export const floorOptions = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
];

export const useObjectTypeOptions = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      { id: EMPLOYEES, name: t('employees') },
      { id: TRANSPORT, name: t('transport')},
      // { id: ITEMS, name: 'Объекты' },
      { id: AREAS, name: t('areas') },
      { id: ANCHORS, name: t('anchors') },
      // { id: USERS, name: 'Пользователи' },
    ];
  }, [t]);
};

export const defaultParams = {
  size: 10,
};
