import { PauseOutlined } from '@ant-design/icons';
import { Button, Slider } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { PlayIcon, SquareSolidIcon, TrackIcon } from 'assets/icons';
import { mapStore } from 'model/MapStore';
import { useTranslation } from 'react-i18next';
import { setKeeperCoordinate } from 'store/actions/ows';

const UPDATE_INTERVAL = 0.1;

const TravelHistory = () => {
  const intervalIdRef = useRef();
  const {t} = useTranslation()
  const [speed, setSpeed] = useState(1);
  const [progress, setProgress] = useState(0);
  const [play, setPlay] = useState(false);
  const [playingTime, setPlayingTime] = useState(0);
  const [{startTime, endTime}, setTime] = useState({});
  const history = useMemo(() => mapStore.keeperHistoryInstance, []);
  const {start, end, duration} = useMemo(() => {
    const start = +startTime;
    const end = +endTime;
    return {start, end, duration: end - start
    };
  }, [startTime, endTime]);

  useEffect(() => {
    const setDuration = (history) => {
      const {startTime, endTime} = history || {};
      if (startTime && endTime) {
        setTime({startTime: moment(startTime), endTime: moment(endTime)});
      }
    };
    setDuration(history?.list?.[history.currentHistoryIndex || 0]);
    const s = history.currentHistoryIndex$.subscribe((index) => {
      if (typeof index === 'number') {
        setDuration(history?.list?.[index]);
      }
    });
    return () => s.unsubscribe();
  }, [history]);

  useEffect(() => {
    setSpeed(history.speed);
    const s = history.speed$.subscribe((speed) => setSpeed(speed));
    return () => s.unsubscribe();
  }, [history]);

  useEffect(() => {
    setPlay(history.play === 'play');
    const s = history.play$.subscribe((v) => {
      setPlay(v === 'play');
      if (v === 'stop') {
        history.currentTime = start;
        setProgress(0);
        setPlayingTime(start);
      }
    });
    return () => s.unsubscribe();
  }, [history, start]);

  const onChangeSlider = (progress) => {
    const current = progress * duration;
    const seekTime = start + current;
    setPlayingTime(seekTime);
    setProgress(progress);
    setPlay(false);
    history.currentTime = seekTime;
    history.setProgress(progress);
  };

  const onAfterChangeSlider = (progress) => {
    history.setProgress(progress, true);
    setPlayingTime(history.currentTime);
  };

  const onChangeSpeed = () => {
    history.setSpeed();
    setPlay(false);
  };

  const togglePlay = () => {
    const value = !play;
    setPlay(value);
    history.setPlay(value ? 'play' : 'pause');
    setPlayingTime(history.currentTime);
  };

  const stop = () => {
    history.currentTime = start;
    setPlay(false);
    setProgress(0);
    setPlayingTime(start);
    history.setPlay('stop');
  };

  const updateTime = () => {
    history.currentTime += speed * UPDATE_INTERVAL * 1000;
    setPlayingTime(history.currentTime);
  };

  useEffect(() => {
    history.currentTime = start
    setPlayingTime(start);
    setPlay(false);
    setProgress(0);
  }, [history, start, end]);

  useEffect(() => {
    if (play) {
      intervalIdRef.current = setInterval(updateTime, 1000 * UPDATE_INTERVAL);
    } else {
      clearInterval(intervalIdRef.current);
    }
  }, [play]);

  useEffect(() => {
    if (playingTime) {
      setProgress((playingTime - start) / duration);
    }
  }, [duration, playingTime, start]);

  useEffect(() => {
    if (progress >= 1) {
      clearInterval(intervalIdRef.current);
    }
  }, [progress]);

  return (
    <div className="travel-history-wrapper">
      <p className="blue-title">{t('movementHistory')}</p>
      <p className="history-sub-title">
        {playingTime ? moment(playingTime).format('DD.MM.YYYY HH:mm:ss') : ' '}&nbsp;
      </p>
      <Button className="speed-btn" onClick={onChangeSpeed}>
        X{speed}
      </Button>
      <Slider
        min={0}
        max={1}
        value={progress}
        step={0.01}
        onChange={onChangeSlider}
        onAfterChange={onAfterChangeSlider}
      />
      <div className="controller-wrapper">
        <TrackIcon className="c-pointer" />
        {play ? (
          <PauseOutlined onClick={togglePlay} />
        ) : (
          <PlayIcon className="c-pointer" onClick={togglePlay} />
        )}
        <SquareSolidIcon className="c-pointer" onClick={stop} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    owsData: state.ows.owsState.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setKeeperCoordinate: (keeperCoordinate) =>
      dispatch(setKeeperCoordinate(keeperCoordinate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelHistory);
