// @ts-nocheck
import { Form, Input, Select } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { capitalize } from 'helpers/convertHelpers';
import { errorMessage } from 'helpers/errorHandlers';
import { uniqueId } from 'lodash';
import { userSettingsCreateFormRoleTypes } from 'pages/Settings/Users/users.config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const UserCreateForm = ({ onUserSave, instance = undefined }) => {
  // ---------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------
  const formOpener = useFormOpener();
  const userDefault = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    role: '',
    username: '',
    date_joined: new Date(),
  };
  const { t } = useTranslation();

  const [requestInput, setRequestInput] = useState(
    instance ? instance : userDefault
  );
  const [isRequesting, setIsRequesting] = useState(false);
  const [errors, setErrors] = useState();

  // ---------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------

  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmitClick,
      submitButtonProps: { loading: isRequesting },
    });
  }, [requestInput, formOpener, isRequesting]);

  useEffect(() => {
    if (!instance) return;
    setRequestInput(instance);
  }, [instance]);

  // ---------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------

  async function onSubmitClick(isValid) {
    if (!isValid) return;
    setIsRequesting(true);

    try {
      let responce;
      if (instance) {
        const { id, ...props } = requestInput;
        responce = await request.put(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/base_users/' + id,
          props
        );
      } else {
        responce = await request.post(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/base_users/',
          requestInput
        );
      }
      if (responce.status >= 200 && responce.status < 300) {
        onUserSave();
        formOpener.close();
      }
    } catch (error) {
      setErrors(errorMessage(error).fields);
    }
    setIsRequesting(false);
  }
  // ---------------------------------------------------------------
  return (
    <>
      <Form.Item
        label={t('userName')}
        rules={[
          {
            required: true,
            message: t('enterName'),
          },
        ]}
        className="form-row"
        name={'username'}
        initialValue={requestInput.username}
        required={true}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              username: e.target.value,
            });
          }}
          value={requestInput.username}
          required={true}
        />
      </Form.Item>

      <Form.Item
        label={capitalize(`${t('name')}`)}
        rules={[
          {
            required: true,
            message: t('enterText'),
          },
        ]}
        className="form-row"
        name={'first_name'}
        required={true}
        initialValue={requestInput.first_name}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              first_name: e.target.value,
            });
          }}
          value={requestInput.first_name}
          required={true}
        />
      </Form.Item>

      <Form.Item
        label={t('surname')}
        rules={[
          {
            required: true,
            message: t('enterText'),
          },
        ]}
        className="form-row"
        required={true}
        name={'last_name'}
        initialValue={requestInput.last_name}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              last_name: e.target.value,
            });
          }}
          required={true}
          value={requestInput.last_name}
        />
      </Form.Item>

      <Form.Item
        label={t('phoneNumber')}
        rules={[
          {
            required: true,
            message: t('enterText'),
          },
        ]}
        className="form-row"
        required={true}
        name={'phone'}
        initialValue={requestInput.phone}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              phone: e.target.value,
            });
          }}
          type="tel"
          required={true}
          value={requestInput.phone}
        />
      </Form.Item>

      <Form.Item
        label={capitalize(t('email'))}
        rules={[
          {
            required: true,
            message: t('enterEmail'),
          },
        ]}
        className="form-row"
        required={true}
        name={'email'}
        initialValue={requestInput.email}
        validateStatus={errors?.email ? 'error' : ''}
        help={errors?.email}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              email: e.target.value,
            });
          }}
          type="email"
          required={true}
          value={requestInput.email}
        />
      </Form.Item>

      <Form.Item
        label={t('password')}
        rules={[
          {
            required: !instance,
            message: t('enterText'),
          },
        ]}
        className="form-row"
        name={'password'}
        initialValue={requestInput.password}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              password: e.target.value,
            });
          }}
          type="password"
          value={requestInput.password}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label={t('role')}
        className="form-row"
        required={true}
        name={'rome'}
        rules={[
          {
            required: true,
            message: t('enterText'),
          },
        ]}
        required={true}
        initialValue={requestInput.role}
      >
        <Select
          backfill={false}
          onChange={(selected) => {
            setRequestInput({
              ...requestInput,
              role: selected,
            });
          }}
          required={true}
          value={requestInput.role}
        >
          {userSettingsCreateFormRoleTypes.map((option, index) => (
            <Select.Option key={uniqueId('_ddd2')} value={option.name}>
              {t(option.name)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
