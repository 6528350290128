import { Form, Input } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { errorNotification } from 'helpers/notificationHelpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OrganizationForm = ({  onSave, instance = undefined }) => {
  // ---------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------
  const formOpener = useFormOpener();
  const defaultValue = {
    title: '',
  };
  const { t } = useTranslation();

  const [requestInput, setRequestInput] = useState(
    instance ? instance : defaultValue
  );
  const [isRequesting, setIsRequesting] = useState(false);

  // ---------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------

  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmitClick,
      submitButtonProps: { loading: isRequesting },
    });
  }, [requestInput, formOpener, isRequesting]);

  useEffect(() => {
    if (!instance) return;
    setRequestInput(instance);
  }, [instance]);

  // ---------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------

  async function onSubmitClick(isValid) {
    if (!isValid) return;
    setIsRequesting(true);

    try {
      let responce;
      if (instance) {
        const { id, ...props } = requestInput;
        responce = await request.put(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/organizations/' + id + "/",
          props
        );
      } else {
        responce = await request.post(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/organizations/',
          requestInput
        );
      }
      if (responce.status >= 200 && responce.status < 300) {
        onSave();
        formOpener.close();
      }
    } catch (error) {
      let errorMessage = '';
      if (error.response.status === 400) {
        if (error.response.data.username) {
          errorMessage = t('allReadyRegistered');
        }
        if (error.response.data.role) {
          errorMessage += '\n' + t('inValidRoleSelect');
        }
      }
      errorNotification(errorMessage === '' ? t('undefinedError') : '');
    }
    setIsRequesting(false);
  }
  // ---------------------------------------------------------------
  return (
    <>
      <Form.Item
        label={t('title')}
        rules={[
          {
            required: true,
            message: t('enterName'),
          },
        ]}
        className="form-row"
        name={'title'}
        initialValue={requestInput.title}
        required={true}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              title: e.target.value,
            });
          }}
          value={requestInput.title}
          required={true}
        />
      </Form.Item>
    </>
  );
};
