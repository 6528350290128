import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

export function ReportsKeeperBatteryLevel({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const reportsEventColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (v) => moment(v).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'tag_id',
      dataIndex: 'tag_id',
      title: t('report.filter.tag'),
    },
    {
      key: 'battery_state',
      dataIndex: 'battery_state',
      title: t('report.filter.battery_state'),
    },
    {
      key: 'employee_name',
      dataIndex: 'employee_name',
      title: t('report.filter.tagOwner'),
      render: (v) => (!v ? t('notSpecified') : v),
    },
    {
      key: 'organization_title',
      dataIndex: 'organization_title',
      title: t('organization'),
      render: (v) => (!v ? t('notSpecified') : v),
    },
  ];

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------
  return (
    <>
      <ReportTable
        columns={reportsEventColumns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}
