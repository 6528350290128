import { TOKEN } from 'consts/storageKeys';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { getStorageItem } from 'helpers/storageHelpers';
import { useEffect, useRef } from 'react';
import { useIsBackendWorking } from './notification-listener-ping.hook';

export const useNotificationListener = (
  /** @type {({ data }: { data: any; }) => void} */ onMessageReceive,
  /** @type {{ results: any[]; count: number; }[]} */ deps
) => {
  const webSocketRef = useRef(null);
  const currentUser = useCurrentUser();
  const isBackendWorking = useIsBackendWorking()

  useEffect(() => {
    if (!isBackendWorking) {
      webSocketRef.current?.close();
      webSocketRef.current = undefined;
    } else {
      webSocketRef.current?.close();
      webSocketRef.current = undefined;
      createConnection();
    }
  }, [isBackendWorking]);



  useEffect(() => {
    createConnection();
  }, [webSocketRef, currentUser]);

  useEffect(() => {
    if (!webSocketRef.current) return;
    webSocketRef.current.onmessage = onMessageReceive;
    webSocketRef.current.onclose = () => createConnection();
    webSocketRef.current.onerror = () => createConnection();
  }, [currentUser.user, webSocketRef.current, ...deps]);

  function createConnection() {
    const token = getStorageItem(TOKEN);
    if (!token) {
      return;
    }
    if (webSocketRef.current) return;
    const notificationListener = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_URL +
        `notifications?token=${token.access}`
    )
    webSocketRef.current?.close();
    webSocketRef.current = notificationListener;
  }
};
