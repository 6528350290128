import { Table, Pagination } from 'antd';
import { useQuery } from 'hooks/query';
import { MIN_PAGINATION_ITEMS } from 'pages/Settings/consts';

export function ReportTable({ columns, items, total, loading }) {
  const { query, setQuery } = useQuery();
  return (
    <div className="reports-table-container">
      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        loading={loading}
      />
      <Pagination
        style={{ padding: '1rem' }}
        total={total}
        current={Number(query.page) || 1}
        pageSize={query.size || MIN_PAGINATION_ITEMS}
        onChange={(page, size) => setQuery({ ...query, page, size })}
        hideOnSinglePage={true}
        showSizeChanger={true}
        defaultCurrent={Number(query.page)}
      />
    </div>
  );
}
