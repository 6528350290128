const submit = (formValues, instance) => {
  const formData = new FormData();

  if (formValues['marker_type']) {
    if (instance && instance['marker_type'] !== formValues['marker_type']) {
      formData.append('marker_type', formValues['marker_type']);
    }
    if (!instance) {
      formData.append('marker_type', formValues['marker_type']);
    }
  }

  if (formValues['icon'] && (formValues['icon'].file || !formValues['icon'].url)) {
    formData.append('icon', formValues['icon'].file || '');
  }

  return formData;
};

export default submit;
