import { Progress } from 'antd';
import client from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeesOnObjectByOrganization = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    client
      .get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/dashboards/employees_by_organizations`
      )
      .then((response) => {
        setData(response.data.items);
      });
  }, []);

  return (
    <div
      className="char-component-wrapper"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="char-component-title">
        <span>{t('dashboard.employeesOnObjectByOrganization')}</span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ padding: 20 }}>
          {data.map((r) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // height: '30px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginBottom:"5px"
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>{r.name}</div>
                  <div
                    style={{ fontWeight: 'normal' }}
                  >{`${r.active}/${r.total}`}</div>
                </div>

                <Progress
                  percent={(r.active * 100) / r.total}
                  strokeColor="#67C587"
                  trailColor="#9E9E9E"
                  style={{ flexGrow: 1, width: '100%' }}
                  showInfo={false}
                  width={100}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
