export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const SET_CATEGORIES_DATA = 'SET_CATEGORIES_DATA';
export const SET_CATEGORIES_FETCHING = 'SET_CATEGORIES_FETCHING';
export const SET_CATEGORIES_ERROR = 'SET_CATEGORIES_ERROR';

export const CATEGORY_BY_ID_REQUEST = 'CATEGORY_BY_ID_REQUEST';
export const CATEGORY_BY_ID_SUCCESS = 'CATEGORY_BY_ID_SUCCESS';
export const CATEGORY_BY_ID_FAILURE = 'CATEGORY_BY_ID_FAILURE';

export const CLEAR_SINGLE_CATEGORY_STATE = 'CLEAR_SINGLE_CATEGORY_STATE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const OPEN_CREATE_MODAL = 'OPEN_CREATE_MODAL';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';

export const categoriesRequest = (payload) => ({
  type: CATEGORIES_REQUEST,
  payload,
});

export const setCategoriesLoading = (payload) => ({
  type: SET_CATEGORIES_FETCHING,
  payload,
});
export const setCategoriesData = (payload) => ({
  type: SET_CATEGORIES_DATA,
  payload,
});
export const setCategoriesError = (payload) => ({
  type: SET_CATEGORIES_ERROR,
  payload,
});

export const categoryByIdRequest = (payload) => ({ type: CATEGORY_BY_ID_REQUEST, payload });
export const categoryByIdSuccess = (payload) => ({ type: CATEGORY_BY_ID_SUCCESS, payload });
export const categoryByIdFailure = (payload) => ({ type: CATEGORY_BY_ID_FAILURE, payload });

export const clearSingleCategoryState = () => ({ type: CLEAR_SINGLE_CATEGORY_STATE });

export const updateCategoryRequest = (payload) => ({ type: UPDATE_CATEGORY_REQUEST, payload });
export const updateCategorySuccess = (payload) => ({ type: UPDATE_CATEGORY_SUCCESS, payload });
export const updateCategoryFailure = (payload) => ({ type: UPDATE_CATEGORY_FAILURE, payload });

export const createCategoryRequest = (payload) => ({ type: CREATE_CATEGORY_REQUEST, payload});
export const createCategorySuccess = (payload) => ({ type: CREATE_CATEGORY_SUCCESS, payload});
export const createCategoryFailure = (payload) => ({ type: CREATE_CATEGORY_FAILURE, payload});

export const deleteCategoryRequest = (payload) => ({ type: DELETE_CATEGORY_REQUEST, payload});
export const deleteCategorySuccess = (payload) => ({ type: DELETE_CATEGORY_SUCCESS, payload});
export const deleteCategoryFailure = (payload) => ({ type: DELETE_CATEGORY_FAILURE, payload});

export const openConfirmModal = (payload) => ({ type: OPEN_CONFIRM_MODAL, payload})
export const openCreateModal = (payload) => ({ type: OPEN_CREATE_MODAL, payload})
