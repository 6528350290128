export const STATE_KEY = '@EMPLOYEES';

export const GET_LIST_REQUEST = `${STATE_KEY}_GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${STATE_KEY}_GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${STATE_KEY}_GET_LIST_FAILURE`;

export const GET_EVENT_TYPE_REQUEST = `${STATE_KEY}_GET_EVENT_TYPE_REQUEST`;
export const GET_EVENT_TYPE_SUCCESS = `${STATE_KEY}_GET_EVENT_TYPE_SUCCESS`;
export const GET_EVENT_TYPE_FAILURE = `${STATE_KEY}_GET_EVENT_TYPE_FAILURE`;

export const SET_IS_CREATE_FORM_OPEN = `${STATE_KEY}_SET_IS_CREATE_FORM_OPEN`;
export const SET_IS_EDIT_FORM_OPEN = `${STATE_KEY}_SET_IS_EDIT_FORM_OPEN`;

export const CREATION_REQUEST = `${STATE_KEY}_CREATION_REQUEST`;
export const CREATION_SUCCESS = `${STATE_KEY}_CREATION_SUCCESS`;
export const CREATION_FAILURE = `${STATE_KEY}_CREATION_FAILURE`;
export const EDITING_REQUEST = `${STATE_KEY}_EDITING_REQUEST`;
export const EDITING_SUCCESS = `${STATE_KEY}_EDITING_SUCCESS`;
export const EDITING_FAILURE = `${STATE_KEY}_EDITING_FAILURE`;

export const OPEN_EDIT_FORM = `${STATE_KEY}_OPEN_EDIT_FORM`;

export const ASK_TO_DELETE = `${STATE_KEY}_ASK_TO_DELETE`;
export const DELETE_REQUEST = `${STATE_KEY}_DELETE_REQUEST`;
export const DELETE_SUCCESS = `${STATE_KEY}_DELETE_SUCCESS`;
export const DELETE_FAILURE = `${STATE_KEY}_DELETE_FAILURE`;


export const getListRequest = (payload = {}) => ({
  type: GET_LIST_REQUEST,
  payload
});
export const getListSuccess = payload => ({
  type: GET_LIST_SUCCESS,
  payload
});
export const getListFailure = payload => ({
  type: GET_LIST_FAILURE,
  payload,
});

export const getEventTypeRequest = payload => ({
  type: GET_EVENT_TYPE_REQUEST,
  payload,
});
export const getEventTypeSuccess = payload => ({
  type: GET_EVENT_TYPE_SUCCESS,
  payload,
});
export const getEventTypeFailure = payload => ({
  type: GET_EVENT_TYPE_FAILURE,
  payload,
});
export const setIsCreateFormOpen = payload => ({
  type: SET_IS_CREATE_FORM_OPEN,
  payload,
});
export const setIsEditFormOpen = payload => ({
  type: SET_IS_EDIT_FORM_OPEN,
  payload,
});
export const createRequest = payload => ({
  type: CREATION_REQUEST,
  payload,
});
export const createSuccess = payload => ({
  type: CREATION_SUCCESS,
  payload,
});
export const createFailure = payload => ({
  type: CREATION_FAILURE,
  payload
});
export const editRequest = payload => ({
  type: EDITING_REQUEST,
  payload,
});
export const editSuccess = payload => ({
  type: EDITING_SUCCESS,
  payload,
});
export const editFailure = payload => ({
  type: EDITING_FAILURE,
  payload
});

export const openEditForm = payload => ({
  type: OPEN_EDIT_FORM,
  payload,
});

export const askToDelete = payload => ({
  type: ASK_TO_DELETE,
  payload,
});

export const deleteRequest = payload => ({
  type: DELETE_REQUEST,
  payload,
});
export const deleteSuccess = payload => ({
  type: DELETE_SUCCESS,
  payload,
});
export const deleteFailure = payload => ({
  type: DELETE_FAILURE,
  payload,
});
