export const SET_NOTIFICATIONS_DATA = 'SET_NOTIFICATIONS_DATA';
export const SET_NOTIFICATIONS_FETCHING = 'SET_NOTIFICATIONS_FETCHING';
export const SET_NOTIFICATIONS_ERROR = 'SET_NOTIFICATIONS_ERROR';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const DELETE_BLOCKING_NOTIFICATIONS = 'DELETE_BLOCKING_NOTIFICATIONS';
export const DELETE_BOTTOM_RIGHT_NOTIFICATIONS = 'DELETE_BOTTOM_RIGHT_NOTIFICATIONS';
export const ADD_BOTTOM_RIGHT_NOTIFICATION = 'ADD_BOTTOM_RIGHT_NOTIFICATION';

export const setNotificationsLoading = (payload) => ({
  type: SET_NOTIFICATIONS_FETCHING,
  payload,
});
export const setNotificationsData = (payload) => {
  return {
    type: SET_NOTIFICATIONS_DATA,
    payload,
  };
};
export const setNotificationsError = (payload) => ({
  type: SET_NOTIFICATIONS_ERROR,
  payload,
});
export const deleteBlockingNotification = (payload) => ({
  type: DELETE_BLOCKING_NOTIFICATIONS,
  payload,
});
export const deleteNotification = (payload) => ({
  type: DELETE_NOTIFICATIONS,
  payload,
});
export const deleteBottomRightNotification = (payload) => ({
  type: DELETE_BOTTOM_RIGHT_NOTIFICATIONS,
  payload,
});
export const addBottomRightNotification = (payload) => ({
  type: ADD_BOTTOM_RIGHT_NOTIFICATION,
  payload,
});
