import { EditFilled } from '@ant-design/icons';
import { Typography, Form, Button } from 'antd';
import { useModal } from 'context/modal/modal.hook';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsLayout from '../SettingsLayout';
import { SmtpForm } from './smtp.form';

const Smtp = ({ history }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  return (
    <SettingsLayout history={history}>
      <Typography.Title className="m-config-title">
        {t('smtp.title')}
      </Typography.Title>

      <SmtpForm loading={loading} setLoading={setLoading} disabled={true} />

      <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
        <Button
          type="primary"
          icon={<EditFilled />}
          disabled={loading}
          onClick={() =>
            modal.open({
              title: t('smtp.title'),
              component: <SmtpForm setLoading={setLoading} loading={loading} />,
              width: 'auto',
            })
          }
        >
          {loading ? t('loading') + '...' : t('edit')}
        </Button>
      </Form.Item>
    </SettingsLayout>
  );
};

export default Smtp;
