import { Select } from 'antd';
import { getQueryParams } from 'hooks/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectGender({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const { t } = useTranslation();
  const types = ['male', 'female'];

  const typeValue = useCallback(() => {
    const queryParams = getQueryParams();
    let currentValue = null;

    if (!tempFilterParams) return;
    if (tempFilterParams.sex) {
      currentValue = types.find((type) => type === tempFilterParams.sex);
    } else {
      currentValue = types.find((type) => type === queryParams.sex);
    }

    if (!currentValue) return null;
    return t(currentValue);
  }, [types, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={t('sex')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={typeValue()}
      onChange={(sex) => {
        setTempFilterParams({
          ...tempFilterParams,
          sex,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {['_none', ...types].map((sex, index) => (
        <Select.Option value={sex} key={index}>
          {sex === '_none' ? t('notSpecified') : t(sex)}
        </Select.Option>
      ))}
    </Select>
  );
}
