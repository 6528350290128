import { Affix, Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ReactComponent as SosLargeLogo } from '../../../../assets/icons/sos-large.svg';
import SosPopoverContent from './SosPopoverContent';

function SosPopover() {
  const {t} = useTranslation()
  return (
    <Affix style={{ position: 'absolute', bottom: 60, left: 60, zIndex: 999 }}>
      <Popover
        placement="topLeft"
        content={<SosPopoverContent />}
        trigger="click"
      >
        <Tooltip title={t('sendSos')}>
          <SosLargeLogo
            style={{
              filter: 'drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.15))',
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </Popover>
    </Affix>
  );
}

export default connect()(SosPopover);
