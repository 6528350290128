import ReactDOM from 'react-dom';
import React from 'react';

export const toHTMLElem = (component) => {
  const div = document.createElement('div');
  ReactDOM.render(component, div);
  return div;
};

export const toDegree = (num, extra = 0) => num * 60 + extra;

export const capitalize = (str) => str.replace(/^./, str => str.toUpperCase())