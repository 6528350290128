export const ParamsHelper = {
  excludeWithValues: (
    /** @type {{ [x: string]: any; }} */ object,
    /** @type { string[]} */ valuesToExclude
  ) => {
    let resultParams = {};
    for (let key in object) {
      if (!valuesToExclude.includes(object[key]))
        resultParams[key] = object[key];
    }
    return resultParams;
  },

  exclude: (
    /** @type {{ [x: string]: any; }} */ object,
    /** @type { string[]} */ paramsToExclude
  ) => {
    let resultParams = {};
    for (let key in object) {
      if (!paramsToExclude.includes(key)) {
        resultParams[key] = object[key];
      }
    }
    return resultParams;
  },
};
