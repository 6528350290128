import { Layout, Menu } from 'antd';
import { Fragment } from 'react';

import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useMenuItems } from './constants';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const SettingsLayout = ({ history, children }) => {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const currentUser = useCurrentUser();
  const { location } = history;
  const menuItems = useMenuItems();
  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------
  const filerPathName = (arrayObjects) => {
    return arrayObjects.filter((item) =>
      item.hasSubMenu
        ? !!item.subItems.filter(
            (subItem) => subItem.path === location.pathname
          ).length
        : item.path === location.pathname
    );
  };

  function sortedTabs() {
    const managerAllowedTabs = [
      'notifications',
      'employees',
      'rotations',
      'groups',
      'setting',
    ];

    if (currentUser.user.role === 'manager') {
      return menuItems.filter((item) => managerAllowedTabs.includes(item.key));
    } else {
      return menuItems;
    }
  }
  const selectedKeys = () => {
    const selected = filerPathName(menuItems);

    let subMenu = [];
    let actualMenu = [];

    for (let i in selected) {
      if (selected[i].hasSubMenu) {
        const selectedSubMenu = filerPathName(selected[i].subItems).map(
          (item) => item.key
        );
        subMenu = [...selectedSubMenu];
      } else {
        actualMenu.push(selected[i].key);
      }
    }
    return [...subMenu, ...actualMenu];
  };

  // ------------------------------------------------------------------------------------
  if (!currentUser.user) return <></>;
  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <Layout
          className="site-layout-background"
          style={{ padding: '24px 0' }}
        >
          <Sider className="site-layout-background" width={200}>
            <Menu
              mode="inline"
              style={{ height: '100%' }}
              selectedKeys={selectedKeys()}
            >
              {sortedTabs().map((item, index) => (
                <Fragment key={index}>
                  {item.hasSubMenu && (
                    <SubMenu key={item.key} title={item.label}>
                      {item.subItems.map((sub) => (
                        <Menu.Item
                          onClick={() => {
                            history.push(sub.path);
                          }}
                          key={sub.key}
                        >
                          {sub.label}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  )}
                  {!item.hasSubMenu && (
                    <Menu.Item
                      onClick={() => {
                        history.push(item.path);
                      }}
                      key={item.key}
                    >
                      {item.label}
                    </Menu.Item>
                  )}
                </Fragment>
              ))}
            </Menu>
          </Sider>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            {children}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};
export default SettingsLayout;
