import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import { Button, Pagination, Table } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { t as tFunc } from 'i18next';
import { UserCreateForm } from 'pages/Settings/Users/user-create-form.component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { initForm } from 'store/actions/forms';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import { getListRequest } from '../../../store/actions/users';
import {
  selectConfigListCount,
  selectConfigListFetching,
  selectConfigTableData,
} from '../../../store/selectors/users';
import useConfig from '../Groups/form/config';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import { CREATE_FORM } from './users.config';

const UsersConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { t } = useTranslation();
  const { query, setQuery } = useQuery();
  const dispatch = useDispatch();
  const count = useSelector(selectConfigListCount);
  const formOpener = useFormOpener();
  const loading = useSelector(selectConfigListFetching);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const config = useConfig();
  const columns = [
    {
      title: t('login'),
      dataIndex: 'instance',
      key: 'instance',
      render: (instance) => {
        return instance ? instance.username ?? '' : '';
      },
    },

    {
      title: t('name'),
      dataIndex: 'instance',
      key: 'instance',
      render: (instance) => {
        return instance ? instance.first_name ?? '' : '';
      },
    },
    {
      title: t('surname'),
      dataIndex: 'instance',
      key: 'instance',
      render: (instance) => {
        return instance ? instance.last_name ?? '' : '';
      },
    },
    {
      title: t('role'),
      dataIndex: 'instance',
      key: 'instance',
      render: (instance) => {
        return instance ? instance.role ?? 'Роль не указана' : '';
      },
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => {
              handleClickEdit(instance);
            }}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={() => {
              formOpener.open({
                title: t('deleteUser'),
                submitText: t('delete'),
                component: <> {t('sureWantDeleteUser')}</>,
                onSubmit: async () => {
                  await remove(instance.id);
                },
                loading: deleteLoading,
              });
            }}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];
  const data = useSelector(selectConfigTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(
    () => {
      dispatch(initForm({ form: CREATE_FORM, config }));
    },
    // eslint-disable-next-line
    []
  );

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  function loadData() {
    dispatch(
      getListRequest({
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
        search: query.search || '',
      })
    );
  }

  async function handleClickCreate() {
    formOpener.open({
      title: tFunc('addingUser'),
      cancelText: tFunc('cancel'),
      submitText: tFunc('create'),
      component: <UserCreateForm onUserSave={() => loadData()} />,
    });
  }

  async function handleClickEdit(instance) {
    formOpener.open({
      title: t('editUser'),
      cancelText: t('cancel'),
      submitText: t('edit'),
      component: (
        <UserCreateForm instance={instance} onUserSave={() => loadData()} />
      ),
    });
  }

  async function remove(id) {
    setDeleteLoading(true);
    await request.delete(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/base_users/' + id
    );
    setDeleteLoading(false);
    loadData();
    formOpener.close();
  }

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div className="item">
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('searchByColumns')}
                inputProps={{ 'aria-label': 'search google maps' }}
                value={query.search || ''}
                onChange={(event) => {
                  setQuery({
                    page: 1,
                    size: MIN_PAGINATION_ITEMS,
                    search: event.target.value,
                  });
                }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            {t('addUser')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data.sort((a, b) =>
            a.instance.last_name.localeCompare(b.instance.last_name)
          )}
          pagination={false}
          loading={loading}
          className="employeesTable"
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={count}
          current={Number(query.page) || 1}
          pageSize={query.size || MIN_PAGINATION_ITEMS}
          onChange={(page, size) => setQuery({ ...query, page, size })}
          hideOnSinglePage={true}
          showSizeChanger={true}
          defaultCurrent={Number(query.page)}
        />
      </div>
    </SettingsLayout>
  );
};

export default UsersConfiguration;
