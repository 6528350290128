
import Status from "./status";

const columns = (t) => [
  {
    title: t('title'),
    dataIndex: 'name',
    key: 'name',
    render: name => name,
  },
  {
    title: t('status'),
    dataIndex: 'status',
    key: 'status',
    render: value => <Status value={value} />
  }
]

export default columns;
