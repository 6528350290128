// @ts-nocheck
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Modal, Pagination, Table } from 'antd';
import { CheckedIcon } from 'components/Icons';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../components/EmployeesForm';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import {
  askToDelete,
  createFailure,
  createRequest,
  deleteRequest,
  editRequest,
  getEventTypeRequest,
  getListRequest,
  openEditForm,
  setIsCreateFormOpen,
  setIsEditFormOpen,
} from '../../../store/actions/employees';
import { initForm } from '../../../store/actions/forms';
import { getListRequest as getRotationsList } from '../../../store/actions/rotations';
import {
  selectConfigList,
  selectConfigListCount,
  selectConfigListFetching,
  selectConfigTableData,
  selectCreationError,
  selectCreationFetching,
  selectDeletingFetching,
  selectEditingError,
  selectEditingFetching,
  selectIsCreateFormOpen,
  selectIsEditFormOpen,
  selectNominatedToRemoveItem,
} from '../../../store/selectors/employees';
import { selectFormValues } from '../../../store/selectors/forms';
import { selectConfigList as selectRotations } from '../../../store/selectors/rotations';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import useConfig from './form/config';
import { CREATE_FORM, EDIT_FORM } from './form/constants';
import submit from './form/submit';

const EmployeesConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const config = useConfig();
  const dispatch = useDispatch();
  const count = useSelector(selectConfigListCount);
  const { query, setQuery } = useQuery();
  const { t } = useTranslation();
  const isCreateFormOpen = useSelector(selectIsCreateFormOpen);
  const isEditFormOpen = useSelector(selectIsEditFormOpen);
  const nominatedToRemoving = useSelector(selectNominatedToRemoveItem);
  const list = useSelector(selectConfigList);
  const rotationList = useSelector(selectRotations);
  const loading = useSelector(selectConfigListFetching);
  const createFormValues = useSelector(selectFormValues(CREATE_FORM));
  const editFormValues = useSelector(selectFormValues(EDIT_FORM));
  const creationLoading = useSelector(selectCreationFetching);
  const editingLoading = useSelector(selectEditingFetching);
  const deletingLoading = useSelector(selectDeletingFetching);
  const createError = useSelector(selectCreationError);
  const editError = useSelector(selectEditingError);
  // eslint-disable-next-line no-undef
  const [error, setError] = useState();

  const columns = [
    {
      title: t('surname'),
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('patronymic'),
      dataIndex: 'patronymic',
      key: 'patronymic',
    },
    {
      title: t('personnelNumber'),
      dataIndex: 'service_number',
      key: 'service_number',
    },
    {
      title: t('mark'),
      dataIndex: 'mark',
      key: 'mark',
    },

    {
      title: t('organization'),
      dataIndex: 'organization_title',
      key: 'organization',
      render: (v) => (v ? v : t('notSpecified')),
    },
    {
      title: t('shift'),
      dataIndex: 'shift',
      key: 'shift',
      render: (shift) =>
        rotationList.find(({ id }) => id === shift)?.shift_name,
    },

    {
      title: t('shift') + ' 2',
      dataIndex: 'shift_second',
      key: 'shift_second',
      render: (shift_second) => {
        const currentShift = rotationList.find(({ id }) => id === shift_second);
        if (currentShift) return currentShift.shift_name;
      },
    },
    {
      title: t('position'),
      dataIndex: 'post',
      key: 'post',
    },
    {
      title: t('label'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('has_biometry'),
      dataIndex: 'has_biometry',
      key: 'has_biometry',
      render: (v) => (
        <div className="actions-wrapper"> {v ? <CheckedIcon /> : null} </div>
      ),
    },
    {
      title: t('has_special_values_of_biometrics'),
      dataIndex: 'has_special_values_of_biometrics',
      key: 'has_special_values_of_biometrics',
      render: (v) => (
        <div className="actions-wrapper"> {v ? <CheckedIcon /> : null} </div>
      ),
    },
    {
      title: t('violatorTerm'),
      dataIndex: 'violator_term',
      key: 'violator_term',
      render: (violator_term) => moment(violator_term).format('DD-MM-YYYY'),
    },
    {
      title: t('active'),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (v) => (
        <div className="actions-wrapper"> {v ? <CheckedIcon /> : null} </div>
      ),
    },
    {
      title: t('violator'),
      dataIndex: 'is_violator',
      key: 'is_violator',
      render: (is_violator) => (
        <div className="actions-wrapper">
          {is_violator ? <CheckedIcon /> : null}
        </div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={handleEditButtonClick(instance)}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={askToRemoveNotification(instance.id)}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  const data = useSelector(selectConfigTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(
    () => {
      loadData();
      dispatch(getEventTypeRequest());
      dispatch(initForm({ form: CREATE_FORM, config }));
      dispatch(initForm({ form: EDIT_FORM, config }));

      return () => {
        dispatch(setIsCreateFormOpen(false));
        dispatch(setIsEditFormOpen(false));
        dispatch(askToDelete(null));
      };
    },
    // Need to call this only once at render
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    dispatch(getRotationsList());
  }, []);

  useEffect(() => {
    if (
      isCreateFormOpen &&
      createError &&
      Object.keys(createError).length !== 0
    ) {
      setError(createError);
    }
    if (isEditFormOpen && editError && Object.keys(editError).length !== 0) {
      setError(createError);
    }
  }, [editError, createError, isEditFormOpen, isCreateFormOpen]);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  function loadData() {
    dispatch(
      getListRequest({
        page: query.page ?? 1,
        size: query.size ?? DEFAULT_PAGE_SIZE,
        search: query.search ?? '',
        isActive: query.isActive ?? false,
      })
    );
  }

  const handleClickCreate = () => {
    dispatch(createFailure({}));
    dispatch(setIsCreateFormOpen(true));
  };

  const handleCreateSubmit = useCallback(() => {
    const formData = submit(createFormValues);
    if (Array.from(formData.keys()).length) {
      dispatch(createRequest({ data: formData }));
    }
  }, [createFormValues, dispatch]);

  const handleEditSubmit = useCallback(() => {
    const { id, ...rest } = editFormValues;
    const instance = list.find((item) => item.id === id);
    const formData = submit(rest, instance);

    if (Array.from(formData.keys()).length) {
      dispatch(editRequest({ id, data: formData }));
    }
  }, [editFormValues, dispatch, list]);

  const handleEditButtonClick = (instance) => (e) => {
    e.preventDefault();
    dispatch(openEditForm({ instance }));
  };

  const handleRemoveNotification = (id) => (e) => {
    e.preventDefault();
    dispatch(deleteRequest({ id }));
  };

  const askToRemoveNotification = (id) => (e) => {
    e.preventDefault();
    dispatch(askToDelete(id));
  };

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div className="item">
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('searchByColumns')}
                inputProps={{ 'aria-label': 'search google maps' }}
                value={query.search ?? ''}
                onChange={(event) => {
                  setQuery({
                    ...query,
                    page: 1,
                    size: MIN_PAGINATION_ITEMS,
                    search: event.target.value,
                  });
                }}
              />
              <IconButton type="button" sx={{ p: '10px' }}>
                <SearchIcon />
              </IconButton>
            </Paper>
            <FormControl component="fieldset">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={
                      query.isActive
                        ? query.isActive === 'true'
                          ? true
                          : false
                        : false
                    }
                    onChange={(_, checked) => {
                      setQuery({ ...query, isActive: checked });
                    }}
                  />
                }
                label={t('onlyActivePosts')}
                labelPlacement="start"
              />
            </FormControl>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            {t('addEmployee')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data.sort((a, b) =>
            (a.surname ?? '').localeCompare(b.surname)
          )}
          pagination={false}
          loading={loading}
          className="employeesTable"
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={count}
          current={Number(query.page) || 1}
          pageSize={query.size || MIN_PAGINATION_ITEMS}
          onChange={(page, size) => setQuery({ ...query, page, size })}
          hideOnSinglePage={true}
          showSizeChanger={true}
          defaultCurrent={Number(query.page)}
        />
      </div>
      <FormDialog
        title={t('createEmpl')}
        visible={isCreateFormOpen}
        onClose={() => dispatch(setIsCreateFormOpen(false))}
        buttonText={creationLoading ? t('loading') + '...' : t('create')}
        form={CREATE_FORM}
        handleSubmit={handleCreateSubmit}
        loading={creationLoading}
        config={config}
        error={error}
      />
      <FormDialog
        title={t('editEmpL')}
        visible={isEditFormOpen}
        onClose={() => dispatch(setIsEditFormOpen(false))}
        buttonText={editingLoading ? t('loading') + '...' : t('edit')}
        form={EDIT_FORM}
        handleSubmit={handleEditSubmit}
        loading={editingLoading}
        config={config}
        error={error}
      />
      <Modal
        visible={Boolean(nominatedToRemoving)}
        onCancel={() => dispatch(askToDelete(null))}
        title={t('confirmAction')}
        okText={deletingLoading ? t('loading') + '...' : t('delete')}
        okButtonProps={{ color: 'red', loading: deletingLoading }}
        cancelText="Нет"
        onOk={handleRemoveNotification(nominatedToRemoving)}
      >
        <p>{t('sureWantToDeleteEmpl')}</p>
      </Modal>
    </SettingsLayout>
  );
};

export default EmployeesConfiguration;
