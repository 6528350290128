// @ts-nocheck
import { TOKEN } from 'consts/storageKeys';
import clearAuth from 'helpers/clearAuth';
import { request } from 'helpers/client';
import { getStorageItem } from 'helpers/storageHelpers';
import { useCallback, useEffect, useState } from 'react';
import { AppDataContext } from './current-user.context';

export function CurrentUserProvider(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [userData, setUserData] = useState({});
  const [macroscopData, setMacroscopData] = useState({
    url: '',
    login: '',
    password: '',
    width: '',
    height: '',
    loading: false,
  });

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    loadCurrentUserData();
    loadMacroscopData();
  }, []);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  const isAuthenticated = useCallback(() => {
    return !!getStorageItem(TOKEN);
  }, []);

  const loadCurrentUserData = useCallback(async () => {
    try {
      const authenticated = isAuthenticated();
      setUserData({
        isAuthenticated: authenticated,
      });
      if (!authenticated) return;
      const response = await request.get('/auth/me/');

      setUserData({
        ...response.data,
        isAuthenticated: authenticated,
      });
    } catch (error) {
      console.log({ error });
      clearAuth();
    }
  }, [isAuthenticated]);

  const loadMacroscopData = useCallback(async () => {
    setMacroscopData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const { data } = await request.get('/common/config/macroscop/');
    setMacroscopData((prev) => {
      return {
        ...data,
        loading: false,
      };
    });
  }, [isAuthenticated]);

  const postMacroscopData = useCallback(
    async (macroscop) => {
      setMacroscopData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const { data } = await request.post(
        '/common/config/macroscop/',
        macroscop
      );
      setMacroscopData((prev) => {
        return {
          ...data,
          loading: false,
        };
      });
    },
    [isAuthenticated]
  );

  // ---------------------------------------------------------------------------
  return (
    <>
      <AppDataContext.Provider
        value={{
          user: userData,
          macroscop: macroscopData,
          updateUserData: loadCurrentUserData,
          setMacroscopData: postMacroscopData,
          updateMacroscopData: loadCurrentUserData,
        }}
      >
        {props.children}
      </AppDataContext.Provider>
    </>
  );
}
