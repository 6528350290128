import client from '../helpers/client';

export const getAnchors = () =>
  client
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/anchors')
    .then((res) => res?.data?.items || [])
    .catch((e) => {
      console.error(e);
      return [];
    });
