import { Button } from 'antd';
import { useQuery } from 'hooks/query';
import { useTranslation } from 'react-i18next';

export function ReportButtonSet({ loading, filterParams, onResetFilters }) {
  const { query, setQuery } = useQuery();
  const { t } = useTranslation();
  return (
    <div className="reports-filter-confirm-container">
      <Button
        color="success"
        style={{
          backgroundColor: '#2e7d32',
          color: '#fff',
          fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
          fontWeight: 500,
          fontSize: '0.875rem',
        }}
        loading={loading}
        onClick={() => {
          const fromDate = new Date(filterParams.fromDate).getTime();
          const toDate = new Date(filterParams.toDate).getTime();
          if (filterParams.event_type === '_none') {
            filterParams.event_type = null;
          }
          setQuery({
            ...query,
            ...filterParams,
            page: 1,
            fromDate: fromDate !== 0 ? fromDate : null,
            toDate: toDate !== 0 ? toDate : null,
          });
        }}
      >
        {t('report.filter.apply')}
      </Button>
      <Button
        loading={loading}
        color="error"
        style={{
          backgroundColor: '#d32f2f',
          color: '#fff',
          fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
          fontWeight: 500,
          fontSize: '0.875rem',
        }}
        onClick={() => {
          onResetFilters();
          setQuery({
            tab: query.tab,
            page: query.page,
            size: query.size,
          });
        }}
      >
        {t('report.filter.reset')}
      </Button>
    </div>
  );
}
