import Buttons from './Buttons';
import './index.scss';

function MapButtons({
  showMapSettings,
  showFloors,
  zoomIn,
  zoomOut,
  disablePrevFloor,
  disableNextFloor,
}) {

  return (
    <>
      <Buttons
        showMapSettings={showMapSettings}
        showFloors={showFloors}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        disableNextFloor={disableNextFloor}
        disablePrevFloor={disablePrevFloor}
      />
    </>
  );
}

export default MapButtons;
