import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal, Pagination, Table } from 'antd';
import { useQuery } from 'hooks/query';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../components/SettingsFormDialog';
import { initForm, setFormInitialValues } from '../../../store/actions/forms';
import {
  askToDelete,
  createRequest,
  deleteRequest,
  getListRequest,
  setIsCreateFormOpen,
  setIsEditFormOpen,
  updateRequest
} from '../../../store/actions/markerConfigurations';
import { selectFormValues } from '../../../store/selectors/forms';
import {
  selectCreationError,
  selectCreationLoading,
  selectDeletingFetching,
  selectEditingLoading,
  selectIsCreateFormOpen,
  selectIsEditFormOpen,
  selectMarkerConfigList,
  selectMarkerConfigListFetching,
  selectMarkerTableData,
  selectNominatedToRemoveItem
} from '../../../store/selectors/markerConfigurations';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import useConfig from './form/config';
import { CREATE_FORM, EDIT_FORM } from './form/constants';
import submit from './form/submit';
import { getMarkerLabel } from './utils';

const Markers = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { query, setQuery } = useQuery();
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const config = useConfig()
  const list = useSelector(selectMarkerConfigList);
  const listLoading = useSelector(selectMarkerConfigListFetching);
  const isCreateFormOpen = useSelector(selectIsCreateFormOpen);
  const isEditFormOpen = useSelector(selectIsEditFormOpen);
  const isCreationLoading = useSelector(selectCreationLoading);
  const isEditingLoading = useSelector(selectEditingLoading);
  const createFormValues = useSelector(selectFormValues(CREATE_FORM));
  const editFormValues = useSelector(selectFormValues(EDIT_FORM));
  const nominatedToRemoving = useSelector(selectNominatedToRemoveItem);
  const deletingLoading = useSelector(selectDeletingFetching);
  const createError = useSelector(selectCreationError);
  const columns = [
    {
      title: t('markerType'),
      dataIndex: 'marker_type',
      key: 'marker_type',
      render: (code) => getMarkerLabel(code,t),
    },
    {
      title: t('icon'),
      dataIndex: 'icon',
      key: 'icon',
      render: (file) => (!file ? null : <Avatar src={file} />),
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={handleEditFormOpen(instance)}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={handleAskToDelete(instance.id)}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  const data = useSelector(selectMarkerTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(
    () => {
      dispatch(getListRequest());
      dispatch(initForm({ form: CREATE_FORM, config }));
      dispatch(initForm({ form: EDIT_FORM, config }));
    },
    // eslint-disable-next-line
    []
  );

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  const handleCreateFormOpen = () => {
    dispatch(setIsCreateFormOpen(true));
  };

  const handleEditFormOpen = (instance) => (e) => {
    e.preventDefault();
    dispatch(setFormInitialValues({ form: EDIT_FORM, instance }));
    dispatch(setIsEditFormOpen(true));
  };

  const handleCreateSubmit = useCallback(() => {
    const data = submit(createFormValues);

    if (Array.from(data.keys()).length) {
      dispatch(createRequest({ data }));
    }
  }, [createFormValues, dispatch]);

  const handleEditSubmit = useCallback(() => {
    const { id, ...rest } = editFormValues;
    const instance = list.find((item) => item.id === id);
    const data = submit(rest, instance);

    if (Array.from(data.keys()).length) {
      dispatch(updateRequest({ id, data }));
    }
  }, [editFormValues, dispatch, list]);

  const handleAskToDelete = (id) => () => {
    dispatch(askToDelete(id));
  };

  const handleDelete = (id) => () => {
    dispatch(deleteRequest({ id }));
  };

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="btn-create-wrapper">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateFormOpen}
          >
            {t('add')}
          </Button>
        </div>
        <Table
          loading={listLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <Pagination
          style={{ marginTop: '1rem' }}
          {...{
            total: 0,
            current: query.page || 1,
            pageSize: query.size || MIN_PAGINATION_ITEMS,
            onChange: (page, size) => setQuery({ ...query, page, size }),
            hideOnSinglePage: true,
            showSizeChanger: true,
            position: ['bottomLeft'],
            defaultCurrent: query.page,
          }}
        />
      </div>

      <FormDialog
        title={t}
        visible={isCreateFormOpen}
        onClose={() => dispatch(setIsCreateFormOpen(false))}
        buttonText={isCreationLoading ? t('loading') + '...' : t('create')}
        form={CREATE_FORM}
        handleSubmit={handleCreateSubmit}
        loading={isCreationLoading}
        config={config}
        error={createError}
      />
      <FormDialog
        title={t('editConfig')}
        visible={isEditFormOpen}
        onClose={() => dispatch(setIsEditFormOpen(false))}
        buttonText={isEditingLoading ? t('loading') +  '...' : t('edit')}
        form={EDIT_FORM}
        handleSubmit={handleEditSubmit}
        loading={isEditingLoading}
        config={config}
      />
      <Modal
        visible={Boolean(nominatedToRemoving)}
        onCancel={() => dispatch(askToDelete(null))}
        title={t('confirmAction')}
        okText={deletingLoading ? t('loading') +  '...' : t('delete')}
        okButtonProps={{ color: 'red', loading: deletingLoading }}
        cancelText="Нет"
        onOk={handleDelete(nominatedToRemoving)}
      >
        <p>{t('sureWantToDeleteConfig')}</p>
      </Modal>
    </SettingsLayout>
  );
};

export default React.memo(Markers);
