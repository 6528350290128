import { useTranslation } from 'react-i18next';

const useConfig = () => {
  const { t } = useTranslation();

  const options = [
    {
      name: 'working_days',
      label: t('workingDays'),
    },
    {
      name: 'days_of_the_month',
      label: t('daysOfTheMonth'),
    },
    {
      name: 'from_date_to_date',
      label: t('fromDateToDate'),
    },
    {
      name: 'x_through_y',
      label: t('wDAYD'),
    },
  ]

  return [
    {
      type: 'text',
      name: 'shift_name',
      label: t('shiftName'),
      required: true,
    },
    {
      type: 'text',
      name: 'description',
      label: t('description'),
      required: true,
    },
    {
      type: 'time-picker',
      name: 'start_time',
      label: t('startTime'),
      required: true,
    },
    {
      type: 'time-picker',
      name: 'end_time',
      label: t('endTime'),
      required: true,
    },
    {
      type: 'date-picker',
      name: 'start_date',
      label: t('dayOfBegin'),
      required: true,
    },
    {
      type: 'date-picker',
      name: 'end_date',
      label: t('expireDate'),
      required: true,
    },
    {
      type: 'boolean',
      name: 'is_active',
      label: t('active'),
      required: true,
    },
    {
      type: 'valueSet',
      selectType: 'raw',
      options,
      name: 'cyclicality',
      label: t('cyclicality'),
      required: true,
      getValueSetLoading: () => { },
    },
    {
      type: 'value_cycle',
      name: 'value_cycle',
      required: true,
    },
  ];
};

export default useConfig;
