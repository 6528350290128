import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import MapButtons from '../../components/MapButtons';
import Spinner from '../../components/Spinner';
import { clearMileagePoint } from '../../store/actions/reports';
import {
  getMapByIdRequest,
  getMapsListRequest,
} from '../../store/actions/svgMap';
import CRSMap from './components/CRSMap';
import MileageModal from './components/MileageModal';

import StackDrawer from '../../components/MapButtons/StackDrawer';
import UserDrawerSandwich from "../../context/user-drawer/user-drawer-sandwich.component";
import { zoomInMap, zoomOutMap } from '../../helpers/mapHelpers';
import { mapStore } from '../../model/MapStore';
import { setIsUserDrawerVisible } from '../../store/actions/labels';
import { makeSelectIsUserDrawerVisible } from '../../store/selectors/labels';
import { makeSelectGetMapsList } from '../../store/selectors/svgMap';
import './index.scss';

function ShowMap({
  getMapById,
  mileagePointFetching,
  mileagePointData,
  closeModal,
}) {
  const { mapId } = useParams();
  const isDrawerVisible = useSelector(makeSelectIsUserDrawerVisible);
  const mapList = useSelector(makeSelectGetMapsList);
  const [keeper, setKeeper] = useState();
  const [trackedKeeperId, setTrackedKeeperId] = useState();
  const [disableNextFloor, setDisableNexFloor] = useState(false);
  const [disablePrevFloor, setDisablePrevFloor] = useState(false);
  const [isMapSettingsVisible, setIsMapSettingsVisible] = useState(false);
  const [map, setMap] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/sandwich') {
      closeDrawer();
    }
  }, [location.pathname]);

  const closeDrawer = () => {
    setKeeper(null);
    dispatch(setIsUserDrawerVisible(false));
  };

  const showDrawer = () => {
    dispatch(setIsUserDrawerVisible(true));
  };

  const handleClickKeeper = (keeper) => {
    setKeeper(keeper);
    if (keeper) {
      showDrawer();
    } else {
      closeDrawer();
    }
  };

  const zoomIn = () => {
    if (map) {
      zoomInMap({ map });
    }
  };

  const zoomOut = () => {
    if (map) {
      zoomOutMap({ map });
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const checkHasFloors = () => {
    const currentMap = mapList?.find((item) => item.id === mapId);

    const currentSandwich = mapList?.length
      ? mapList.filter(
          (item) => item?.category?.id === currentMap?.category?.id
        )
      : [];
    const nextFloors = currentSandwich?.length
      ? currentSandwich.filter((item) => item.floor > currentMap.floor)
      : [];
    const prevFloors = currentSandwich?.length
      ? currentSandwich.filter((item) => item.floor < currentMap.floor)
      : [];

    setDisableNexFloor(!nextFloors.length);
    setDisablePrevFloor(!prevFloors.length);
  };

  const onSelectKeeperToTrack = (id) => {
    setTrackedKeeperId(id);
  };

  useEffect(() => {
    mapStore.setMapId(mapId);
  }, [mapId]);

  useEffect(() => {
    if (mapList) {
      checkHasFloors();
    }
  }, [mapId, mapList]);

  useEffect(() => {
    dispatch(getMapsListRequest({ size: 1000 }));
    getMapById(mapId);
  }, [mapId]);

  return (
    <>
      <UserDrawerSandwich
        visible={isDrawerVisible}
        keeper={keeper}
        onClose={closeDrawer}
        onSelectKeeperToTrack={onSelectKeeperToTrack}
      />
      {mileagePointFetching && <Spinner />}
      <CRSMap
        selectedKeeper={keeper}
        trackedKeeperId={trackedKeeperId}
        handleClickKeeper={handleClickKeeper}
        showDrawer={showDrawer}
        map={map}
        setMap={setMap}
        mapIdUrl={mapId}
      />
      <MapButtons
        showMapSettings={() => setIsMapSettingsVisible(true)}
        showFloors={true}
        disableNextFloor={disableNextFloor}
        disablePrevFloor={disablePrevFloor}
        zoomOut={zoomOut}
        zoomIn={zoomIn}
      />
      {mileagePointData && (
        <MileageModal
          mileagePointData={mileagePointData}
          handleCancel={handleCancel}
        />
      )}

      <StackDrawer
        visible={isMapSettingsVisible}
        setVisible={setIsMapSettingsVisible}
        map={map}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    mileagePointFetching: state.reports.mileagePointState.fetching,
    mileagePointData: state.reports.mileagePointState.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMapById: (mapId) => dispatch(getMapByIdRequest(mapId)),
    closeModal: () => dispatch(clearMileagePoint()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowMap);
