export const STATE_KEY = '@USERS';

export const GET_LIST_REQUEST = `${STATE_KEY}_GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${STATE_KEY}_GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${STATE_KEY}_GET_LIST_FAILURE`;

export const getListRequest = (payload = {}) => ({
  type: GET_LIST_REQUEST,
  payload,
});
export const getListSuccess = (payload) => ({
  type: GET_LIST_SUCCESS,
  payload,
});
export const getListFailure = (payload) => ({
  type: GET_LIST_FAILURE,
  payload,
});
