/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import L from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { buildFormData } from 'helpers/formData';
import { MapData } from 'model/MapData';
import { categoriesRequest } from 'store/actions/categories';
import {
  editMapRequest,
  setGetSingleMapData,
  uploadMapRequest,
} from 'store/actions/svgMap';
import { makeSelectGetSingleMap } from 'store/selectors/svgMap';

import { useTranslation } from 'react-i18next';
import { CRSMap } from './components/CRSMap/CRSMap';
import { SideBar } from './components/SideBar/SideBar';
import './index.scss';

const convertFromMeters = (data, ratio) => {
  const { bounds, point1, x, y } = data || {};
  const { lng: x0, lat: y0 } = point1 || {};
  if (
    x0 !== undefined &&
    y0 !== undefined &&
    x !== undefined &&
    y !== undefined &&
    ratio !== undefined &&
    bounds?.length
  ) {
    return bounds.map(([lat, lng]) => [
      (lat - y) * ratio + y0,
      (lng - x) * ratio + x0,
    ]);
  }
  return [];
};

const convertToMeters = (coords, x0, y0, x0m, y0m, ratio) => {
  if (
    coords?.length &&
    x0 !== undefined &&
    y0 !== undefined &&
    x0m !== undefined &&
    y0m !== undefined &&
    ratio !== undefined
  ) {
    return coords.map(([lat, lng]) => [
      (lat - y0) / ratio + +y0m,
      (lng - x0) / ratio + +x0m,
    ]);
  }
  return [];
};

const UploadMap = ({ newMap: isNewMap }) => {
  const { t } = useTranslation();

  const submitRef = useRef(null);
  const { mapId } = useParams();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState();
  const [coordinatesFromValues, setCoordinatesFormValues] = useState({});
  const [pointA, setPointA] = useState();
  const [pointL0, setPointL0] = useState();
  const [pointL1, setPointL1] = useState();
  const [ratio, setRatio] = useState();
  const [bounds, setBounds] = useState();
  const [boundsEditingMode, setBoundsEditingMode] = useState(false);
  const [boundsCoords, setBoundsCoords] = useState();
  const [segmentLength, setSegmentLength] = useState();

  const stateMapData = useSelector(makeSelectGetSingleMap);
  const [mapData, setMapData] = useState();

  useEffect(() => {
    const isCurrentRouteMap =
      !mapData && stateMapData && stateMapData.id === mapId;
    const isNewMap = mapData && mapData.id !== stateMapData.id;
    if (isCurrentRouteMap || isNewMap) {
      setMapData(isNewMap ? null : stateMapData);
    }

  }, [isNewMap, stateMapData, mapData]);

  useEffect(() => {
    if (mapData && !bounds) {
      const { min_x = 0, min_y = 0, max_x = 0, max_y = 0 } = mapData;
      if (max_x && max_y) {
        setBounds([
          { lat: min_y, lng: min_x },
          { lat: max_y, lng: max_x },
        ]);
      }
    }
  }, [bounds, mapData]);

  useEffect(() => {
    if (mapData && ratio !== undefined && !boundsCoords) {
      setBoundsCoords(convertFromMeters(mapData, ratio));
    }
  }, [boundsCoords, mapData, ratio]);

  useEffect(() => {
    const { lat, lng } = mapData?.point1 || {};
    if (lat !== undefined && lng !== undefined && !pointA) {
      setPointA(L.latLng([lat, lng]));
    }
  }, [pointA, mapData?.point1]);

  useEffect(() => {
    const { lat, lng } = mapData?.point2 || {};
    if (lat !== undefined && lng !== undefined && !pointL0) {
      setPointL0(L.latLng([lat, lng]));
    }
  }, [pointL0, mapData?.point2]);

  useEffect(() => {
    const { lat, lng } = mapData?.point3 || {};
    if (lat !== undefined && lng !== undefined && !pointL1) {
      setPointL1(L.latLng([lat, lng]));
    }
  }, [pointL1, mapData?.point3]);

  useEffect(() => {
    if (mapData?.segment_length && !segmentLength) {
      setSegmentLength(mapData.segment_length);
    }
  }, [segmentLength, mapData]);

  const onSubmitName = (values) => {
    onSubmit({ ...values, ...coordinatesFromValues });
  };

  const uploadMap = (formData) => {
    dispatch(uploadMapRequest(formData));
  };

  const getCategoriesList = useCallback(() => {
    dispatch(categoriesRequest());
  }, [dispatch]);

  const onSubmitCoordinates = (values) => {
    setCoordinatesFormValues(values);
    submitRef.current.click();
  };

  const onSubmit = (values) => {
    const { x, y, segment_length } = values;

    let newMapData;
    if (!ratio) {
      newMapData = new MapData({
        point2: pointL0,
        point3: pointL1,
        segment_length,
      });
      newMapData.setRatio();
    }

    const result = {
      ...values,
      min_x: bounds?.[0]?.lng || 0,
      min_y: bounds?.[0]?.lat || 0,
      max_x: bounds?.[1]?.lng || 0,
      max_y: bounds?.[1]?.lat || 0,
      ratio: ratio || 0,
    };

    const formData = new FormData();

    if (pointA) formData.append('point1', JSON.stringify(pointA));
    if (pointL0) formData.append('point2', JSON.stringify(pointL0));
    if (pointL1) formData.append('point3', JSON.stringify(pointL1));
    if (boundsCoords) {
      formData.append(
        'bounds',
        JSON.stringify(
          convertToMeters(
            boundsCoords,
            pointA?.lng || 0,
            pointA?.lat || 0,
            x || 0,
            y || 0,
            ratio || newMapData?.ratio
          )
        )
      );
    }
    if (imageFile) formData.append('file', imageFile);

    Object.entries(result).forEach(([key, value]) => {
      buildFormData(formData, value, key);
    });

    if (!!mapId) {
      dispatch(editMapRequest({ formData, id: mapId }));
    } else {
      uploadMap({ formData });
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  useEffect(() => {
    return () => {
      dispatch(setGetSingleMapData(null));
    };
  }, [dispatch]);

  const image = useMemo(() => {
    if (mapData?.file) {
      const list = mapData.file.split('/');
      if (list[1] === 'media') {
        const host =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_BACK_END_URL
            : window.location.origin;
        mapData.file = `${host}${mapData.file}`;
      }
    }
    return imageFile ? URL.createObjectURL(imageFile) : mapData?.file;
  }, [imageFile, mapData]);

  return (
    <div className="content">
      <SideBar
        boundsCoords={boundsCoords}
        boundsEditingMode={boundsEditingMode}
        imageFile={imageFile}
        newMap={isNewMap}
        onSubmit={onSubmitName}
        onSubmitCoordinates={onSubmitCoordinates}
        setBoundsEditingMode={setBoundsEditingMode}
        setImageFile={setImageFile}
        setSegmentLength={setSegmentLength}
        submitRef={submitRef}
      />
      <div className="content-page">
        {imageFile || !!mapData ? (
          <CRSMap
            bounds={bounds}
            boundsCoords={boundsCoords}
            boundsEditingMode={boundsEditingMode}
            image={image}
            pointA={pointA}
            pointL0={pointL0}
            pointL1={pointL1}
            ratio={ratio}
            segmentLength={segmentLength}
            setBounds={setBounds}
            setBoundsCoords={setBoundsCoords}
            setBoundsEditingMode={setBoundsEditingMode}
            setPointA={setPointA}
            setPointL0={setPointL0}
            setPointL1={setPointL1}
            setRatio={setRatio}
          />
        ) : (
          <h4>{t('siftplfcsc')}</h4>
        )}
      </div>
    </div>
  );
};

export default UploadMap;
