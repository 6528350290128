export const GET_MILEAGE_POINT_REQUEST = 'GET_MILEAGE_POINT_REQUEST';
export const GET_MILEAGE_POINT_SUCCESS = 'GET_MILEAGE_POINT_SUCCESS';
export const GET_MILEAGE_POINT_FAILURE = 'GET_MILEAGE_POINT_FAILURE';
export const CLEAR_MILEAGE_POINT = 'CLEAR_MILEAGE_POINT';

export const getMileagePointRequest = (payload) => ({
  type: GET_MILEAGE_POINT_REQUEST,
  payload,
});

export const getMileagePointSuccess = (payload) => ({
  type: GET_MILEAGE_POINT_SUCCESS,
  payload,
});

export const getMileagePointFailure = (payload) => ({
  type: GET_MILEAGE_POINT_FAILURE,
  payload,
});

export const clearMileagePoint = () => ({ type: CLEAR_MILEAGE_POINT });
