/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Drawer } from 'antd';
import { useQuery } from 'hooks/query';
import { t } from 'i18next';
import { mapStore } from 'model/MapStore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toShowMapId } from '../../consts/routes';
import { request } from '../../helpers/client';
import { errorNotification } from '../../helpers/notificationHelpers';
import { UserDrawer } from './user-drawer.component';
import { UserDrawerContext } from './user-drawer.context';
import { useCurrentKeeper } from './user-drawer.hook';

export function UserDrawerProvider(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const defaultOptions = useRef({
    onClose: () => {},
    width: '600px',
    placement: 'left',
    className: '',
    onSelectKeeperToTrack: () => {},
    keeper: {
      holder: undefined,
      instance: undefined,
    },
  });
  const { setQuery } = useQuery();
  const [isRendered, setRendered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(defaultOptions.current);
  const { keeper, clearKeeperData } = useCurrentKeeper({
    keeperInstance: options.keeper.instance,
    holder: options.keeper.holder,
  });
  const history = useHistory();
  // ---------------------------------------------------------------------------
  // memo callbacks
  // ---------------------------------------------------------------------------


  useEffect(() => {
    if (!keeper || keeper.type !== "EMPLOYEE" || !keeper.employees[0]) return;
    mapStore.setSelectedKeeperId(keeper.id);
    request
      .get(`/employees/${keeper.employees[0].id}/get_located_layouts`)
      .then((response) => {
        const data = response.data;
        if (data.length === 0) {
          errorNotification(t('notOnMaps'));
          return;
        }
        const firstMap = data[0];
        history.push(toShowMapId(firstMap.id));
      });
  }, [keeper, history]);

  const openFn = useCallback((options) => {
    setRendered(true);
    setIsOpen(true);
    if (options)
      setOptions((prev) => {
        return { ...prev, ...options };
      });
  }, []);

  const closeFn = useCallback(() => {
    setIsOpen(false);
    options.onClose();
    setTimeout(() => {
      setRendered(false);
    }, 300);
    setOptions(defaultOptions.current);
    clearKeeperData();
  }, [options, defaultOptions]);

  const updateOptions = useCallback((options) => {
    setOptions((prev) => {
      return { ...prev, ...options };
    });
  }, []);

  // ---------------------------------------------------------------------------
  return (
    <UserDrawerContext.Provider
      value={{
        open: openFn,
        close: closeFn,
        updateOptions,
        isOpened: isOpen,
        keeper,
      }}
    >
      {isRendered && keeper && (
        <Drawer
          open={isOpen}
          placement={options.placement}
          closable={true}
          className={options.className}
          onClose={() => {
            clearKeeperData();
            closeFn();
            setQuery({ showKeeperCamera: null });
          }}
        >
          <UserDrawer
            keeperInstance={keeper}
            onSelectKeeperToTrack={options.onSelectKeeperToTrack}
          />
        </Drawer>
      )}

      {props.children}
    </UserDrawerContext.Provider>
  );
}
