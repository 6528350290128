import { CloseOutlined } from '@ant-design/icons';
import {
  Form as BaseForm,
  Input,
  Select,
  Switch
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFormValue } from '../../store/actions/forms';
import { selectFormValues } from '../../store/selectors/forms';

import { errorMessage } from '../../helpers/errorHandlers';

import { useTranslation } from 'react-i18next';
import store from '../../store';

const Form = ({ name, loading, config, error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formValues = useSelector(selectFormValues(name));
  const [isEmailSendEnable, setIsEmailSendEnable] = useState(false);
  const [emailAdresses, setEmailAddresses] = useState([
    { index: '1232', value: '' },
  ]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailAddresses([]);
  };

  const handleFieldUpdate = (field, value) => {
    dispatch(
      setFormValue({
        form: name,
        field,
        value: value,
      })
    );
  };

  const handleFileFieldChange = (field, type) => (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (type.startsWith('image')) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event) => {
        handleFieldUpdate(field, {
          file,
          url: event.target.result,
        });
      };
    } else {
      handleFieldUpdate(field, {
        file,
        url: file.name,
      });
    }
  };

  const getValueSetLoading = (item) => {
    if (item.selectType === 'raw') return false;
    return item.getValueSetLoading(store.getState());
  };

  const getOptions = (item) => {
    if (item.selectType === 'raw') return item.options;
    return item.getValueSet(store.getState());
  };

  const { fields: errors } = errorMessage(error);

  return (
    <BaseForm
      onSubmit={handleSubmit}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      {config.map((item, idx) => {
        const key = `FORM_${name}_${item.name}_FIELD_${idx}`;
        const value = formValues[item.name];

        // const regularNotifications = [
        //   'MAN_DOWN',
        //   'MAN_DOWN_CANCEL',
        //   'BATTERY_LOW',
        //   'WRONG_ORIENTATION',
        //   'NO_SIGNAL',
        // ];
        // const dangerNotifications = [
        //   'FREE_FALL',
        //   'danger_zone_entrance',
        //   'BUTTON_PRESSED',
        // ];

        // let notificationType = '';
        // if (item.type === 'disabled') {
        //   if (regularNotifications.includes(formValues.event_type)) {
        //     notificationType = t('popupNotification');
        //   } else if (dangerNotifications.includes(formValues.event_type)) {
        //     notificationType = t('blockingNotification');
        //   } else {
        //     notificationType = t('notSpecified');
        //   }
        // }

        if (item.type === 'file') {
          return (
            <BaseForm.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              key={key}
              required={item.require}
            >
              {value && value.url ? (
                (item.accept || '').startsWith('image') ? (
                  <div className="image-field-preview">
                    <CloseOutlined
                      className="remove"
                      onClick={() =>
                        handleFieldUpdate(item.name, { file: null, url: null })
                      }
                    />
                    <img src={value.url} alt={item.name} />
                  </div>
                ) : (
                  <div className="audio-field-preview">
                    <p>Выбрано:</p>
                    <span className="link-chip">
                      <span className="link-el">{value.url}</span>
                      <CloseOutlined
                        className="close"
                        onClick={() =>
                          handleFieldUpdate(item.name, {
                            file: null,
                            url: null,
                          })
                        }
                      />
                    </span>
                  </div>
                )
              ) : (
                <Input
                  type="file"
                  shape="rounded"
                  onChange={handleFileFieldChange(item.name, item.accept)}
                  accept={item.accept}
                  disabled={loading}
                  required={item.require}
                />
              )}
            </BaseForm.Item>
          );
        }

        if (item.type === 'boolean') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.require}
            >
              <Switch
                checked={value}
                onChange={(checked) => handleFieldUpdate(item.name, checked)}
                loading={loading}
              />
            </BaseForm.Item>
          );
        }

        return (
          <BaseForm.Item
            label={item.label}
            rules={[
              { required: Boolean(item.required), message: t('enterText') },
            ]}
            className="form-row"
            key={key}
            initialValue={value}
            validateStatus={errors[item.name] ? 'error' : ''}
            help={errors[item.name]}
            required={item.require}
          >
            {item.type === 'valueSet2' ? (
              <Select
                shape="round"
                backfill={false}
                onChange={(selected) => handleFieldUpdate(item.name, selected)}
                value={value}
              >
                {Object.keys(item.data).map((key, index) => (
                  <Select.Option
                    key={`FORM_${name}_SELECT_OPTION_${index}`}
                    value={key}
                  >
                    {item.data[key]}
                  </Select.Option>
                ))}
              </Select>
            ) : item.type === 'valueSet' ? (
              <Select
                shape="round"
                backfill={false}
                onChange={(selected) => handleFieldUpdate(item.name, selected)}
                value={value}
                loading={getValueSetLoading(item) || loading}
              >
                {getOptions(item).map((option, index) => (
                  <Select.Option
                    key={`FORM_${name}_SELECT_OPTION_${index}`}
                    value={option.name}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            ) : item.type === 'textarea' ? (
              <Input.TextArea
                shape="round"
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                value={value}
                disabled={loading}
                required={item.require}
              />
            ) : (
              <Input
                type={item.name === 'password' ? 'password' : 'text'}
                shape="round"
                // name={item.name}
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                value={value}
                disabled={loading}
                required={item.require}
              />
            )}
          </BaseForm.Item>
        );
      })}

      {/* <Checkbox
        style={{
          marginBottom: '1rem',
        }}
        onChange={() => setIsEmailSendEnable(!isEmailSendEnable)}
      >
        {t('allowEmailDistr')}
      </Checkbox>
      {emailAdresses.map((item, key) => (
        <BaseForm.Item
          key={item.index}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          label={t('email') + ' ' + (key + 1)}
          className="form-row"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Input
              key={key}
              type="email"
              onChange={(e) => {
                const updated = [];
                for (let _item of emailAdresses) {
                  if (_item.index === item.index) {
                    _item.value = e.target.value;
                    updated.push(_item);
                  } else {
                    updated.push(_item);
                  }
                }
                setEmailAddresses(updated);
              }}
              value={item.value}
              disabled={!isEmailSendEnable}
            />
            <Tooltip
              title={
                emailAdresses.length === 1
                  ? t('yCantDelLastEntry')
                  : t('deleteEntry')
              }
            >
              <Button
                onClick={() => {
                  if (emailAdresses.length === 1) return;
                  setEmailAddresses(
                    emailAdresses.filter((_item) => {
                      return item.index !== _item.index;
                    })
                  );
                }}
                style={{ marginLeft: '0.5rem' }}
                icon={<CloseOutlined />}
                shape="circle"
              />
            </Tooltip>
          </div>
        </BaseForm.Item>
      ))}
      <Button
        disabled={!isEmailSendEnable}
        onClick={() =>
          setEmailAddresses([
            ...emailAdresses,
            { index: uniqueId(), value: '' },
          ])
        }
      >
        {t('addEmail')}
      </Button> */}
    </BaseForm>
  );
};

export default React.memo(Form);
