import { normalizeInstance } from '../../helpers/table';

const selectState = state => state.markerConfigurations;

export const selectMarkerConfigList = state => selectState(state).list.data;
export const selectMarkerTableData = (columns = []) => state => {
  const data = selectMarkerConfigList(state) || [];
  return data.map(normalizeInstance(columns));
};
export const selectMarkerConfigListFetching = state => selectState(state).list.fetching;

export const selectIsCreateFormOpen = state => selectState(state).isCreateFormOpen;
export const selectIsEditFormOpen = state => selectState(state).isEditFormOpen;

export const selectCreationLoading = state => selectState(state).creation.fetching;
export const selectCreationError = state => selectState(state).creation.error;
export const selectEditingLoading = state => selectState(state).editing.fetching;
export const selectEditingError = state => selectState(state).editing.error;

export const selectNominatedToRemoveItem = state => selectState(state).deleting.selected;
export const selectDeletingFetching = state => selectState(state).deleting.fetching;

