import { Space, Typography } from 'antd';
import { getPointOnMapPathNotification } from 'consts/routes';
import { request } from 'helpers/client';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snakeToCamel } from '../../../helpers/notificationHelpers';
import cicrle_loading from './../../../assets/images/circle.svg';

export function NotificationBodyReportExportCancel({ item }) {
  const { t } = useTranslation();
  let label = item.params.queue.name;
  if (item.params.queue.name === 'anchor_event') {
    label = t('anchorReport');
  } else if (item.params.queue.name === 'Employee_interaction_report') {
    label = t('interactionReport');
  }
  return (
    <Space direction={'vertical'} style={{ padding: 5 }}>
      <Typography.Text>{label}</Typography.Text>

      <Typography.Text>
        {t('time')}:
        {moment(item.params.queue.created_at).format('DD.MM.YYYY HH:mm:ss')}
      </Typography.Text>
      {item.params.queue.name && (
        <Typography.Text>
          {t('title')}: {t(`report.${snakeToCamel(item.params.queue.name)}`)}
        </Typography.Text>
      )}
      <Typography.Text>{t('cancelAnErrorOccurred')}</Typography.Text>

      <Typography.Text>{item.params.status_information}</Typography.Text>
    </Space>
  );
}

export function NotificationBody({ item }) {
  const { t } = useTranslation();

  return (
    <Space direction={'vertical'} style={{ padding: 5 }}>
      {item.time && (
        <Typography.Text>
          {t('time')}: {moment(item.time).format('DD.MM.YYYY HH:mm:ss')}
        </Typography.Text>
      )}
      {item.owner && (
        <Typography.Text>
          {t('fullName')}: {item.owner}
        </Typography.Text>
      )}
      {item.links?.map && (
        <Typography.Text>
          {t('map')}: {item.links?.map?.label}
        </Typography.Text>
      )}
      {item.params?.area_name && (
        <Typography.Text>
          {t('zone')}: {item.params?.area_name}
        </Typography.Text>
      )}
      {item.associated_layouts && item.associated_layouts.length !== 0 && (
        <Typography.Text
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            const path = getPointOnMapPathNotification(
              item.associated_layouts[0].id,
              item.params,
              item.id
            );
            if (path) {
              window.open(path, '_blank');
            }
          }}
        >
          {t('map')}:&nbsp;{item.associated_layouts[0].name}
        </Typography.Text>
      )}
    </Space>
  );
}

export function NotificationBodyReportExportDone({ item }) {
  const { t } = useTranslation();

  return (
    <Space direction={'vertical'} style={{ padding: 5 }}>
      <Typography.Text>
        {t('time')}:
        {moment(item.params.queue.created_at).format('DD.MM.YYYY HH:mm:ss')}
      </Typography.Text>
      {item.params.queue.name && (
        <Typography.Text>
          {t('title')}: {t(`report.${snakeToCamel(item.params.queue.name)}`)}
        </Typography.Text>
      )}

      <Typography.Text>
        {t('excelReportRows', {
          val: item.params.queue.extra_information.rows_count,
        })}
      </Typography.Text>

      <Typography.Text
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => window.open(item.params.queue.file, '_blank')}
      >
        {t('linkToFile')}
      </Typography.Text>
    </Space>
  );
}

export function NotificationBodyReportExportInProcess({ item }) {
  const timerRef = useRef(null);
  const { t } = useTranslation();
  const [queueStatus, setQueueStatus] = useState(item.params.queue.status);
  const [counter, setCounter] = useState(0);

  const updateStatus = async () => {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports_export_queue/${item.params.queue.id}/`,
      {}
    );
    const data = response.data;
    if (data) {
      setQueueStatus(data.status);
    }
  };

  useEffect(() => {
    if (counter > 50) {
      setQueueStatus('DONE');
    }
    if (queueStatus === 'WAITING' && counter > 0) {
      updateStatus();
    }
  }, [counter]);

  useEffect(() => {
    if (queueStatus === 'WAITING' && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [queueStatus]);

  useEffect(() => {

    if (!item.params?.queue?.created_at) {
      setQueueStatus('DONE');
      return;
    }

    const diff =
      new Date().getTime() / 1000 - moment(item.params.queue.created_at).unix();
    if (diff < 180 && queueStatus === 'WAITING') {
      timerRef.current = setInterval(() => {
        setCounter(counter + 1);
      }, 3000);
      return () => clearInterval(timerRef.current);
    } else {
      setQueueStatus('DONE');
    }
  });

  return (
    <Space direction={'vertical'} style={{ padding: 5 }}>
      <Typography.Text>
        {t('time')}:
        {moment(item.params.queue.created_at).format('DD.MM.YYYY HH:mm:ss')}
      </Typography.Text>
      {item.params.queue.name && (
        <Typography.Text>
          {t('title')}: {t(`report.${snakeToCamel(item.params.queue.name)}`)}
        </Typography.Text>
      )}
      {queueStatus === 'WAITING' ? (
        <Typography.Text>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img
              src={cicrle_loading}
              style={{ width: '30px', height: '30px' }}
            />
            <div>Пожалуйста подождите {counter % 2 === 0 ? '..' : '....'}</div>
          </div>
        </Typography.Text>
      ) : (
        ''
      )}

      {/*<Typography.Text>*/}
      {/*  {t('excelReportRows', {*/}
      {/*    val: item.params.queue.extra_information.rows_count,*/}
      {/*  })}*/}
      {/*</Typography.Text>*/}

      {/*<Typography.Text*/}
      {/*  style={{ cursor: 'pointer', textDecoration: 'underline' }}*/}
      {/*  onClick={() => window.open(item.params.queue.file, '_blank')}*/}
      {/*>*/}
      {/*  {t('linkToFile')}*/}
      {/*</Typography.Text>*/}
    </Space>
  );
}
