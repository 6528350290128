import React, { useCallback, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { connect } from 'react-redux';

import { searchRequest } from '../../../store/actions/search';
import { ANCHORS, useObjectTypeOptions } from '../../../consts/options';

import '../index.css';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function SearchForm({ search, objectType, setObjectType }) {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const objectTypeOptions = useObjectTypeOptions()
  // ------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    sendSearchRequest({ type: objectType });
  }, [objectType]); // eslint-disable-line

  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------

  const sendSearchRequest = ({ type = objectType, searchValue = '' }) => {
    const params =
      objectType === ANCHORS ? { sn: searchValue } : { search: searchValue };

    search({
      slug: type,
      params : {
        size : 1000,
        ...params
      },
    });
  };

  const handleSelect = (value) => {
    form.resetFields();
    setObjectType(value);
    sendSearchRequest({ type: value });
  };

  const handleSearchChange = useCallback(
    (e) => {
      const searchValue = e.target.value;

      sendSearchRequest({ type: objectType, searchValue });
    },
    [objectType]
  );

  return (
    <div className="search-popover-wrapper">
      <div className="search-popover-item-left">
        <p>{t('objectType')}</p>
        <Select
          defaultValue={objectType}
          style={{ width: 120 }}
          onChange={handleSelect}
        >
          {objectTypeOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="search-popover-item-right">
        <p>{t('objectName')}</p>
        <Form form={form}>
          <Form.Item name="search">
            <Input
              placeholder={t('enterToSearch')}
              onChange={handleSearchChange}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (arg) => {
      dispatch(searchRequest(arg));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
