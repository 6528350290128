import { normalizeInstance } from '../../helpers/table';
import { safeGet } from '../../helpers/getters';

const getState = (state) => safeGet(state, 'users', {});

export const selectConfigList = (state) =>
  safeGet(getState(state), 'list.data', []);

export const selectConfigTableData =
  (columns = []) =>
  (state) => {
    const data = selectConfigList(state) || [];
    return data.map(normalizeInstance(columns));
  };

export const selectConfigListFetching = (state) =>
  safeGet(getState(state), 'list.fetching');

export const selectConfigListCount = (state) =>
  safeGet(getState(state), 'list.count', 0);

export const selectEventTypesFetching = (state) =>
  safeGet(getState(state), 'eventTypes.fetching');
