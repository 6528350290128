import {
  SET_OWS_DATA,
  SET_OWS_FETCHING,
  SET_OWS_ERROR,
  RESET_OWS_DATA,
  SET_KEEPER_COORDINATE,
} from '../actions/ows';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  owsState: reqState,
  keeperCoordinate: null,
};

export default function ows(state = initialState, { type, payload }) {
  switch (type) {
    case SET_OWS_FETCHING:
      return {
        ...state,
        owsState: { ...state.owsState, fetching: payload },
      };
    case SET_OWS_DATA:
      return {
        ...state,
        owsState: { ...state.owsState, data: payload },
      };
    case SET_OWS_ERROR:
      return {
        ...state,
        owsState: { ...state.owsState, error: payload },
      };
    case RESET_OWS_DATA:
      return {
        ...state,
        owsState: reqState,
      };

    case SET_KEEPER_COORDINATE:
      return { ...state, keeperCoordinate: payload };
    default:
      return state;
  }
}
