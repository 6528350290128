import React from 'react';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 8,
  },
  on: {
    backgroundColor: '#57e04e',
  },
  off: {
    backgroundColor: '#ff5c5c',
  },
};

const Status = ({ value }) => {
  const status = value ? 'on' : 'off';
  return (
    <div style={styles.wrapper}>
      <span style={{ ...styles.icon, ...styles[status] }} />
      {status}
    </div>
  );
};

export default Status;
