export const getListZ = (keepers) => {
  return keepers
    .map((keeper) => {
      const { _embedded } = keeper;
      if (!_embedded) return;
      const { tag } = _embedded;
      if (!tag) return;
      return tag.z;
    })
    .filter((z) => z);
};

export const getAmountAmong = (listZ, start, limit = null) => {
  return listZ.reduce((counter, z) => {
    if (z >= start) {
      if (limit) {
        if (z < limit) {
          return counter + 1;
        } else {
          return counter;
        }
      } else {
        return counter + 1;
      }
    } else {
      return counter;
    }
  }, 0);
};

export const updateSandwichAmount = (keepers, sandwichList) => {
  const listZ = getListZ(keepers);
  const [first] = sandwichList;
  const list = first.list;
  const length = list.length;

  const start = list[0].height;
  const amount = getAmountAmong(listZ, start);
  list[0].amount = amount;

  for (let i = 1; i < length; i++) {
    const limit = list[i - 1].height;
    const start = list[i].height;
    const amountIn = getAmountAmong(listZ, start, limit);
    list[i].amount = amountIn;
  }

  return sandwichList;
};

export const filterMapsByCategoryId = (maps, categoryId) => {
  const filtered = maps.filter(
    ({ category }) => category && category.id === categoryId
  );
  return filtered.sort((a, b) => a.floor + b.floor);
};

export const groupMapsByCategories = (maps, categories) => {
  return categories.map((category) => {
    return { ...category, list: filterMapsByCategoryId(maps, category.id) };
  });
};

export const sortSandwichesByOrder = (list) => {
  return list.sort((a, b) => {
    return a.order - b.order;
  });
};
