export const handleRemoveAllMarkers = (
  {
    markerInstance,
    position,
    map,
    createType,
    cameraMarker,
    deviceMarker,
    setIsMarkerPlacing,
    setCameraMarker,
    setDeviceMarker,
  }
) => {
  if (!!cameraMarker?.marker) {
    map.removeLayer(cameraMarker.marker);
  }
  if (!!deviceMarker?.marker) {
    map.removeLayer(deviceMarker.marker);
  }

  if(createType === 'with-camera'){
    setCameraMarker({marker: markerInstance, latlng: position});
  } else if(createType === 'with-device'){
    setDeviceMarker({marker: markerInstance, latlng: position});
  }

  setIsMarkerPlacing(false);
};
