import { CheckedIcon } from 'components/Icons';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { useReportFilterParams } from '../report-page.hook';
import { ReportActionLayoutOpener } from '../ui-components/report-action-layout';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportSelectEmployeePosts } from "../ui-components/report-filter/report-select-employee-posts.jsx";
import { ReportSelectEmployees } from "../ui-components/report-filter/report-select-employees.jsx";
import { ReportSelectLayout } from '../ui-components/report-filter/report-select-layout';
import { ReportSelectShift } from '../ui-components/report-filter/report-select-shift';
import { ReportSelectZone } from '../ui-components/report-filter/report-select-zone';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

function ReportsEmployeeInteraction({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useReportFilterParams();

  const columns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (created_at) => moment(created_at).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'by_employee',
      dataIndex: 'by_employee',
      title: t('report.filter.tagOwner'),
      render: (by_employee) =>
        `${by_employee?.surname ?? ''} ${by_employee?.name ?? ''} ${
          by_employee?.patronymic ?? ''
        }`,
    },
    {
      key: 'position',
      dataIndex: 'position',
      title: t('position'),
    },
    {
      key: 'shift',
      dataIndex: 'shift',
      title: t('shift'),
      render: (shift) =>
        shift.shift_name === '' ? t('shiftNotSpecified') : shift.shift_name,
    },
    {
      key: 'duration',
      dataIndex: 'duration',
      title: t('interractTime'),
    },

    {
      key: 'to_employee',
      dataIndex: 'to_employee',
      title: t('toEmployee'),
      render: (to_employee) =>
        ` ${to_employee.surname ?? ''} ${to_employee.name ?? ''} ${
          to_employee.patronymic ?? ''
        }`,
    },
    {
      key: 'zone',
      dataIndex: 'zone',
      title: t('zone'),
      render: (zone) => {
        return zone ? zone.name : t('zoneNotSpecified');
      },
    },
    {
      title: t('violator'),
      dataIndex: 'violator',
      key: 'violator',
      render: (violator) => (
        <div className="actions-wrapper">{violator && <CheckedIcon />}</div>
      ),
    },
    {
      key: 'layout',
      dataIndex: 'layout',
      title: t('map'),
      render: (/** @type {{ name: any; }} */ render) => render.name,
    },
    {
      title: t('actions'),
      key: 'layout',
      dataIndex: 'layout',
      render: (_, record) => (
        <ReportActionLayoutOpener record={record} />
      ),
    },
    {
      key: 'organization_title_1',
      dataIndex: 'by_employee',
      title: t('organization') + ' 1',
      render: (v) => (!v || !v.organization_title) ? t("notSpecified") : v.organization_title
    },
    {
      key: 'organization_title_2',
      dataIndex: 'to_employee',
      title: t('organization') + ' 2',
      render: (v) => (!v || !v.organization_title) ? t("notSpecified") : v.organization_title
    },
  ];
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
  }, []);

  // ----------------------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            <div className="filter-params">
              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('report.filter.tagOwner')}
                paramName={'by_employee'}
              />

              <ReportSelectEmployeePosts
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={"position"}
              />

              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('toEmployee')}
                paramName={'to_employee'}
              />

              <ReportSelectZone
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectLayout
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectShift
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />
            </div>
          </div>
          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}
      <ReportTable
        columns={columns}
        items={reports.results.map((item) => {
          const coordinatesExist =
            item.coordinates !== undefined &&
            item.coordinates !== null &&
            item.coordinates.x &&
            item.coordinates.y &&
            item.coordinates.z;
          const coordinates = coordinatesExist
            ? `x: ${item.coordinates.x.toFixed(
                2
              )} y: ${item.coordinates.y.toFixed(
                2
              )} z: ${item.coordinates.z.toFixed(2)}`
            : '';
          return {
            ...item,
            layout: item.layout ?? t('mapNotSpecified'),
            information: item.information ?? ' ' + coordinates,
            coordinates: item.coordinates,
          };
        })}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}

export default ReportsEmployeeInteraction;
