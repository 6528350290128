import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../../helpers/client';
import { errorNotification } from '../../helpers/notificationHelpers';
import {
  SEARCH_REQUEST,
  setSearchData,
  setSearchError,
  setSearchLoading,
} from '../actions/search';

function* getSearch({ payload }) {
  const { slug, params } = payload;

  try {
    yield put(setSearchLoading(true));
    const response = yield call(
      request.get,
      `${process.env.REACT_APP_LOCAL_API_BASE_URL}/${slug.replace(
        '-items',
        '&'
      )}`,
      { params }
    );

    let responseItems = [];
    switch (slug) {
      case 'employees':
        responseItems = response.data.results;
        break;
      case 'transports':
        responseItems = response.data.results;
        break;
      case 'keepers-items':
        responseItems = response.data.results;
        break;
      case 'base_users':
        responseItems = response.data.results;
        break;
      case 'anchors':
        responseItems = response.data.items;
        break;
      case 'keepers':
        responseItems = response.data.items;
        break;
      default:
        responseItems = response.data;
        break;
    }
    const sortedItems = responseItems.sort((a, b) => {
      if (a.name === b.name) {
        // last name is important when names are same
        return ('' + a.lastname).localeCompare(b.lastname);
      }
      return ('' + a.name).localeCompare(b.name);
    });
    const data = { items: sortedItems };
    yield put(setSearchData(data));
  } catch (err) {
    console.log({ err });
    errorNotification(
      `Код ошибки ${err?.response?.status}. Сообщение ошибки "${err?.response?.data?.descr}"`
    );
    yield put(setSearchError(err));
  } finally {
    yield put(setSearchLoading(false));
  }
}

function* Saga() {
  yield all([takeLatest(SEARCH_REQUEST, getSearch)]);
}

export default Saga;
