export const OWS_REQUEST = 'OWS_REQUEST';
export const SET_OWS_DATA = 'SET_OWS_DATA';
export const SET_OWS_FETCHING = 'SET_OWS_FETCHING';
export const SET_OWS_ERROR = 'SET_OWS_ERROR';
export const RESET_OWS_DATA = 'RESET_OWS_DATA';
export const SET_KEEPER_COORDINATE = 'SET_KEEPER_COORDINATE';

export const owsRequest = (payload) => ({
  type: OWS_REQUEST,
  payload,
});

export const setOwsLoading = (payload) => ({
  type: SET_OWS_FETCHING,
  payload,
});
export const setOwsData = (payload) => ({
  type: SET_OWS_DATA,
  payload,
});
export const setOwsError = (payload) => ({
  type: SET_OWS_ERROR,
  payload,
});
export const resetOwsData = (payload) => ({ type: RESET_OWS_DATA, payload });
export const setKeeperCoordinate = (payload) => ({
  type: SET_KEEPER_COORDINATE,
  payload,
});
