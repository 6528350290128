import { useTranslation } from 'react-i18next';

const useConfig = () => {
  const { t } = useTranslation();

  const reportEventEventsType = {
    danger_zone_entrance: t('dangerZoneEntr'),
    BUTTON_PRESSED: t('buttonPressed'),
    FREE_FALL: t('theFall'),
    MAN_DOWN: t('immobility'),
    MAN_DOWN_CANCEL: t('immobilityCancel'),
  };

  const notification_modes = {
    none: t('notification.none'),
    regular: t('notification.popup'),
    danger: t('notification.blocking'),
  };

  return [
    {
      type: 'valueSet2',
      name: 'event_type',
      label: '*' + t('eventType'),
      required: true,
      data: reportEventEventsType,
    },
    {
      type: 'valueSet2',
      name: 'mode',
      label: '*' + t('notificationType'),
      data: notification_modes,
      required: true,
    },
    // {
    //   type: 'text',
    //   name: 'title',
    //   label: 'Наименование',
    // },
    // {
    //   type: 'textarea',
    //   name: 'description',
    //   label: 'Описание',
    //   rows: 3,
    // },
    {
      type: 'file',
      name: 'audio',
      label: '*' + t('sound'),
      accept: 'audio/*',
      required: true,
    },
    // {
    //   type: 'file',
    //   name: 'thumbnail',
    //   label: 'Изображение',
    //   accept: 'image/*',
    // },
  ];
};

export default useConfig;
