import React from 'react';
import {AreaForm} from '../AreaForm/AreaForm';

export const SideBar = ({setFormValues,area}) => {

  return (
    <div className="sidebar">
      <AreaForm setFormValues={setFormValues} area={area}/>
    </div>
  );
};
