import {
  SET_KEEPERS_FETCHING,
  SET_KEEPERS_ERROR,
  SET_KEEPERS_DATA,
} from '../actions/keepers';

const reqData = { fetching: false, data: null, error: null };

const initialState = {
  keepersData: reqData,
};

export default function keepers(state = initialState, { type, payload }) {
  switch (type) {
    case SET_KEEPERS_FETCHING:
      return {
        ...state,
        keepersData: { ...state.keepersData, fetching: payload },
      };
    case SET_KEEPERS_DATA:
      return {
        ...state,
        keepersData: { ...state.keepersData, data: payload },
      };
    case SET_KEEPERS_ERROR:
      return {
        ...state,
        keepersData: { ...state.keepersData, error: payload },
      };
    default:
      return state;
  }
}
