import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import NativeSelect from '@mui/material/NativeSelect';
import { Popover, Tooltip, notification } from 'antd';
import { request } from 'helpers/client';
import { ReportHelper } from 'helpers/report';
import { getQueryParams, useQuery } from 'hooks/query';
import { MIN_PAGINATION_ITEMS } from 'pages/Settings/consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { XlsFileProcessingNotification } from 'ui-components/notification/notification-xls';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-report.svg';
import {
  reportPageConfig,
  useReportPageRegistry,
} from './reports-page.registry';
import { ReportTemplate } from './ui-components/report-template/report-template';

export const ReportsPage = () => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const [popupVisible, setPopupVisible] = useState(false);
  const { reloadQuery, setQuery } = useQuery();
  const [showFilter, setShowFilter] = useState(false);
  const [reports, setReports] = useState({
    count: 0,
    results: [],
  });
  const [loading, setLoading] = useState(false);
  const reportPageRegistry = useReportPageRegistry();
  const { t } = useTranslation();
  const config = reportPageConfig(reportPageRegistry);
  const routerParams = useParams();
  const ReportBody = useMemo(() => {
    const queryParams = getQueryParams();
    const _component = reportPageRegistry.find(
      (e) => e.key === queryParams.tab
    );
    return _component ? _component.component : config.defaultPage.component;
  }, [routerParams]);

  const tabValue = useMemo(() => {
    const queryParams = getQueryParams();
    return queryParams.tab ?? config.defaultPage.key;
  }, [routerParams]);

  const onlyViolators = useMemo(() => {
    const queryParams = getQueryParams();
    return queryParams.violator ? queryParams.violator === 'true' : false;
  }, [routerParams]);

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    setReports({
      results: [],
      count: 0,
    });
    loadData();
  }, [routerParams]);


  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------

  const loadData = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const path =
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/base_reports/` + tabValue;
    const queryParams = getQueryParams();
    try {
      const response = await request.get(path, {
        params: ReportHelper.params(queryParams, 'load'),
      });
      if (!response || !response.data) {
        setLoading(false);
        return;
      }
      setReports(response.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [loading, routerParams]);

  async function requestForExcelReport() {
    const queryParams = getQueryParams();
    const path = '/base_reports/' + tabValue + `/export/`;
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + path,
      {
        params: ReportHelper.params(queryParams, 'export'),
      }
    );
    // notification.open(XlsFileProcessingNotification(response.data, t));
  }

  function onViolatorCheckboxChange(checked) {
    const queryParams = getQueryParams();
    if (checked) {
      setQuery({ ...queryParams, violator: checked });
    } else {
      setQuery({
        ...queryParams,
        violator: checked,
        page: 1,
        size: queryParams.size ?? MIN_PAGINATION_ITEMS,
        tab: queryParams.tab,
      });
    }
  }

  // ----------------------------------------------------------------------------------------
  return (
    <div className="reports-container">
      <div className="reports-nav-container">
        <div className="reports-select-container">
          <h2 className="reports-nav-container--title">{t('reportType')}</h2>
          <FormControl variant="filled">
            <NativeSelect
              defaultValue={tabValue}
              value={tabValue}
              inputProps={{
                name: 'reports',
                id: 'reports-select',
              }}
              onChange={(e) => {
                setReports({
                  results: [],
                  count: 0,
                });
                setQuery({ tab: e.target.value });
              }}
            >
              {reportPageRegistry.map((item, index) => (
                <option key={index} value={item.key}>
                  {item.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {!config.excludeViolatorFilter.includes(getQueryParams().tab) && (
            <FormControl component="fieldset">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={onlyViolators}
                    onChange={(_, checked) => onViolatorCheckboxChange(checked)}
                  />
                }
                label={t('report.filter.onlyViolators')}
                labelPlacement="start"
              />
            </FormControl>
          )}
        </div>
        <div className="reports-button-group-container">
          <IconButton size="large" onClick={() => reloadQuery()}>
            <RefreshIcon />
          </IconButton>
          <IconButton size="large" onClick={() => requestForExcelReport()}>
            <DownloadIcon />
          </IconButton>
          <IconButton size="large" onClick={() => setShowFilter(!showFilter)}>
            <SettingsIcon />
          </IconButton>

          <Popover
            placement="bottomRight"
            arrowPointAtCenter
            align={{
              points: ['tr', 'cc'],
            }}
            color={'#FDFDFD'}
            style={{
              borderRadius: '0.5rem',
            }}
            content={
              <ReportTemplate
                onSetTemplate={() => {
                  setReports({
                    results: [],
                    count: 0,
                  });
                }}
                closePopup={() => setPopupVisible(false)}
              />
            }
            trigger="click"
            visible={popupVisible}
            onVisibleChange={setPopupVisible}
          >
            <Tooltip title={t('report.template.title')}>
              <IconButton size="large">
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Popover>
        </div>
      </div>

      <ReportBody showFilter={showFilter} reports={reports} loading={loading} />
    </div>
  );
};
