export const styleFloorButtons = {
  // position: 'absolute',
  // top: 230,
  // right: 60,
  // zIndex: 999,
  display: 'flex',
  flexDirection: 'column',
}
export const commonButtonStyles = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: 20,
  color: '#fff',
  background: '#FFFFFF',
  border: 'none',
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
}
