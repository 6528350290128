export const SOMETHING_WENT_WRONG = 'Что-то пошло не так...';
export const UNRECOGNIZED_ERROR = 'Неизвестная ошибка';


export const errorMessage = error => {
  let status = 500;
  let fields = {};
  if (!error || typeof error !== 'object') return { message: UNRECOGNIZED_ERROR, status, fields };
  let { response, message = SOMETHING_WENT_WRONG } = error;

  if (response) {
    if (response.status) {
      // eslint-disable-next-line
      status = response.status;
      switch (response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = SOMETHING_WENT_WRONG;
      }
    }

    if (response.data) {
      if (response.data['non_field_error']) {
        message = response.data['non_field_error'];
      }

      if (Object.keys(response.data).length > 0) {
        fields = response.data;
      }
    }
  }

  return { message, status, fields };
};
