import { createContext } from 'react';

// export type FormOpenerOptions = {
//   component?: JSX.Element,
//   onClose?: () => void,
// };

// export type FormOpenerContextType = {
//   open(options?: FormOpenerOptions): void,
//   close(): void,
//   setOptions(options: FormOpenerOptions): void,
// };

export const ModalContext = createContext({
  open(options) {},
  close() {},
  updateOptions(options) {},
});
