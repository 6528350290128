import { safeGet } from "../../helpers/getters";

const selectState = state => state.polygons;

export const makeSelectPolygon = state => safeGet(selectState(state), 'form.polygon');
export const makeSelectColor = state => safeGet(selectState(state), 'form.color');
export const makeSelectName = state => safeGet(selectState(state), 'form.name');
export const makeSelectDeviceId = state => safeGet(selectState(state), 'form.deviceId');
export const makeSelectCameraId = state => safeGet(selectState(state), 'form.cameraId');
export const makeSelectCameraPoint = state => safeGet(selectState(state), 'form.cameraPoint');
export const makeSelectDevicePoint = state => safeGet(selectState(state), 'form.devicePoint');

export const makeSelectNominatedToDelete = state => safeGet(selectState(state), 'deleting.selected');

export const makeSelectAreas = state => safeGet(selectState(state), 'areas.data.features', []);
export const makeSelectAreasLoading = state => safeGet(selectState(state), 'areas.fetching', false);

export const makeSelectCreationData = state => safeGet(selectState(state), 'creation.data');
export const makeSelectCreationLoading = state => safeGet(selectState(state), 'creation.fetching', false);

export const makeSelectDeletingLoading = state => safeGet(selectState(state), 'deleting.fetching', false);

export const makeSelectUpdatingLoading = state => safeGet(selectState(state), 'updating.fetching', false);
export const makeSelectUpdatingData = state => safeGet(selectState(state), 'updating.data');
export const makeSelectIsEditing = state => safeGet(selectState(state), 'updating.isUpdating', false);
