const STATE_KEY = '@DEVICES';

export const GET_DEVICES_REQUEST = `${STATE_KEY}_GET_DEVICES_REQUEST`;
export const GET_DEVICES_SUCCESS = `${STATE_KEY}_GET_DEVICES_SUCCESS`;
export const GET_DEVICES_FAILURE = `${STATE_KEY}_GET_DEVICES_FAILURE`;

export const CREATE_DEVICE_REQUEST = `${STATE_KEY}_CREATE_DEVICE_REQUEST`;
export const CREATE_DEVICE_SUCCESS = `${STATE_KEY}_CREATE_DEVICE_SUCCESS`;
export const CREATE_DEVICE_FAILURE = `${STATE_KEY}_CREATE_DEVICE_FAILURE`;
export const SET_IS_CREATE_MODAL_OPEN = `${STATE_KEY}_SET_IS_CREATE_MODAL_OPEN`;

export const UPDATE_DEVICE_REQUEST = `${STATE_KEY}_UPDATE_DEVICE_REQUEST`;
export const UPDATE_DEVICE_SUCCESS = `${STATE_KEY}_UPDATE_DEVICE_SUCCESS`;
export const UPDATE_DEVICE_FAILURE = `${STATE_KEY}_UPDATE_DEVICE_FAILURE`;
export const SET_IS_UPDATE_MODAL_OPEN = `${STATE_KEY}_SET_IS_UPDATE_MODAL_OPEN`;

export const DELETE_DEVICE_REQUEST = `${STATE_KEY}_DELETE_DEVICE_REQUEST`;
export const DELETE_DEVICE_SUCCESS = `${STATE_KEY}_DELETE_DEVICE_SUCCESS`;
export const DELETE_DEVICE_FAILURE = `${STATE_KEY}_DELETE_DEVICE_FAILURE`;
export const NOMINATE_TO_DELETE = `${STATE_KEY}_NOMINATE_TO_DELETE`;


export const getDevicesRequest = payload => ({
  type: GET_DEVICES_REQUEST,
  payload: payload || {},
});
export const getDevicesSuccess = payload => ({
  type: GET_DEVICES_SUCCESS,
  payload,
});
export const getDevicesFailure = payload => ({
  type: GET_DEVICES_FAILURE,
  payload,
});

export const createDevicesRequest = payload => ({
  type: CREATE_DEVICE_REQUEST,
  payload: payload || {},
});
export const createDevicesSuccess = payload => ({
  type: CREATE_DEVICE_SUCCESS,
  payload,
});
export const createDevicesFailure = payload => ({
  type: CREATE_DEVICE_FAILURE,
  payload,
});
export const setIsCreateModalOpen = payload => ({
  type: SET_IS_CREATE_MODAL_OPEN,
  payload,
})

export const updateDevicesRequest = payload => ({
  type: UPDATE_DEVICE_REQUEST,
  payload: payload || {},
});
export const updateDevicesSuccess = payload => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload,
});
export const updateDevicesFailure = payload => ({
  type: UPDATE_DEVICE_FAILURE,
  payload,
});
export const setIsUpdateModalOpen = payload => ({
  type: SET_IS_UPDATE_MODAL_OPEN,
  payload,
});

export const deleteDevicesRequest = payload => ({
  type: DELETE_DEVICE_REQUEST,
  payload: payload || {},
});
export const deleteDevicesSuccess = payload => ({
  type: DELETE_DEVICE_SUCCESS,
  payload,
});
export const deleteDevicesFailure = payload => ({
  type: DELETE_DEVICE_FAILURE,
  payload,
});
export const nominateToDelete = payload => ({
  type: NOMINATE_TO_DELETE,
  payload,
});
