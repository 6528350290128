import client, { request } from '../helpers/client';

export const getAreas = () =>
  request
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/layouts/zones/')
    .then((res) => res?.data || {})
    .catch((e) => {
      console.error(e);
      return {};
    });

export const createArea = (params) =>
  request
    .post(process.env.REACT_APP_LOCAL_API_BASE_URL + '/layouts/zones/', params)
    .then((res) => {
      return res?.data || {};
    });

export const updateArea = (id, params) =>
  request
    .put(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/zones/${id}/`,
      params
    )
    .then((res) => res?.data || {});

export const deleteArea = (id) =>
  request
    .delete(process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/zones/${id}/`)
    .then((res) => res?.data || {})
    .catch((e) => {
      console.error(e);
      return [];
    });
