import { mapStore } from 'model/MapStore';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setCenterCoordinates } from 'store/actions/crsMap';
import { UserDrawerBody } from './common/user-drawer-body';
import { useUserDrawer } from './user-drawer.hook';

export function UserDrawer({ keeperInstance, onSelectKeeperToTrack }) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------

  const [isHistoryVisible, setHistoryVisible] = useState(false);
  const userDrawer = useUserDrawer();

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    userDrawer.updateOptions({
      onClose: onClose,
    });
  }, []);

  // ------------------------------------------------------------------------------------------------------------
  // function
  // ------------------------------------------------------------------------------------------------------------

  const onClose = () => {
    setHistoryVisible(false);
    mapStore.keeperHistoryInstance.clear();
  };

  // ------------------------------------------------------------------------------------------------------------
  return (
    <UserDrawerBody
      keeperInstance={keeperInstance}
      onSelectKeeperToTrack={onSelectKeeperToTrack}
      isHistoryVisible={isHistoryVisible}
      setHistoryVisible={setHistoryVisible}
    />
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setKeeperCoordinates: (coordinates) =>
      dispatch(setCenterCoordinates(coordinates)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer);
