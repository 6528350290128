import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectShift({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const [variants, setVariants] = useState([]);
  const { t } = useTranslation();
  
  useEffect(() => {
    loadData();
  }, []);

  const value = useCallback(() => {
    const queryParams = getQueryParams();
    if (!variants || !tempFilterParams) return null;
    let currentValue = null;
    if (tempFilterParams.shift) {
      currentValue = variants.find(
        (shift) => shift.id === Number(tempFilterParams.shift)
      );
    } else {
      currentValue = variants.find(
        (shift) => shift.id === Number(queryParams.shift)
      );
    }

    if (!currentValue) return null;
    return currentValue.shift_name;
  }, [variants, tempFilterParams]);

  async function loadData() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/shifts?size=1000`
    );

    if (!responce || !responce.data) return;
    setVariants(responce.data.results);
  }
  return (
    <Select
      showSearch={true}
      placeholder={t('shift')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={value()}
      onChange={(value) => {
        setTempFilterParams({
          ...tempFilterParams,
          shift: value,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {variants &&
        [{ id: '_none', shift_name: t('notSpecified') }, ...variants].map(
          (item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.shift_name}
            </Select.Option>
          )
        )}
    </Select>
  );
}
