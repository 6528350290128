export const mockedVideoSelect = [
  {
    id: 1,
    name: 'video test 1',
  },
  {
    id: 2,
    name: 'video test 2',
  },
  {
    id: 3,
    name: 'video test 3',
  },
  {
    id: 4,
    name: 'video test 4',
  },
]

export const drawMapHeaderFilterItems = [
  {
    key: 'oblast',
    value: 'Области',
  },
  {
    key: 'camera',
    value: 'Камеры',
  },
  {
    key: 'device',
    value: 'Оборудования',
  },
]

export const createTypesFilter = [
  {
    key: 'no-device',
    label: 'Без оборудования'
  },
  {
    key: 'with-device',
    label: 'С оборудованием'
  },
  {
    key: 'with-camera',
    label: 'С камерой'
  },
]
