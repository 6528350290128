import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, List, Tabs } from 'antd';
import { useNotifications } from 'context/notification/notification-hooks/notification.hook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventTypeNameMapper } from '../../../pages/Settings/Reactions/utils';
import {
    NotificationBody,
    NotificationBodyReportExportCancel,
    NotificationBodyReportExportDone, NotificationBodyReportExportInProcess,
} from './NotificationBody';

const BellPopoverContent = ({ show }) => {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const [selectedTab, setSelectedTab] = useState('notRead');
  const notification = useNotifications();
  const { t } = useTranslation();
  const eventTypeNameMapper = useEventTypeNameMapper();
  // ------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      show &&
      selectedTab === 'read' &&
      notification.readed.results.length === 0
    ) {
      notification.loadReaded({ fromStart: true });
    }
  }, [show, selectedTab]);

  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------

  function loadMore() {
    if (selectedTab === 'read') {
      notification.loadReaded({ fromStart: false });
    } else {
      notification.loadNotReaded({ fromStart: false });
    }
  }

  // ------------------------------------------------------------------------------------
  return (
    <div className="bell-content-wrapper">
      <Tabs
        activeKey={selectedTab}
        onChange={(activeKey) => {
          if (activeKey === 'clear') {
            notification.clear();
          } else {
            setSelectedTab(activeKey);
          }
        }}
      >
        <Tabs.TabPane tab={t('unreaded')} key="notRead" />
        <Tabs.TabPane tab={t('readed')} key="read" />

        {selectedTab === 'notRead' && (
          <Tabs.TabPane
            key={'clear'}
            tab={
              <div
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <CloseOutlined />
              </div>
            }
            closable={false}
          />
        )}
      </Tabs>
      <List
        itemLayout="vertical"
        dataSource={
          selectedTab === 'read'
            ? notification.readed.results
            : notification.notReaded
        }
        loadMore={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: 10,
            }}
          >
            <Button
              loading={notification.readedLoading}
              disabled={notification.readedLoading}
              onClick={async () => loadMore()}
            >
              {notification.readedLoading ? t('loading') : t('loadMore')}
            </Button>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            key={item.id}
            extra={
              <CloseOutlined
                style={{
                  display: item.read_is ? 'none' : 'block',
                  color: '#00A2E8',
                  fontSize: 16,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  notification.read(item.id);
                }}
              />
            }
          >
            <List.Item.Meta
              avatar={
                <BellOutlined style={{ color: '#00A2E8', fontSize: 28 }} />
              }
              title={<>{eventTypeNameMapper[item.type] ?? item.type}</>}
              description={
                <>
                  {item.type === 'REPORT_EXPORT_CANCEL' && (
                    <NotificationBodyReportExportCancel item={item} />
                  )}
                  {item.type === 'REPORT_EXPORT_DONE' && (
                    <NotificationBodyReportExportDone item={item} />
                  )}
                  {item.type === 'REPORT_EXPORT_IN_PROCESS' && (
                    <NotificationBodyReportExportInProcess item={item} />
                  )}

                  {item.type !== 'REPORT_EXPORT_DONE' &&
                    item.type !== 'REPORT_EXPORT_CANCEL' && item.type !== 'REPORT_EXPORT_IN_PROCESS' && (
                      <NotificationBody item={item} />
                    )}
                </>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default BellPopoverContent;
