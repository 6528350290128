export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SET_SIGN_IN_DATA = 'SET_SIGN_IN_DATA';
export const SET_SIGN_IN_FETCHING = 'SET_SIGN_IN_FETCHING';
export const SET_SIGN_IN_ERROR = 'SET_SIGN_IN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

export const signInRequest = (payload) => ({
  type: SIGN_IN_REQUEST,
  payload,
});

export const setSignInLoading = (payload) => ({
  type: SET_SIGN_IN_FETCHING,
  payload,
});
export const setSignInData = (payload) => ({
  type: SET_SIGN_IN_DATA,
  payload,
});
export const setSignInError = (payload) => ({
  type: SET_SIGN_IN_ERROR,
  payload,
});
export const signOut = () => ({ type: SIGN_OUT });
