import { useTranslation } from 'react-i18next';
import { markerTypeSelect } from '../utils';

const useConfig = () => {
  const {t} = useTranslation()
  return [
    {
      type: 'valueSet',
      selectType: 'raw',
      options: markerTypeSelect(t),
      name: 'marker_type',
      label: t('markerType'),
      required: true,
    },
    {
      type: 'file',
      name: 'icon',
      label: t('icon'),
      accept: 'image/*',
      required: true,

    }
  ]
}

export default useConfig;
