import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getQueryParams } from '../../hooks/query';

/**
 * @returns {[any, import("react").Dispatch<import("react").SetStateAction<any>>]}
 */
export function useReportFilterParams() {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const routerParams = useParams();
  const [filterParams, setFilterParams] = useState(() => {
    const query = getQueryParams();
    return {
      fromDate: query.fromDate
        ? new Date(Number(query.fromDate)).toISOString()
        : null,
      toDate: query.toDate
        ? new Date(Number(query.toDate)).toISOString()
        : null,
    };
  });

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------
  useEffect(() => {
    const query = getQueryParams();
    let params;
    if (query.fromDate === '0' || query.toDate === '0') {
      params = { ...query, fromDate: null, toDate: null };
    } else {
      params = {
        ...query,
        fromDate: query.fromDate
          ? new Date(Number(query.fromDate)).toISOString()
          : null,
        toDate: query.toDate
          ? new Date(Number(query.toDate)).toISOString()
          : null,
      };
    }
    setFilterParams(params);
  }, [routerParams]);

  return [filterParams, setFilterParams];
}
