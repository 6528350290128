// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Select, Switch } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { errorMessage } from 'helpers/errorHandlers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { selectConfigList as selectEmployees } from '../../../store/selectors/employees';

export const TransaportsForm = ({ onSave, instance = undefined }) => {
  // ---------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------
  const formOpener = useFormOpener();
  const defaultValue = {
    driver_1: null,
    driver_2: null,
    driver_3: null,
    driver_4: null,
    onboard_number: '',
    transport_mark: '',
    state_number: '',
    organization: null,
    description: '',
    is_active: false,
  };
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [requestInput, setRequestInput] = useState(
    instance ? instance : defaultValue
  );
  const [isRequesting, setIsRequesting] = useState(false);
  const [errors, setErrors] = useState();
  const dispatch = useDispatch();

  const employees = useSelector(selectEmployees);

  // ---------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
  }, []);

  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmitClick,
      submitButtonProps: { loading: isRequesting },
    });
  }, [requestInput, formOpener, isRequesting]);

  useEffect(() => {
    if (!instance) return;
    setRequestInput(instance);
  }, [instance]);

  useEffect(() => {
    loadOrganizations();
  }, []);

  // ---------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------

  async function loadOrganizations() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/organizations/?size=1000`
    );

    if (!responce || !responce.data) return;
    setOrganizations(responce.data.results);
  }

  async function onSubmitClick(isValid) {
    if (!isValid) return;
    setIsRequesting(true);
    try {
      let responce;

      let payload = {};

      for (const key in requestInput) {
        if (requestInput[key] === '_none') {
          payload[key] = null;
        } else {
          payload[key] = requestInput[key];
        }
      }
      console.log({ payload });
      if (instance) {
        const { id, ...props } = payload;
        responce = await request.put(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/transports/' + id + '/',
          props
        );
      } else {
        responce = await request.post(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/transports/',
          payload
        );
      }
      if (responce.status >= 200 && responce.status < 300) {
        onSave();
        formOpener.close();
      }
    } catch (error) {
      setErrors(errorMessage(error).fields);
    }
    setIsRequesting(false);
  }

  function DriverFormItem({ number }) {
    return (
      <Form.Item
        label={t('driver') + ` ${number}`}
        className="form-row"
        name={`driver_${number}`}
        initialValue={requestInput[`driver_${number}`]}
        validateStatus={errors?.[`driver_${number}`] ? 'error' : ''}
        help={errors?.[`driver_${number}`]}
      >
        <Select
          placeholder={t('Водитель')}
          optionFilterProp="children"
          bordered={false}
          style={{
            width: '85%',
          }}
          value={employees.find(
            (o) => o.id === requestInput[`driver_${number}`]
          )}
          onChange={(driver) =>
            setRequestInput({ ...requestInput, [`driver_${number}`]: driver })
          }
          filterOption={(input, option) =>
            String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {employees &&
            [{ key: '_none', value: t('notSpecified') }, ...employees].map(
              (employee, index) => (
                <>
                  {employee.key === '_none' ? (
                    <Select.Option value={employee.key} key={index}>
                      {employee.value}
                    </Select.Option>
                  ) : (
                    <Select.Option value={employee.id} key={index}>{`${
                      employee.surname ?? ''
                    } ${employee.name ?? ''} ${
                      employee.patronymic ?? ''
                    }`}</Select.Option>
                  )}
                </>
              )
            )}
        </Select>
      </Form.Item>
    );
  }

  // ---------------------------------------------------------------
  return (
    <>
      <Form.Item
        label={t('onboard_number')}
        rules={[
          {
            required: true,
            message: 'Введите название onboard_number',
          },
        ]}
        className="form-row"
        name={'onboard_number'}
        initialValue={requestInput.onboard_number}
        required={true}
        validateStatus={errors?.onboard_number ? 'error' : ''}
        help={errors?.onboard_number}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              onboard_number: e.target.value,
            });
          }}
          value={requestInput.onboard_number}
          required={true}
        />
      </Form.Item>

      <Form.Item
        label={t('transport_mark')}
        rules={[
          {
            required: true,
            message: 'Введите значение',
          },
        ]}
        className="form-row"
        name={'transport_mark'}
        initialValue={requestInput.transport_mark}
        required={true}
        validateStatus={errors?.transport_mark ? 'error' : ''}
        help={errors?.transport_mark}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              transport_mark: e.target.value,
            });
          }}
          value={requestInput.transport_mark}
          required={true}
        />
      </Form.Item>

      <Form.Item
        label={t('state_number')}
        rules={[
          {
            required: true,
            message: 'Введите номер state_number',
          },
        ]}
        className="form-row"
        name={'state_number'}
        initialValue={requestInput.state_number}
        required={true}
        validateStatus={errors?.state_number ? 'error' : ''}
        help={errors?.state_number}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              state_number: e.target.value,
            });
          }}
          value={requestInput.state_number}
          required={true}
        />
      </Form.Item>

      <DriverFormItem number={1} />
      <DriverFormItem number={2} />
      <DriverFormItem number={3} />
      <DriverFormItem number={4} />

      <Form.Item
        label={t('organization')}
        className="form-row"
        name={'organization'}
        initialValue={requestInput.organization}
        validateStatus={errors?.organization ? 'error' : ''}
        help={errors?.organization}
      >
        <Select
          placeholder={t('organization')}
          optionFilterProp="children"
          bordered={false}
          style={{
            width: '85%',
          }}
          value={organizations.find((o) => o.id === requestInput.organization)}
          onChange={(organization) =>
            setRequestInput({ ...requestInput, organization })
          }
          filterOption={(input, option) =>
            String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {organizations &&
            [{ id: '_none', title: t('notSpecified') }, ...organizations].map(
              (item, index) => (
                <Select.Option value={item.id} key={index}>
                  {item.title}
                </Select.Option>
              )
            )}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('active')}
        className="form-row"
        name={'is_active'}
        initialValue={requestInput.is_active}
        validateStatus={errors?.is_active ? 'error' : ''}
        help={errors?.is_active}
      >
        <Switch
          checked={requestInput.is_active}
          onChange={(checked) =>
            setRequestInput({
              ...requestInput,
              is_active: checked,
            })
          }
        />
      </Form.Item>
      <Form.Item
        label={t('description')}
        className="form-row"
        name={'description'}
        initialValue={requestInput.description}
        validateStatus={errors?.description ? 'error' : ''}
        help={errors?.description}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              description: e.target.value,
            });
          }}
          value={requestInput.description}
        />
      </Form.Item>
    </>
  );
};
