// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { t as tFunc } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import { TransaportsForm } from './transaports-form.component';

const TransportsConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { t } = useTranslation();
  const { query, setQuery } = useQuery();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const formOpener = useFormOpener();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
    },

    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (created_at) =>
        new Date(created_at)
          .toLocaleString('en-US', { hour12: false })
          .split('/')
          .join('.'),
    },

    {
      title: t('onboard_number'),
      dataIndex: 'onboard_number',
      key: 'onboard_number',
    },

    {
      title: t('transport_mark'),
      dataIndex: 'transport_mark',
      key: 'transport_mark',
    },

    {
      title: t('state_number'),
      dataIndex: 'state_number',
      key: 'state_number',
    },

    {
      title: t('actions'),
      key: 'actions',
      render: (_, instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => {
              handleClickEdit(instance);
            }}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={() => {
              formOpener.open({
                title: 'Удалить транспорт',
                submitText: t('delete'),
                component: 'Вы действительно хотите удалить транспорт?',
                onSubmit: () => remove(instance.id),
                loading: deleteLoading,
              });
            }}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(() => {
    loadData();
  }, [query]);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  async function loadData() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/transports/',
      {
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
      }
    );
    setCount(response.data.count);
    setData(response.data.results);
  }

  async function handleClickCreate() {
    formOpener.open({
      title: 'Добавить транспорт',
      cancelText: tFunc('cancel'),
      submitText: tFunc('create'),
      component: <TransaportsForm onSave={loadData} />,
    });
  }

  async function handleClickEdit(instance) {
    formOpener.open({
      title: 'Изменить транспорт',
      cancelText: t('cancel'),
      submitText: t('edit'),
      component: <TransaportsForm instance={instance} onSave={loadData} />,
    });
  }

  async function remove(id) {
    setDeleteLoading(true);
    await request.delete(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/transports/' + id
    );
    setDeleteLoading(false);
    loadData();
    formOpener.close();
  }

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div></div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            Добавить транспорт
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={count}
          current={Number(query.page) || 1}
          pageSize={query.size || MIN_PAGINATION_ITEMS}
          onChange={(page, size) => setQuery({ ...query, page, size })}
          hideOnSinglePage={true}
          showSizeChanger={true}
          defaultCurrent={Number(query.page)}
        />
      </div>
    </SettingsLayout>
  );
};

export default TransportsConfiguration;
