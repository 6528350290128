import {
  SET_SIGN_IN_ERROR,
  SET_SIGN_IN_FETCHING,
  SET_SIGN_IN_DATA,
  SIGN_OUT,
} from '../actions/auth';
import { getStorageItem, setStorageItem } from '../../helpers/storageHelpers';
import { TOKEN } from '../../consts/storageKeys';
import { setCookie } from '../../helpers/cookieHelpers';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  isAuthenticated: !!getStorageItem(TOKEN),
  signInState: reqState,
};

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SIGN_IN_FETCHING:
      return {
        ...state,
        signInState: { ...state.signInState, fetching: payload },
      };
    case SET_SIGN_IN_DATA:
      const { access, refresh, cookies } = payload;
      setStorageItem(TOKEN, { access, refresh });
      setCookie(cookies);
      return {
        ...state,
        isAuthenticated: true,
        signInState: { ...state.signInState, data: payload, error: null },
      };
    case SET_SIGN_IN_ERROR:
      return {
        ...state,
        signInState: { ...state.signInState, error: payload },
      };
    case SIGN_OUT:
      return {
        ...state,
        signInState: reqState,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
