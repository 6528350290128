import { safeGet } from "./getters";


export const normalizeInstance = columns => item => columns.reduce(
  (memo, curr) => {
    if (!curr.dataIndex) return Object.assign(memo, { id: item.id });
    if (curr.dataIndex === 'instance') return Object.assign(memo, { instance: item });
    return Object.assign(memo, { [curr.dataIndex]: safeGet(item, curr.dataIndex) });
  },
  {}
);
