import { takeLatest, all, put, call, delay } from 'redux-saga/effects';

import client, { request } from '../../helpers/client';

import {
  GET_LIST_REQUEST,
  GET_EVENT_TYPE_REQUEST,
  CREATION_REQUEST,
  EDITING_REQUEST,
  OPEN_EDIT_FORM,
  DELETE_REQUEST,
  getListRequest,
  getListSuccess,
  getListFailure,
  getEventTypeSuccess,


  getEventTypeFailure,
  createSuccess,
  createFailure,
  editSuccess,
  editFailure,
  setIsCreateFormOpen,
  setIsEditFormOpen,
  askToDelete,
  deleteSuccess,
  deleteFailure,
} from '../actions/notificationConfigurations';
import { setFormInitialValues } from '../actions/forms';

import { DEFAULT_PAGE_SIZE } from '../../components/Pagination/constants';
import { EDIT_FORM } from '../../pages/Settings/Reactions/form/constants';
import { eventTypeNameMapped } from 'pages/Settings/Reactions/utils';
import { t } from 'i18next';

function* fetchConfigList(action) {
  const { page = 1, size = DEFAULT_PAGE_SIZE } = action.payload;

  try {
    const response = yield call(request.get, '/events/', {
      params: { page, size },
    });
    yield put(getListSuccess(response.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

function* fetchEventTypes() {
  try {
    const response = yield call(
      client.get,
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/dicts/EVENT_TYPE'
    );
    const types = eventTypeNameMapped(t);
    const data = response.data.items.map((item) => ({
      name: item.name,
      label: types[item.name] ? types[item.name] : item.name,
    }));

    yield put(getEventTypeSuccess(data));
  } catch (error) {
    yield put(getEventTypeFailure(error));
  }
}

function* create(action) {
  const { data } = action.payload;
  try {
    const response = yield call(request.post, '/events/', data);

    yield put(createSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsCreateFormOpen(false));
  } catch (error) {
    yield put(createFailure(error));
  }
}

function* update(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(request.put, `/events/${id}/`, data);

    yield put(editSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsEditFormOpen(false));
  } catch (error) {
    yield put(editFailure(error));
  }
}

function* openEditForm(action) {
  const { instance } = action.payload;
  yield put(setFormInitialValues({ form: EDIT_FORM, instance }));
  yield delay(300);
  yield put(setIsEditFormOpen(true));
}

function* deleteRequest(action) {
  const { id } = action.payload;

  try {
    const response = yield call(request.delete, `/events/${id}/`);
    yield put(deleteSuccess(response.data));
    yield put(getListRequest());
    yield put(askToDelete(null));
  } catch (error) {
    yield put(deleteFailure(error));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_LIST_REQUEST, fetchConfigList),
    takeLatest(GET_EVENT_TYPE_REQUEST, fetchEventTypes),
    takeLatest(CREATION_REQUEST, create),
    takeLatest(EDITING_REQUEST, update),
    takeLatest(OPEN_EDIT_FORM, openEditForm),
    takeLatest(DELETE_REQUEST, deleteRequest),
  ]);
}

export default Saga;
