import { Button } from 'antd';

import './index.css';

import { useModal } from 'context/modal/modal.hook';
import {
  errorNotification,
  successNotification,
} from 'helpers/notificationHelpers';
import { useTranslation } from 'react-i18next';
import { sendSos } from 'services/sos';

function UserDrawerSosModal({ keeperId }) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const modal = useModal();
  const { t } = useTranslation();
  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------
  const handleClickSendSos = () => {
    sendSos(keeperId)
      .then(() => successNotification(t('signalSent')))
      .catch((err) => {
        errorNotification(t('signalNotSendRepaid'));
        throw err;
      });
  };

  return (
    <>
      <p className="sos-modal-title">{t('sendSos')}</p>
      <p className="sos-modal-text">{t('sendSosQ')}</p>
      <div className="d-flex-sb">
        <Button
          className="sos-modal-send-btn"
          onClick={() => {
            handleClickSendSos();
            modal.close();
          }}
        >
          {t('send')}
        </Button>
        <Button
          className="sos-modal-cancel-btn"
          onClick={() => {
            modal.close();
          }}
        >
          {t('cancel')}
        </Button>
      </div>
    </>
  );
}

export default UserDrawerSosModal;
