import axios from 'axios';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BiometryEventsTags = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          '/dashboards/biometry_events_by_zones'
      )
      .then((response) => setData(response.data.items));
  }, []);

  const datasets = [
    {
      label: '',
      data: data.map((r) => r.count),
      backgroundColor: '#67C587',
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const extraStyles = {
    gridColumn: (data.length > 40 ? 1 : 2) + ' / 4',
    gridRow: '1 / 3',
  };

  return (
    <div
      className="char-component-wrapper"
      style={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...(data.length > 10 ? extraStyles : {}),
      }}
    >
      <div className="char-component-title">
        <span>{t("dashboard.healthEventByMark")}</span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div style={{ padding: 20 }}>
          {data.map((r) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <div
                  style={{
                    background: '#67C587',
                    height: '10px',
                    width: '10px',
                  }}
                ></div>
              </div>
              <div style={{ flexGrow: 1 }}>{r.name}</div>
              <div style={{ fontWeight: 'bold' }}>{r.count}</div>
            </div>
          ))}
        </div>

        <div
          className="char-component"
          style={{
            height: 'min-content',
          }}
        >
          <Bar
            options={options}
            data={{
              labels: data.map((d) => d.name),
              datasets,
            }}
          />
        </div>
      </div>
    </div>
  );
};
