import { useModal } from 'context/modal/modal.hook';
import { mapStore } from 'model/MapStore';
import { useEffect, useState } from 'react';

import { Divider } from 'antd';
import { UserHistory } from '../UserHistory/UserHistory';
import UserDrawerSosModal from '../sos-modal/sos-modal.component';
import { IconsGroup } from './IconsGroup';
import { UserDrawerEmployeeMainStats } from './main-stats/user-drawer-employee-main-stats';
import { UserDrawerVehicleMainStats } from './main-stats/user-drawer-vehicle-main-stats';
import { UserDrawerEmployeeStats } from './stats/user-drawer-employee-stats';
import { UserDrawerVehicleStats } from './stats/user-drawer-vehicle-stats';

export function UserDrawerBody({
  keeperInstance,
  onSelectKeeperToTrack,
  isHistoryVisible,
  setHistoryVisible,
}) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const [keeper, setKeeper] = useState(keeperInstance);
  const modal = useModal();
  const [trackedId, setTrackedId] = useState(null);

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (keeper?.id) {
      if (mapStore.trackedKeeper[keeper.id]) {
        setTrackedId(keeper.id);
      } else {
        setTrackedId(null);
      }
    }
  }, [keeper]);

  useEffect(() => {
    setKeeper(keeperInstance);
  }, [keeperInstance]);

  useEffect(() => {
    const subscription = mapStore.keepersByMap$.subscribe((allKeepers) => {
      if (!keeper) return;
      const keepers = [];
      if (!allKeepers) return;
      Object.keys(allKeepers).forEach((mapId) => {
        for (let keeperItem in allKeepers[mapId]) {
          keepers.push(allKeepers[mapId][keeperItem]);
        }
      });
      const currentKeeper = keepers.find((item) => item.id === keeper.id);
      if (!currentKeeper) return;
      setKeeper(currentKeeper.data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [keeper]);

  // ------------------------------------------------------------------------------------------------------------
  // function
  // ------------------------------------------------------------------------------------------------------------

  // function getMapId() {
  //   const map = mapStore.mapsByKeeper[keeper.id];
  //   return map.id;
  // }

  const handleClickHistoryOpen = () => {
    // history.push(SHOW_MAP + getMapId())
    setHistoryVisible(true);
    mapStore.enableHistory();
  };

  const closeHistory = () => {
    setHistoryVisible(false);
    mapStore.keeperHistoryInstance.clear();
  };

  const onTrackKeeper = () => {
    const newKeeperId = (keeper?.id && keeper?.id !== trackedId) ?? keeper.id;
    mapStore.setTrackedKeeper(newKeeperId);
    onSelectKeeperToTrack(newKeeperId);
    setTrackedId(newKeeperId);
  };

  function handleClickShowSosModal() {
    modal.open({
      component: <UserDrawerSosModal keeperId={keeper.id} />,
    });
  }

  if (!keeper) return <></>;
  return (
    <div style={{ marginTop: 36 }}>
      {isHistoryVisible ? (
        <UserHistory closeHistory={closeHistory} keeper={keeper} />
      ) : (
        <>
          {keeper.type === 'VEHICLE' && (
            <UserDrawerVehicleMainStats keeper={keeper} />
          )}

          {keeper.type === 'EMPLOYEE' && (
            <UserDrawerEmployeeMainStats keeper={keeper} />
          )}

          <Divider />
          <IconsGroup
            onShowSosModal={handleClickShowSosModal}
            onClickClock={handleClickHistoryOpen}
            onTrackKeeper={onTrackKeeper}
            trackedId={trackedId}
            keeper={keeper}
          />
          <Divider />

          {keeper.type === 'VEHICLE' && (
            <UserDrawerVehicleStats keeper={keeper} />
          )}

          {keeper.type === 'EMPLOYEE' && (
            <UserDrawerEmployeeStats keeper={keeper} />
          )}
        </>
      )}
    </div>
  );
}
