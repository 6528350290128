import { Modal } from 'antd';
import {
  CREATE_FORM,
  EDIT_FORM,
} from 'pages/Settings/Employees/form/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsCreateFormOpen
} from 'store/selectors/employees';
import { selectFormValues } from 'store/selectors/forms';
import { clearForm } from '../../store/actions/forms';
import Form from './form';

const Dialog = ({
  title,
  visible,
  onClose,
  buttonText,
  form,
  handleSubmit,
  loading,
  config,
  error,
}) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = React.createRef();
  const editFormValues = useSelector(selectFormValues(EDIT_FORM));
  const createFormValues = useSelector(selectFormValues(CREATE_FORM));
  const isCreateFormOpen = useSelector(selectIsCreateFormOpen);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------
  const handleClose = () => {
    if (onClose) onClose();
    dispatch(clearForm({ form }));
    formRef.current?.resetFields();
  };

  async function onSubmit() {
    const values = isCreateFormOpen ? createFormValues : editFormValues;
    for (const key in values) {
      formRef.current.setFieldValue(key, values[key]);
    }

    console.log({ values, ref: formRef.current.getFieldsValue() });
    await formRef.current.validateFields();
    handleSubmit();
  }

  // ------------------------------------------------------------------------------

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={handleClose}
      okText={buttonText}
      onOk={onSubmit}
      cancelText={t('cancel')}
      okButtonProps={{ loading }}
      keyboard={!loading}
      cancelButtonProps={{ loading }}
      width="800px"
    >
      <Form
        formRef={formRef}
        loading={loading}
        name={form}
        config={config}
        error={error}
      />
    </Modal>
  );
};

export default React.memo(Dialog);
