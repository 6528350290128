import client, { request } from '../helpers/client';

export const fetchMaps = () =>
  request
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/layouts/list/')
    .then((res) => res?.data?.results || [])
    .catch((e) => {
      console.error(e);
      return [];
    });

export const getMapZones = (mapId) =>
  request
    .get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        '/layouts/zones/' +
        `?layout=${mapId}`
    )
    .then((res) => res?.data || []);

export const deleteMap = (mapId) =>
  request.delete(
    process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/${mapId}/delete/`
  );

export const fetchIcons = () =>
  client
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/common/config/markers/')
    .then((res) => {})
    .catch((e) => {
      console.error(e);
      return [];
    });
