const submit = (formValues, instance) => {
  const formData = new FormData();

  if (formValues['event_type']) {
    if (instance && instance['event_type'] !== formValues['event_type']) {
      formData.append('event_type', formValues['event_type']);
    }
    if (!instance) {
      formData.append('event_type', formValues['event_type']);
    }
  }

  if (formValues['mode']) {
    if (instance && instance['mode'] !== formValues['mode']) {
      formData.append('mode', formValues['mode']);
    }
    if (!instance) {
      formData.append('mode', formValues['mode']);
    }
  }

  if (formValues['title']) {
    if (instance && instance['title'] !== formValues['title']) {
      formData.append('title', formValues['title']);
    }
    if (!instance) {
      formData.append('title', formValues['title']);
    }
  }

  if (formValues['description']) {
    if (instance && instance['description'] !== formValues['description']) {
      formData.append('description', formValues['description']);
    }
    if (!instance) {
      formData.append('description', formValues['description']);
    }
  }

  if (
    formValues['audio'] &&
    (formValues['audio'].file || !formValues['audio'].url)
  ) {
    formData.append('audio', formValues['audio'].file || '');
  }

  // i

  if (
    formValues['thumbnail'] &&
    (formValues['thumbnail'].file || !formValues['thumbnail'].url)
  ) {
    formData.append('thumbnail', formValues['thumbnail'].file || '');
  }

  if (formValues['email_to']) {
    if (instance && instance['email_to'] !== formValues['email_to']) {
      formData.append('email_to', formValues['email_to']);
    }
    if (!instance) {
      formData.append('email_to', formValues['email_to']);
    }
  }
  return formData;
};

export default submit;
