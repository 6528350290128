import React from 'react';
import { Spin } from 'antd';

const Spinner = () => (
  <div
    style={{
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      width: '100%',
      height: '100%',
    }}
  >
    <Spin />
  </div>
);

export default Spinner;
