import { CloseOutlined } from '@ant-design/icons';
import { List, Modal } from 'antd';
import { getQueryParams, useQuery } from 'hooks/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReportTemplateIcon } from '../../../../assets/icons/report-template.svg';
import styles from './report-template.module.scss';
import { selectConfigList as selectEmployees } from '../../../../store/selectors/employees';
import { useSelector } from 'react-redux';
import { ReportTemplateRequest } from './report-template.request';
import { ParamsHelper } from '../../../../helpers/params';
import { useModal } from 'context/modal/modal.hook';

export const ReportTemplateItem = ({ item, closePopup, onDelete }) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const { setQuery } = useQuery();
  const [loading, setLoading] = useState(false);
  /** @type {{id: number, is_active: boolean, is_danger: boolean, is_violator: boolean, label: null, mark: string, name: string, notifications: [], patronymic: string, post: string, service_number: string, shift: number, shift_second: number, surname: string, violator_term: string}[]} */
  const employees = useSelector(selectEmployees);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------
  function renderParams() {
    const resultNode = [];
    const keys = Object.keys(item.params);
    for (const key in item.params) {
      if (typeof item.params[key] === 'boolean') {
        resultNode.push(<>{t(`report.template.params.${key}`)}</>);
      } else {
        let val = item.params[key];
        if (key === 'employees') {
          const employee = employees.find(
            (employee) => employee.id === item.params[key]
          );
          if (employee) {
            const { name, surname, patronymic } = employee;
            val = `${surname ?? ''} ${name ?? ''} ${patronymic ?? ''}`;
          }
        }
        if (key === 'event_type' || key === 'sos_type') {
          resultNode.push(
            <>
              {t(`report.template.params.${key}`, {
                val: t(`report.template.params.${val}`),
              })}
            </>
          );
        } else {
          resultNode.push(
            <>
              {t(`report.template.params.${key}`, {
                val,
              })}
            </>
          );
        }
      }
      resultNode.push(<br />);
    }

    return resultNode;
  }

  function onUseTemplateParamsClick() {
    closePopup();
    const queryParams = getQueryParams();
    const newParams = item.params;
    setQuery(
      ParamsHelper.exclude({ ...queryParams, ...newParams }, [
        'toDate',
        'fromDate',
        'id',
        'page',
        'size',
      ])
    );
  }

  async function onDeleteTemplateClick() {
    setLoading(true);
    await ReportTemplateRequest.delete(item.id);
    setLoading(false);
    onDelete();
    setDeleteModalOpened(false);
  }

  // ----------------------------------------------------------------------------------------
  return (
    <List.Item className={styles.item} key={item.id}>
      <div className={styles.itemHeader}>
        <List.Item.Meta
          avatar={
            <ReportTemplateIcon style={{ color: '#00A2E8', fontSize: 28 }} />
          }
          title={<h3 className={styles.title}>{item.name}</h3>}
        />
        <CloseOutlined
          className={styles.extra}
          onClick={() => {
            closePopup();
            setDeleteModalOpened(true);
          }}
        />
      </div>
      <div className={styles.itemContent}>
        <div className={styles.params}>{renderParams()}</div>
        <div className={styles.buttonsWrapper}>
          <div onClick={onUseTemplateParamsClick}>
            {t('report.template.use')}
          </div>
        </div>
      </div>
      <Modal
        visible={deleteModalOpened}
        onCancel={() => setDeleteModalOpened(false)}
        title={t('confirmAction')}
        okText={loading ? t('loading') + '...' : t('delete')}
        okButtonProps={{ color: 'red', loading: loading }}
        cancelText={t('cancel')}
        onOk={() => {
          onDeleteTemplateClick();
        }}
      >
        <p>{t('sureWantToDeleteTemplate')}</p>
      </Modal>
    </List.Item>
  );
};
