import {
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { useQuery } from 'hooks/query';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function CameraPreview({
  type,
  setCameraId,
  setSelectedPolygon,
  setCurrentKeeperId,
  currentUser,
  cameraId,
  wrapperRef,
}) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { setQuery } = useQuery();
  const [cameraStyle, setCameraStyle] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const { t } = useTranslation();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    if (fullscreen) {
      const { clientWidth, clientHeight } = wrapperRef.current;
      let width = clientWidth;
      let height = (clientWidth * 9) / 11;
      if (height > clientHeight) {
        height = clientHeight;
        width = (11 * height) / 9;
      }
      setCameraStyle({ width: `${width}px`, height: `${height}px` });
    } else {
      setCameraStyle({ width: `400px`, height: `240px` });
    }
  }, [fullscreen]);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------

  const getVideoPath = (macroscop, cameraId) => {
    const params = new URLSearchParams();
    params.append('login', macroscop.login);
    params.append('password', macroscop.password);

    params.append('channelid', cameraId);
    // authParams.append('streamtype', 'alternative');
    // authParams.append('sound', 'on');
    params.append('withcontenttype', 'true');
    params.append('resolutionx', macroscop.width);
    params.append('resolutiony', macroscop.height);
    const { url } = macroscop;
    return url + `/mobile?${params.toString()}`;
  };

  // ----------------------------------------------------------------------------------------
  return (
    <div className="camera-stream" style={cameraStyle}>
      <div className="controls">
        <div className="close">
          <CloseOutlined
            onClick={() => {
              // console.log('CloseOutlined');
              setCameraId('-'); // провоцирует ошибку, чтоб остановить загрузку стрима
              setCameraId(undefined);
              setSelectedPolygon(null);
              setQuery({ showKeeperCamera: null });
              setCurrentKeeperId(undefined);
            }}
          />
        </div>
        <div className="bottom-controls">
          {fullscreen ? (
            <FullscreenExitOutlined onClick={() => setFullscreen(false)} />
          ) : (
            <FullscreenOutlined onClick={() => setFullscreen(true)} />
          )}
        </div>
      </div>

      {type === 'preview' && (
        <img
          alt="Video"
          style={{ width: '100%', height: 'auto' }}
          src={getVideoPath(currentUser.macroscop, cameraId)}
          onError={() => setCameraId('')}
        />
          // <div
          //   style={{
          //     width: '100%',
          //     height: '100%',
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //     backgroundColor: 'white',
          //   }}
          // >
          //   <h2 style={{ color: '#767676', textAlign: 'center' }}>
          //    CAMERA PREVIEW
          //   </h2>
          // </div>
      )}

      {type === 'notPermitted' && (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <h2 style={{ color: '#767676', textAlign: 'center' }}>
              {t('userWithoutAccess')}
            </h2>
          </div>
        </>
      )}

      {type === 'outOfZone' && (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <h2 style={{ color: '#767676', textAlign: 'center' }}>
              {t('outOfCameraZone')}
            </h2>
          </div>
        </>
      )}
    </div>
  );
}
