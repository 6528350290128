import client, { request } from '../helpers/client';

export const getGroups = () =>
  request
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/groups/')
    .then((res) => res?.data || {})
    .catch((e) => {
      console.error(e);
      return {};
    });

// export const createArea = (params) => client
// .post(process.env.REACT_APP_LOCAL_API_BASE_URL + '/layouts/zones/', params)
// .then((res) => {
//   return res?.data || {}
// })
// .catch((e) => {
//   console.error(e);
//   return [];
// });

// export const updateArea = (id, params) => client
// .put(process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/zones/${id}/`, params)
// .then((res) => res?.data || {})
// .catch((e) => {
//   console.error(e);
//   return [];
// });
