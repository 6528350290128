import {
  GET_MILEAGE_POINT_REQUEST,
  GET_MILEAGE_POINT_SUCCESS,
  GET_MILEAGE_POINT_FAILURE,
  CLEAR_MILEAGE_POINT,
} from '../actions/reports';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  mileagePointState: reqState,
};

export default function reports(state = initialState, { type, payload }) {
  switch (type) {
    case GET_MILEAGE_POINT_REQUEST:
      return {
        ...state,
        mileagePointState: { ...state.mileagePointState, fetching: true },
      };
    case GET_MILEAGE_POINT_SUCCESS:
      return {
        ...state,
        mileagePointState: {
          ...state.mileagePointState,
          fetching: false,
          data: payload,
        },
      };
    case GET_MILEAGE_POINT_FAILURE:
      return {
        ...state,
        mileagePointState: {
          ...state.mileagePointState,
          fetching: false,
          error: payload,
        },
      };
    case CLEAR_MILEAGE_POINT:
      return { ...state, mileagePointState: reqState };
    default:
      return state;
  }
}
