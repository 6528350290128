import React from 'react';

const CheckedIcon = () => {
    return (
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2.91729" height="8.75186" rx="1" transform="matrix(0.728008 -0.685568 0.728008 0.685568 0 5.96875)" fill="#353535" />
            <rect width="2.91729" height="14.5864" rx="1" transform="matrix(-0.728008 -0.685568 -0.728008 0.685568 17.0007 2)" fill="#353535" />
        </svg>
    );
};

export default CheckedIcon;
