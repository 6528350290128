// @ts-nocheck
import L from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getBounds } from 'helpers/getters';
import { createImage } from 'helpers/imageHelpers';
import {
  addMarker,
  addPolyLine,
  getBlueMapMarkerIcon,
  initMap,
} from 'helpers/mapHelpers';

import { useTranslation } from 'react-i18next';
import './index.scss';

export const CRSMap = ({
  bounds,
  boundsCoords,
  boundsEditingMode,
  image,
  pointA,
  pointL0,
  pointL1,
  ratio,
  segmentLength,
  setBounds,
  setBoundsCoords,
  setBoundsEditingMode,
  setPointA,
  setPointL0,
  setPointL1,
  setRatio,
  singleMap,
}) => {
  const mapId = 'map';

  const [map, setMap] = useState();
  const { t } = useTranslation();
  const [line, setLine] = useState();
  const markersAdded = useRef(false);
  const pointAMarker = useRef(null);
  const currentImage = useRef(null);
  const prevEditMode = useRef();
  const [boundsLine, setBoundsLine] = useState();
  const handleLoadImage = useCallback(
    (img) => {
      setBounds(getBounds(img.width, img.height));
    },
    [setBounds]
  );

  const initBounds = useCallback(() => {
    createImage(image, handleLoadImage);
  }, [handleLoadImage, image]);

  const initPoints = useCallback(() => {
    if (!pointL0 && !pointL1) {
      const [{ lat: y0, lng: x0 }, { lat: y1, lng: x1 }] = bounds;
      const midY = (y0 + y1) / 2;
      setPointL0(L.latLng([midY, x0]));
      setPointL1(L.latLng([midY, x1]));
      setPointA(L.latLng([0, 0]));
    }
  }, [bounds, pointL0, pointL1, setPointL0, setPointL1, setPointA]);

  const drawMarkers = useCallback(() => {
    if (pointA) {
      const pointAMarker = addMarker({
        map,
        latLng: pointA,
        draggable: true,
        setPosition: setPointA,
        icon: getBlueMapMarkerIcon(),
      });
      pointAMarker.bindPopup(t('calculationPoint'));
      pointAMarker.on('mouseover', () => pointAMarker.openPopup());
      pointAMarker.on('mouseout', () => pointAMarker.closePopup());
      pointAMarker.current = pointAMarker;
    }

    if (pointL0) {
      addMarker({
        map,
        latLng: pointL0,
        draggable: true,
        setPosition: setPointL0,
        icon: getBlueMapMarkerIcon(),
      });
    }

    if (pointL1) {
      addMarker({
        map,
        latLng: pointL1,
        draggable: true,
        setPosition: setPointL1,
        icon: getBlueMapMarkerIcon(),
      });
    }
  }, [map, pointA, pointL0, pointL1, setPointA, setPointL0, setPointL1]);

  useEffect(() => {
    if (pointAMarker.current && pointA) {
      pointAMarker.current.setLatLng(pointA);
    }
  }, [pointA]);

  useEffect(() => {
    if (pointL0 && pointL1 && segmentLength) {
      const { lat: y0, lng: x0 } = pointL0;
      const { lat: y1, lng: x1 } = pointL1;
      const width = Math.abs(x1 - x0);
      const height = Math.abs(y1 - y0);
      let length = 0;
      let ratio = 0;
      if (height === 0 && width) {
        length = segmentLength;
        ratio = width / length;
      } else if (width === 0 && height) {
        length = segmentLength;
        ratio = height / length;
      } else if (width > height) {
        const angle = Math.atan(height / width);
        length = Math.cos(angle) * segmentLength;
        ratio = width / length;
      } else if (height > width) {
        const angle = Math.atan(width / height);
        length = Math.cos(angle) * segmentLength;
        ratio = height / length;
      }
      if (ratio) setRatio(ratio);
    }
  }, [pointL0, pointL1, ratio, segmentLength, setRatio]);

  useEffect(() => {
    initBounds();
  }, [initBounds]);

  useEffect(() => {
    if (bounds && image) {
      currentImage.current = image;
      initPoints();
      if (!map && bounds)
        setMap(initMap({ mapId, image, bounds, editable: true }));
    }
    return () => {
      if (!map) return;
    };
  }, [bounds, image, initPoints, map, singleMap]);

  // Draw marker
  useEffect(() => {
    if (map && !markersAdded.current && (pointA || pointL0 || pointL1)) {
      markersAdded.current = true;
      drawMarkers();
    }
  }, [map, pointA, pointL0, pointL1, drawMarkers]);

  useEffect(() => {
    if (map && pointL0 && pointL1) {
      if (line) {
        line.setLatLngs([pointL0, pointL1]);
      } else {
        const polyline = addPolyLine({
          map,
          latLngs: [pointL0, pointL1],
          options: {
            weight: 2,
            dashArray: '5',
          },
        });
        setLine(polyline);
      }
    }
  }, [line, map, pointL0, pointL1]);

  //Draw image layer
  useEffect(() => {
    if (map && bounds && map.currentImageOverlay && image) {
      map.removeLayer(map.currentImageOverlay);
      map.currentImageOverlay = L.imageOverlay(image, bounds).addTo(map);
    }
  }, [bounds, image, map, singleMap]);

  useEffect(() => {
    if (!boundsEditingMode && prevEditMode.current) {
      map?.fire('editable:drawing:commit', undefined);
    }
    prevEditMode.current = boundsEditingMode;
  }, [boundsEditingMode, map]);

  // Draw contours
  useEffect(() => {
    let coords;
    if (boundsCoords && boundsCoords.length > 2) {
      coords = boundsCoords;
    }
    if (boundsLine) {
      if (coords) {
        boundsLine.setLatLngs(coords);
      }
    } else if (map && coords) {
      setBoundsLine(L.polygon(coords, { fill: false }).addTo(map));
    }
  }, [boundsCoords, boundsLine, map]);

  useEffect(() => {
    if (boundsEditingMode) {
      boundsLine?.remove();
    } else if (map) {
      boundsLine?.addTo(map);
    }
  }, [boundsEditingMode, boundsLine, map]);

  useEffect(() => {
    if (boundsEditingMode && map) {
      let polygon;
      const eventName = 'editable:drawing:commit';
      const onCommit = (e) => {
        const [latLngs] = polygon?.getLatLngs?.() || [];
        if (latLngs) {
          setBoundsCoords(latLngs.map((point) => [point.lat, point.lng]));
        }
        if (prevEditMode.current) {
          setBoundsEditingMode(false);
        }
        polygon?.remove();
        map?.off(eventName, onCommit);
      };
      map?.on(eventName, onCommit);

      if (boundsCoords?.length) {
        polygon = L.polygon(boundsCoords, { opacity: 0.1 }).addTo(map);
        polygon.enableEdit();
      } else {
        polygon = map?.editTools?.startPolygon?.(undefined, { opacity: 0.1 });
      }
    }
  }, [
    boundsCoords,
    boundsEditingMode,
    map,
    setBoundsCoords,
    setBoundsEditingMode,
  ]);

  return (
    <div className="map-wrapper">
      <div id={mapId} />
    </div>
  );
};
