import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { PhotoshopPicker } from "react-color";
import {useDispatch} from "react-redux";
import {setUserColorRequest} from "../../store/actions/user";


const PICKER_WIDTH = 514;
const OFFSET = 60;

const ColorPicker = (
  {
    onChange,
    onClose,
    open,
    anchor,
  }
) => {
  const [color, setColor] = useState('');

  const handleColorChange = ({ hex }) => setColor(hex);

  const dispatch = useDispatch()

  const handleChange = () => {
    if (typeof onChange === 'function') {
      dispatch(setUserColorRequest(color))
      onChange(color);
    }

    handleClose();
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const getPickerPosition = () => {
    if (!open || !anchor) return { display: 'none' };
    const bounds = anchor.getBoundingClientRect();

    return {
      left: bounds.right - (PICKER_WIDTH + OFFSET),
      top: bounds.top,
    };
  };

  if (!open) return null;
  return createPortal(
    (
      <div
        className="color-picker-wrapper"
        style={{
          width: PICKER_WIDTH,
          ...getPickerPosition()
        }}
      >
        <PhotoshopPicker
          color={color}
          onChange={handleColorChange}
          onAccept={handleChange}
          onCancel={onClose}
        />
      </div>
    ),
    document.body
  )
};

export default ColorPicker;

