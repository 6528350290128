import { request } from '../../../../helpers/client';

export const ReportTemplateRequest = {
  /**
   * @param {{ created_at__range: string; report_type: string; search: string; }} params
   * @return {Promise<{id: number, created_at: string, name: string, report_type: string, params: {[key in string]: string}, created_by_user: string}[] | undefined>}
   * */
  get: async (params) => {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/filtering_templates/`,
      {
        params,
      }
    );

    if (!response || !response.data) return;
    return response.data;
  },
  /**
   * @param {{name, report_type: ("anchor_events"|"biometric"|"employee_interaction"|"employee_sos"|"events"|"sos"|"transactions_between_levels"|"user_activity"|"violators"|"working_time"|string), params: {}}} params
   * @return {Promise<{id: number, created_at: string, name: string, report_type: string, params: {[key in string]: string}, created_by_user: string}[] | undefined>}
   * */
  post: async (params) => {
    // console.log('post - params',params)
    const response = await request.post(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/filtering_templates/`,
      params
    );
    if (!response || !response.data) return;
    return response.data;
  },
  /**
   * @param {id: number} id
   * @return {Promise<{id: number, created_at: string, name: string, report_type: string, params: {[key in string]: string}, created_by_user: string}[] | undefined>}
   * */
  getById: async (id) => {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/filtering_templates/${id}`
    );
    if (!response || !response.data) return;
    return response.data;
  },
  /**
   * @param {id: number} id
   * */
  delete: async (id) => {
    await request.delete(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/filtering_templates/${id}`
    );
  },
};
