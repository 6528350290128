import { Select } from 'antd';
import { getQueryParams } from 'hooks/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectConfigList as selectEmployees } from '../../../../store/selectors/employees';

export function ReportSelectEmployees({
  setTempFilterParams,
  tempFilterParams,
  loading,
  placeholder,
  paramName,
}) {
  const { t } = useTranslation();
  const employees = useSelector(selectEmployees);

  const employeeValue = useCallback(() => {
    const queryParams = getQueryParams();
    if (!employees || !tempFilterParams) return null;
    let currentEmployee = null;
    if (tempFilterParams[paramName]) {
      currentEmployee = employees.find(
        (employee) => employee.id === Number(tempFilterParams[paramName])
      );
    } else {
      currentEmployee = employees.find(
        (employee) => employee.id === Number(queryParams[paramName])
      );
    }

    if (!currentEmployee) return null;
    return `${currentEmployee.surname ?? ''} ${currentEmployee.name ?? ''} ${
      currentEmployee.patronymic ?? ''
    }`;
  }, [employees, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={placeholder}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={employeeValue()}
      onChange={(value) => {
        const tempParam = { ...tempFilterParams };
        tempParam[paramName] = value;
        setTempFilterParams(tempParam);
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {employees &&
        [{ key: '_none', value: t('notSpecified') }, ...employees].map(
          (employee, index) => (
            <>
              {employee.key === '_none' ? (
                <Select.Option value={employee.key} key={index}>
                  {employee.value}
                </Select.Option>
              ) : (
                <Select.Option value={employee.id} key={index}>{`${
                  employee.surname ?? '' 
                } ${employee.name ?? ''} ${
                  employee.patronymic ?? ''
                }`}</Select.Option>
              )}
            </>
          )
        )}
    </Select>
  );
}
