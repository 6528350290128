import { Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as DisableTrackedKeeperIcon } from 'assets/icons/disable-tracked.svg';
import { ReactComponent as NotTrackedKeeperIcon } from 'assets/icons/not-tracked.svg';
import { ReactComponent as TrackedKeeperIcon } from 'assets/icons/tracked.svg';
import { mapStore } from 'model/MapStore';

import { ReactComponent as AimLogo } from 'assets/icons/aim.svg';
import { ReactComponent as ClockLogo } from 'assets/icons/clock.svg';
import { ReactComponent as AimDisableLogo } from 'assets/icons/disable-aim.svg';
import { ReactComponent as SosDisableLogo } from 'assets/icons/disable-sos-medium.svg';
import { ReactComponent as SosLogo } from 'assets/icons/sos-medium.svg';

import { useTranslation } from 'react-i18next';
import { CameraLogo } from './camera-logo';
import './index.css';

export function IconsGroup({
  onShowSosModal,
  onClickClock,
  onTrackKeeper,
  trackedId,
  keeper = undefined,
}) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------

  const [isInCameraArea, setInCameraArea] = useState(true);
  const { t } = useTranslation();

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    setInCameraArea(!!mapStore.selectedKeeperInCameraArea);
    const s = mapStore.selectedKeeperInCameraArea$.subscribe((v) =>
      setInCameraArea(v)
    );
    return () => s.unsubscribe();
  }, []);

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  const center = () => {
    const id = mapStore.selectedKeeperId;
    if (id && mapStore.keepers[id]) {
      const { x, y } = mapStore.keepers[id];
      mapStore.center([y, x]);
    }
  };

  const logos = useMemo(() => {
    const employeeItems = [
      {
        id: 1,
        icon: !keeper?.is_active ? (
          <DisableTrackedKeeperIcon />
        ) : trackedId ? (
          <TrackedKeeperIcon onClick={onTrackKeeper} />
        ) : (
          <NotTrackedKeeperIcon onClick={onTrackKeeper} />
        ),
        text: t('track'),
      },
      {
        id: 2,
        text: t('onMap'),
        icon: !keeper?.is_active ? (
          <AimDisableLogo />
        ) : (
          <AimLogo onClick={center} />
        ),
      },
      {
        id: 3,
        icon: !keeper?.is_active ? (
          <SosDisableLogo />
        ) : (
          <SosLogo onClick={onShowSosModal} />
        ),
        text: t('urgently'),
      },
      {
        id: 4,
        icon: <CameraLogo inCameraArea={isInCameraArea} />,
        text: isInCameraArea ? t('videoMonitor') : t('outOfCameraArea'),
      },
      {
        id: 5,
        icon: <ClockLogo onClick={onClickClock} />,
        text: t('movementHistory'),
      },
    ];

    const vehicleItems = [
      {
        id: 1,
        icon: !keeper?.is_active ? (
          <DisableTrackedKeeperIcon />
        ) : trackedId ? (
          <TrackedKeeperIcon onClick={onTrackKeeper} />
        ) : (
          <NotTrackedKeeperIcon onClick={onTrackKeeper} />
        ),
        text: t('track'),
      },
      {
        id: 2,
        text: t('onMap'),
        icon: !keeper?.is_active ? (
          <AimDisableLogo />
        ) : (
          <AimLogo onClick={center} />
        ),
      },
      {
        id: 4,
        icon: <CameraLogo inCameraArea={isInCameraArea} />,
        text: isInCameraArea ? t('videoMonitor') : t('outOfCameraArea'),
      },
      {
        id: 5,
        icon: <ClockLogo onClick={onClickClock} />,
        text: t('movementHistory'),
      },
    ];

    if (keeper?.type === 'EMPLOYEE') {
      return employeeItems;
    }

    if (keeper.type === 'VEHICLE') {
      return vehicleItems;
    }

    return [];
  }, [
    keeper,
    trackedId,
    onTrackKeeper,
    t,
    isInCameraArea,
    onShowSosModal,
    onClickClock,
  ]);

  // ------------------------------------------------------------------------------------------------------------
  return (
    <div className="logos-wrapper">
      {logos.map((item) => {
        return (
          <Tooltip title={item.text}>
            <span key={item.id}>{item.icon}</span>
          </Tooltip>
        );
      })}
    </div>
  );
}
