import React from 'react';
import { Tooltip } from 'antd';


import './index.scss';

const KeeperCounter = ({ amount, title }) => {
  return (
    <Tooltip title={title}>
      <div className="counter-wrapper">{amount}</div>
    </Tooltip>
  );
};

export default KeeperCounter;
