import { normalizeInstance } from '../../helpers/table';

const getState = state => state.svgMap;

export const makeSelectGetMapsList = state => getState(state).mapsListState.data;
export const makeSelectTableData = (columns = []) => state => {
  const data = makeSelectGetMapsList(state) || [];
  return data.map(normalizeInstance(columns));
};
export const makeSelectMapListCount = state => getState(state).mapsListState.count;
export const makeSelectMapsListFetching = state => getState(state).mapsListState.fetching;

export const makeSelectGetSingleMap = state => getState(state).singleMapState.data;
export const makeSelectSingleMapFetching = state => getState(state).singleMapState.fetching;

export const makeSelectGetMainMap = state => getState(state).mainMapState.data
export const makeSelectGetMainMapFetching = state => getState(state).mainMapState.fetching
