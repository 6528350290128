import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../../helpers/client';
import {
  GET_MILEAGE_POINT_REQUEST,
  getMileagePointFailure,
  getMileagePointSuccess,
} from '../actions/reports';

function* getMileagePoint({ payload }) {
  const { slug, params } = payload;
  try {
    const response = yield call(
      request.get,
      `${process.env.REACT_APP_LOCAL_API_BASE_URL}/BA-API/v0.1/reports/mileage/point/${slug}`,
      { params }
    );

    yield put(getMileagePointSuccess(response.data));
  } catch (err) {
    yield put(getMileagePointFailure(err));
  }
}

function* Saga() {
  yield all([takeLatest(GET_MILEAGE_POINT_REQUEST, getMileagePoint)]);
}

export default Saga;
