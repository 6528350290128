import pointInPolygon from 'point-in-polygon';
import {Area} from './Area';
import {getAreas} from '../services/areas';
import {Subject} from 'rxjs';

export class MapData {

  id;
  bounds;
  ratio;
  x0;
  y0;
  minZ;
  maxZ;
  x0m;
  y0m;

  name;

  data;

  areas = {};
  areaVisible = false;
  areaVisible$ = new Subject();

  constructor(data) {
    this.update(data);
  }

  contains(object) {
    if (object) {
      const {xM, yM, z} = object;
      if (this.bounds?.length) {
        const bounds = this.bounds.map(([y, x]) => [x, y]);
        if (z >= this.minZ && z <= this.maxZ && pointInPolygon([xM, yM], bounds)) {
          object.x = (xM - this.x0m) * this.ratio + this.x0;
          object.y = (yM - this.y0m) * this.ratio + this.y0;
          return true;
        }
      }
    }
    return false;
  }

  update(data) {
    if (data) {
      const {bounds, point1, x, y, min_z, max_z, id, name} = data;
      this.id = id;
      this.bounds = bounds;
      this.x0 = point1.lng;
      this.y0 = point1.lat;
      this.minZ = min_z;
      this.maxZ = max_z;
      this.x0m = x;
      this.y0m = y;
      this.data = data;
      this.name = name;
      this.setRatio();
    }
  }

  setRatio() {
    if (this.data) {
      const type = 'number';
      const {point2, point3, segment_length} = this.data || {};
      const {lat: lat1, lng: lng1} = point2 || {};
      const {lat: lat2, lng: lng2} = point3 || {};
      if (
        segment_length &&
        typeof lat1 === type &&
        typeof lng1 === type &&
        typeof lat2 === type &&
        typeof lng2 === type
      ) {
        this.ratio = Math.sqrt((lng2 - lng1) ** 2 + (lat2 - lat1) ** 2) / segment_length;
      }
    }
  }

  getAreas() {
    getAreas()
      .then((res) => {
        if (res?.results?.length) {
          if (!this.areas) {
            this.areas = {};
          }
          res.results.forEach((data) => {
            this.areas[data.id] = new Area(this, data);
          });
        }
    })
      .catch(console.error);
  }

  updateArea(data) {
    if (!this.areas) {
      this.areas = {};
    }
    const {id} = data;
    if (id) {
      if (this.areas[id]) {
        this.areas[id].update(data);
      } else {
        this.areas[id] = new Area(this, data);
      }
    }
  }

  setAreaVisibility(isVisible) {
    this.areaVisible = isVisible;
    this.areaVisible$.next(isVisible);
  }

}
