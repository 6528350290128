import client, { request } from '../helpers/client';
import store from '../store/index';

import {
  setLabelsData,
  setLabelsError,
  setLabelsLoading,
} from '../store/actions/labels';
import {
  errorNotification,
  successNotification,
} from '../helpers/notificationHelpers';

export const getLabels = () => {
  store.dispatch(setLabelsLoading(true));
  // ?offset=0&sort=label
  return request
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/groups/')
    .then((res) => {
      store.dispatch(setLabelsData(res.data));
    })
    .catch((err) => {
      store.dispatch(setLabelsError(err));
    })
    .finally(() => {
      store.dispatch(setLabelsLoading(false));
    });
};

export const sendSOS = (groups) => {
  store.dispatch(setLabelsLoading(true));

  return request
    .post(process.env.REACT_APP_LOCAL_API_BASE_URL + '/send_sos_by_groups/', {
      groups,
    })
    .then((res) => {
      successNotification(`Сигнал отправлен ${res.data.sent_counter ?? res.data.sent_keeper_ids.length} группам!`);
    })
    .catch((err) => {
      if (err?.response?.data?.message)
        errorNotification(err?.response?.data?.message);
      else errorNotification(err.message);
    })
    .finally(() => {
      store.dispatch(setLabelsLoading(false));
    });
};
