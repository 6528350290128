import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
// import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Form, Input, Divider } = require('antd');

export function SettingsForm({
  generalSettings,
  onSaveGeneralSettings = undefined,
  isPreviewMode = true,
}) {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const formOpener = useFormOpener();
  const [isPending, setIsPending] = useState(false);
  const {t } = useTranslation()
  // ------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmit,
      submitButtonProps: { loading: isPending },
    });
  }, [formOpener, isPending]);

  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------
  async function onSubmit(isValid, value) {
    if (!isValid) return;
    value.scada_path = generalSettings.scada_path;
    setIsPending(true);
    const response = await request.put(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/settings/',
      value
    );
    setIsPending(false);
    onSaveGeneralSettings(response.data);
    formOpener.close();
  }

  // ------------------------------------------------------------------------------------
  return (
    <>
      <Form.Item
        className="m-config-item"
        label={t('interractDistM')}
        name={!isPreviewMode ? 'interaction_distance' : undefined}
        initialValue={generalSettings.interaction_distance}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.interaction_distance}
        />
      </Form.Item>
   
      <Form.Item
        className="m-config-item"
        label={t('dspw')}
        name={
          !isPreviewMode ? 'man_without_movement_color_fill_time' : undefined
        }
        initialValue={generalSettings.man_without_movement_color_fill_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.man_without_movement_color_fill_time}
          disabled={!generalSettings}
        />
      </Form.Item>
     
      <Form.Item
        className="m-config-item"
        label={t('stainiDurFallS')}
        name={!isPreviewMode ? 'fall_color_fill_time' : undefined}
        initialValue={generalSettings.fall_color_fill_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.fall_color_fill_time}
          disabled={!generalSettings}
        />
      </Form.Item>
    
      <Form.Item
        className="m-config-item"
        label={t('interractTimeS')}
        name={!isPreviewMode ? 'interaction_time' : undefined}
        initialValue={generalSettings.interaction_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.interaction_time}
          disabled={!generalSettings}
        />
      </Form.Item>
     
      <Form.Item
        className="m-config-item"
        label={t('stainingDurDangerZ')}
        name={!isPreviewMode ? 'danger_zone_exit_color_fill_time' : undefined}
        initialValue={generalSettings.danger_zone_exit_color_fill_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.danger_zone_exit_color_fill_time}
          disabled={!generalSettings}
        />
      </Form.Item>
      
      <Form.Item
        className="m-config-item"
        label={t('stainingDurBtnPress')}
        name={!isPreviewMode ? 'button_is_pressed_color_fill_time' : undefined}
        initialValue={generalSettings.button_is_pressed_color_fill_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.button_is_pressed_color_fill_time}
          disabled={!generalSettings}
        />
      </Form.Item>
            
      <Form.Item
        className="m-config-item"
        label={'Расстояние сближения транспорта с сотрудником'}
        name={!isPreviewMode ? 'employee_transport_convergence_distance' : undefined}
        initialValue={generalSettings.employee_transport_convergence_distance}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.employee_transport_convergence_distance}
          disabled={!generalSettings}
        />
      </Form.Item>
      
      <Divider />

      <Form.Item
        className="m-config-item"
        label={'Глобальная карта ось X'}
        name={!isPreviewMode ? 'global_map_x' : undefined}
        initialValue={generalSettings.global_map_x}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.global_map_x}
          disabled={!generalSettings}
        />
      </Form.Item>

      <Form.Item
        className="m-config-item"
        label={'Глобальная карта ось Y'}
        name={!isPreviewMode ? 'global_map_y' : undefined}
        initialValue={generalSettings.global_map_y}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.global_map_y}
          disabled={!generalSettings}
        />
      </Form.Item>

      <Form.Item
        className="m-config-item"
        label={'Глобальная карта зум'}
        name={!isPreviewMode ? 'global_map_zoom' : undefined}
        initialValue={generalSettings.global_map_zoom}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.global_map_zoom}
          disabled={!generalSettings}
        />
      </Form.Item>

      <Divider />

      <Form.Item
        className="m-config-item"
        label={'Время отслеживания аномального сердечного ритма'}
        name={!isPreviewMode ? 'abnormal_heart_rate_tracking_time' : undefined}
        initialValue={generalSettings.abnormal_heart_rate_tracking_time}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={generalSettings.abnormal_heart_rate_tracking_time}
          disabled={!generalSettings}
        />
      </Form.Item>
    </>
  );
}
