import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectEmployeePosts({
  setTempFilterParams,
  tempFilterParams,
  loading,
  propKey
}) {
  const [employeesPosts, setEmployeesPosts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadEmployeePosts();
  }, []);

  async function loadEmployeePosts() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/employee_interaction/positions_list/`
    );
    if (!response || !response.data) return;
    setEmployeesPosts(response.data);
  }
  const employeePost = useMemo(() => {
    const queryParams = getQueryParams();
    if (!employeesPosts || !tempFilterParams) return null;
    let currentPost = null;
    if (tempFilterParams[propKey]) {
      currentPost = employeesPosts.find(
        (employee) => employee === tempFilterParams[propKey]
      );
    } else {
      currentPost = employeesPosts.find(
        (employee) => employee === queryParams[propKey]
      );
    }
    if (!currentPost) return null;
    return currentPost;
  }, [employeesPosts, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={t('position')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={employeePost}
      onChange={(value) => {
        // const queryParams = getQueryParams();
        // const isEventsTab = !queryParams.tab || queryParams.tab === 'events';
        // let key = isEventsTab ? 'post' : 'position';
        let updatedParams = { ...tempFilterParams };
        updatedParams[propKey] = value;
        setTempFilterParams(updatedParams);
      }}
      disabled={loading}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {['_none', ...employeesPosts].map((post, index) => (
        <Select.Option value={post} key={index}>
          {post === '_none' ? t('notSpecified') : post}
        </Select.Option>
      ))}
    </Select>
  );
}
