import classNames from 'classnames';
import { mapStore } from 'model/MapStore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toShowMapId } from '../../consts/routes';

export function SandwichColumnItem({ item, thumbnail }) {
  // ------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------
  // const [cardState, setCardState] = useState('none');
  const [keepersByMap, setKeepersByMap] = useState([]);
  const [countById, setCountById] = useState({});
  // let cardColor = 'none';

  // ------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const subscription = mapStore.keepersByMap$.subscribe((allKeepers) => {
      if (allKeepers) {
        const tempKeepersList = [];
        Object.keys(allKeepers).forEach((mapId) => {
          const tempKeeperItem = [];
          for (let keeperItem in allKeepers[mapId]) {
            tempKeeperItem.push(allKeepers[mapId][keeperItem]);
          }
          tempKeepersList.push({
            mapId: mapId,
            items: tempKeeperItem,
          });
          setKeepersByMap(tempKeepersList);
        });
        const countByMap = {};
        Object.keys(allKeepers).forEach((mapId) => {
          countByMap[mapId] = Object.keys(allKeepers[mapId]).length;
        });
        setCountById(countByMap);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // ------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------


  function keeperColor(mapId) {
    const keeper = keepersByMap.filter((_item) => _item.mapId === mapId)[0];
    if (!keeper) return `#FFFFFF`;
    const currentKeeperItems = keeper.items;
    const keeperColoring = currentKeeperItems.map((item) => item.data.coloring);
    if (!keeperColoring) return `#FFFFFF`;
    const needColorSandwich = keeperColoring.some(
      (item) => item.color_sandwich === true
    );

    if (needColorSandwich) {
      const anyOnDanger = keeperColoring.some(
        item => item.color === "red"
      )
      if (anyOnDanger) return "red"
    }
    if (countById[item.id] !== 0) return '#598B36';
    return `#FFFFFF`;
  }



  // ------------------------------------------------------------------------------------------------------
  return (
    <Link to={toShowMapId(item.id)}>
      <div
        className={classNames({
          'block-item': true,
          // 'block-white': cardState === 'none',
          // 'block-green': cardState === 'normal',
          // 'block-red': cardState === 'danger',
        })}
        style={{ backgroundColor: keeperColor(item.id) }}
      >
        <h4
          className={classNames({
            'block-white-text': keeperColor(item.id) === '#FFFFFF',
            'block-red-text': keeperColor(item.id) !== '#FFFFFF',
          })}
        >
          {countById[item.id] ?? 0}
        </h4>
        <div
          className="block-red-inner"
          style={{ backgroundImage: `url(${thumbnail})` }}
        >
          <h4 className="block-inner-text">
            {item.height && item.height + ' - '}
            {item.name}
          </h4>
        </div>
      </div>
    </Link>
  );
}
