import { all, takeLatest, put, call } from 'redux-saga/effects';

import { request } from '../../helpers/client';
import { errorNotification, successNotification } from '../../helpers/notificationHelpers';

import {
  GET_AREAS_REQUEST,
  CREATE_AREA_REQUEST,
  DELETE_AREA_REQUEST,
  UPDATE_AREA_REQUEST,
  GET_CAMERA_LIST_REQUEST,

  getAreasRequest,
  getAreasSuccess,
  getAreasFailure,
  createAreaSuccess,
  createAreaFailure,
  deleteAreaSuccess,
  deleteAreaFailure,
  nominateToDelete,
  updateAreaFailure,
  updateAreaSuccess,
  clearForm,
  setIsEditing,
  getCameraListFailure,
  getCameraListSuccess,
} from '../actions/drawPolygons';


function* getAreas(action) {
  const { layoutId, filter } = action.payload;

  try {
    const response = yield call(request.get, '/layouts/areas/', {
      params: { layout_id: layoutId, attachment: filter === 'oblast' ? "" : filter }
    });
    const { results } = response.data;
    yield put(getAreasSuccess(results));
  } catch (error) {
    yield put(getAreasFailure(error));
  }
}

function* createArea(action) {
  const { form, layoutId } = action.payload;
  const { region, camera, device, ...properties } = form;
  region.properties = properties;
  region.properties.layout = layoutId;

  let point, cameraId, deviceId

  if(!!form.camera){
    point = camera.point;
    cameraId = camera.cameraId
    point.properties = point.properties || {};
  }
  if(!!form.device){
    point = device.point;
    deviceId = device.cameraId
    point.properties = point.properties || {};
  }

  try {
    const response = yield call(
      request.post,
      '/layouts/areas/',
      region
    );

    if(form.camera){
      point.properties.camera_id = cameraId;
      point.properties.area = response.data.id;

      yield* createCamera(point);
    }

    yield put(createAreaSuccess(response.data));
    yield put(getAreasRequest({ layoutId }));
    successNotification('Область успешно добавлена');
  } catch (error) {
    yield put(createAreaFailure(error));
    errorNotification('Ошибка создания');
  }
}

function* updateCamera(payload){
  try{
    yield call(
      request.put,
      `/layouts/areas/camera/${payload.properties.camera_id}/`,
      payload,
    );
  } catch (error){
    errorNotification('Ошибка при обновлении камер')
  }
}

function* createCamera(payload){
  try{
    yield call(
      request.post,
      `/layouts/areas/camera/`,
      payload,
    );
  } catch (error){
    errorNotification('Ошибка при попытке связать камеру с областью')
  }
}

function* deleteArea(action) {
  const { id, layoutId } = action.payload;

  try {
    const response = yield call(
      request.delete,
      `layouts/areas/${id}/`
    );
    yield put(deleteAreaSuccess(response.data));
    successNotification(`Область успешно удалена`);
    yield put(nominateToDelete(null));
    yield put(getAreasRequest({ layoutId }));
  } catch (error) {
    yield put(deleteAreaFailure(error));
    errorNotification('Произошла неизвестная ошибка');
  }
}

function* updateArea(action) {
  const { form, layoutId } = action.payload;
  const { region, id, camera, device, ...properties } = form;
  region.id = id;
  region.properties = properties;
  region.properties.layout = layoutId;

  let point, cameraId, deviceId

  if(!!camera){
    point = camera.point;
    cameraId = camera.cameraId
    point.properties = point.properties || {};
  }
  if(!!device){
    point = device.point;
    deviceId = device.cameraId
    point.properties = point.properties || {};
  }

  try {
    const response = yield call(
      request.put,
      `/layouts/areas/${id}/`,
      region,
    );

    if(camera){
      point.properties.camera_id = cameraId;
      point.properties.area = response.data.id;

      yield* updateCamera(point)
    }

    yield put(updateAreaSuccess(response.data));
    yield put(clearForm());
    yield put(setIsEditing(false));
    yield put(getAreasRequest({ layoutId }));
    successNotification(`Область успешно обновлена`);
  } catch (error) {
    yield put(updateAreaFailure(error));
    errorNotification('Произошла неизвестная ошибка');
  }
}

function* getCamera(action){
  const payload = action.payload
  const id = 2

  try{
    const response = yield call(request.get, `/layouts/areas/${id}`)

    yield put(getCameraListSuccess(response.data))
    successNotification(`Камеры успешно загрузились`);
  } catch (error){
    yield put(getCameraListFailure(error))
    errorNotification('Ошибка при загрузке камер')
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_AREAS_REQUEST, getAreas),
    takeLatest(CREATE_AREA_REQUEST, createArea),
    takeLatest(DELETE_AREA_REQUEST, deleteArea),
    takeLatest(UPDATE_AREA_REQUEST, updateArea),
    takeLatest(GET_CAMERA_LIST_REQUEST, getCamera)
  ])
}

export default Saga;
