export const SET_KEEPERS_DATA = 'SET_KEEPERS_DATA';
export const SET_KEEPERS_FETCHING = 'SET_KEEPERS_FETCHING';
export const SET_KEEPERS_ERROR = 'SET_KEEPERS_ERROR';

export const setKeepersLoading = (payload) => ({
  type: SET_KEEPERS_FETCHING,
  payload,
});
export const setKeepersData = (payload) => ({
  type: SET_KEEPERS_DATA,
  payload,
});
export const setKeepersError = (payload) => ({
  type: SET_KEEPERS_ERROR,
  payload,
});
