export const markerTypeSelect = (t) => [
  { name: 'EMPLOYEE', label: t('employee') },
  { name: 'ITEM', label: t('object') },
  { name: 'VEHICLE', label: t('vehicle') },
  { name: 'EMPLOYEE_CHOOSE', label: t('dedicEmployee') },
  { name: 'ITEM_CHOOSE', label: t('selectedObject') },
  { name: 'VEHICLE_CHOOSE', label: t('dedicVehicle') },
  { name: 'EMPLOYEE_LOW_BATTERY', label: t('disChargEmployee') },
  { name: 'ITEM_LOW_BATTERY', label: t('disChargObject') },
  { name: 'VEHICLE_LOW_BATTERY', label: t('disChargedVehicle') },
  { name: 'EMPLOYEE_SITUATION', label: t('eventWithEmployee') },
  { name: 'ITEM_SITUATION', label: t('eventWithObject') },
  { name: 'VEHICLE_SITUATION', label: t('eventWithVehicle') },
  { name: 'EMPLOYEE_INTRUDER', label: t('violatorEmployee') },
  { name: 'ITEM_INTRUDER', label: t('violatorObject') },
  { name: 'VEHICLE_INTRUDER', label: t('violatorVehicle') },
];

export const getMarkerLabel = (marker, t) => {
  const target = markerTypeSelect(t).find((item) => item.name === marker);
  return target ? target.label : marker;
};
