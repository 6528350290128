import { Tooltip } from 'antd';
import { ReactComponent as InactiveGoal } from 'assets/icons/goal-disabled.svg';
import { ReactComponent as GoalLogo } from 'assets/icons/goal.svg';
import { SHOW_MAP } from 'consts/urls';
import { ReportHelper } from 'helpers/report';
import { useQuery } from 'hooks/query';
import { t } from 'i18next';

export const ReportActionLayoutOpener = ({ record }) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { query } = useQuery();
  const isCoordinatesExist = ReportHelper.isCoordinatesExist(record);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------
  function getPath() {
    const { x, y, z } = record.coordinates;
    const eventType = query.tab ?? 'events';
    const point = `${(+x).toFixed(2)}/${(+y).toFixed(2)}/${(+z).toFixed(2)}`;
    return (
      SHOW_MAP +
      record.layout.id +
      `/point/${point}/report/${eventType}/${record.id}`
    );
  }

  // ----------------------------------------------------------------------------------------
  return (
    <>
      {isCoordinatesExist ? (
        <GoalLogo
          onClick={() => window.open(getPath(), '_blank')}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <Tooltip title={t('report.noCoordinateOrMap')}>
          <InactiveGoal />
        </Tooltip>
      )}
    </>
  );
};
