import { UploadMap } from 'pages';
import { ReportsPage } from 'pages/Reports';
import { SandwichPage } from 'pages/Sandwich/Sandwich';
import EmployeesConfiguration from 'pages/Settings/Employees';
import GroupsConfiguration from 'pages/Settings/Groups';
import RotationsConfiguration from 'pages/Settings/Rotations';
import SettingsConfiguration from 'pages/Settings/Settings';
import Smtp from 'pages/Settings/Smtp';
import TransportsConfiguration from 'pages/Settings/Transports/transaports.component';
import { Area } from '../pages/Areas/Area';
import { Areas } from '../pages/Areas/Areas';
import Authorization from '../pages/Authorization';
import DrawPolygons from '../pages/DrawPolygons';
import EditMap from '../pages/EditMap';
import Home from '../pages/Home';
import PutAnchors from '../pages/PutAnchors';
import SettingsPage from '../pages/Settings';
import CategoriesPage from '../pages/Settings/Categories';
import { DevicesPage } from '../pages/Settings/Devices';
import Macroscop from '../pages/Settings/Macroscop';
import MapsConfiguration from '../pages/Settings/Maps';
import MapMarkerConfiguration from '../pages/Settings/Markers';
import OrganizationsConfiguration from "../pages/Settings/Organizations/organizations.component";
import NotificationConfiguration from '../pages/Settings/Reactions/new';
import UsersConfiguration from '../pages/Settings/Users/users.component.jsx';
import ShowMap from '../pages/ShowMap/ShowMap';
import ChartsPage from '../pages/Statistics';
import {
  DRAW_POLYGONS,
  DRAW_POLYGONS_MAP_ID,
  EDIT_MAP,
  EDIT_MAP_ID,
  LOGIN,
  MAIN,
  PUT_ANCHORS,
  PUT_ANCHORS_URL,
  REPORTS,
  SANDWICH,
  SETTINGS,
  SETTINGS_CATEGORIES,
  SETTINGS_DEVICES,
  SETTINGS_EMPLOYESS,
  SETTINGS_GROUPS,
  SETTINGS_MACROSCOP,
  SETTINGS_MAPS,
  SETTINGS_MAP_AREA,
  SETTINGS_MAP_AREA_CREATE,
  SETTINGS_MAP_AREA_EDIT,
  SETTINGS_MAP_MARKERS,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_ORGANIZATIONS,
  SETTINGS_ROTATIONS,
  SETTINGS_SETTING,
  SETTINGS_SMPT,
  SETTINGS_TRANSPORTS,
  SETTINGS_USERS,
  SHOW_MAP,
  SHOW_MAP_ID,
  SHOW_MAP_ID_NOTIFICATION,
  SHOW_MAP_ID_POINT,
  SHOW_MAP_ID_REPORT,
  STATISTICS,
  UPLOAD_MAP,
} from './urls';

export const toShowMapId = (mapId) => SHOW_MAP + mapId;
export const getPointOnMapPath = (mapId, coords) => {
  const { x, y, z } = coords ?? {};
  if (mapId && x && y && z) {
    return (
      SHOW_MAP +
      mapId +
      `/point/${(+x).toFixed(2)}/${(+y).toFixed(2)}/${(+z).toFixed(2)}`
    );
  }
  return null;
};
export const getPointOnMapPathNotification = (
  mapId,
  coords,
  notificationId
) => {
  const { x, y, z } = coords ?? {};
  if (mapId && x && y && z) {
    return (
      SHOW_MAP +
      mapId +
      `/point/${(+x).toFixed(2)}/${(+y).toFixed(2)}/${(+z).toFixed(
        2
      )}/${notificationId}`
    );
  }
  return null;
};
export const toEditMapId = (mapId) => EDIT_MAP + mapId;
export const toDrawPolygonsMapId = (mapId) => DRAW_POLYGONS + mapId;
export const toPutAnchors = (mapId) => `${PUT_ANCHORS}/${mapId}`;
export const getMapAreasPath = (mapId) => `${SETTINGS}/map/${mapId}/areas`;
export const getCreateMapAreaPath = (mapId) =>
  `${SETTINGS}/map/${mapId}/areas/create`;
export const getEditMapAreaPath = (mapId, areaId) =>
  `${SETTINGS}/map/${mapId}/areas/${areaId}/edit`;

export const ROUTES = [
  {
    isPrivate: false,
    exact: true,
    path: LOGIN,
    component: Authorization,
  },
  {
    isPrivate: true,
    exact: true,
    path: MAIN,
    component: Home,
  },
  {
    isPrivate: true,
    exact: true,
    path: UPLOAD_MAP,
    component: UploadMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: EDIT_MAP_ID,
    component: EditMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: SHOW_MAP_ID_POINT,
    component: ShowMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: SHOW_MAP_ID_NOTIFICATION,
    component: ShowMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: SHOW_MAP_ID_REPORT,
    component: ShowMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: SHOW_MAP_ID,
    component: ShowMap,
  },
  {
    isPrivate: true,
    exact: true,
    path: SANDWICH,
    component: SandwichPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS,
    component: SettingsPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_CATEGORIES,
    component: CategoriesPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_NOTIFICATIONS,
    component: NotificationConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MAP_MARKERS,
    component: MapMarkerConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MAPS,
    component: MapsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_EMPLOYESS,
    component: EmployeesConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_SETTING,
    component: SettingsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_ROTATIONS,
    component: RotationsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_GROUPS,
    component: GroupsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_USERS,
    component: UsersConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_ORGANIZATIONS,
    component: OrganizationsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_TRANSPORTS,
    component: TransportsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_GROUPS,
    component: GroupsConfiguration,
  },
  {
    isPrivate: true,
    exact: true,
    path: DRAW_POLYGONS_MAP_ID,
    component: DrawPolygons,
  },
  {
    isPrivate: true,
    exact: true,
    path: PUT_ANCHORS_URL,
    component: PutAnchors,
  },
  {
    isPrivate: true,
    exact: true,
    path: REPORTS,
    component: ReportsPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: STATISTICS,
    component: ChartsPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_DEVICES,
    component: DevicesPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MACROSCOP,
    component: Macroscop,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_SMPT,
    component: Smtp,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MAP_AREA,
    component: Areas,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MAP_AREA_CREATE,
    component: Area,
  },
  {
    isPrivate: true,
    exact: true,
    path: SETTINGS_MAP_AREA_EDIT,
    component: Area,
  },
];
