import { Select } from 'antd';
import { getQueryParams } from 'hooks/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { reportEventEventsType } from '../../reports-page.registry';

export function ReportSelectNotificationType({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const { t } = useTranslation();
  const eventTypeValue = useCallback(() => {
    let currentEventType = null;
    if (!tempFilterParams) return;
    const queryParams = getQueryParams();
    if (tempFilterParams.event_type) {
      currentEventType = Object.keys(reportEventEventsType(t)).find(
        (event) => event === tempFilterParams.event_type
      );
    } else {
      currentEventType = Object.keys(reportEventEventsType(t)).find(
        (event) => event === queryParams.event_type
      );
    }

    if (!currentEventType) return null;
    return currentEventType;
  }, [tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={t('notificationType')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={eventTypeValue()}
      onChange={(value) => {
        setTempFilterParams({
          ...tempFilterParams,
          event_type: value,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {Object.keys(reportEventEventsType(t)).map((key) => (
        <Select.Option value={key}>
          {reportEventEventsType(t)[key]}
        </Select.Option>
      ))}
    </Select>
  );
}
