import { request } from 'helpers/client';
import { convertFromMeters, getMarkerIcon } from 'helpers/mapHelpers';
import { t } from 'i18next';
import L from 'leaflet';
import { mapStore } from 'model/MapStore';
import { useEventTypeNameMapper } from 'pages/Settings/Reactions/utils';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import goalIcon from './../../../assets/icons/goal.svg';
import { useNotifications } from './notification.hook';

export const useNotificationMapExtention = ({ map, mapData }) => {
  /**
   * @type {{x : string,y : string,z : string,notificationId : string,reportType : string,reportId : string}}
   */
  const { x, y, z, notificationId, reportType, reportId } = useParams();
  const notification = useNotifications();
  const eventTypeNameMapper = useEventTypeNameMapper();

  async function loadReport() {
    const report = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/base_reports/${reportType}/` +
        reportId
    );
    return report.data;
  }

  useEffect(() => {
    draw();
  }, [
    map,
    mapData,
    x,
    y,
    z,
    notificationId,
    reportType,
    reportId,
    notification,
  ]);

  const draw = useCallback(async () => {
    if (map && mapData && x && y && z) {
      const [lat, lng] = convertFromMeters([[+y, +x]], mapData)?.[0];
      if (lat && lng) {
        if (reportType) {
          if (reportType === 'events') {
            const report = await loadReport();
            const employees = report.employees
              .map(
                (employee) =>
                  `${employee.name ?? ''} ${employee.surname ?? ''} ${
                    employee.patronymic ?? ''
                  }`
              )
              .join(' / ');
            const time = new Date(report.created_at)
              .toLocaleString('en-US', { hour12: false })
              .split('/')
              .join('.');
            L.marker(L.latLng(lat, lng), { icon: getMarkerIcon(goalIcon) })
              .bindPopup(
                `${
                  eventTypeNameMapper[report.event_type]
                }<br> ${time} <br> ${employees}`
              )
              .addTo(map)
              .openPopup();
          } else if (reportType === 'employee_transport_convergence') {
            const report = await loadReport();
            const title = t('report.transportEmployeeConvergence');
            const time = new Date(report.created_at)
              .toLocaleString('en-US', { hour12: false })
              .split('/')
              .join('.');
            L.marker(L.latLng(lat, lng), { icon: getMarkerIcon(goalIcon) })
              .bindPopup(
                `${title}<br>${time} <br> ${
                  t('employee') + ': ' + report.to_employee_name
                } <br> ${t('transport') + ': ' + report.transport_name}`
              )
              .addTo(map)
              .openPopup();
          } else {
            const report = await loadReport();
            const title = t('interraction');
            const time = new Date(report.created_at)
              .toLocaleString('en-US', { hour12: false })
              .split('/')
              .join('.');
            const by_employee = `${report.by_employee.name ?? ''} ${
              report.by_employee.surname ?? ''
            } ${report.by_employee.patronymic ?? ''}`;
            const to_employee = `${report.to_employee.name ?? ''} ${
              report.to_employee.surname ?? ''
            } ${report.to_employee.patronymic ?? ''}`;
            L.marker(L.latLng(lat, lng), { icon: getMarkerIcon(goalIcon) })
              .bindPopup(
                `${title}<br>${time} <br> ${by_employee} <br> ${to_employee}`
              )
              .addTo(map)
              .openPopup();
          }
        } else if (notificationId) {
          const currentNotification = await notification.load(notificationId);
          const notificationSubject = `${
            currentNotification.associated_employee.name ?? ''
          } ${currentNotification.associated_employee.surname ?? ''} ${
            currentNotification.associated_employee.patronymic ?? ''
          }`;
          const notificationTime = new Date(currentNotification.time)
            .toLocaleString('en-US', { hour12: false })
            .split('/')
            .join('.');
          L.marker(L.latLng(lat, lng), { icon: getMarkerIcon(goalIcon) })
            .bindPopup(
              `${
                eventTypeNameMapper[currentNotification.type]
              }<br> ${notificationTime} <br> ${notificationSubject}`
            )
            .addTo(map)
            .openPopup();
        } else {
          L.marker(L.latLng(lat, lng), { icon: getMarkerIcon(goalIcon) })
            .bindPopup(`x: ${x}, y: ${y}, z: ${z}`)
            .addTo(map)
            .openPopup();
        }

        mapStore.setLayerState('users', false);
      }
    }
  }, [map, x, y, z, notificationId, reportType, reportId, mapData]);
};
