import { notification } from 'antd';
import L from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { buildFormData } from 'helpers/formData';
import { getFinalBounds } from 'helpers/getters';
import { categoriesRequest } from 'store/actions/categories';
import {
  editMapRequest,
  setGetSingleMapData, uploadMapRequest
} from 'store/actions/svgMap';
import { makeSelectGetSingleMap } from 'store/selectors/svgMap';

import { useTranslation } from 'react-i18next';
import { createArea, updateArea } from '../../../services/areas';
import { CRSMap } from './components/CRSMap/CRSMap';
import { SideBar } from './components/SideBar/SideBar';
import './index.scss';
import submit from './submit';
import {mapStore} from "../../../model/MapStore";

export const MapArea = ({ map }) => {
  const { t } = useTranslation()
  const history = useHistory();
  const submitRef = useRef(null);
  const { mapId, areaId } = useParams();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState();
  const [coordinatesFromValues, setCoordinatesFormValues] = useState({});
  const [pointA, setPointA] = useState();
  const [pointL0, setPointL0] = useState();
  const [pointL1, setPointL1] = useState();
  const [ratio, setRatio] = useState();
  const [bounds, setBounds] = useState();
  const [boundsEditingMode, setBoundsEditingMode] = useState(false);
  const [boundsCoords, setBoundsCoords] = useState();
  const [segmentLength, setSegmentLength] = useState();
  const [area, setArea] = useState();
  const location = useLocation()
  const instance = useSelector(makeSelectGetSingleMap);
  const tempSingleMap = useSelector(makeSelectGetSingleMap);
  const [singleMap, setSingleMap] = useState()



  useEffect(() => {
    if (tempSingleMap.id === mapId) {
      setSingleMap(tempSingleMap)
    }
  }, [tempSingleMap, mapId])


  async function loadArea() {
    await mapStore.fetchMaps()
    await mapStore.fetchAreas()
    const map = mapStore.maps[mapId]
    if ((areaId && map && Object.keys(map.areas)) || (area && area.id !== areaId)) {
      for (const key in map.areas) {
        if (key === areaId) {
          setArea(map.areas[key])
          return
        }
      }
    }
  }

  useEffect(() => {
    loadArea()
  }, [areaId, mapId])

  useEffect(() => {
    if (singleMap && !bounds) {
      const { min_x = 0, min_y = 0, max_x = 0, max_y = 0 } = singleMap;
      if (max_x && max_y) {
        setBounds([
          { lat: min_y, lng: min_x },
          { lat: max_y, lng: max_x },
        ])
      }
    }
  }, [bounds, singleMap]);

  useEffect(() => {
    if (singleMap?.bounds?.length && !boundsCoords) {
      setBoundsCoords(singleMap.bounds);
    }
  }, [boundsCoords, singleMap]);

  useEffect(() => {
    if (!pointA) {
      const { lat = 0, lng = 0 } = singleMap?.point1 || {};
      setPointA(L.latLng([lat, lng]));
    }
  }, [pointA, singleMap]);

  useEffect(() => {
    const { lat, lng } = singleMap?.point2 || {};
    if (lat !== undefined && lng !== undefined && !pointL0) {
      setPointL0(L.latLng([lat, lng]));
    }
  }, [pointL0, singleMap?.point2]);

  useEffect(() => {
    const { lat, lng } = singleMap?.point3 || {};
    if (lat !== undefined && lng !== undefined && !pointL1) {
      setPointL1(L.latLng([lat, lng]));
    }
  }, [pointL1, singleMap?.point3]);

  useEffect(() => {
    if (singleMap?.segment_length && !segmentLength) {
      setSegmentLength(singleMap.segment_length);
    }
  }, [segmentLength, singleMap]);

  const onSubmitName = (values) => {
    onSubmit({ ...values, ...coordinatesFromValues });
  };

  const uploadMap = (formData) => {
    dispatch(uploadMapRequest(formData));
  };

  const getCategoriesList = useCallback(() => {
    dispatch(categoriesRequest());
  }, [dispatch]);

  const onSubmitCoordinates = (values) => {
    setCoordinatesFormValues(values);
    submitRef.current.click();
  };

  const onSubmit = (values) => {
    const { x, y, segment_length, ...rest } = values;
    const [, { lat: height, lng: width }] = bounds;
    const finalBounds = getFinalBounds({
      segment_length,
      x,
      y,
      pointA,
      pointL0,
      pointL1,
      height,
      width,
    });

    const result = {
      ...finalBounds,
      ...rest,
      // pointA,
      // pointL0,
      // pointL1,
      file: imageFile,
    };

    const [
      { lat: min_y = 0, lng: min_x = 0 },
      { lat: max_y = 0, lng: max_x = 0 }
    ] = bounds || [{}, {}];

    const resultNew = {
      ...values,
      min_x,
      min_y,
      max_x,
      max_y,
      ratio: ratio || 0,
      file: imageFile
    };

    const formData = new FormData();

    if (pointA) formData.append('point1', JSON.stringify(pointA));
    if (pointL0) formData.append('point2', JSON.stringify(pointL0));
    if (pointL1) formData.append('point3', JSON.stringify(pointL1));
    if (boundsCoords) formData.append('bounds', JSON.stringify(boundsCoords));

    Object.entries(resultNew).forEach(([key, value]) => {
      buildFormData(formData, value, key);
    });

    if (!!mapId) {
      const data = submit(resultNew, instance);
      formData.delete('file');

      dispatch(editMapRequest({ formData, id: mapId }));
    } else {
      uploadMap({ formData });
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  useEffect(() => {
    return () => {
      dispatch(setGetSingleMapData(null));
    };
  }, [dispatch]);


  const image = useMemo(
    () => {
      if (singleMap?.file) {
        const list = singleMap.file.split('/');
        if (list[1] === 'media') {
          const host = process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_BACK_END_URL
            : window.location.origin;
          singleMap.file = `${host}${singleMap.file}`;
        }
      }
      return imageFile ? URL.createObjectURL(imageFile) : singleMap?.file
    },
    [imageFile, singleMap]);

  const setFormValues = (values) => {
    if (mapId) {
      values.layout = mapId;
      values.coordinates = area?.coordinates || [];
      const fn = () => areaId ? updateArea(areaId, values) : createArea(values);
      fn()
        .then((res) => {
          if (areaId) {
            notification.success({ message: t('succesUpdate') });
          } else if (mapId) {
            history.push(`/settings/map/${mapId}/areas`);
            notification.success({ message: t('succesCreated') });
          }
        })
        .catch((e) => {
          console.error(e);
          notification.error({ message: t('zoneCreationError') });
        });
    }
  };

  return (
    <div className="content">
      <SideBar setFormValues={setFormValues} area={area} />
      <div className="content-page">
        {imageFile || !!singleMap ? (
          <CRSMap
            area={area}
            setArea={setArea}
            bounds={bounds}
            boundsCoords={boundsCoords}
            boundsEditingMode={boundsEditingMode}
            image={image}
            setBounds={setBounds}
            setBoundsCoords={setBoundsCoords}
            setBoundsEditingMode={setBoundsEditingMode}
          />
        ) : (
          <h4>
            {t('siftplfcsc')}
          </h4>
        )}
      </div>
    </div>
  );
};
