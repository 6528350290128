import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  OWS_REQUEST,
  setOwsError,
  setOwsLoading,
  setOwsData,
} from '../actions/ows';
import client from '../../helpers/client';

function* getOws({ payload: params }) {
  try {
    yield put(setOwsLoading(true));
    const response = yield call(client.get, '/geoserver/rtls/ows', { params });
    const result = response.data.replace(/[()]|parseResponse/gi, '');

    yield put(setOwsData(JSON.parse(result)));
  } catch (err) {
    yield put(setOwsError(err));
  } finally {
    yield put(setOwsLoading(false));
  }
}

function* Saga() {
  yield all([takeLatest(OWS_REQUEST, getOws)]);
}

export default Saga;
