import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import { BiometryEventCount } from './biometry-events-count';
import { BiometryEventsPosition } from './biometry-events-position';
import { BiometryEventsTags } from './biometry-events-tags';
import { ChartsSOSCount } from './charts-sos-count';
import { ChartsTagsCharger } from './charts-tags-charge';
import { EmployeesByMap } from './employees-by-map';
import { EmployeesObObject } from './employees-on-object';
import { EmployeesOnObjectByOrganization } from './employees-on-object-by-organization';
import { EmployeesOnObjectByPosition } from './employees-on-object-by-position';

const Charts = () => {
  return (
    <Col>
      <Row justify={'center'} style={{ margin: '25px 0px 0px 0px ' }}>
        <Typography.Title
          level={4}
          style={{ color: '#25ACE3', textDecoration: 'underline' }}
        >
          Время обновления: {moment().format('DD.MM.YYYY HH:mm:ss')}
        </Typography.Title>
      </Row>
      <div className="statistics-page">
        <EmployeesObObject />
        <EmployeesByMap />
        <EmployeesOnObjectByPosition />
        <ChartsSOSCount />
        <EmployeesOnObjectByOrganization />
        <ChartsTagsCharger />
        <BiometryEventCount/>
        <BiometryEventsTags/>
        <BiometryEventsPosition/>
      </div>
    </Col>
  );
};

export default Charts;
