import { Modal } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryForm from './CategoryForm';

const FormModal = ({ handleOk, handleCancel, category, loading }) => {
  const submitRef = useRef(null);
  const {t} = useTranslation()
  const handleClickOk = () => {
    submitRef.current.click();
  };
  return (
    <>
      <Modal
        title={category?.id ? t('edit') : t('add')}
        visible={true}
        onOk={handleClickOk}
        onCancel={handleCancel}
        okText={
          loading
            ? t('loading') + '...'
            : category?.id
            ? t('save')
            : t('create')
        }
        cancelText={t('cancel')}
      >
        <CategoryForm
          category={category}
          submitRef={submitRef}
          handleSubmit={handleOk}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default FormModal;
