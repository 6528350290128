import { StarOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BookmarkMiniLogo } from '../../../assets/icons/bookmark-mini.svg';
import { ReactComponent as BookMarkLogo } from '../../../assets/icons/bookmark.svg';
import { ReactComponent as ClockMiniLogo } from '../../../assets/icons/clock-mini.svg';
import { ReactComponent as StartMiniYellowLogo } from '../../../assets/icons/star-mini-yellow.svg';
import { ReactComponent as StartMiniLogo } from '../../../assets/icons/star-mini.svg';
import { BOOKMARKS } from '../../../consts/tooltip';
import { setFavoriteDrawerVisible } from '../../../store/actions/labels';
import {
  makeSelectGetFavorites,
  makeSelectGetFrequentlyVisited,
  makeSelectGetHistory,
} from '../../../store/selectors/user';

import { defaultParams } from '../../../consts/options';
import { SHOW_MAP } from '../../../consts/urls';
import {
  addFavoritesRequest,
  getFavoritesRequest,
  getFrequentlyVisitedRequest,
  getHistoryRequest,
} from '../../../store/actions/user';
import { makeSelectIsFavoriteDrawerVisible } from '../../../store/selectors/labels';
import { useTranslation } from 'react-i18next';

const FavoriteMaps = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const favoritesList = useSelector(makeSelectGetFavorites);
  const visibleBook = useSelector(makeSelectIsFavoriteDrawerVisible);
  const frequentlyVisitedList = useSelector(makeSelectGetFrequentlyVisited);
  const historyList = useSelector(makeSelectGetHistory);

  const addToFavorites = (id) => {
    dispatch(addFavoritesRequest({ id }));
  };

  const handleGoToMap = (id) => {
    history.push(`${SHOW_MAP}${id}`);
  };

  const handleCloseDrawer = () => {
    dispatch(setFavoriteDrawerVisible());
  };

  const showBook = () => {
    dispatch(setFavoriteDrawerVisible());
  };

  useEffect(() => {
    dispatch(getFavoritesRequest(defaultParams));
    dispatch(getFrequentlyVisitedRequest(defaultParams));
    dispatch(getHistoryRequest(defaultParams));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Tooltip title={t('bookmarks')}>
        <Button
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
          }}
          shape="circle"
          type="link"
          onClick={showBook}
        >
          <BookMarkLogo />
        </Button>
      </Tooltip>
      <Drawer
        className="book-drawer"
        headerStyle={{
          color: '#25ACE3',
        }}
        contentWrapperStyle={{
          width: 350,
        }}
        title={t('bookmarks')}
        placement={'right'}
        closable={true}
        onClose={handleCloseDrawer}
        visible={visibleBook}
        key={'right'}
      >
        <div className="book-drawer__body">
          <p
            className="book-drawer__body-title"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              paddingLeft: 24,
              fontWeight: 500,
              fontSize: 18,
              lineHeight: '21px',
              color: '#353535',
            }}
          >
            <ClockMiniLogo
              style={{
                marginRight: 15,
              }}
            />
            {t('recentZones')}
          </p>
          <ul
            style={{
              paddingLeft: 64,
            }}
          >
            {historyList &&
              historyList.map(({ layout }) => {
                return (
                  <li className="frequent-zone__item" key={layout.id}>
                    <span
                      className="icon_wrapper"
                      onClick={() => addToFavorites(layout.id)}
                    >
                      {layout.is_favorite ? (
                        <StartMiniYellowLogo />
                      ) : (
                        <StarOutlined className="outlined-star" />
                      )}
                    </span>
                    <span onClick={() => handleGoToMap(layout.id)}>
                      {layout.name}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
        <Divider />
        <div className="book-drawer__body">
          <p
            className="book-drawer__body-title"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              paddingLeft: 24,
              fontWeight: 500,
              fontSize: 18,
              lineHeight: '21px',
              color: '#353535',
            }}
          >
            <BookmarkMiniLogo
              style={{
                marginRight: 15,
              }}
            />
            {t('frequentZones')}
          </p>
          <ul
            style={{
              paddingLeft: 64,
            }}
          >
            {frequentlyVisitedList &&
              frequentlyVisitedList.map(({ name, is_favorite, id }) => {
                return (
                  <li className="frequent-zone__item" key={id}>
                    <span className="icon_wrapper">
                      {is_favorite ? (
                        <StartMiniYellowLogo
                          onClick={() => addToFavorites(id)}
                        />
                      ) : (
                        <StarOutlined
                          className="outlined-star"
                          onClick={() => addToFavorites(id)}
                        />
                      )}
                    </span>
                    <span onClick={() => handleGoToMap(id)}>{name}</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <Divider />
        <div className="favorites book-drawer__body">
          <p
            className="book-drawer__body-title"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              paddingLeft: 24,
              fontWeight: 500,
              fontSize: 18,
              lineHeight: '21px',
              color: '#353535',
            }}
          >
            <StartMiniLogo
              style={{
                marginRight: 15,
              }}
            />
            {t('favorites')}
          </p>
          <ul
            style={{
              paddingLeft: 56,
            }}
          >
            {favoritesList &&
              favoritesList.map(({ name, id }) => {
                return (
                  <li
                    style={{
                      listStyle: 'none',
                      color: '#9E9E9E',
                      fontSize: 16,
                      lineHeight: '19px',
                      marginTop: 10,
                    }}
                    key={id}
                    className="favorites__item"
                  >
                    <span className="icon_wrapper">
                      <StartMiniYellowLogo
                        style={{
                          marginRight: 8,
                        }}
                        onClick={() => addToFavorites(id)}
                      />
                    </span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleGoToMap(id)}
                    >
                      {name}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default FavoriteMaps;
