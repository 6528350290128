
export const userSettingsCreateFormRoleTypes = [
  {
    name: 'manager',
  },
  {
    name: 'administrator',
  },
  {
    name: 'operator',
  },
];

export const USERS_CONFIG = 'usersConfig';
export const CREATE_FORM = `${USERS_CONFIG}_create`;
export const EDIT_FORM = `${USERS_CONFIG}_edit`;
