import { BellOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import moment from 'moment';
import { snakeToCamel } from '../../helpers/notificationHelpers';

/**
 * @return {any}
 */
export const XlsFileProcessingNotification = (xlsReady, t) => {
  return {
    placement: 'topRight',
    icon: <BellOutlined style={{ color: 'rgb(0, 162, 232)' }} />,
    message: (
      <Typography.Text>
        {t('excelFileCreating', {
          val: xlsReady.id,
        })}
      </Typography.Text>
    ),
    duration: 8,
    description: (
      <Space direction={'vertical'} style={{ padding: 5 }}>
        {xlsReady.name && (
          <Typography.Text>
            {t('title')}: {t(`report.${snakeToCamel(xlsReady.name)}`)}
          </Typography.Text>
        )}

        <Typography.Text>
          {t('time')}:
          {moment(xlsReady.created_at).format('DD.MM.YYYY HH:mm:ss')}
        </Typography.Text>
      </Space>
    ),
  };
};

export const XlsFileReadyNotification = (xlsReady, t) => {
  return {
    placement: 'topRight',
    icon: <BellOutlined style={{ color: 'rgb(0, 162, 232)' }} />,
    message: (
      <Typography.Text>
        {t('excelFileReady', {
          val: xlsReady.id,
        })}
      </Typography.Text>
    ),
    duration: 8,
    description: (
      <Space direction={'vertical'} style={{ padding: 5 }}>
        <Typography.Text>
          {t('title')}: {t(`report.${snakeToCamel(xlsReady.name)}`)}
        </Typography.Text>
        <Typography.Text>
          {t('time')}:{' '}
          {moment(xlsReady.created_at).format('DD.MM.YYYY HH:mm:ss')}
        </Typography.Text>
        <Typography.Text>
          {t('excelFileRowsC', {
            val: xlsReady.extra_information.rows_count,
          })}
        </Typography.Text>

        <Typography.Text
          style={{
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => window.open(xlsReady.file, '_blank')}
        >
          {t('linkToFile')}
        </Typography.Text>
      </Space>
    ),
  };
};

export const XlsFileErrorNotification = (queueId, t) => {
  return {
    placement: 'topRight',
    icon: <BellOutlined style={{ color: 'rgb(0, 162, 232)' }} />,
    message: (
      <Typography.Text>
        {t('excelFileReady', {
          val: queueId,
        })}
      </Typography.Text>
    ),
    duration: 8,
    description: (
      <Space direction={'vertical'} style={{ padding: 5 }}>
        <Typography.Text>{t('errorOnFileCreation')}</Typography.Text>
      </Space>
    ),
  };
};
