import client from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const BiometryEventCount = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    client
      .get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/dashboards/biometry_events_count`
      )
      .then((response) => {
        setCount(response.data.today);
      });
  }, []);

  return (
    <div className="char-component-wrapper">
      <div className="char-component-title">
        <span>{t('dashboard.countOfHealthEvent')}</span>
      </div>
      <div className="char-component">
        <div className="char-wrapper">
          <div className="content">
            <div
              className="char-pie-count"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('reports?tab=events&for_today_biometrics=true&page=1');
              }}
            >
              <span className="char-pie-text">{count}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
