const getState = state => state.forms;

export const selectFormValues = form => state => {
  form = getState(state)[form];
  if (!form) return {};

  return Object.keys(form).reduce(
    (memo, key) => {
      if (key === '_META') return memo;
      return Object.assign(memo, { [key]: form[key] });
    },
    {}
  );
};
