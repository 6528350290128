import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SosLargeLogo } from '../../../../assets/icons/sos-large.svg';
import { sendSOS } from '../../../../services/labels';

const CheckboxGroup = Checkbox.Group;

function SosPopoverContent({ labels }) {
  const {t} = useTranslation()

  const plainOptions = labels.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const [checkedList, setCheckedList] = useState([]);
  const [options, setOptions] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const formOpener = useFormOpener();
  const onChange = (list) => {
    setCheckedList(list);

    setIndeterminate(!!list.length && list.length < options.length);

    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map(({ value }) => value) : []);

    setIndeterminate(false);

    setCheckAll(e.target.checked);
  };

  const onSearch = (e) => {
    const value = e.target.value;

    if (value) {
      const searchedOptions = plainOptions.filter(({ label }) =>
        label.toLowerCase().includes(value.toLowerCase())
      );

      setOptions(searchedOptions);
    } else {
      setInitialOptions();
    }
  };

  const handleSubmit = () => {
    if (!checkedList.length) return;

    formOpener.open({
      title: t('sosSignal'),
      submitText: t('send'),
      component: <>{t('sendSosQ')} </>,
      onSubmit: () => {
        sendSOS(checkedList);
        formOpener.close();
      },
    });
  };

  const setInitialOptions = () => {
    setOptions(plainOptions);
  };

  useEffect(() => {
    setInitialOptions();
  }, [labels]);

  return (
    <div className="sos-checkbox__wrapper">
      <div className="footer">
        <Input
          className="search"
          placeholder={t('startTyping')}
          onChange={onSearch}
          suffix={<SearchOutlined />}
        />
        <span>
          <SosLargeLogo
            onClick={handleSubmit}
            style={{
              cursor: 'pointer',
            }}
          />
        </span>
      </div>
      <div className="checkbox-group">
        <Checkbox
          className="head"
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t('groups')}
        </Checkbox>
        <CheckboxGroup
          options={options}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.labelsData.data?.results || [],
  };
};

export default connect(mapStateToProps)(SosPopoverContent);
