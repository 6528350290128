import { request } from '../helpers/client';
import axios from 'axios';

export const sendSos = (/** @type {string} */ keeperId) => {
  return request.get(
    process.env.REACT_APP_LOCAL_API_BASE_URL +
      `/send_sos_to_employee/${keeperId}`
  );
};

export const sendGroupSos = (keeperIds) => {
  const requestsList = keeperIds.map((id) => sendSos(id));
  return axios.all(requestsList);
};
