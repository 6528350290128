export const PolygonSvg = () => <svg width="1em" height="1em" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line x1="12.9015" y1="0.432731" x2="0.901523" y2="25.4327" stroke="black" strokeWidth="2"/>
  <line x1="38.9015" y1="0.432731" x2="26.9015" y2="25.4327" stroke="black" strokeWidth="2"/>
  <line x1="38" y1="1" x2="12" y2="1" stroke="black" strokeWidth="2"/>
  <line x1="27" y1="25" x2="1" y2="25" stroke="black" strokeWidth="2"/>
</svg>

export const SquareSvg = () => <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="28" height="28" stroke="black" strokeWidth="2"/>
</svg>
