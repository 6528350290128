export const initialMarker = [52.94462650569327, 62.13709473609924];

export const shapes = [
  // {
  //   shape: [
  //     [52.94541523492811, 62.13526546955109],
  //     [52.94541523492811, 62.135496139526374],
  //     [52.9451857291341, 62.135501503944404],
  //     [52.9451857291341, 62.13527083396912],
  //   ],
  //   name: 'Столовая',
  //   color: 'red',
  // },
  // {
  //   shape: [
  //     [52.94576757243961, 62.13611841201783],
  //     [52.945764339998426, 62.13639199733735],
  //     [52.94564473950455, 62.13639199733735],
  //     [52.94563504215271, 62.13612377643586],
  //   ],
  //   name: 'Участок дробления',
  //   color: 'red'
  // },
  {
    shape: [
      [
        [52.9449917796266, 62.135978937149055],
        [52.9449917796266, 62.136257886886604],
        [52.94502410460489, 62.136263251304634],
        [52.945027337101386, 62.1366173028946],
        [52.94536028294745, 62.13660657405854],
        [52.94536028294745, 62.1365636587143],
        [52.9455477658884, 62.13655829429627],
        [52.9455477658884, 62.13660657405854],
        [52.94557362554062, 62.13660657405854],
        [52.94558009045125, 62.13715910911561],
        [52.945799896838246, 62.13715374469758],
        [52.94580312927678, 62.137448787689216],
        [52.94479136422201, 62.137470245361335],
        [52.9447881317079, 62.137228846549995],
        [52.944917432084836, 62.137228846549995],
        [52.944917432084836, 62.1371752023697],
        [52.945030569597655, 62.13716983795167],
        [52.945030569597655, 62.13714838027955],
        [52.94434850753562, 62.13716983795167],
        [52.94434850753562, 62.137255668640144],
        [52.94427739144092, 62.137255668640144],
        [52.94427739144092, 62.13705182075501],
        [52.945030569597655, 62.13703036308289],
        [52.945027337101386, 62.136987447738655],
        [52.94485924696304, 62.136987447738655],
        [52.944856014453975, 62.137008905410774],
        [52.94463297074545, 62.1370142698288],
        [52.9446232731668, 62.13644027709962],
        [52.944561855118344, 62.13644027709962],
        [52.94455862258706, 62.13617742061616],
        [52.94461680811317, 62.13617205619813],
        [52.94461680811317, 62.135995030403144],
      ],
    ],
    name: 'ЗИиОФ',
    color: 'purple'
  },
  {
    shape: [
      [52.94399454217985, 62.136964648962014],
      [52.94394928612647, 62.13694855570793],
      [52.94374240070816, 62.138768434524536],
      [52.94357996263563, 62.1369445323944],
      [52.94353551430055, 62.136956602334976],
      [52.943697144391024, 62.138768434524536],
      [52.94367532436408, 62.13877245783805],
      [52.94367451621473, 62.13888242840767],
      [52.94372058070404, 62.13888242840767],
      [52.943719772555525, 62.13923916220664],
      [52.943873320501886, 62.13923916220664],
      [52.94387493679316, 62.13893607258796],
      [52.94380947694828, 62.13893473148345],
      [52.943808668801424, 62.138768434524536],
      [52.943787656977975, 62.138768434524536],
      [52.94399454217985, 62.136964648962014]
    ],
    name: "Дробление",
    color: "purple"
  }
];

export const mapInitialSvgBounds = [
  [52.945020135602356, 62.13703822308163],
  [52.94435100356542, 62.1371616046963],
  [52.94502983309203, 62.1371616046963],
  [52.94427342236987, 62.13705968075375],
];
