import { CloseOutlined } from '@ant-design/icons';
import { Form as BaseForm, DatePicker, Input, Select, Switch } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFormValue } from '../../store/actions/forms';
import { selectFormValues } from '../../store/selectors/forms';

import { errorMessage } from '../../helpers/errorHandlers';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EllipsisText } from 'ui-components/ellipsis-text.component';
import store from '../../store';

dayjs.extend(customParseFormat);

const Form = ({ formRef, name, loading, config, error }) => {
  const dispatch = useDispatch();
  const formValues = useSelector(selectFormValues(name));
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleFieldUpdate = (field, value) => {
    dispatch(
      setFormValue({
        form: name,
        field,
        value,
      })
    );
  };

  const handleFileFieldChange = (field, type) => (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (type.startsWith('image')) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event) => {
        handleFieldUpdate(field, {
          file,
          url: event.target.result,
        });
      };
    } else {
      handleFieldUpdate(field, {
        file,
        url: file.name,
      });
    }
  };

  const getValueSetLoading = (item) => {
    if (item.selectType === 'raw') return false;
    return item.getValueSetLoading(store.getState());
  };

  const { fields: errors } = errorMessage(error);

  return (
    <BaseForm
      onSubmit={handleSubmit}
      ref={formRef}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      {config.map((item, idx) => {
        const key = `FORM_${name}_${item.name}_FIELD_${idx}`;
        const value = formValues[item.name];
        if (item.type === 'file') {
          return (
            <BaseForm.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              key={key}
            >
              {value && value.url ? (
                (item.accept || '').startsWith('image') ? (
                  <div className="image-field-preview">
                    <CloseOutlined
                      className="remove"
                      onClick={() =>
                        handleFieldUpdate(item.name, { file: null, url: null })
                      }
                    />
                    <img src={value.url} alt={item.name} />
                  </div>
                ) : (
                  <div className="audio-field-preview">
                    <p>{t('selected')}:</p>
                    <span className="link-chip">
                      <span className="link-el">{value.url}</span>
                      <CloseOutlined
                        className="close"
                        onClick={() =>
                          handleFieldUpdate(item.name, {
                            file: null,
                            url: null,
                          })
                        }
                      />
                    </span>
                  </div>
                )
              ) : (
                <Input
                  type="file"
                  shape="rounded"
                  onChange={handleFileFieldChange(item.name, item.accept)}
                  accept={item.accept}
                  disabled={loading}
                  required={item.required}
                />
              )}
            </BaseForm.Item>
          );
        }

        if (item.type === 'boolean') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              label={<EllipsisText>{item.label}</EllipsisText>}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              getValueProps={() => ({
                value,
              })}
            >
              <Switch
                checked={value}
                onChange={(checked) => handleFieldUpdate(item.name, checked)}
                loading={loading}
              />
            </BaseForm.Item>
          );
        }

        if (item.name === 'violator_term' && !formValues.is_violator) {
          return null;
        }

        if (item.type === 'date-picker') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              rules={[
                { required: Boolean(item.required), message: t('enterText') },
              ]}
              getValueProps={() => ({
                value: value ? moment(value, 'YYYY-MM-DDThh:mm:ss') : null,
              })}
            >
              <DatePicker
                allowClear
                disabled={loading}
                style={{ width: '100%' }}
                format={'YYYY-MM-DD'}
                placeholder="YYYY-MM-MM"
                onChange={(m) =>
                  handleFieldUpdate(item.name, m?.format('YYYY-MM-DDThh:mm:ss'))
                }
              />
            </BaseForm.Item>
          );
        }

        if (item.type === 'date-time-picker') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              rules={[
                { required: Boolean(item.required), message: t('enterText') },
              ]}
              getValueProps={() => ({
                value: value ? moment(value) : null,
              })}
            >
              <DatePicker
                showTime
                allowClear
                disabled={loading}
                style={{ width: '100%' }}
                disabledDate={(current) =>
                  moment(current).isBefore(moment().subtract('1', 'day'))
                }
                format="DD-MM-YYYY HH:mm"
                onChange={(moment) =>
                  handleFieldUpdate(item.name, moment?.format())
                }
              />
            </BaseForm.Item>
          );
        }

        if (item.type === 'valueSet') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              initialValue={
                item.options.find(({ name }) => name === value)?.label
              }
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              rules={[
                { required: Boolean(item.required), message: t('enterText') },
              ]}
              getValueProps={() => ({ value: value === 'null' ? null : value })}
            >
              <Select
                backfill={false}
                className="input"
                onChange={(selected) => {
                  handleFieldUpdate(item.name, selected);
                }}
                // value={value}
                loading={getValueSetLoading(item) || loading}
              >
                {
                  <>
                    {[{ name: null, label: 'Не указано' }, ...item.options].map(
                      (option, index) => (
                        <Select.Option
                          key={`FORM_${name}_SELECT_OPTION_${index}`}
                          value={option.name}
                        >
                          {option.label}
                        </Select.Option>
                      )
                    )}
                  </>
                }
              </Select>
            </BaseForm.Item>
          );
        }

        return (
          <BaseForm.Item
            label={item.label}
            labelCol={{ span: 8 }}
            className="form-row"
            key={key}
            initialValue={value}
            validateStatus={errors[item.name] ? 'error' : ''}
            help={errors[item.name]}
            required={item.required}
            name={item.name}
            rules={[
              { required: Boolean(item.required), message: t('enterText') },
            ]}
            getValueProps={() => ({ value })}
          >
            {item.type === 'textarea' ? (
              <Input.TextArea
                shape="round"
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                // value={value}
                disabled={loading}
                required={item.required}
              />
            ) : (
              <Input
                shape="round"
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                placeholder={item.placeholder}
                style={
                  item.placeholder
                    ? {
                        textOverflow: 'unset',
                        height: '32px',
                        borderRadius: '0px',
                        border: '1px solid #d9d9d9',
                      }
                    : undefined
                }
                // value={value}
                disabled={loading}
                required={item.required}
              />
            )}
          </BaseForm.Item>
        );
      })}
    </BaseForm>
  );
};

export default React.memo(Form);
