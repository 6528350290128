import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Tooltip } from 'antd';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useModal } from 'context/modal/modal.hook';
import { useNotifications } from 'context/notification/notification-hooks/notification.hook';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as LogoutLogo } from '../../../assets/icons/logout.svg';
import { ReactComponent as SettingAltLogo } from '../../../assets/icons/settings-alt.svg';
import { ReactComponent as UserLogo } from '../../../assets/icons/user.svg';
import { PROFILE } from '../../../consts/tooltip';
import { authenticationService } from '../../../services/authenticationService';
import './index.css';
import { SettingsPopoverUserData } from './settings-popover-user-data.modal';

function SettingsPopoverComponent({ history, signOut }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const currentUser = useCurrentUser();
  const modal = useModal();
  const {t} = useTranslation()

  const notification = useNotifications()

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------


  function logout() {
    authenticationService.logout();
    signOut()
    currentUser.updateUserData();
    notification.clear()
  }



  // ----------------------------------------------------------------------------------------
  return (
    <>
      <Popover
        placement="bottomRight"
        content={
          <div className="content-wrapper settings-popover">
            <p
              className="content-item-wrapper"
              onClick={() => {
                modal.open({
                  component: <SettingsPopoverUserData />,
                });
              }}
            >
              <SettingAltLogo className="content-item-wrapper__logo" />
              <span className="content-item-wrapper__span">{t('settings')}</span>
            </p>
            <p
              className="content-item-wrapper"
              onClick={() => {
                logout();
              }}
            >
              <LogoutLogo className="content-item-wrapper__logo" />
              <span className="content-item-wrapper__span">{t('exit')}</span>
            </p>
          </div>
        }
        title={
          <>
            <div className="user-title-wrapper">
              <Avatar size="large" icon={<UserOutlined />} />
              <p style={{ paddingLeft: 8, margin: 0 }}>
                <span className="user-title__name-text">
                  {currentUser.user.username}
                </span>
                <br />
                <span className="user-title__position-text">{t(currentUser.user.role)}</span>
              </p>
            </div>
          </>
        }
        trigger="click"
      >
        <Tooltip title={t('profile')}>
          <UserLogo
            style={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </Popover>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch({ type: 'SIGN_OUT' }),
  };
}

export const SettingsPopover = withRouter(
  connect(null, mapDispatchToProps)(SettingsPopoverComponent)
);
