import {
  SET_CATEGORIES_DATA,
  SET_CATEGORIES_FETCHING,
  SET_CATEGORIES_ERROR,
  CATEGORY_BY_ID_REQUEST,
  CATEGORY_BY_ID_FAILURE,
  CATEGORY_BY_ID_SUCCESS,
  CLEAR_SINGLE_CATEGORY_STATE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  OPEN_CONFIRM_MODAL,
  OPEN_CREATE_MODAL
} from '../actions/categories';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  createModalOpen: false,
  confirmModalOpen: false,
  categoriesState: reqState,
  singleCategory: reqState,
  categoryUpdateState: {
    fetching: false,
    error: null,
  },
  createCategoryState: reqState,
  deleteCategoryState: reqState
};

export default function categories(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CATEGORIES_FETCHING:
      return {
        ...state,
        categoriesState: { ...state.categoriesState, fetching: payload },
      };
    case SET_CATEGORIES_DATA:
      return {
        ...state,
        categoriesState: { ...state.categoriesState, data: payload },
      };
    case SET_CATEGORIES_ERROR:
      return {
        ...state,
        categoriesState: { ...state.categoriesState, error: payload },
      };

    case CATEGORY_BY_ID_REQUEST:
      return {
        ...state,
        singleCategory: {
          ...state.singleCategory,
          fetching: true
        }
      };
    case CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        singleCategory: {
          ...state.singleCategory,
          fetching: false,
          data: payload
        }
      };
    case CATEGORY_BY_ID_FAILURE:
      return {
        ...state,
        singleCategory: {
          ...state.singleCategory,
          fetching: false,
          error: payload
        }
      };
    case CLEAR_SINGLE_CATEGORY_STATE:
      return {
        ...state,
        singleCategory: initialState.singleCategory
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryUpdateState: {
          ...state.categoryUpdateState,
          fetching: true
        }
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryUpdateState: {
          ...state.categoryUpdateState,
          fetching: false
        },
        categoriesState: {
          ...state.categoriesState,
          data: state.categoriesState.data ? {
            ...state.categoriesState.data,
            results: state.categoriesState.data.results.map((item) => {
              if (item.id !== payload.id) return item;
              return payload;
            })
          } : null,
        },
        singleCategory: {
          data: payload
        }
      }
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        categoryUpdateState: {
          ...state.categoriesState,
          fetching: false,
          error: payload,
        }
      }
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        createCategoryState: {
          ...state.createCategoryState,
          fetching: true
        }
      }
    case CREATE_CATEGORY_SUCCESS:
      return  {
        ...state,
        createCategoryState: {
          ...state.createCategoryState,
          fetching: false,
          data: payload
        }
      }
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createCategoryState: {
          ...state.createCategoryState,
          fetching: false,
          error: payload
        }
      }
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        deleteCategoryState: {
          ...state.deleteCategoryState,
          fetching: true,
          data: null
        }
      }
    case DELETE_CATEGORY_SUCCESS:
      return  {
        ...state,
        deleteCategoryState: {
          ...state.deleteCategoryState,
          fetching: false,
          data: payload
        }
      }
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        deleteCategoryState: {
          ...state.deleteCategoryState,
          fetching: false,
          error: payload
        }
      }
    case OPEN_CREATE_MODAL: {
      return {
        ...state,
        createModalOpen: payload
      }
    }
    case OPEN_CONFIRM_MODAL: {
      return {
        ...state,
        confirmModalOpen: payload
      }
    }
    default:
      return state;
  }
}
