import { Tooltip } from 'antd';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BuildingLogo } from '../../assets/icons/building.svg';
import { ReactComponent as MapLogo } from '../../assets/icons/map.svg';
import { ReactComponent as PlanLogo } from '../../assets/icons/plan.svg';
import { toShowMapId } from '../../consts/routes';
import { SANDWICH } from '../../consts/urls';
import { makeSelectGetMainMap } from '../../store/selectors/svgMap';
import '../../pages/Main/index.css';
import { useTranslation } from 'react-i18next';

function MapTypes({ changeMapTypes, className, history }) {
  const isMain = useSelector(makeSelectGetMainMap);
  const { t} = useTranslation()
  const logos = [
    {
      id: 1,
      to: '/',
      icon: <MapLogo />,
      mapTypes: 'MAP',
      title: t('home'),
      show: true,
    },
    {
      id: 2,
      icon: <BuildingLogo />,
      to: SANDWICH,
      mapTypes: 'BUILDING',
      title: t('sandwich'),
      show: true,
    },
    {
      id: 3,
      to: toShowMapId(isMain?.id),
      icon: <PlanLogo />,
      mapTypes: 'PLAN',
      title: t("mainMap"),
      show: !!isMain,
    },
  ];

  return (
    <div className={`logos-wrapper ${className}`}>
      {logos.map((item) => {
        return (
          <div key={item.id}>
            {item.show && (
              <Tooltip title={item.title}>
                <NavLink
                  to={item.to}
                  exact
                  className="logos-wrapper__link"
                  activeClassName="logos-wrapper__link-active"
                >
                  <span>{item.icon}</span>
                </NavLink>
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    changeMapTypes: (type) => dispatch({ type }),
  };
}

export default connect(null, mapDispatchToProps)(MapTypes);
