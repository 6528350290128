import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Modal, Pagination, Table } from 'antd';
import { CheckedIcon } from 'components/Icons';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import FormDialog from '../../../components/RotationsForm';
import { useQuery } from '../../../hooks/query';
import { initForm } from '../../../store/actions/forms';
import {
  askToDelete,
  createRequest,
  deleteRequest,
  editRequest,
  getEventTypeRequest,
  getListRequest,
  openEditForm,
  setIsCreateFormOpen,
  setIsEditFormOpen,
} from '../../../store/actions/rotations';
import { selectFormValues } from '../../../store/selectors/forms';
import {
  selectConfigList,
  selectConfigListCount,
  selectConfigListFetching,
  selectConfigTableData,
  selectCreationError,
  selectCreationFetching,
  selectDeletingFetching,
  selectEditingError,
  selectEditingFetching,
  selectIsCreateFormOpen,
  selectIsEditFormOpen,
  selectNominatedToRemoveItem
} from '../../../store/selectors/rotations';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import useConfig from './form/config';
import { CREATE_FORM, EDIT_FORM } from './form/constants';
import submit from './form/submit';

const RotationsConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const config = useConfig();
  const { query, setQuery } = useQuery();
  const count = useSelector(selectConfigListCount);
  const isCreateFormOpen = useSelector(selectIsCreateFormOpen);
  const isEditFormOpen = useSelector(selectIsEditFormOpen);
  const nominatedToRemoving = useSelector(selectNominatedToRemoveItem);
  const list = useSelector(selectConfigList);
  const loading = useSelector(selectConfigListFetching);
  const createFormValues = useSelector(selectFormValues(CREATE_FORM));
  const editFormValues = useSelector(selectFormValues(EDIT_FORM));
  const creationLoading = useSelector(selectCreationFetching);
  const editingLoading = useSelector(selectEditingFetching);
  const deletingLoading = useSelector(selectDeletingFetching);
  const format = (date, format) => moment(date).format(format);
  const createError = useSelector(selectCreationError);
  const editError = useSelector(selectEditingError);

  const columns = [
    {
      title: t('shiftName'),
      dataIndex: 'shift_name',
      key: 'shift_name',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('startTime'),
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: t('endTime'),
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: t('dayOfBegin'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (start_date) => format(start_date, 'DD-MM-YYYY'),
    },
    {
      title: t('expireDate'),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (end_date) => format(end_date, 'DD-MM-YYYY'),
    },
    {
      title: t('cyclicality'),
      dataIndex: 'instance',
      key: 'instance',
      render: (instance) =>
        instance.cyclicality === 'from_date_to_date'
          ? `${instance.value_cycle[0]} - ${instance.value_cycle[1]}`
          : instance.cyclicality === 'working_days'
          ? `${instance.value_cycle
              ?.sort((a, b) => (+a > +b ? 1 : -1))
              ?.map((value) => `${moment.weekdays(+value)}`)
              ?.join(', ')}`
          : instance.cyclicality === 'days_of_the_month'
          ? `${instance.value_cycle
              ?.sort((a, b) => (+a > +b ? 1 : -1))
              ?.join(', ')}`
          : instance.cyclicality === 'x_through_y'
          ? `${instance.value_cycle?.join('/')}`
          : '',
    },
    {
      title: t('active'),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (is_active) => (
        <div className="actions-wrapper">
          {is_active ? <CheckedIcon /> : null}
        </div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={handleEditButtonClick(instance)}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={askToRemoveNotification(instance.id)}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];
  const data = useSelector(selectConfigTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------
  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [query]
  );
  useEffect(
    () => {
      loadData();
      dispatch(getEventTypeRequest());
      dispatch(initForm({ form: CREATE_FORM, config }));
      dispatch(initForm({ form: EDIT_FORM, config }));

      return () => {
        dispatch(setIsCreateFormOpen(false));
        dispatch(setIsEditFormOpen(false));
        dispatch(askToDelete(null));
      };
    },
    // Need to call this only once at render
    // eslint-disable-next-line
    []
  );

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------
  function loadData() {
    dispatch(
      getListRequest({
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
        search: query.search || '',
        isActive: query.isActive || false,
      })
    );
  }
  const handleClickCreate = () => {
    dispatch(setIsCreateFormOpen(true));
  };

  const handleCreateSubmit = useCallback(() => {
    const formData = submit(createFormValues);

    if (Array.from(formData.keys()).length) {
      dispatch(createRequest({ data: formData }));
    }
  }, [createFormValues, dispatch]);

  const handleEditSubmit = useCallback(() => {
    const { id, ...rest } = editFormValues;
    const instance = list.find((item) => item.id === id);
    const formData = submit(rest, instance);

    if (Array.from(formData.keys()).length) {
      dispatch(editRequest({ id, data: formData }));
    }
  }, [editFormValues, dispatch, list]);

  const handleEditButtonClick = (instance) => (e) => {
    e.preventDefault();

    dispatch(openEditForm({ instance }));
  };

  const handleRemoveShift = (id) => (e) => {
    e.preventDefault();
    dispatch(deleteRequest({ id }));
  };

  const askToRemoveNotification = (id) => (e) => {
    e.preventDefault();
    dispatch(askToDelete(id));
  };

  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div className="item">
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('searchByColumns')}
                inputProps={{ 'aria-label': 'search google maps' }}
                value={query.search || ''}
                onChange={(event) => {
                  setQuery({
                    ...query,
                    page: 1,
                    size: MIN_PAGINATION_ITEMS,
                    search: event.target.value,
                  });
                }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <FormControl component="fieldset">
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={
                      query.isActive
                        ? query.isActive === 'true'
                          ? true
                          : false
                        : false
                    }
                    onChange={(_, checked) => {
                      setQuery({ ...query, isActive: checked });
                    }}
                  />
                }
                label={t('onlyActivePosts')}
                labelPlacement="start"
              />
            </FormControl>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            {t('addShifts')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          className="employeesTable"
        />
        <Pagination
          style={{ marginTop: '1rem' }}
          {...{
            total: count,
            current: query.page || 1,
            pageSize: query.size || MIN_PAGINATION_ITEMS,
            onChange: (page, size) => setQuery({ ...query, page, size }),
            hideOnSinglePage: true,
            showSizeChanger: true,
            position: ['bottomLeft'],
            defaultCurrent: query.page,
          }}
        />
      </div>
      <FormDialog
        title={t('createShift')}
        visible={isCreateFormOpen}
        onClose={() => dispatch(setIsCreateFormOpen(false))}
        buttonText={creationLoading ? t('loading') + '...' : t('create')}
        form={CREATE_FORM}
        handleSubmit={handleCreateSubmit}
        loading={creationLoading}
        config={config}
        error={createError}
      />
      <FormDialog
        title="Редактировать смены"
        visible={isEditFormOpen}
        onClose={() => dispatch(setIsEditFormOpen(false))}
        buttonText={editingLoading ? t('loading') + '...' : t('edit')}
        form={EDIT_FORM}
        handleSubmit={handleEditSubmit}
        loading={editingLoading}
        config={config}
        error={editError}
      />
      <Modal
        visible={Boolean(nominatedToRemoving)}
        onCancel={() => dispatch(askToDelete(null))}
        title="Подтвердите действие"
        okText={deletingLoading ? t('loading') + '...' : t('delete')}
        okButtonProps={{ color: 'red', loading: deletingLoading }}
        cancelText={t('not')}
        onOk={handleRemoveShift(nominatedToRemoving)}
      >
        <p>{t('sureWantToDelShift')}</p>
      </Modal>
    </SettingsLayout>
  );
};

export default RotationsConfiguration;
