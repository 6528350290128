import { UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, PageHeader } from 'antd';
import { mapStore } from 'model/MapStore';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { selectConfigList as selectEmployees } from 'store/selectors/employees';
import { DateForm } from './DateForm';
import TravelHistory from './TravelHistory';
import './styles.scss';

export const UserHistory = ({ closeHistory, keeper }) => {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------

  const { t } = useTranslation();
  const [historyList, setHistoryList] = useState([]);
  const [historyIndex, setHistoryIndex] = useState();
  const history = useMemo(() => mapStore.keeperHistoryInstance, []);
  const employees = useSelector(selectEmployees);
  const dispatch = useDispatch();

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
  }, []);

  useEffect(() => {
    const state = { ...mapStore.layers };
    Object.keys(state).forEach((name) => {
      mapStore.setLayerState(name, false);
    });
    const restoreState = () => {
      Object.keys(state).forEach((name) => {
        mapStore.setLayerState(name, state[name]);
      });
    };
    mapStore.selectedKeeperId$.subscribe((id) => {
      if (!id) {
        restoreState();
        closeHistory();
      }
    });
    return () => {
      restoreState();
    };
  }, []);

  useEffect(() => {
    if (history.list) {
      setHistoryList(history.list);
    }
    if (typeof history.currentHistoryIndex === 'number') {
      // @ts-ignore
      setHistoryIndex(history.currentHistoryIndex);
    }
    const indexS = history.currentHistoryIndex$.subscribe((i) => {
      setHistoryIndex(i);
      if (history.list) {
        setHistoryList(history.list);
      }
    });
    const listS = history.list$.subscribe((h) => setHistoryList(h));
    return () => {
      indexS.unsubscribe();
      listS.unsubscribe();
    };
  }, [history, keeper]);

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  const close = () => {
    closeHistory();
    history.clear();
  };

  function serviceNumber() {
    if (!keeper) return '';
    if (keeper.type === 'VEHICLE') {
      if (!employees) return '';
      if (employees.length === 0) return '';
      let drivers = [];
      for (const key in keeper.transport_info) {
        if (!key.startsWith('driver')) continue;
        const driverId = keeper.transport_info[key];
        if (!driverId) continue;
        const driver = employees.find((e) => e.id === driverId);
        drivers.push(driver.service_number);
      }
      return drivers.join(' / ');
    }
    if (!keeper.employees) return '';
    if (keeper?.employees?.length === 1) {
      return keeper.employees[0].mark;
    } else {
      return keeper.employees.map((employee) => employee.mark).join(' / ');
    }
  }

  function position() {
    if (!keeper) return '';
    if (keeper.type === 'VEHICLE') {
      return keeper.post;
    }
    if (!keeper.employees) return '';
    if (keeper?.employees?.length === 1) {
      return keeper.employees[0].post;
    } else {
      return keeper.employees.map((employee) => employee.post).join(' / ');
    }
  }

  // ------------------------------------------------------------------------------------------------------------
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={close}
        title={t('back')}
      />
      <div className="d-flex-center-center">
        <Avatar size="large" icon={<UserOutlined />} />
      </div>
      <p className="stack-drawer-title mt-20">{keeper?.name}</p>
      <p className="d-flex-sb">
        <span>{t('personnelNumber')}:</span>
        <span className="green">{serviceNumber()}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('position')}:</span>{' '}
        <span className="green">{position()}</span>
      </p>
      <Divider />
      <DateForm />
      <Divider />
      <TravelHistory />
      <Divider />
      {historyList?.map((h, i) => {
        const id = h.mapId;
        const name = mapStore.maps[id]?.name;
        return (
          <div
            key={h.mapId + h.startTime}
            onClick={() => history.setCurrentHistoryIndex(i)}
            style={{
              cursor: 'pointer',
              color: i === historyIndex ? '#25ace3' : null,
            }}
          >
            <div>{name}</div>
            <small>
              {moment(h.startTime).format('DD.MM.YYYY HH:mm')}
              {' - '}
              {moment(h.endTime).format('DD.MM.YYYY HH:mm')}
            </small>
          </div>
        );
      })}
    </>
  );
};
