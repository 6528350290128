import { Drawer, Layout, Modal } from 'antd';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as DashboardLogo } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DocumentLogo } from '../../assets/icons/document.svg';
import { ReactComponent as MapLocationLogo } from '../../assets/icons/map-location.svg';
import { ReactComponent as SettingsLogo } from '../../assets/icons/settings.svg';
import { HeaderNav } from '../../components/HeaderNav';
import SwitchGroup from '../../components/SwitchGroup';
import {
  MAIN,
  REPORTS,
  SETTINGS_CATEGORIES,
  STATISTICS
} from '../../consts/urls';
import { deleteBlockingNotification } from '../../store/actions/notifications';
import { makeSelectGetBlockingNotifications } from '../../store/selectors/notifications';
import { ContentLayoutLink } from './content-layout-link';

const { Content } = Layout;

export function ContentLayout(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [visible, setVisible] = useState(false);
  const blockingNotificationsList = useSelector(
    makeSelectGetBlockingNotifications
  );
  const dispatch = useDispatch();


  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  const closeDrawer = () => setVisible(false);
  const deleteBlockingPopup = (idx) => {
    dispatch(deleteBlockingNotification(idx));
  };

  // ---------------------------------------------------------------------------
  return (
    <>
      <>
        <Layout style={{ height: '100vh' }}>
          <Layout className="site-layout">
            {currentUser.user && currentUser.user.isAuthenticated && (
              <>
                <Drawer
                  width={350}
                  placement={'left'}
                  onClose={closeDrawer}
                  visible={visible}
                  key={'left'}
                  footer={
                    <div className="d-flex-center-center mb-38">
                      <SwitchGroup
                        list={[
                          { id: 0, title: 'KZ' },
                          { id: 1, title: 'RU' },
                          { id: 2, title: 'EN' },
                        ]}
                      />
                    </div>
                  }
                >
                  <div className="auth-container__logo" />
                  <div className="auth-container__content">
                    <ContentLayoutLink
                      logo={<MapLocationLogo />}
                      onClick={closeDrawer}
                      to={MAIN}
                      divider={false}
                      text={t('maps')}
                    />

                    <ContentLayoutLink
                      logo={<DashboardLogo />}
                      onClick={closeDrawer}
                      to={STATISTICS}
                      divider={false}
                      text={t('dashboards')}
                    />

                    {currentUser.user.role !== 'operator' && (
                      <ContentLayoutLink
                        logo={<DocumentLogo />}
                        onClick={closeDrawer}
                        to={REPORTS}
                        divider={true}
                        text={t('reports')}
                      />
                    )}

                    {currentUser.user.role !== 'operator' && (
                      <ContentLayoutLink
                        logo={<SettingsLogo />}
                        onClick={closeDrawer}
                        to={SETTINGS_CATEGORIES}
                        divider={false}
                        text={t('configuration')}
                      />
                    )}
                  </div>
                </Drawer>
                <HeaderNav
                  onOpen={() => {
                    setVisible(true);
                  }}
                />
              </>
            )}
            <Content>{props.children}</Content>
          </Layout>
        </Layout>
        {blockingNotificationsList &&
          blockingNotificationsList?.length &&
          blockingNotificationsList.map((item, idx) => (
            <Modal
              key={idx}
              title={t('attention')}
              visible={!!item?.showBlockingPopup}
              className="notification-modal"
              onCancel={() => deleteBlockingPopup(idx)}
              onOk={() => deleteBlockingPopup(idx)}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <div className="notification-modal__content">
                <p>{t('btnPressed')}</p>
                <p>{moment(item?.time).format('DD.MM.YY HH:mm:ss')}</p>
                <p>{item?.message}</p>
                <div>
                  <Link to={REPORTS} onClick={() => deleteBlockingPopup(idx)}>
                    {t('report.report')}
                  </Link>
                </div>
              </div>
            </Modal>
          ))}
      </>

      {!currentUser.user && <>{props.children}</>}
    </>
  );
}
