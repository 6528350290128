import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DEFAULT_PAGE_SIZE } from '../../components/Pagination/constants';
import { request } from '../../helpers/client';
import {
  errorNotification,
  successNotification,
} from '../../helpers/notificationHelpers';
import {
  ADD_MAIN_MAP_FETCHING,
  EDIT_MAP_FETCHING,
  GET_MAIN_MAP_FETCHING,
  GET_MAPS_LIST_REQUEST,
  GET_SINGLE_MAP_REQUEST,
  NEXT_FLOOR,
  UPLOAD_MAP_REQUEST,
  editMapData,
  editMapError,
  getMainMapData,
  getMainMapError,
  getMainMapFetching,
  setGetMapsListData,
  setGetMapsListError,
  setGetMapsListLoading,
  setGetSingleMapData,
  setGetSingleMapError,
  setGetSingleMapLoading,
  setUploadMapData,
  setUploadMapError,
  setUploadMapLoading,
} from '../actions/svgMap';

function* uploadMap({ payload }) {
  const { formData } = payload;
  try {
    yield put(setUploadMapLoading(true));
    const response = yield call(request.post, '/layouts/upload/', formData);

    yield put(setUploadMapData(response.data));
  } catch (err) {
    yield put(setUploadMapError(err));
    yield put(getMainMapFetching());
  } finally {
    yield put(setUploadMapLoading(false));
  }
}

function* editMap({ payload }) {
  const { formData, id } = payload;
  try {
    const response = yield call(
      request.put,
      `/layouts/${id}/update/`,
      formData
    );

    yield put(editMapData(response.data));
    yield put(getMainMapFetching());
    successNotification('Данные успешно изменены');
  } catch (err) {
    yield put(editMapError(err));
    errorNotification('Ой, произошла ошибка');
  }
}

function* getMapById({ payload: mapId }) {
  try {
    yield put(setGetSingleMapLoading(true));
    const response = yield call(request.get, `/layouts/${mapId}/`);

    yield put(setGetSingleMapData(response.data));
  } catch (err) {
    yield put(setGetSingleMapError(err));
  } finally {
    yield put(setGetSingleMapLoading(false));
  }
}

function* getMapsList(action) {
  const { page = 1, size = DEFAULT_PAGE_SIZE } = action.payload;
  try {
    yield put(setGetMapsListLoading(true));
    const response = yield call(request.get, '/layouts/list/', {
      params: { page, size },
    });
    yield put(setGetMapsListData(response.data));
  } catch (err) {
    yield put(setGetMapsListError(err));
  } finally {
    yield put(setGetMapsListLoading(false));
  }
}

function* getMainMap() {
  try {
    const response = yield call(request.get, '/layouts/main/');

    yield put(getMainMapData(response.data));
  } catch (err) {
    yield put(getMainMapError(err));
  }
}

function* addMainMap({ payload }) {
  try {
    const response = yield call(request.post, '/layouts/main/', payload);

    yield put(getMainMapData(response.data));
    successNotification('Данная карта выбрана главной');
  } catch (err) {
    yield put(getMainMapError(err));
    errorNotification('Ошибка');
  }
}

function* nextFloor(action) {
  const { id, nextFloorCallback, mode } = action.payload;
  try {
    const response = yield call(request.get, `/layouts/${id}/next-floor/`, {
      params: { mode: mode },
    });

    yield call(nextFloorCallback, response.data.next);
  } catch (err) {
    errorNotification('Нету следующего этажа');
  }
}

function* Saga() {
  yield all([
    takeLatest(UPLOAD_MAP_REQUEST, uploadMap),
    takeLatest(GET_SINGLE_MAP_REQUEST, getMapById),
    takeLatest(GET_MAPS_LIST_REQUEST, getMapsList),
    takeLatest(GET_MAIN_MAP_FETCHING, getMainMap),
    takeLatest(ADD_MAIN_MAP_FETCHING, addMainMap),
    takeLatest(EDIT_MAP_FETCHING, editMap),
    takeLatest(NEXT_FLOOR, nextFloor),
  ]);
}

export default Saga;
