import { Select } from 'antd';
import { request } from 'helpers/client';
import { useQuery } from 'hooks/query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportFilterParams } from '../report-page.hook';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

function ReportsAnchor({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const anchorStatuses = [t('notSpecified'), 'UP', 'DOWN', 'CONFIGURE'];
  const { query } = useQuery();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const [anchors, setAnchors] = useState();
  const columns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (created_at) =>
        new Date(created_at)
          .toLocaleString('en-US', { hour12: false })
          .split('/')
          .join('.'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('status'),
    },
    {
      key: 'anchor_label',
      dataIndex: 'anchor_label',
      title: t('report.filter.anchor'),
    },
  ];

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    loadAnchors();
  }, []);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------

  async function loadAnchors() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/anchors/`
    );

    if (!response || !response.data) return;
    setAnchors(response.data);
  }

  const anchorValue = useCallback(() => {
    let currentAnchor = null;
    if (!filterParams || !anchors) return null;
    if (filterParams.anchor_id) {
      currentAnchor = anchors.items.find(
        (anchor) => String(anchor.id) === String(filterParams.anchor_id)
      );
    } else {
      currentAnchor = anchors.items.find(
        (anchor) => String(anchor.id) === String(query.anchor_id)
      );
    }

    if (!currentAnchor) return null;
    return currentAnchor.sn;
  }, [anchors, filterParams, query]);

  const anchorStatus = useCallback(() => {
    let currentStatus = null;
    if (!filterParams || !anchorStatuses) return null;
    if (filterParams.status) {
      currentStatus = anchorStatuses.find(
        (status) => status === filterParams.status
      );
    } else {
      currentStatus = anchorStatuses.find((status) => status === query.status);
    }

    return currentStatus;
  }, [anchorStatuses, filterParams, query]);

  // ----------------------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div className="reports-filter-container">
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            <div className="filter-params">
              <Select
                showSearch={true}
                placeholder={t('report.filter.anchor')}
                optionFilterProp="children"
                bordered={false}
                style={{
                  background: 'transparent',
                  width: '12rem',
                  borderBottom: '2px solid #c2c2c2',
                }}
                value={anchorValue()}
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    anchor_id: value,
                  });
                }}
                onFocus={(e) => {
                  e.currentTarget.style.borderBottom = '2px solid #1976d2';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
                }}
                filterOption={(input, option) =>
                  String(option.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={loading}
              >
                {anchors &&
                  anchors.items.map((anchor, index) => (
                    <>
                      {index === 0 ? (
                        <Select.Option value={'_none'} key={index}>
                          {t('notSpecified')}
                        </Select.Option>
                      ) : (
                        <Select.Option value={anchor.id} key={index}>
                          {anchor.sn}
                        </Select.Option>
                      )}
                    </>
                  ))}
              </Select>

              <Select
                showSearch={true}
                placeholder={t('status')}
                optionFilterProp="children"
                bordered={false}
                style={{
                  background: 'transparent',
                  width: '12rem',
                  borderBottom: '2px solid #c2c2c2',
                }}
                value={anchorStatus()}
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    status: value,
                  });
                }}
                onFocus={(e) => {
                  e.currentTarget.style.borderBottom = '2px solid #1976d2';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
                }}
                filterOption={(input, option) =>
                  String(option.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={loading}
              >
                {anchorStatuses.map((status, index) => (
                  <>
                    {status === t('notSpecified') ? (
                      <Select.Option value={'_none'} key={index}>
                        {t('notSpecified')}
                      </Select.Option>
                    ) : (
                      <Select.Option value={status} key={index}>
                        {status}
                      </Select.Option>
                    )}
                  </>
                ))}
              </Select>

              {/* <TextField
                  id="standard-basic"
                  label="Статус"
                  variant="standard"
                  value={tempFilterParams ? tempFilterParams.status : ''}
                  onChange={(e) => {
                    setTempFilterParams({
                      ...tempFilterParams,
                      status: e.target.value,
                    });
                  }}
                  disabled={loading}
                /> */}
            </div>
          </div>
          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}
      <ReportTable
        columns={columns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}

export default ReportsAnchor;
