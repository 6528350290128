// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { request } from 'helpers/client';
import { t } from 'i18next';
import KeeperCounter from '../../components/Map/components/KeeperCounter';
import MapButtons from '../../components/MapButtons';
import StackDrawer from '../../components/MapButtons/StackDrawer';
import {
  addColorToLayer,
  addPolygon,
  clearMap,
  createColorLayer,
  initGlobalMap,
  zoomInMap,
  zoomOutMap,
} from '../../helpers/mapHelpers';
import { getKeepers } from '../../services/keepers';
import { setIsUserDrawerVisible } from '../../store/actions/labels';
import { makeSelectGetUserColor } from '../../store/selectors/user';
import { shapes } from './fixtures';
import './index.scss';

const Home = () => {
  const mapId = 'home-global-map';
  const [generalSettings, setGeneralSettings] = useState({});

  const [map, setMap] = useState();
  const [colorLayer, setColorLayer] = useState('');
  const [isKeeperDrawerVisible, setIsKeeperDrawerVisible] = useState(false);
  const mapColor = useSelector(makeSelectGetUserColor);
  const [keepers, setKeepers] = useState('');
  const keepersInterval = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const style = {
    fillColor: mapColor ? mapColor : '#fff',
    fillOpacity: '0.2',
  };

  const setIsUserSideDrawerVisible = () => {
    dispatch(setIsUserDrawerVisible());
  };

  const clearKeepersInterval = () => {
    clearInterval(keepersInterval.current);
  };

  const setKeepersInterval = () => {
    keepersInterval.current = setInterval(requestKeepers, 5000);
  };

  const zoomIn = () => {
    if (map) {
      zoomInMap({ map });
    }
  };

  const zoomOut = () => {
    if (map) {
      zoomOutMap({ map });
    }
  };

  const requestKeepers = () => {
    getKeepers({
      embed: 'tag',
      filter: '[deleted==false;name=@;type==EMPLOYEE]',
      sort: 'name',
    })
      .then((res) => setKeepers(res?.data?.items))
      .catch((err) => {
        clearKeepersInterval();
        throw err;
      });
  };

  const handleShapeClick = () => {
    history.push('/sandwich');
  };

  const statusKeeper = [];
  if (keepers) {
    keepers.map((items) => {
      statusKeeper.push(items._embedded?.tag?.status);
    });
  }

  const statusKeeperCount = statusKeeper.filter(
    (n) => n && n !== 'NO_SIGNAL'
  ).length;

  useEffect(() => {
    loadGeneralSettings();
  }, []);

  async function loadGeneralSettings() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/settings/'
    );
    setGeneralSettings(response.data);
  }

  useEffect(
    () => {
      if (!generalSettings.global_map_x) return;
      const _map = initGlobalMap({
        mapId,
        position: [generalSettings.global_map_x, generalSettings.global_map_y],
        zoom: generalSettings.global_map_zoom,
      });
      setMap(_map);

      return () => {
        clearMap(mapId);
        setMap(undefined);
      };
    },
    // eslint-disable-next-line
    [generalSettings]
  );
  useEffect(() => {
    if (map) {
      if (!colorLayer) {
        createColorLayer({ map, style, setColorLayer });
      } else {
        const color = mapColor ? mapColor : '#fff';
        addColorToLayer({ mapColor: color, colorLayer });
      }
    }
  }, [mapColor, colorLayer]);

  useEffect(() => {
    if (map) {
      if (!colorLayer) {
        createColorLayer({ map, style, setColorLayer });
      }

      for (const item of shapes) {
        addPolygon({
          map,
          coords: item.shape,
          options: {
            color: item.color,
            style: {
              zIndex: 500,
            },
          },
          handleShapeClick: handleShapeClick,
          tooltip: {
            text: item.name,
          },
        });
      }
    }
  }, [map]);

  useEffect(() => {
    requestKeepers();
    setKeepersInterval();
    return () => {
      clearKeepersInterval();
    };
  }, []);

  return (
    <React.Fragment>
      <div className="home-page-wrapper">
        <MapButtons
          showMapSettings={() => setIsKeeperDrawerVisible(true)}
          showFloors={false}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
        <div className="map-holder" id={mapId} />
      </div>
      {!!keepers?.length && (
        <KeeperCounter
          title={t('totalNumberOfActiveUsers')}
          amount={statusKeeperCount}
        />
      )}
      <StackDrawer
        visible={isKeeperDrawerVisible}
        setVisible={setIsKeeperDrawerVisible}
        map={map}
      />
    </React.Fragment>
  );
};

export default React.memo(Home);
