import { createContext } from 'react';

export const AppDataContext = createContext({
  user: {
    id: '',
    username: '',
    map_color: '',
    role: '',
    isAuthenticated: false,
  },
  macroscop: {
    url: '',
    login: '',
    password: '',
    width: '',
    height: '',
    loading: false,
  },
  updateMacroscopData: () => {},
  setMacroscopData: (macroscop) => {},
  updateUserData: () => {},
});
