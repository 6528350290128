export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_SEARCH_FETCHING = 'SET_SEARCH_FETCHING';
export const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR';

export const searchRequest = (payload) => ({
  type: SEARCH_REQUEST,
  payload,
});

export const setSearchLoading = (payload) => ({
  type: SET_SEARCH_FETCHING,
  payload,
});
export const setSearchData = (payload) => ({
  type: SET_SEARCH_DATA,
  payload,
});
export const setSearchError = (payload) => ({
  type: SET_SEARCH_ERROR,
  payload,
});
