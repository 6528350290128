import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setSignInData,
  setSignInError,
  setSignInLoading,
  SIGN_IN_REQUEST,
} from '../actions/auth';
import { request } from '../../helpers/client';
import { SANDWICH } from '../../consts/urls';

function* signIn({ payload }) {
  try {
    const { history } = payload;

    yield put(setSignInLoading(true));

    const response = yield call(
      request.post,
      '/auth/sign-in/',
      payload.payload
    );

    yield put(setSignInData({ ...response.data }));

    yield call(history.push, SANDWICH);
  } catch (err) {
    yield put(setSignInError(err));
  } finally {
    yield put(setSignInLoading(false));
  }
}

function* Saga() {
  yield all([takeLatest(SIGN_IN_REQUEST, signIn)]);
}

export default Saga;
