import { all, takeLatest, put, call } from 'redux-saga/effects';

import { request } from '../../helpers/client';
import { errorNotification, successNotification } from '../../helpers/notificationHelpers';

import {
  GET_DEVICES_REQUEST,
  CREATE_DEVICE_REQUEST,
  UPDATE_DEVICE_REQUEST,
  DELETE_DEVICE_REQUEST,

  getDevicesRequest,
  getDevicesSuccess,
  getDevicesFailure,
  createDevicesSuccess,
  createDevicesFailure,
  setIsCreateModalOpen,
  updateDevicesSuccess,
  updateDevicesFailure,
  setIsUpdateModalOpen,
  deleteDevicesSuccess,
  deleteDevicesFailure,
  nominateToDelete,
} from '../actions/devices';


function* fetchDevices(action) {
  const { page = 1, size = 20 } = action.payload;

  try {
    const response = yield call(
      request.get,
      '/layouts/devices/',
      { params: { page, size } }
    );

    yield put(getDevicesSuccess(response.data));
  } catch (error) {
    yield put(getDevicesFailure(error));
    errorNotification('Произошла неизвестная ошибка')
  }
}

function* createDevice(action) {
  const { data } = action.payload;

  try {
    const response = yield call(
      request.post,
      '/layouts/devices/',
      data
    );

    successNotification('Устройство создано');
    yield put(createDevicesSuccess(response.data));
    yield put(getDevicesRequest());
    yield put(setIsCreateModalOpen(false));
  } catch (error) {
    yield put(createDevicesFailure(error));
    errorNotification('Произошла неизвестная ошибка');
  }
}

function* updateDevice(action) {
  const { id, data } = action.payload;

  try {
    const response = yield call(
      request.put,
      `/layouts/devices/${id}/`,
      data
    );

    successNotification('Устройство отредактированно');
    yield put(updateDevicesSuccess(response.data));
    yield put(getDevicesRequest());
    yield put(setIsUpdateModalOpen(false));
  } catch (error) {
    yield put(updateDevicesFailure(error));
  }
}

function* deleteDevice(action) {
  const { id } = action.payload;

  try {
    const response = yield call(request.delete, `/layouts/devices/${id}/`);

    successNotification('Устройство удалено');
    yield put(deleteDevicesSuccess(response.data));
    yield put(getDevicesRequest());
    yield put(nominateToDelete(null));
  } catch (error) {
    yield put(deleteDevicesFailure(error));
    errorNotification('Произошла неизвестная ошибка');
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_DEVICES_REQUEST, fetchDevices),
    takeLatest(CREATE_DEVICE_REQUEST, createDevice),
    takeLatest(UPDATE_DEVICE_REQUEST, updateDevice),
    takeLatest(DELETE_DEVICE_REQUEST, deleteDevice),
  ]);
}

export default Saga;
