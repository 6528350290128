import { useTranslation } from 'react-i18next';
import { SandwichColumnItem } from './SandwichColumnItem';

export function SandwichColumn({ data: { name, thumbnail, list } }) {
  // ------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------
  const {t} = useTranslation()
  // ------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------
  const sortedObjectList = () =>
    list.sort((a, b) => (a.floor < b.floor ? 1 : -1));

  // ------------------------------------------------------------------------------------------------------
  return (
    <div className="block-gallery">
      {list && list.length !== 0 ? (
        <>
          {sortedObjectList().map((item, index) => (
            <SandwichColumnItem item={item} key={index} thumbnail={thumbnail} />
          ))}
        </>
      ) : (
        <div className="sandwich-no-items">{t('mapsNotYetAddToCateg')}</div>
      )}

      <h2 className="block-text" style={{ marginBottom: '1rem' }}>
        {name}
      </h2>
    </div>
  );
}
