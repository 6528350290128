import { CheckedIcon } from 'components/Icons';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest } from 'store/actions/employees';
import { useReportFilterParams } from '../report-page.hook';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportSelectEmployeePosts } from "../ui-components/report-filter/report-select-employee-posts.jsx";
import { ReportSelectEmployees } from '../ui-components/report-filter/report-select-employees';
import { ReportSelectLayout } from '../ui-components/report-filter/report-select-layout';
import { ReportSelectShift } from '../ui-components/report-filter/report-select-shift';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

export function ReportsTransactionBetweenLevels({
  showFilter,
  reports,
  loading,
}) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const reportsEventColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (/** @type {moment.MomentInput} */ created_at) =>
        moment(created_at).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'employee',
      dataIndex: 'employee',
      title: t('report.filter.tagOwner'),
      render: (
        /** @type {{ surname: string; name: string; patronymic: string; }} */ employee
      ) =>
        `${employee.surname ?? ''} ${employee.name ?? ''} ${
          employee.patronymic ?? ''
        }`,
    },
    {
      key: 'position',
      dataIndex: 'position',
      title: t('position'),
    },
    {
      key: 'shift',
      dataIndex: 'shift',
      title: t('shift'),
      render: (/** @type {{ shift_name: string; }} */ shift) =>
        shift === null || shift.shift_name === ''
          ? t('shiftNotSpecified')
          : shift.shift_name,
    },
    {
      key: 'start_time',
      dataIndex: 'start_time',
      title: t('startTime'),
      width: '15%',
      render: (/** @type {moment.MomentInput} */ start_time) =>
        moment(start_time).format('DD.MM.YYYY HH:mm:ss'),
    },

    {
      key: 'end_time',
      dataIndex: 'end_time',
      title: t('endTime'),
      width: '15%',
      render: (/** @type {moment.MomentInput} */ end_time) =>
        moment(end_time).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'duration',
      dataIndex: 'duration',
      title: t('duration'),
      width: '15%',
      render: (/** @type {number} */ duration) => {
        return moment.utc(duration * 1000).format('HH:mm:ss');
      },
    },
    {
      key: 'layout',
      dataIndex: 'layout',
      title: t('map'),
      render: (/** @type {{ name: any; }} */ layout) => layout.name,
    },
    {
      title: t('violator'),
      dataIndex: 'violator',
      key: 'violator',
      render: (violator) => (
        <div className="actions-wrapper">
          {violator ? <CheckedIcon /> : null}
        </div>
      ),
    },
    {
      key: 'organization_title',
      dataIndex: 'employee',
      title: t('organization'),
      render: (v) => (!v ? t('notSpecified') : v.organization_title),
    },
  ];
  const dispatch = useDispatch();
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getListRequest({
        size: 1000,
      })
    );
  }, []);

  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            <div className="filter-params">
              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('report.filter.tagOwner')}
                paramName={'employee'}
              />

              <ReportSelectEmployeePosts
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={"position"}
              />

              <ReportSelectShift
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectLayout
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />
            </div>
          </div>

          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}

      <ReportTable
        columns={reportsEventColumns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}
