import {
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,

  UPDATE_CONFIG_REQUEST,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_CONFIG_FAILURE,
  SET_IS_EDIT_FORM_OPEN,
} from '../actions/cameras';


const initialState = {
  config: {
    data: null,
    fetching: false,
    error: null,
  },
  updating: {
    isOpen: false,
    fetching: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUEST: return {
      ...state,
      config: {
        ...state.config,
        fetching: true,
        data: null,
      }
    };
    case GET_CONFIG_SUCCESS: return {
      ...state,
      config: {
        ...state.config,
        fetching: false,
        data: action.payload,
      }
    };
    case GET_CONFIG_FAILURE: return {
      ...state,
      config: {
        ...state.config,
        fetching: false,
        error: action.payload,
      }
    };

    case UPDATE_CONFIG_REQUEST: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: true,
        error: null,
      }
    };
    case UPDATE_CONFIG_SUCCESS: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: false,
      },
      config: {
        ...state.config,
        data: action.payload,
      }
    };
    case UPDATE_CONFIG_FAILURE: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: false,
        error: action.payload,
      }
    };
    case SET_IS_EDIT_FORM_OPEN: return {
      ...state,
      updating: {
        ...state.updating,
        isOpen: action.payload,
      }
    };
    default: return state;
  }
};

export default reducer;
