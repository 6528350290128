import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectLayout({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const { t } = useTranslation();
  const [layouts, setLayout] = useState([]);

  useEffect(() => {
    loadLayouts();
  }, []);

  async function loadLayouts() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/list?size=1000`
    );

    if (!responce || !responce.data) return;
    setLayout(responce.data.results);
  }
  const layoutValue = useCallback(() => {
    const queryParams = getQueryParams();
    let currentValue = null;
    if (!tempFilterParams) return;
    if (tempFilterParams.layout) {
      currentValue = layouts.find(
        (layout) => layout.id === tempFilterParams.layout
      );
    } else {
      currentValue = layouts.find((layout) => layout.id === queryParams.layout);
    }

    if (!currentValue) return null;
    return currentValue.name;
  }, [layouts, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={t('map')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={layoutValue()}
      onChange={(value) => {
        setTempFilterParams({
          ...tempFilterParams,
          layout: value,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {[{ id: '_none', name: t('notSpecified') }, ...layouts].map((layout) => (
        <Select.Option value={layout.id}>{layout.name}</Select.Option>
      ))}
    </Select>
  );
}
