import { CheckedIcon } from 'components/Icons';

import moment from 'moment';
import { useEventTypeNameMapper } from 'pages/Settings/Reactions/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest } from 'store/actions/employees';
import { useReportFilterParams } from '../report-page.hook';
import { ReportActionLayoutOpener } from '../ui-components/report-action-layout';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportFilterBoolean } from '../ui-components/report-filter/report-filter-boolean.component';
import { ReportSelectEmployeePosts } from "../ui-components/report-filter/report-select-employee-posts.jsx";
import { ReportSelectEmployees } from '../ui-components/report-filter/report-select-employees';
import { ReportSelectLayout } from '../ui-components/report-filter/report-select-layout';
import { ReportSelectNotificationType } from '../ui-components/report-filter/report-select-notification-type';
import { ReportSelectShift } from '../ui-components/report-filter/report-select-shift';
import { ReportSelectZone } from '../ui-components/report-filter/report-select-zone';
import { ReportTable } from '../ui-components/reports-table';

import './../index.scss';

export function ReportsEvent({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const eventTypeNameMapper = useEventTypeNameMapper();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const reportsEventColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (v) => moment(v).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'event_type',
      dataIndex: 'event_type',
      title: t('eventType'),
      render: (event_type) => eventTypeNameMapper[event_type] ?? event_type,
    },
    {
      key: 'employees',
      dataIndex: 'employees',
      title: t('report.filter.tagOwner'),
      render: (employees) =>
        (employees ?? [])
          .map(
            (employee) =>
              `${employee.surname ?? ''} ${employee.name ?? ''} ${
                employee.patronymic ?? ''
              }`
          )
          .join(' / '),
    },
    {
      key: 'employees',
      dataIndex: 'employees',
      title: t('position'),
      render: (employees) =>
      (employees ?? [])
          .map((employee) => employee.post)
          .filter((item) => item !== null)
          .join(' / '),
    },
    {
      key: 'shift',
      dataIndex: 'shift',
      title: t('shift'),
      render: (shift) =>
        shift === null || shift.shift_name === ''
          ? t('shiftNotSpecified')
          : shift.shift_name,
    },

    {
      key: 'zone',
      dataIndex: 'zone',
      title: t('zone'),
      render: (zone) => (!zone ? t('zoneNotSpecified') : zone.name),
    },

    {
      key: 'layout',
      dataIndex: 'layout',
      title: t('map'),
      render: ({ name }) => name,
    },

    {
      title: t('violator'),
      dataIndex: 'violator',
      key: 'violator',
      render: (violator) => (
        <div className="actions-wrapper">
          {violator ? <CheckedIcon /> : null}
        </div>
      ),
    },
    {
      title: t('information'),
      key: 'information',
      dataIndex: 'information',
    },
    {
      title: t('actions'),
      key: 'layout',
      dataIndex: 'layout',
      render: (_, record) => (
        <ReportActionLayoutOpener record={record} />
      ),
    },
    {
      key: 'organization_title',
      dataIndex: 'employees',
      title: t('organization'),
      render: (v) =>
        !v?.length
          ? t('notSpecified')
          : v.map((e) => e.organization_title).join(' / '),
    },
  ];
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getListRequest({
        size: 1000,
      })
    );
  }, []);

  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />

            <div className="filter-params">
              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('report.filter.tagOwner')}
                paramName={'employees'}
              />
              
              <ReportSelectEmployeePosts
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={"post"}
              />

              <ReportSelectZone
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectShift
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectNotificationType
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectLayout
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportFilterBoolean
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={"for_today_biometrics"}
                localizationKey={'report.filter.forTodayBiometrics'}
              />

            </div>
          </div>

          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}

      <ReportTable
        columns={reportsEventColumns}
        items={reports.results.map((item) => {
          const coordinatesExist =
            item.coordinates !== undefined &&
            item.coordinates !== null &&
            item.coordinates.x &&
            item.coordinates.y &&
            item.coordinates.z;
          const coordinates = coordinatesExist
            ? `x: ${item.coordinates.x.toFixed(
                2
              )} y: ${item.coordinates.y.toFixed(
                2
              )} z: ${item.coordinates.z.toFixed(2)}`
            : '';
          return {
            ...item,
            layout: item.layout ?? t('mapNotSpecified'),
            information: item.information ?? ' ' + coordinates,
            coordinates: item.coordinates,
          };
        })}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}
