export const SET_CENTER_COORDINATES = 'SET_CENTER_COORDINATES';
export const CLEAR_CENTER_COORDINATES = 'CLEAR_CENTER_COORDINATES';

export const setCenterCoordinates = (payload) => ({
  type: SET_CENTER_COORDINATES,
  payload,
});
export const clearCenterCoordinates = (payload) => ({
  type: CLEAR_CENTER_COORDINATES,
  payload,
});
