import { Affix, Button, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import SosPopover from './components/SosPopover/SosPopover';

import { ReactComponent as CameraLogo } from '../../assets/icons/camera.svg';
import { DownArrow } from '../../assets/icons/downArrow';
import { ReactComponent as StackLogo } from '../../assets/icons/stack.svg';
import { UpArrow } from '../../assets/icons/upArrow';
import { ReactComponent as ZoomIn } from '../../assets/icons/zoomIn.svg';
import { ReactComponent as ZoomOut } from '../../assets/icons/zoomOut.svg';

import { SHOW_MAP } from '../../consts/urls';

import { mapNextFloor } from '../../store/actions/svgMap';
import { makeSelectGetSingleMap } from '../../store/selectors/svgMap';

import { useTranslation } from 'react-i18next';
import { mapStore } from '../../model/MapStore';
import { floorModes } from './consts';
import { commonButtonStyles, styleFloorButtons } from './styles';

const Buttons = ({
  showBook,
  showMapSettings,
  showFloors,
  zoomIn,
  zoomOut,
  disableNextFloor,
  disablePrevFloor,
}) => {
  const singleMapData = useSelector(makeSelectGetSingleMap);
  const [mapData, setMapData] = useState();
  const [cameraDisabled, setCameraDisabled] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { mapId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const areas = mapStore.currentMapAreas || {};
    setCameraDisabled(
      !Object.keys(areas).filter((id) => areas[id].type === 'camera').length
    );
    const s = mapStore.currentMapAreas$.subscribe((zones) => {
      setCameraDisabled(
        !Object.keys(zones || {}).filter((id) => zones[id].type === 'camera')
          .length
      );
    });
    return () => {
      s.unsubscribe();
    };
  }, [mapData]);

  useEffect(() => {
    if (mapId && mapStore.maps[mapId]) {
      setMapData(mapStore.maps[mapId]);
    }
    const s = mapStore.maps$.subscribe((maps) => {
      if (mapId && maps[mapId]) {
        setMapData(maps[mapId]);
      }
    });
    return () => s.unsubscribe();
  }, [mapId]);

  const nextFloorCallback = useCallback(
    (id) => {
      history.push(`${SHOW_MAP}${id}`);
    },
    [mapId]
  );

  const handleNextFloor = (mode) => {
    dispatch(mapNextFloor({ id: mapId, nextFloorCallback, mode }));
  };

  return (
    <>
      <SosPopover />
      <div className="buttons-position">
        {showFloors && (
          <Affix style={styleFloorButtons} className="asd">
            <Tooltip
              title={t('upper')}
              placement="top"
              style={{
                ...commonButtonStyles,
                borderTopRightRadius: '1000px',
                borderTopLeftRadius: '1000px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              <Button
                style={{
                  width: '52px',
                  borderTopRightRadius: '1000px',
                  borderTopLeftRadius: '1000px',
                  borderBottomLeftRadius: '0',
                  borderBottomRightRadius: '0',
                }}
                onClick={() => handleNextFloor(floorModes.up)}
                disabled={disableNextFloor}
              >
                <UpArrow />
              </Button>
            </Tooltip>
            <div
              style={{
                ...commonButtonStyles,
                width: '50px',
                color: '#373737',
                borderRadius: '0',
                lineHeight: 16,
                fontWeight: 'normal',
              }}
            >
              {singleMapData?.floor}
            </div>
            <Tooltip
              title={t('lower')}
              placement="bottom"
              style={{
                ...commonButtonStyles,
                borderBottomLeftRadius: '1000px',
                borderBottomRightRadius: '1000px',
                borderTopRightRadius: '0',
                borderTopLeftRadius: '0',
              }}
            >
              <Button
                style={{
                  width: '52px',
                  borderBottomLeftRadius: '1000px',
                  borderBottomRightRadius: '1000px',
                  borderTopRightRadius: '0',
                  borderTopLeftRadius: '0',
                }}
                onClick={() => handleNextFloor(floorModes.down)}
                disabled={disablePrevFloor}
              >
                <DownArrow />
              </Button>
            </Tooltip>
          </Affix>
        )}
        <Affix>
          <Tooltip title={t('zoomIn')} placement="top">
            <Button
              onClick={zoomIn}
              style={{
                ...commonButtonStyles,
                borderTopRightRadius: '1000px',
                borderTopLeftRadius: '1000px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              <ZoomIn />
            </Button>
          </Tooltip>
          <div className="buttons-divider"> </div>
          <Tooltip title={t('zoomOut')} placement="bottom">
            <Button
              onClick={zoomOut}
              style={{
                ...commonButtonStyles,
                borderTopRightRadius: '0',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '1000px',
                borderBottomRightRadius: '1000px',
              }}
            >
              <ZoomOut />
            </Button>
          </Tooltip>
        </Affix>
        <Affix>
          <Tooltip title={cameraDisabled ? t('noCameras') : t('camera')}>
            <Button
              style={{
                filter: 'drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.15))',
                ...commonButtonStyles,
                marginBottom: '40px',
                lineHeight: cameraDisabled ? 0 : 20,
                color: cameraDisabled ? '#666666' : '#25ACE3',
              }}
              shape="circle"
              onClick={() => {
                mapStore.setLayerState(
                  'areas',
                  mapStore.layers.areas === 'camera' ? null : 'camera'
                );
              }}
              disabled={cameraDisabled}
            >
              <CameraLogo />
            </Button>
          </Tooltip>
          <Tooltip title={t('mapSettings')}>
            <Button
              style={{
                filter: 'drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.15))',
                ...commonButtonStyles,
              }}
              shape="circle"
              onClick={showMapSettings}
            >
              <StackLogo />
            </Button>
          </Tooltip>
        </Affix>
      </div>
    </>
  );
};

export default Buttons;
