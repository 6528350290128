import {
  GET_FAVORITES_DATA,
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_ERROR,
  GET_FREQUENTLY_DATA,
  GET_FREQUENTLY_REQUEST,
  GET_FREQUENTLY_ERROR,
  GET_HISTORY_ERROR,
  GET_HISTORY_REQUEST,
  GET_HISTORY_DATA,
  ADD_FAVORITES_ERROR,
  ADD_FAVORITES_REQUEST,
  ADD_FAVORITES_DATA,
  SET_USER_COLOR_DATA,
  SET_USER_COLOR_ERROR,
  SET_USER_DATA_REQUEST,
} from '../actions/user';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  favorites: reqState,
  addFavorites: reqState,
  frequentlyVisited: reqState,
  history: reqState,
  color: reqState,
};

export default function user(state = initialState, { type, payload, id }) {
  switch (type) {
    case ADD_FAVORITES_REQUEST:
      return {
        ...state,
        addFavorites: { ...state.favorites, fetching: true },
      };
    case ADD_FAVORITES_DATA:
      const { results: frequently } = state.frequentlyVisited.data;
      const { results: history } = state.history.data;

      frequently.length &&
        frequently.forEach((item) => {
          if (item.id === id) {
            item.is_favorite = !item.is_favorite;
          }
        });
      history.length &&
        history.forEach((item) => {
          if (item.layout.id === id) {
            item.layout.is_favorite = !item.layout.is_favorite;
          }
        });
      return {
        ...state,
        addFavorites: { ...state.favorites, data: payload, fetching: false },
      };
    case ADD_FAVORITES_ERROR:
      return {
        ...state,
        addFavorites: { ...state.favorites, error: payload, fetching: false },
      };
    case GET_FAVORITES_REQUEST:
      return {
        ...state,
        favorites: { ...state.favorites, fetching: true },
      };
    case GET_FAVORITES_DATA:
      return {
        ...state,
        favorites: { ...state.favorites, data: payload, fetching: false },
      };
    case GET_FAVORITES_ERROR:
      return {
        ...state,
        favorites: { ...state.favorites, error: payload, fetching: false },
      };
    case GET_FREQUENTLY_REQUEST:
      return {
        ...state,
        frequentlyVisited: { ...state.favorites, fetching: true },
      };
    case GET_FREQUENTLY_DATA:
      return {
        ...state,
        frequentlyVisited: {
          ...state.favorites,
          data: payload,
          fetching: false,
        },
      };
    case GET_FREQUENTLY_ERROR:
      return {
        ...state,
        frequentlyVisited: {
          ...state.favorites,
          error: payload,
          fetching: false,
        },
      };
    case GET_HISTORY_REQUEST:
      return {
        ...state,
        history: { ...state.history, fetching: true },
      };
    case GET_HISTORY_DATA:
      return {
        ...state,
        history: { ...state.history, data: payload, fetching: false },
      };
    case GET_HISTORY_ERROR:
      return {
        ...state,
        history: { ...state.history, error: payload, fetching: false },
      };
    case SET_USER_COLOR_DATA:
      return {
        ...state,
        color: { ...state.color, data: payload, fetching: false },
      };
    case SET_USER_COLOR_ERROR:
      return {
        ...state,
        color: { ...state.color, error: payload, fetching: false },
      };
    case SET_USER_DATA_REQUEST:
      return {
        ...state,
        color: { ...state.color, error: payload, fetching: false },
      };
    default:
      return state;
  }
}
