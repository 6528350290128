import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BatteryLogo } from '../../../../assets/icons/battery.svg';
import { ReactComponent as BrowserSafariLogo } from '../../../../assets/icons/browser-safari.svg';
import { ReactComponent as CelsiusLogo } from '../../../../assets/icons/celsius.svg';
import { ReactComponent as DashboardLogo } from '../../../../assets/icons/dashboard.svg';
import { ReactComponent as PlanLogo } from '../../../../assets/icons/plane.svg';
import { ReactComponent as SignalGraphLogo } from '../../../../assets/icons/signal-graph.svg';
import { ReactComponent as StepsLogo } from '../../../../assets/icons/steps.svg';
import { ReactComponent as TemperatureLogo } from '../../../../assets/icons/temperature.svg';
import { ReactComponent as WarningLogo } from '../../../../assets/icons/warning-triangle.svg';

export const UserDrawerEmployeeStats = ({ keeper }) => {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const sensors = keeper?._embedded?.tag?.sensors;
  const { t } = useTranslation();

  const elements = [
    {
      text: t('violator'),
      logo: <WarningLogo />,
      value: keeper?.violator ? t('violatorDetected') : '',
    },
    {
      text: capitalize(t('danger')),
      logo: <WarningLogo />,
      value: keeper?.is_danger ? t('employeeOnDanger') : '',
    },
    {
      text: t('movement'),
      logo: <PlanLogo />,
      value: getMotionDesc(),
    },
    {
      text: t('temperature'),
      logo: <TemperatureLogo />,
      value: sensors?.temperature,
    },
    {
      text: t('dropImpact'),
      logo: <WarningLogo />,
      value:
        sensors?.impact_fall === 'NORMAL' ? 'Нормально' : sensors?.impact_fall,
    },
    {
      text: t('regulation'),
      logo: <BrowserSafariLogo />,
      value: sensors?.orientation,
    },
    {
      text: t('barometer'),
      logo: <DashboardLogo />,
      value: sensors?.barometer,
    },
    {
      text: t('barometerTemp'),
      logo: <CelsiusLogo />,
      value: sensors?.barometer_temperature,
    },
    {
      text: t('pulceBracelet'),
      logo: <SignalGraphLogo />,
      value:
        !sensors?.heartrate || sensors?.heartrate === 'FAIL'
          ? '0'
          : `${sensors?.heartrate}`,
    },
    {
      text: t('pedometBracelet'),
      logo: <StepsLogo />,
      value:
        !sensors?.steps || sensors?.steps === 'FAIL'
          ? '0'
          : `${sensors?.steps}`,
    },
    {
      text: t('batteryBracelet'),
      logo: <BatteryLogo />,
      value:
        !sensors?.blebatterylvl || sensors?.blebatterylvl === 'FAIL'
          ? '0%'
          : `${sensors?.blebatterylvl}%`,
    },
  ];

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  function getMotionDesc() {
    let motionDesc;
    if (sensors?.motion === 'STILL') {
      motionDesc = 'Стоит';
    } else if (sensors?.motion === 'MOTION') {
      motionDesc = 'В движении';
    }
    return motionDesc;
  }

  // ------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------
  if (!keeper?.is_active) return <></>;
  return (
    <>
      {elements.map((item, idx) => {
        return (
          item.value && (
            <div key={idx} className={'data-wrapper'}>
              {item.logo}
              <span className="data-text" style={{ color: '#373737' }}>
                {item.text}
              </span>
              <span className="data-value green">{item.value}</span>
            </div>
          )
        );
      })}
    </>
  );
};
