import { Modal } from 'antd';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LogArrowAltUp from '../../../components/Icons/LogArrowAltUp';
import { GREEN, RED } from '../../../consts/color';
import { toDegree } from '../../../helpers/convertHelpers';

function MileageModal({ handleCancel, mileagePointData }) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('passingThrouthArea')}
      wrapClassName="mileage-modal-wrapper"
      centered
      visible={true}
      footer={null}
      onCancel={handleCancel}
    >
      {mileagePointData.items.map((item) => {
        const isStatusOk = item.status === 'OK';
        return (
          <div className="item-wrapper" key={item.id}>
            <LogArrowAltUp
              style={{ transform: `rotate(${toDegree(item.rotate)}deg)` }}
              color={isStatusOk ? GREEN : RED}
            />
            <div className="text-wrapper">
              <p className="header">
                {t('time')}: {moment(item.time).format('DD.MM.YY HH:mm:ss')}
              </p>
              <p className="text">
                {t('state')}: {isStatusOk ? 'Ок' : t('error')}
              </p>
            </div>
          </div>
        );
      })}
    </Modal>
  );
}

export default MileageModal;
