// @ts-nocheck
import { CurrentUserProvider } from 'context/current-user/current-user.provider';
import { FormOpenerProvider } from 'context/form-opener/form-opener.provider';
import { NotificationProvider } from 'context/notification/notification.provider';
import { UserDrawerProvider } from 'context/user-drawer/user-drawer.provider';
import 'leaflet/dist/leaflet.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ModalProvider } from './context/modal/modal.provider';
import './index.css';
import store from './store';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <CurrentUserProvider>
        <ModalProvider>
          <UserDrawerProvider>
            <FormOpenerProvider>
              <NotificationProvider>
                <App />
              </NotificationProvider>
            </FormOpenerProvider>
          </UserDrawerProvider>
        </ModalProvider>
      </CurrentUserProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

window.onbeforeunload = function () {
  return false;
};
