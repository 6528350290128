import anchorIcon from 'assets/icons/anchor-icon.svg';
import anchorRedIcon from 'assets/icons/anchor-red-icon.svg';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LocationArrow } from 'components/Icons';

import { toDegree } from 'helpers/convertHelpers';
import {
  addMarker,
  convertCoordinatesToLatLng,
  convertFromMeters,
  createAnimatedMarker,
  createMarker,
  getMarkerIcon,
  initMap,
} from 'helpers/mapHelpers';
import { mapStore } from 'model/MapStore';

import { Button, List, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { toShowMapId } from 'consts/routes';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useNotificationMapExtention } from 'context/notification/notification-hooks/notification-map-extention.hook';
import { request } from 'helpers/client';
import { getQueryParams, useQuery } from 'hooks/query';
import { Keeper } from 'model/Keeper';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { clearCenterCoordinates } from 'store/actions/crsMap';
import { getListRequest } from 'store/actions/markerConfigurations';
import { getMileagePointRequest } from 'store/actions/reports';
import { selectMarkerConfigList } from 'store/selectors/markerConfigurations';
import { makeSelectGetUserColor } from 'store/selectors/user';
import '../../../../leaflet/moving-marker';
import { CameraPreview } from '../camera/camera-preview';
import './index.scss';

let isHistory = false;

const getClusters = () =>
  L.markerClusterGroup({
    showCoverageOnHover: false,
    removeOutsideVisibleBounds: false,
    zoomToBoundsOnClick: true,
    spiderfyOnMaxZoom: true,
    maxClusterRadius: 15,
    spiderfyDistanceMultiplier: 3,
    spiderLegPolylineOptions: { weight: 0, color: '#000', opacity: 0 },
  });

function CRSMap({
  handleClickKeeper,
  singleMap,
  owsData,
  showDrawer,
  keeperCoordinate,
  getMileagePointHistory,
  centerCoordinates,
  clearCenterCoordinates,
  map,
  setMap,
  selectedKeeper,
  trackedKeeperId,
}) {
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const clusterRef = useRef(getClusters());
  const mapId = 'map';
  const [keeperMarker, setKeeperMarker] = useState();
  const [keeperList, setKeeperList] = useState([]);
  const [trackedKeeperMapChanges, setTrackedKeeperMapChanges] = useState(null);
  const currentUser = useCurrentUser();
  const [mapData, setMapData] = useState();
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [markerTitle, setMarkerTitle] = useState(mapStore.layers.title);
  const markerById = useRef({});
  const wrapperRef = useRef();
  const history = useHistory();
  const [areaTypeDeviceStates, setAreaTypeDeviceStates] = useState([]);
  const mapColor = useSelector(makeSelectGetUserColor);
  const [anchor, setAnchor] = useState();
  const areaLayersRef = useRef([]);
  const [cameraZones, setCameraZones] = useState([]);

  useNotificationMapExtention({
    map,
    mapData,
  });
  useEffect(() => {
    currentUser.updateUserData();
  }, []);

  useEffect(() => {
    mapStore.center$.subscribe((coords) => map?.setView(coords));
  }, [map]);

  useEffect(() => {
    let anchorMarker;
    if (map && anchor && anchor.mapId === mapData?.id) {
      const latLng = L.latLng(anchor.y, anchor.x);
      const icon = getMarkerIcon(
        anchor.status === 'UP' ? anchorIcon : anchorRedIcon
      );
      anchorMarker = L.marker(latLng, {
        icon: icon,
      }).addTo(map);
      anchorMarker.bindPopup(
        `x: ${anchor.xM}, y: ${anchor.yM}, z: ${anchor.z}`
      );
      anchorMarker.on('click', () => anchorMarker.openPopup());
      map.panTo(latLng);
    }
    return () => {
      mapStore.setSelectedAnchor(null);
      anchorMarker?.off();
      anchorMarker?.remove();
    };
  }, [anchor, map, mapData]);

  useEffect(() => {
    const s = mapStore.selectedAnchor$.subscribe((anchor) => {
      if (anchor) {
        setAnchor(anchor);
      }
    });
    return () => {
      s.unsubscribe();
    };
  }, []);

  const anchorMarkersRef = useRef([]);

  useEffect(() => {
    const s = mapStore.layers$.subscribe((state) => {
      setMarkerTitle(!!state.title);
    });
    return () => s?.unsubscribe();
  }, []);

  useEffect(() => {
    document.mapStore = mapStore;
    let anchors = [...anchorMarkersRef.current];
    const remove = () => {
      anchors.forEach((anchor) => {
        if (anchor.getTooltip()) {
          anchor.unbindTooltip();
        }
        anchor.off();
        anchor.remove();
      });
      anchors = [];
    };
    const toolTipOptions = {
      permanent: true,
      direction: 'top',
      offset: L.point(-15, 0),
    };
    const add = () => {
      remove();
      mapStore.anchorList.forEach((anchor) => {
        if (mapData?.id === anchor.mapId) {
          console.log({ anchor });
          const icon = getMarkerIcon(
            anchor.status === 'UP' ? anchorIcon : anchorRedIcon
          );
          const marker = L.marker(L.latLng(anchor.y, anchor.x), {
            icon: icon,
          })
            .bindPopup(`x: ${anchor.xM}, y: ${anchor.yM}, z: ${anchor.z}`)
            .on('click', function () {
              this.openPopup();
            });
          if (markerTitle) {
            marker
              .bindTooltip(`${anchor.sn ?? ''}`, toolTipOptions)
              .openTooltip();
          }

          marker.addTo(map);
          anchors.push(marker);
        }
      });
      anchorMarkersRef.current = anchors;
    };
    const update = () => {
      if (map && mapData) {
        if (mapStore.layers.anchors) {
          add();
        } else {
          remove();
        }
      }
    };
    const anchorListS = mapStore.anchorList$.subscribe(update);
    const anchorStateS = mapStore.layers$.subscribe(update);
    update();
    return () => {
      anchorListS.unsubscribe();
      anchorStateS.unsubscribe();
      remove();
    };
  }, [map, mapData, markerTitle]);

  useEffect(() => {
    selectedPolygon?.setStyle({ color: '#10931f' });
    return () => selectedPolygon?.setStyle({ color: '#3388ff' });
  }, [selectedPolygon]);

  useEffect(() => {
    const selectedId = mapStore.selectedKeeperId;
    if (!selectedKeeper?.id && selectedId) {
      if (markerById.current[selectedId]) {
        const marker = markerById.current[selectedId];
        const keeper = mapStore?.keepers?.[selectedId];
        if (marker && keeper) {
          keeper.select(false);
          markerById.current[selectedId]?.setIcon(getMarkerIcon(keeper.icon));
          mapStore.setSelectedKeeperId(null);
        }
      }
    }
  }, [selectedKeeper?.id]);

  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: mapColor ? mapColor : '#ddd',
    opacity: 0.2,
    zIndex: 399,
  };

  const getBounds = useCallback(() => {
    const { min_x, min_y, max_x, max_y } = singleMap;
    const minY = min_y || 0;
    const maxY = max_y || mapRef.current.clientHeight;
    const minX = min_x || 0;
    const maxX = max_x || mapRef.current?.clientWidth;
    return [
      { lat: minY, lng: minX },
      { lat: maxY, lng: maxX },
    ];
  }, [singleMap]);

  useEffect(() => {
    const layer = clusterRef.current;
    const animatedMarkers = [];
    if (mapStore.layers.users && layer) {
      const mapId = mapStore.currentMapId;
      const markers = markerById.current || {};
      const prevMarkers = { ...markers };
      const layer = clusterRef.current;
      const newMarkers = [];
      keeperList?.forEach((keeper) => {
        const keeperLabel = `${keeper.name}`;
        delete prevMarkers[keeper.id];
        const { x: lng, y: lat } = keeper;
        const toolTipOptions = {
          permanent: true,
          direction: 'top',
          offset: L.point(-15, 0),
        };
        let marker = markers[keeper.id];
        if (marker) {
          if (keeper.icon) {
            marker.setIcon(getMarkerIcon(keeper.icon));
          }
          if (keeper.isMoved) {
            const latLng = marker.getLatLng();
            layer?.removeLayer(marker);
            const animatedMarker = createAnimatedMarker({
              map,
              latLng,
              latLng2: { lat, lng },
              icon: getMarkerIcon(keeper.icon),
              onEnd: () => {
                animatedMarker?.remove();
                if (mapId === mapStore.currentMapId) {
                  marker.setLatLng({ lat, lng });
                  layer?.addLayer(marker);
                }
              },
            });
            animatedMarkers.push(animatedMarker);
            if (markerTitle) {
              animatedMarker
                .bindTooltip(keeperLabel, toolTipOptions)
                .openTooltip();
            }
            map?.addLayer(animatedMarker);
            // animatedMarker.addTo(map);
          }
          if (markerTitle) {
            if (!marker.getTooltip()) {
              marker.bindTooltip(keeperLabel, toolTipOptions).openTooltip();
            }
          } else if (marker.getTooltip()) {
            marker.unbindTooltip();
          }
          // keeper.setColor();
          keeper._setKeeperIcon();
        } else {
          keeper._setKeeperIcon();
          // keeper.setColor();
          const marker = createMarker({
            map,
            latLng: { lat, lng },
            icon: getMarkerIcon(keeper.icon),
          });
          if (keeper.icon) {
            marker.setIcon(getMarkerIcon(keeper.icon));
          }
          if (markerTitle) {
            marker.bindTooltip(keeperLabel, toolTipOptions).openTooltip();
          }
          marker.on('click', () => {
            mapStore.setTrackedKeeper();
            if (keeper.selected) {
              keeper.select(false);
              marker.setIcon(getMarkerIcon(keeper.icon));
              mapStore.setSelectedKeeperId(null);
              handleClickKeeper?.(null);
            } else {
              const keeperId = mapStore.selectedKeeperId;
              if (keeperId && mapStore?.keepers?.[keeperId]) {
                const prevTrackedKeeper = mapStore.keepers[keeperId];
                prevTrackedKeeper.select(false);
                markers[keeperId]?.setIcon(
                  getMarkerIcon(prevTrackedKeeper.icon)
                );
              }
              keeper.select(true);
              marker.setIcon(getMarkerIcon(keeper.icon));
              mapStore.setSelectedKeeperId(keeper.id);
              handleClickKeeper?.(keeper.data);
            }
          });
          newMarkers.push(marker);
          markers[keeper.id] = marker;
        }
        keeper.select(keeper.selected);
      });

      const prevMarkersList = Object.values(prevMarkers);
      if (newMarkers.length) {
        layer.addLayers(newMarkers);
      }
      if (prevMarkersList.length) {
        layer?.removeLayers(Object.values(prevMarkers));
        Object.keys(prevMarkers).forEach((id) => {
          delete markers[id];
        });
      }
    }
    return () => {
      animatedMarkers.forEach((marker) => marker.remove());
    };
  }, [handleClickKeeper, keeperList, map, markerTitle]);

  useEffect(() => {
    if (trackedKeeperId) {
      if (mapStore.trackedKeeper[trackedKeeperId]) {
        const prevMaps = { ...mapStore.trackedKeeper[trackedKeeperId] };
        const newMaps = {};
        const currentMapIds = Object.keys(
          mapStore.mapsByKeeper[trackedKeeperId] || {}
        );
        currentMapIds.forEach((mapId) => {
          if (mapStore.maps[mapId]) {
            if (prevMaps[mapId]) {
              delete prevMaps[mapId];
            } else {
              newMaps[mapId] = mapStore.maps[mapId];
            }
          }
        });
        const newMapList = Object.keys(newMaps);
        const newMapCount = newMapList.length;
        mapStore.trackedKeeper[trackedKeeperId] = {
          ...(mapStore.mapsByKeeper[trackedKeeperId] || {}),
        };
        if (newMapCount > 1) {
          setTrackedKeeperMapChanges({ prev: prevMaps, new: newMaps });
        } else if (newMapCount === 1) {
          const [newMapId] = newMapList;
          history.push(toShowMapId(newMapId));
        }
      }
    }
  }, [keeperList, history, singleMap, trackedKeeperId]);

  useEffect(() => {
    const subscription = mapStore.mapKeeperList$.subscribe((keepers) => {
      setKeeperList(keepers);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const keeperHistory = mapStore.keeperHistoryInstance;
    let marker;

    const playS = keeperHistory.play$.subscribe((play) => {
      const keeper = new Keeper(mapStore?.keepers?.[mapStore.selectedKeeperId]);
      const { currentHistoryIndex, list, speed } = keeperHistory;
      const history = list?.[currentHistoryIndex];
      if (play === 'play') {
        if (marker) {
          if (!map.hasLayer(marker)) {
            marker.addTo(map);
          }
          marker.setIcon(getMarkerIcon(keeper.getHistoryIcon('play')));
          marker.start(speed);
        } else if (history) {
          marker = new L.MovingMarker(
            history.points.map((point) => [
              point.y,
              point.x,
              +moment(point.time),
            ]),
            {
              icon: getMarkerIcon(keeper.getHistoryIcon('play')),
              speed,
              onEnd: () => keeperHistory.setPlay('stop'),
            }
          );
          marker.addTo(map);
        }
      } else if (play === 'pause') {
        const point = marker?.pause?.();
        if (point) {
          keeperHistory.currentTime = point[2];
        }
        const keeper = new Keeper(
          mapStore?.keepers?.[mapStore.selectedKeeperId]
        );
        marker?.setIcon(getMarkerIcon(keeper.getHistoryIcon('pause')));
      } else if (play === 'stop') {
        marker?.stop?.();
      }
    });
    const listS = keeperHistory.list$.subscribe((list) => {
      if (list === null) {
        marker?.remove();
        marker = null;
      }
    });
    const historyIndexS = keeperHistory.currentHistoryIndex$.subscribe(
      (index) => {
        marker?.remove();
        if (keeperHistory.list?.[index]) {
          const points = keeperHistory.list[index].points.map((point) => [
            point.y,
            point.x,
            +moment(point.time),
          ]);
          if (marker) {
            marker.setLine(points);
          } else {
            const keeper = new Keeper(
              mapStore?.keepers?.[mapStore.selectedKeeperId]
            );
            marker = new L.MovingMarker(points, {
              autoStart: false,
              icon: getMarkerIcon(keeper.getHistoryIcon('pause')),
              onEnd: () => keeperHistory.setPlay('stop'),
            });
            marker.addTo(map);
          }
        }
      }
    );
    let isProgress = false;
    const progressS = keeperHistory.progress$.subscribe(({ final }) => {
      if (!isProgress) {
        marker?.pause?.();
      }
      const keeper = new Keeper(mapStore?.keepers?.[mapStore.selectedKeeperId]);
      const time = marker?.moveToTime(keeperHistory.currentTime);
      if (final) {
        keeperHistory.currentTime = time;
        isProgress = false;
        marker?.setIcon(getMarkerIcon(keeper.getHistoryIcon('pause')));
      } else {
        if (!isProgress) {
          isProgress = true;
          marker?.setIcon(getMarkerIcon(keeper.getHistoryIcon('ff')));
        }
      }
    });
    return () => {
      historyIndexS.unsubscribe();
      listS.unsubscribe();
      playS.unsubscribe();
      progressS.unsubscribe();
    };
  }, [history, map]);

  useEffect(() => {
    const keeperHistory = mapStore.keeperHistoryInstance;
    let historyLine = mapStore.historyLine;
    if (historyLine && map) {
      historyLine.addTo(map);
    }
    const indexS = keeperHistory.currentHistoryIndex$.subscribe((index) => {
      historyLine?.remove();
      const mapHistory = keeperHistory?.list?.[index];
      if (mapHistory) {
        let points = mapHistory.points;

        // points = points.filter(point => {
        //     const timeStamp = new Date(point.created_at).getTime()
        //     const lastSixHours = new Date()
        //     lastSixHours.setHours(lastSixHours.getHours() - 6)
        //     return timeStamp > lastSixHours.getTime()
        // })
        // points.forEach((point) => {
        //   const date = moment(point.created_at).format('DD.MM.YYYY HH:mm:ss');
        //   console.log('point drew', date);
        // });
        historyLine = L.polyline(points.map((point) => [point.y, point.x]));
        if (mapStore.currentMapId === mapHistory.mapId) {
          historyLine.addTo(map);
        } else {
          history.push(toShowMapId(mapHistory.mapId));
        }
        mapStore.historyLine = historyLine;
      }
    });
    const listS = keeperHistory.list$.subscribe((list) => {
      if (list === null) {
        historyLine?.remove();
        delete mapStore.historyLine;
      }
    });
    return () => {
      indexS.unsubscribe();
      listS.unsubscribe();
      historyLine?.remove();
    };
  }, [history, map, mapData]);

  useEffect(() => {
    let clusters;
    if (mapStore.layers.users) {
      clusters = getClusters();
      clusterRef.current = clusters;
      map?.addLayer(clusters);
    } else {
      clusters?.remove();
      clusterRef.current = null;
      markerById.current = {};
    }
    const s = mapStore.layers$.subscribe((layers) => {
      if (layers.users) {
        if (clusters) {
          if (map && !map.hasLayer(clusters)) {
            map.addLayer(clusters);
          }
        } else {
          clusters = getClusters();
          clusterRef.current = clusters;
          map?.addLayer(clusters);
        }
        mapStore.setMapId(singleMap?.id);
      } else {
        clusters?.remove();
        clusterRef.current = null;
        markerById.current = {};
      }
    });
    markerById.current = {};
    mapStore.setMapId(singleMap?.id);
    return () => {
      clusters?.remove?.();
      markerById.current = {};
      mapStore.currentMapId = null;
      s.unsubscribe();
    };
  }, [map, singleMap]);

  const onClickMap = () => {
    if (isHistory) {
      const params = {
        limit: 20,
        filter:
          '[time>=2021-09-07T00:00:00.0+06:00;time<=2021-09-14T00:00:00.0+06:00;site.id==1]',
        sort: 'time',
        geom: '[31.917787341957723,-17.622006951840362,0.6968306590637847]',
      };

      getMileagePointHistory({
        slug: '1519',
        params,
      });
    }
  };

  useEffect(() => {
    if (map && singleMap?.file && document.location?.origin) {
      singleMap.file = `${process.env.REACT_APP_BASE_URL_MEDIA}${singleMap.file}`;
      if (map.currentImageOverlay) {
        map.removeLayer(map.currentImageOverlay);
      }
      const mapBounds = getBounds();
      map.fitBounds(mapBounds);
      map.currentImageOverlay = L.imageOverlay(singleMap.file, mapBounds).addTo(
        map
      );
    }
  }, [getBounds, map, singleMap]);

  useEffect(() => {
    if (!map && singleMap) {
      setMap(
        initMap({
          mapId,
          image: singleMap.file,
          bounds: getBounds(),
          minZoom: -3,
          maxZoom: 6,
        })
      );
    }
  }, [map, singleMap]);

  useEffect(() => {
    if (
      singleMap?.id &&
      mapData?.id !== singleMap.id &&
      mapStore.maps[singleMap.id]
    ) {
      setMapData(mapStore.maps[singleMap.id]);
    }
    const subscription = mapStore.maps$.subscribe((maps) => {
      if (
        singleMap?.id &&
        mapData?.id !== singleMap.id &&
        maps?.[singleMap.id]
      ) {
        setMapData(maps[singleMap.id]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [mapData, singleMap]);

  const addAreas = useCallback(
    (areas, type = 'all') => {
      const areasList = [];
      if (map) {
        let currentList = [];
        if (type === 'all') {
          currentList = Object.values(areas);
        } else {
          currentList = Object.values(areas).filter(
            (area) => area.type === type
          );
        }

        const dangerZones = currentList.filter(
          (area) => area.type === 'danger_zone' || area.type === 'device'
        );
        const greenZones = currentList.filter(
          (area) => area.type === 'green_zone'
        );
        const cameraZones = currentList.filter(
          (area) => area.type === 'camera'
        );
        const forbittenZones = currentList.filter(
          (area) => area.type === 'forbitten_zone'
        );
        const otherZones = currentList.filter((area) => area.type === 'none');

        drawAreaShapes(otherZones.reverse(), areasList);
        drawAreaShapes(forbittenZones.reverse(), areasList);
        drawAreaShapes(cameraZones.reverse(), areasList);
        drawAreaShapes(dangerZones.reverse(), areasList);
        drawAreaShapes(greenZones.reverse(), areasList);
      }
      return areasList;
    },
    [map, areaTypeDeviceStates]
  );

  async function deviceState(deviceId) {
    const res = await request.get(`/layouts/devices/${deviceId}`);
    const { data } = res;
    return { id: deviceId, status: data.status };
  }

  function drawAreaShapes(list, areasList) {
    list.forEach((area) => {
      const polygon = L.polygon(convertFromMeters(area.coordinates, area.map));
      const { type, name } = area;

      if (type === 'device') {
        const gray = 'rgb(108, 122, 137)';
        const red = 'rgb(255, 0,0)';

        const statuses = [
          {
            id: 'aaebf234-a4b0-4e42-b2fa-33df39a43317',
            status: false,
          },
          {
            id: '693071bf-a965-4011-9bfc-3db8c33baac4',
            status: false,
          },
          {
            name: 'A10_URS_CRU01',
            status: false,
          },
          {
            id: '271bc6ce-25bc-404e-bbe6-8b7b9cf84f49',
            status: true,
          },
          {
            id: 'e10155ee-3342-41a8-ac48-c21aca6f0b44',
            status: true,
          },
          {
            id: '29b9ce33-a97b-451e-a02c-d8843fc6ef94',
            status: true,
          },
          {
            id: 'da9077cf-0fba-4e6e-9663-b1468b248bff',
            status: true,
          },
          {
            id: 'b254fbb9-4264-41cf-8c00-c87d5bf00559',
            status: true,
          },
          {
            id: '6e89d88d-192a-492a-8886-7c0a07d4d65f',
            status: true,
          },
        ];

        const deviceState = statuses.find((item) => item.id === area.device_id);
        if (deviceState)
          if (deviceState.status) {
            polygon.setStyle({ fillColor: red });
          } else {
            polygon.setStyle({ fillColor: gray });
          }
      }
      if (type === 'danger_zone') {
        polygon.setStyle({ fillColor: '#cb272c' });
      } else if (type === 'green_zone') {
        polygon.setStyle({ fillColor: 'rgba(63, 255, 128,1)' });
      }
      let popupContent = `<div>${name}</div>`;
      if (type === 'camera') {
        if (area.hasAccess) {
          polygon.on('click', () => {
            setCameraId(area.camera_id);
            setSelectedPolygon(polygon);
            setQuery({ showKeeperCamera: 'false' });
          });
        } else {
          popupContent += `<div style="color: red">${t(
            'notEnoughtPermiss'
          )}</div>`;
        }
      }
      polygon.bindPopup(popupContent);
      polygon.on('mouseover', () => polygon.openPopup());
      polygon.on('mouseout', () => polygon.closePopup());
      areasList.push(polygon);
      map.addLayer(polygon);
    });
  }

  // useEffect(() => {
  //   const areaVisible = mapStore.layers.areas;
  //   let polygons = [];
  //   if (areaVisible && map && mapData) {
  //     if (Object.keys(mapData.areas).length) {
  //       polygons = addAreas(mapData.areas, areaVisible);
  //     }
  //   }
  //   return () => {
  //     polygons.forEach((polygon) => {
  //       polygon.off();
  //       map?.removeLayer(polygon);
  //     });
  //   };
  // }, [addAreas, map, mapData]);

  useEffect(() => {
    const remove = () => {
      areaLayersRef.current.forEach((polygon) => {
        polygon.off();
        map?.removeLayer(polygon);
      });
      areaLayersRef.current = [];
    };
    const s = mapStore.currentMapAreas$.subscribe((zones) => {
      setCameraZones(
        Object.values(zones).filter((area) => area.type === 'camera')
      );
      remove();
      const tempStateArr = [];
      for (const area of Object.values(zones)) {
        if (!area.device_id) continue;
        deviceState(area.device_id).then((state) => tempStateArr.push(state));
      }
      setAreaTypeDeviceStates(tempStateArr);
      if (mapStore.layers.areas) {
        areaLayersRef.current = addAreas(zones, mapStore.layers.areas);
      }
    });
    return () => {
      s.unsubscribe();
      remove();
    };
  }, [addAreas, map]);

  useEffect(() => {
    let prevState = mapStore.layers.areas;
    let polygons = [];
    const remove = () => {
      areaLayersRef.current.forEach((polygon) => {
        polygon.off();
        map?.removeLayer(polygon);
      });
    };
    let subscription;
    if (mapData) {
      if (prevState) {
        areaLayersRef.current = addAreas(mapData.areas, prevState);
      }
      subscription = mapStore.layers$.subscribe((layers) => {
        if (layers.areas !== prevState) {
          prevState = layers.areas;
          remove();
          if (prevState) {
            const tempStateArr = [];
            for (const area of Object.values(mapData.areas)) {
              if (!area.device_id) continue;
              deviceState(area.device_id).then((state) =>
                tempStateArr.push(state)
              );
            }
            setAreaTypeDeviceStates(tempStateArr);
            areaLayersRef.current = addAreas(mapData.areas, prevState);
          }
        }
      });
    }
    return () => {
      subscription?.unsubscribe();
      remove();
    };
  }, [addAreas, map, mapData]);

  useEffect(() => {
    if (map) map.on('click', onClickMap);
  }, [map]);

  useEffect(() => {
    if (keeperCoordinate) {
      keeperMarker?.remove();
      const {
        geometry: { coordinates },
        properties: { rotate },
      } = keeperCoordinate;
      const latLng = convertCoordinatesToLatLng(coordinates);
      const icon = L.divIcon({
        html: ReactDOMServer.renderToString(<LocationArrow />),
      });
      setKeeperMarker(
        addMarker({
          map,
          latLng,
          rotationAngle: toDegree(rotate, -45),
          icon,
        })
      );
    }
  }, [keeperCoordinate]);

  useEffect(() => {
    if (centerCoordinates) {
      map?.flyTo(centerCoordinates, 3);
      clearCenterCoordinates();
    }
  }, [centerCoordinates]);

  const [currentKeeperId, setCurrentKeeperId] = useState();
  const [cameraId, setCameraId] = useState('');
  const [hasPermitionToViewCamera, setHasPermitionToViewCamera] =
    useState(false);
  const { setQuery } = useQuery();

  useEffect(() => {
    setQuery({ showKeeperCamera: null });
  }, []);

  useEffect(() => {
    let keepers = mapStore.keepersByMap$.subscribe((keepersByMap) => {
      const queryParams = getQueryParams();
      if (
        !currentKeeperId ||
        !queryParams.showKeeperCamera ||
        queryParams.showKeeperCamera === 'false'
      )
        return;
      console.log(`Watching for keeper camera: ${currentKeeperId}`);
      const keeper = getCurrentKeeper(currentKeeperId, keepersByMap);

      const keeperCameraId = getKeeperCameraId(keeper);
      if (keeperCameraId) {
        setCameraId(keeperCameraId);
        console.log(`Camera updated to ${keeperCameraId} `);
      } else {
        setCameraId(undefined);
        console.warn(`Keeper out of camera areas `);
      }
    });
    return () => keepers.unsubscribe();
  }, [currentKeeperId, cameraId, cameraZones, currentUser]);

  useEffect(() => {
    const s = mapStore.currentCameraId$.subscribe((id) => {
      console.log('currentCameraId$', id);
      setCameraId(id);
      // setCurrentKeeperId(undefined);
    });

    const u = mapStore.selectedKeeperId$.subscribe((selectedKeeperId) => {
      setCurrentKeeperId(selectedKeeperId);
      setCameraId(undefined);
    });

    return () => {
      s.unsubscribe();
      u.unsubscribe();
    };
  }, []);

  function getKeeperCameraId(keeper) {
    let zoneId = undefined;
    // console.log();
    // console.log('keeper', keeper);
    // console.log('cameraZones', cameraZones);
    // console.log('user', currentUser.user.id);

    if (!keeper) return;
    const { current_zones } = keeper.data;
    console.log('current_zones', current_zones);

    for (const zone of current_zones) {
      if (zone.type === 'camera') {
        if (!zone.camera_id) return;
        zoneId = zone.camera_id;
      }
    }
    // console.log('zoneId', zoneId);
    const zone = cameraZones.find(
      (cameraZone) => cameraZone.camera_id === zoneId
    );
    if (!zone) return;

    // console.log('zone', zone);
    const currentUserHasAccess = zone.users_has_access.find(
      (userId) => userId === currentUser.user.id
    );

    if (currentUserHasAccess) {
      setHasPermitionToViewCamera(true);
    } else {
      setHasPermitionToViewCamera(false);
    }
    // console.log('currentUserHasAccess', currentUserHasAccess);
    setSelectedPolygon();
    return zoneId;
  }

  useEffect(() => {
    const zone = cameraZones.find((zone) => zone.camera_id === cameraId);
    if (!zone) return;
    const currentUserHasAccess = zone.users_has_access.find(
      (userId) => userId === currentUser.user.id
    );
    if (currentUserHasAccess) {
      setHasPermitionToViewCamera(true);
    } else {
      setHasPermitionToViewCamera(false);
    }
  }, [cameraId, cameraZones]);

  function getCurrentKeeper(keeperId, keepersByMap) {
    for (let mapId in keepersByMap) {
      const keepers = keepersByMap[mapId];
      return keepers[keeperId];
    }
  }

  const showKeeperCamera =
    currentKeeperId &&
    getQueryParams().showKeeperCamera &&
    getQueryParams().showKeeperCamera === 'true';

  return (
    <>
      <div className="map-wrapper" ref={wrapperRef}>
        {owsData && <button onClick={showDrawer}>open drawer</button>}
        {singleMap?.name ? (
          <div className="map-title">{singleMap.name}</div>
        ) : null}
        <div id={mapId} ref={mapRef}>
          <div style={style} />
        </div>
        {showKeeperCamera ? (
          <>
            {cameraId && hasPermitionToViewCamera && (
              <>
                <CameraPreview
                  wrapperRef={wrapperRef}
                  setCameraId={setCameraId}
                  setSelectedPolygon={setSelectedPolygon}
                  setCurrentKeeperId={setCurrentKeeperId}
                  currentUser={currentUser}
                  cameraId={cameraId}
                  type="preview"
                />
              </>
            )}

            {cameraId && !hasPermitionToViewCamera && (
              <CameraPreview
                wrapperRef={wrapperRef}
                setCameraId={setCameraId}
                setSelectedPolygon={setSelectedPolygon}
                setCurrentKeeperId={setCurrentKeeperId}
                currentUser={currentUser}
                cameraId={cameraId}
                type="notPermitted"
              />
            )}

            {!cameraId && (
              <CameraPreview
                wrapperRef={wrapperRef}
                setCameraId={setCameraId}
                setSelectedPolygon={setSelectedPolygon}
                setCurrentKeeperId={setCurrentKeeperId}
                currentUser={currentUser}
                cameraId={cameraId}
                type="outOfZone"
              />
            )}
          </>
        ) : (
          <>
            {cameraId &&
              (!getQueryParams().showKeeperCamera ||
                getQueryParams().showKeeperCamera === 'false') &&
              hasPermitionToViewCamera && (
                <CameraPreview
                  wrapperRef={wrapperRef}
                  setCameraId={setCameraId}
                  setSelectedPolygon={setSelectedPolygon}
                  setCurrentKeeperId={setCurrentKeeperId}
                  currentUser={currentUser}
                  cameraId={cameraId}
                  type="preview"
                />
              )}
          </>
        )}
      </div>
      {trackedKeeperMapChanges ? (
        <Modal
          onCancel={() => setTrackedKeeperMapChanges(null)}
          visible={!!trackedKeeperMapChanges}
          footer={null}
          className="trackedKeeperModal"
        >
          {Object.keys(trackedKeeperMapChanges?.prev || {}).length ? (
            <List
              size="small"
              header={<Title level={4}>{t('abandondMaps')}</Title>}
              footer={null}
              bordered
              dataSource={Object.values(trackedKeeperMapChanges?.prev || {})}
              renderItem={(map) => (
                <List.Item key={map.id}>{map?.data?.name}</List.Item>
              )}
            />
          ) : null}
          {Object.keys(trackedKeeperMapChanges?.new || {}).length ? (
            <List
              size="small"
              header={<Title level={4}>Новые карты</Title>}
              footer={null}
              bordered
              dataSource={Object.values(trackedKeeperMapChanges?.new || {})}
              renderItem={(map) => (
                <List.Item key={map.id}>
                  <List.Item.Meta title={map?.data?.name} />
                  <Button
                    type="link"
                    onClick={() => history.push(toShowMapId(map.id))}
                  >
                    {t('jump')}
                  </Button>
                </List.Item>
              )}
            />
          ) : null}
        </Modal>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    singleMap: state.svgMap.singleMapState.data,
    owsData: state.ows.owsState.data,
    keeperCoordinate: state.ows.keeperCoordinate,
    centerCoordinates: state.crsMap.centerCoordinates,
    markerTypes: selectMarkerConfigList(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMileagePointHistory: (args) => dispatch(getMileagePointRequest(args)),
    clearCenterCoordinates: () => dispatch(clearCenterCoordinates()),
    getMarkerTypes: () => dispatch(getListRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CRSMap);
