import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '../../helpers/client';

import {
  GET_LIST_REQUEST,
  getListFailure,
  getListSuccess,
} from '../actions/users';

import { DEFAULT_PAGE_SIZE } from '../../components/Pagination/constants';

function* fetchConfigList(action) {
  const { page = 1, size = DEFAULT_PAGE_SIZE, search } = action.payload;

  try {
    const response = yield call(
      request.get,
      '/base_users/?' + `${!!search ? `search=${search}` : ''}`,
      {
        params: { page, size },
      }
    );

    yield put(getListSuccess(response.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

function* Saga() {
  yield all([takeLatest(GET_LIST_REQUEST, fetchConfigList)]);
}

export default Saga;
