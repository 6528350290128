import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { errorNotification } from 'helpers/notificationHelpers';
import { uniqueId } from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const GroupForm = ({ onCreate, groupId = undefined }) => {
  // ---------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------
  const formOpener = useFormOpener();
  const [requestInput, setRequestInput] = useState({
    name: '',
    description: '',
    responsible: '',
  });
  const [isRequesting, setIsRequesting] = useState(false);
  const [employees, setEmployees] = useState();
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [tempSelectedEmployee, setTempSelectedEmployee] = useState();
  const [groupStaffs, setGroupStaffs] = useState([]);
  const { t } = useTranslation();
  // ---------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------

  useEffect(() => {
    if (!groupId) return;
    loadGroupEmployees();
    loadGroup();
  }, [groupId]);

  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmitClick,
      submitButtonProps: { loading: isRequesting },
    });
  }, [requestInput, formOpener, isRequesting, selectedEmployeeIds]);

  useEffect(() => {
    loadEmployees();
  }, []);

  // ---------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------
  async function loadGroup() {
    const responce = await request.get(`/groups/` + groupId);
    const data = responce.data;
    setRequestInput(data);
  }

  async function loadGroupEmployees() {
    const responce = await request.get(`/group_staffs/`);
    const data = responce.data.results;
    const groupStaffs = data.filter(
      (group_staff) => group_staff.group === groupId
    );
    setSelectedEmployeeIds(groupStaffs.map((group_staff) => group_staff.staff));
    setGroupStaffs(groupStaffs);
  }

  async function loadEmployees() {
    const responce = await request.get(`/employees?size=1000`);
    const data = responce.data;
    setEmployees(data);
  }

  async function onSubmitClick(isValid) {
    if (!isValid) return;
    setIsRequesting(true);

    if (groupId && groupStaffs) {
      try {
        const requestFormData = new FormData();
        Object.keys(requestInput).forEach((key) => {
          if (key !== 'id') {
            requestFormData.append(key, requestInput[key]);
          }
        });
        const response = await request.put(
          process.env.REACT_APP_LOCAL_API_BASE_URL +
            '/groups/' +
            requestInput.id +
            '/',
          requestFormData
        );
        if (response.status >= 200 && response.status < 300) {
          for (let selectedEmployeeId of selectedEmployeeIds) {
            const currentGroupStaff = groupStaffs.find(
              (item) => item.staff === selectedEmployeeId
            );

            if (currentGroupStaff) {
              const userFormData = new FormData();
              userFormData.append('group', response.data.id);
              userFormData.append('staff', selectedEmployeeId);
              await request.put(
                process.env.REACT_APP_LOCAL_API_BASE_URL +
                  '/group_staffs/' +
                  currentGroupStaff.id +
                  '/',
                userFormData
              );
            } else {
              const userFormData = new FormData();
              userFormData.append('group', response.data.id);
              userFormData.append('staff', selectedEmployeeId);
              await request.post(
                process.env.REACT_APP_LOCAL_API_BASE_URL + '/group_staffs/',
                userFormData
              );
            }
          }
          const removedGroupStaffs = groupStaffs.filter(
            (item) => !selectedEmployeeIds.includes(item.staff)
          );
          for (let removedGroupStaff of removedGroupStaffs) {
            await request.delete(
              process.env.REACT_APP_LOCAL_API_BASE_URL +
                '/group_staffs/' +
                removedGroupStaff.id +
                '/'
            );
          }
        }
        onCreate();
      } catch (error) {
        errorNotification('Неопределенная ошибка');
      }
    } else {
      try {
        const requestFormData = new FormData();
        Object.keys(requestInput).forEach((key) => {
          requestFormData.append(key, requestInput[key]);
        });
        const response = await request.post(
          process.env.REACT_APP_LOCAL_API_BASE_URL + '/groups/',
          requestFormData
        );
        if (response.status >= 200 && response.status < 300) {
          for (let selectedEmployeeId of selectedEmployeeIds) {
            const userFormData = new FormData();
            userFormData.append('group', response.data.id);
            userFormData.append('staff', selectedEmployeeId);
            const response2 = await request.post(
              process.env.REACT_APP_LOCAL_API_BASE_URL + '/group_staffs/',
              userFormData
            );
            onCreate();
          }
        }
      } catch (error) {
        errorNotification('Неопределенная ошибка');
      }
      setIsRequesting(false);
    }
    setIsRequesting(false);
    onCreate();
  }

  const responsibleUserName = useCallback(() => {
    if (!employees || !requestInput.responsible) return '';
    const responsibleUser = employees.results.find(
      (employee) => employee.id === Number(requestInput.responsible)
    );
    if (!responsibleUser) return '';
    return responsibleUser.name + ' ' + responsibleUser.surname;
  }, [employees, requestInput.responsible]);

  function selectedEmployees() {
    const results = [];

    for (let employee of employees.results) {
      for (let selectedId of selectedEmployeeIds) {
        if (employee.id === selectedId) {
          results.push(employee);
        }
      }
    }
    return results.sort((a, b) => a.surname.localeCompare(b.surname));
  }

  // ---------------------------------------------------------------
  return (
    <>
      <Form.Item
        label={t('title')}
        rules={[
          {
            required: true,
            message: t('enterTitle'),
          },
        ]}
        className="form-row"
        name={groupId ? null : 'name'}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              name: e.target.value,
            });
          }}
          value={requestInput.name}
        />
      </Form.Item>

      <Form.Item
        label={t('description')}
        className="form-row"
        name={groupId ? null : 'description'}
        rules={[
          {
            required: true,
            message: t('enterDesc'),
          },
        ]}
      >
        <Input
          shape="round"
          onChange={(e) => {
            setRequestInput({
              ...requestInput,
              description: e.target.value,
            });
          }}
          value={requestInput.description}
          required={true}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label={t('responsible') + ':'}
        className="form-row"
        required={true}
        name={groupId ? null : 'responsible'}
      >
        <Select
          backfill={false}
          onChange={(selected) => {
            setRequestInput({
              ...requestInput,
              responsible: selected,
            });
          }}
          value={responsibleUserName()}
        >
          {employees &&
            employees.results.map((employee, index) => (
              <Select.Option key={uniqueId('_ddd2')} value={employee.id}>
                {employee.surname} &nbsp; {employee.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Fragment>
        <div className="users">
          <Select
            className="users-select"
            shape="round"
            backfill={false}
            onChange={(selected) => {
              setTempSelectedEmployee(selected);
            }}
            value={tempSelectedEmployee}
            // loading={getValueSetLoading(item) || loading}
          >
            {employees &&
              employees.results.map((employee, index) => (
                <Select.Option
                  key={`FORM_${employee.name}_SELECT_OPTION_${index}`}
                  value={employee.id}
                >
                  {employee.surname} &nbsp; {employee.name}
                </Select.Option>
              ))}
          </Select>
          <Button
            onClick={() => {
              if (!tempSelectedEmployee) return;
              setSelectedEmployeeIds([
                ...selectedEmployeeIds,
                tempSelectedEmployee,
              ]);
              setTempSelectedEmployee();
            }}
          >
            {t('add')}
          </Button>
        </div>
        <div>
          <div className="users-table">
            <p>№</p>
            <p>{t('participant')}</p>
            <p />
          </div>
          {employees &&
            selectedEmployees().map((employee, index) => (
              <div key={index} className="users-table">
                <p>{index + 1}</p>
                <p>
                  {employee.name}
                  &nbsp;
                  {employee.surname}
                </p>
                <CloseOutlined
                  onClick={() => {
                    setSelectedEmployeeIds(
                      selectedEmployeeIds.filter((item) => item !== employee)
                    );
                  }}
                />
              </div>
            ))}
        </div>
      </Fragment>
    </>
  );
};
