
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from 'consts/localization';

export default function initI18n() {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: resources,
      fallbackLng: 'ru',
      interpolation: {
        escapeValue: false,
      },
    });
}