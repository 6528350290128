import { EditFilled } from '@ant-design/icons';
import { Button, Form, Pagination, Table, Typography } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import { getDevicesRequest } from '../../../store/actions/devices';
import {
  makeSelectDevicesCount,
  makeSelectDevicesLoading,
  makeSelectDevicesTableData
} from '../../../store/selectors/devices';
import { MIN_PAGINATION_ITEMS } from '../consts';
import SettingsLayout from '../SettingsLayout';
import columns from './columns';
import { ScadaSettingsForm } from './form/scada-settings-form';

export const DevicesPage = ({ history }) => {
  // --------------------------------------------------------------------------------
  // variables
  // --------------------------------------------------------------------------------
  const { query, setQuery } = useQuery();
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const formOpener = useFormOpener();
  const [scadaSettings, setScadaSettings] = useState({});
  const preparedColumns = columns(t);
  const devices = useSelector(makeSelectDevicesTableData(preparedColumns));
  const devicesLoading = useSelector(makeSelectDevicesLoading);
  const devicesTotalCount = useSelector(makeSelectDevicesCount);

  // --------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [query]
  );

  useEffect(
    () => {
      loadData();
      loadScadaSettings();
    },
    // Need to call this only once at render
    // eslint-disable-next-line
    []
  );

  // --------------------------------------------------------------------------------
  // functions
  // --------------------------------------------------------------------------------

  function loadData() {
    dispatch(
      getDevicesRequest({
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
      })
    );
  }

  async function loadScadaSettings() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/scada_settings/'
    );
    setScadaSettings(response.data);
  }

  function onEditSettingsClick() {
    formOpener.open({
      title: t('editSettings'),
      submitText: t('edit'),
      component: (
        <ScadaSettingsForm
          scadaSettings={scadaSettings}
          onSaveScadaSettings={setScadaSettings}
          isPreviewMode={false}
        />
      ),
    });
  }

  // --------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-actions">
          <Typography.Title>{t('objects')}</Typography.Title>
        </div>
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 20 }}
          className="m-config-wrapper"
        >
          <ScadaSettingsForm scadaSettings={scadaSettings} />
          <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
            <Button
              type="primary"
              icon={<EditFilled />}
              disabled={Object.keys(scadaSettings).length === 0}
              onClick={onEditSettingsClick}
            >
              {Object.keys(scadaSettings).length === 0
                ? t('loading') + '...'
                : t('edit')}
            </Button>
          </Form.Item>
        </Form>

        <Table
          loading={devicesLoading}
          columns={preparedColumns}
          dataSource={devices}
          pagination={false}
        />
        <Pagination
          style={{ marginTop: '1rem' }}
          {...{
            total: devicesTotalCount,
            current: query.page || 1,
            pageSize: query.size || MIN_PAGINATION_ITEMS,
            onChange: (page, size) => setQuery({ page, size }),
            hideOnSinglePage: true,
            showSizeChanger: true,
            position: ['bottomLeft'],
            defaultCurrent: query.page,
          }}
        />
      </div>
    </SettingsLayout>
  );
};
