import { useTranslation } from 'react-i18next';
import { selectEventTypesFetching } from '../../../../store/selectors/groups';

const useConfig = () => {
  const { t } = useTranslation();
  return [
    {
      type: 'text',
      name: 'name',
      label: t('title'),
      require: true,
    },
    {
      type: 'text',
      name: 'description',
      label: t('description'),
      require: true,
    },
    {
      type: 'valueSet',
      name: 'responsible',
      label: t('responsible'),
      selectType: 'employees',
      required: true,
      getValueSet: [],
      getValueSetLoading: selectEventTypesFetching,
    },
    {
      type: 'users',
      name: 'users',
      selectType: 'users',
      require: true,
      getValueSetLoading: selectEventTypesFetching,
    },
  ];
};

export default useConfig;
