import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { useModal } from 'context/modal/modal.hook';
import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import submit from '../form/submit';

export const ReactionsForm = ({
  instance = undefined,
  existTypes,
  onSubmit,
}) => {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const notification_modes = {
    none: t('notification.none'),
    regular: t('notification.popup'),
    danger: t('notification.blocking'),
  };
  const allTypes = {
    danger_zone_entrance: t('dangerZoneEntr'),
    BUTTON_PRESSED: t('buttonPressed'),
    FREE_FALL: t('theFall'),
    MAN_DOWN: t('immobility'),
    MAN_DOWN_CANCEL: t('immobilityCancel'),
    HIGH_HEART_RATE: t("reportHighHeartRate"),
    LOW_HEART_RATE: t("reportLowHeartRate"),
    BATTERY_LOW: t('batteryLow'),
    ANCHORT_STATE_UP: t("anchortStateUp"),
    ANCHORT_STATE_DOWN: t("anchortStateDown")
  };

  const defaultData = {
    event_type: '',
    mode: 'none',
    email_to: '',
    audio: {
      file: undefined,
      url: undefined,
    },
  };

  const [data, setData] = useState(defaultData);
  const isRemoteAudioFile =
    data.audio && !data.audio.file && !data.audio.url && data.audio.length > 1;
  const isLocalAudioFile = data.audio && data.audio.file && data.audio.url;
  const isAudioFileChoosed = isRemoteAudioFile || isLocalAudioFile;


  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (!instance) {
      setData(defaultData);
    } else {
      setData(instance);
    }
  }, [instance]);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  function reportEventEventsTypes() {
    const allowedTypes = {};

    const allTypeKeys = Object.keys(allTypes);

    const allowedTypesKeys = [];

    for (const type of allTypeKeys) {
      if (existTypes.includes(type)) continue;
      allowedTypesKeys.push(type);
    }

    for (const key in allTypes) {
      if (!allowedTypesKeys.includes(key)) continue;
      allowedTypes[key] = allTypes[key];
    }
    return allowedTypes;
  }

  async function updateData(obj) {
    setLoading(true);
    if (!obj['event_type']) {
      obj.event_type = Object.keys(reportEventEventsTypes())[0];
    }
    if (instance) {
      let res = {};
      for (const key in obj) {
        if (key === 'audio' && !obj[key]?.url) continue;
        res[key] = obj[key];
      }
      await request.put(`/events/${instance.id}/`, submit(res));
    } else {
      await request.post('/events/', submit(data));
    }
    setLoading(false);
    onSubmit();
    modal.close();
  }

  // ---------------------------------------------------------------------------
  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 20 }}
      onFinish={(e) => {
        updateData(data);
      }}
      className="m-config-wrapper"
    >
      <Form.Item
        initialValue={'event_type'}
        label={t(`reaction.event_type`)}
        style={{ flex: '0 0 10%', width: '40rem' }}
        rules={[{ required: true, message: t('enterText') }]}
        className="form-row"
        required
      >
        <Select
          onChange={(selected) => setData({ ...data, event_type: selected })}
          value={allTypes[data.event_type]}
          defaultValue={allTypes[Object.keys(reportEventEventsTypes())[0]]}
        >
          {Object.keys(reportEventEventsTypes()).map((key, index) => (
            <Select.Option
              key={`FORM_event_type_SELECT_OPTION_${index}`}
              value={key}
            >
              {reportEventEventsTypes()[key]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        initialValue={'mode'}
        label={t(`reaction.mode`)}
        style={{ flex: '0 0 10%', width: '40rem' }}
        rules={[{ required: true, message: t('enterText') }]}
        className="form-row"
        required
      >
        <Select
          onChange={(selected) => setData({ ...data, mode: selected })}
          value={data.mode ? t(`reaction.${data.mode}`) : ''}
        >
          {Object.keys(notification_modes).map((key, index) => (
            <Select.Option key={`FORM_mode_SELECT_OPTION_${index}`} value={key}>
              {notification_modes[key]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        initialValue={'email_to'}
        label={t(`reaction.email_to`)}
        style={{ flex: '0 0 10%', width: '40rem' }}
        className="form-row"
      >
        <Input
          name={'email_to'}
          onChange={(e) => setData({ ...data, email_to: e.target.value })}
          value={data.email_to}
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        initialValue={'audio'}
        label={t(`reaction.audio`)}
        style={{ flex: '0 0 10%', width: '40rem' }}
        className="form-row"
      >
        {isAudioFileChoosed ? (
          <>
            {isLocalAudioFile && (
              <div className="audio-field-preview">
                <p>Выбрано:</p>
                <span
                  className="link-chip"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className="link-el">
                    {
                      data.audio.url.split('/')[
                        data.audio.url.split('/').length - 1
                      ]
                    }
                  </span>
                  <CloseOutlined
                    className="close"
                    onClick={() => setData({ ...data, audio: null })}
                  />
                </span>
              </div>
            )}

            {isRemoteAudioFile && (
              <>
                <div className="audio-field-preview">
                  <p>Выбрано:</p>
                  <span
                    className="link-chip"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span className="link-el">
                      {data.audio.split &&
                        data.audio.split('/')[data.audio.split('/').length - 1]}
                    </span>
                    <CloseOutlined
                      className="close"
                      onClick={() => setData({ ...data, audio: null })}
                    />
                  </span>
                </div>
              </>
            )}
          </>
        ) : (
          <Input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              setData({
                ...data,
                audio: {
                  file,
                  url: file.name,
                },
              });
            }}
            accept={'.mp3,audio/*'}
            disabled={loading}
          />
        )}
      </Form.Item>

      <Button type="primary" loading={loading} htmlType="submit">
        {t('save')}
      </Button>
    </Form>
  );
};
