import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { useReportFilterParams } from '../report-page.hook';
import { ReportActionLayoutOpener } from '../ui-components/report-action-layout';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportSelectEmployees } from '../ui-components/report-filter/report-select-employees';
import { ReportSelectLayout } from '../ui-components/report-filter/report-select-layout';
import { ReportSelectTransport } from '../ui-components/report-filter/report-select-transport';
import { ReportSelectZone } from '../ui-components/report-filter/report-select-zone';
import { ReportTable } from '../ui-components/reports-table';

export const ReportsEmployeeTransportConvergence = ({
  showFilter,
  reports,
  loading,
}) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const columns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (v) => moment(v).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'transport_name',
      dataIndex: 'transport_name',
      title: t('transport'),
    },
    {
      key: 'duration',
      dataIndex: 'duration',
      title: t('duration'),
    },
    {
      key: 'to_employee_name',
      dataIndex: 'to_employee_name',
      title: t('toEmployee'),
    },
    {
      key: 'zone_name',
      dataIndex: 'zone_name',
      title: t('zone'),
    },
    {
      key: 'organization_title_1',
      dataIndex: 'transport_organization',
      title: t('organization') + ' 1',
      render: (v) => (!v ? t('notSpecified') : v),
    },
    {
      key: 'organization_title_2',
      dataIndex: 'to_employee_organization',
      title: t('organization') + ' 2',
      render: (v) => (!v ? t('notSpecified') : v),
    },
    {
      key: 'layout_name',
      dataIndex: 'layout_name',
      title: t('map'),
    },
    {
      key: 'actions',
      dataIndex: 'layout_name',
      title: t('actions'),
      render: (_, record) => (
        <ReportActionLayoutOpener
          record={{ ...record, layout: { id: record?.layout_id } }}
        />
      ),
    },
  ];
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
  }, []);

  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />

            <div className="filter-params">
              <ReportSelectTransport
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('toEmployee')}
                paramName={'to_employee_id'}
              />

              <ReportSelectZone
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectLayout
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />
            </div>
          </div>
          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}
      <ReportTable
        columns={columns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
};
