import Icon, {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import L from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getBounds } from 'helpers/getters';
import { createImage } from 'helpers/imageHelpers';
import { initMap } from 'helpers/mapHelpers';
import { Area } from 'model/Area';
import { mapStore } from 'model/MapStore';
import { useTranslation } from 'react-i18next';
import { PolygonSvg, SquareSvg } from './icons';
import './styles.scss';

const commitDrawingEvent = 'editable:drawing:commit';

const convertFromMeters = (coords, map) => {
  const { x0, y0, x0m, y0m, ratio } = map || {};
  if (
    x0 !== undefined &&
    y0 !== undefined &&
    x0m !== undefined &&
    y0m !== undefined &&
    ratio !== undefined &&
    coords?.length
  ) {
    return coords.map(([lat, lng]) => [
      (lat - y0m) * ratio + y0,
      (lng - x0m) * ratio + x0,
    ]);
  }
  return [];
};

const convertToMeters = (coords, map) => {
  const { x0, y0, x0m, y0m, ratio } = map || {};
  if (
    x0 !== undefined &&
    y0 !== undefined &&
    x0m !== undefined &&
    y0m !== undefined &&
    ratio !== undefined &&
    coords?.length
  ) {
    return coords.map(([lat, lng]) => [
      (lat - y0) / ratio + y0m,
      (lng - x0) / ratio + x0m,
    ]);
  }
  return [];
};

export const CRSMap = ({ area, setArea, bounds, image, setBounds }) => {
  const { t } = useTranslation();
  const mapElementId = 'map';
  const { mapId, areaId } = useParams();
  const [map, setMap] = useState();
  const currentImage = useRef(null);
  const shape = useRef();
  const mapInstanse = useMemo(() => mapId && mapStore?.maps[mapId], [mapId]);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const location = useLocation();
  const [_, forceRender] = useState({});
  const handleLoadImage = useCallback(
    (img) => {
      setBounds(getBounds(img.width, img.height));
    },
    [setBounds]
  );

  const initBounds = useCallback(() => {
    createImage(image, handleLoadImage);
  }, [handleLoadImage, image]);

  useEffect(() => {
    if (map && area && !shape.current) {
      const { coordinates, figure_type } = area;
      const factory = figure_type === 'rectangle' ? L.rectangle : L.polygon;
      const newShape = factory(
        convertFromMeters(coordinates, mapInstanse)
      ).addTo(map);
      const commit = () => {
        const [latLngs] = newShape?.getLatLngs?.() || [];
        if (latLngs && shape.current) {
          const coordinates = convertToMeters(
            [...latLngs?.map((point) => [point.lat, point.lng])],
            mapInstanse
          );
          if (coordinates.length > 2) {
            area.update({ coordinates });
            shape.current?.shape?.disableEdit();
          } else {
            shape.current?.shape?.remove();
            shape.current = null;
            forceRender({});
          }
        }
      };
      map?.on(commitDrawingEvent, commit);
      shape.current = { shape: newShape, commit };
      forceRender({});
    }
  }, [area, map, mapInstanse, shape.current]);

  useEffect(
    () => () => {
      setDeleting(false);
      setEditing(false);
    },
    []
  );

  useEffect(() => {
    initBounds();
  }, [initBounds]);

  useEffect(() => {
    if (bounds && image) {
      currentImage.current = image;
      if (!map && bounds)
        setMap(initMap({ mapId: mapElementId, image, bounds, editable: true }));
    }
  }, [bounds, image, map]);

  useEffect(() => {
    if (map && bounds && image) {
      map.currentImageOverlay = L.imageOverlay(image, bounds).addTo(map);
    }
    return () => {
      if (map?.currentImageOverlay) {
        map.removeLayer(map.currentImageOverlay);
      }
    };
  }, [bounds, image, map]);

  const createShape = (isRectangle) => {
    if (map) {
      const figure_type = isRectangle ? 'rectangle' : 'polygon';
      const newShape = map?.editTools?.[
        isRectangle ? 'startRectangle' : 'startPolygon'
      ]?.(undefined, { opacity: 0.1 });
      const commit = () => {
        const [latLngs] = newShape?.getLatLngs?.() || [];
        if (latLngs && shape) {
          const coordinates = convertToMeters(
            [...latLngs?.map((point) => [point.lat, point.lng])],
            mapInstanse
          );
          if (coordinates.length > 2) {
            shape.current?.shape?.disableEdit();
            if (area) {
              area.update({ figure_type, coordinates });
            } else {
              setArea(new Area(mapInstanse, { figure_type, coordinates }));
            }
          }
        }
      };
      map?.on(commitDrawingEvent, commit);
      shape.current = { commit, shape: newShape };
    }
  };

  const editShapes = (editMode) => {
    setEditing(editMode);
    if (editMode) {
      shape?.current?.commit?.();
      shape?.current?.shape?.enableEdit();
    } else {
      shape?.current?.commit?.();
    }
  };

  const removeShapes = () => {
    setDeleting(true);
    if (shape.current) {
      shape.current?.commit?.();
      shape.current?.shape?.remove();
      map?.off(commitDrawingEvent, shape.current?.commit);
      shape.current = null;
      setArea(null);
    }
  };

  return (
    <div className="map-wrapper">
      <div id={mapElementId} />
      <div className="controls">
        <Tooltip title={t('arbitraryArea')} placement="left">
          <Button
            size="small"
            onClick={() => createShape(false)}
            icon={<Icon component={PolygonSvg} />}
            disabled={!!shape.current}
          />
        </Tooltip>
        <Tooltip title={t('square')} placement="left">
          <Button
            size="small"
            onClick={() => createShape(true)}
            icon={<Icon component={SquareSvg} />}
            disabled={!!shape.current}
          />
        </Tooltip>
        <Tooltip title={t('edit')} placement="left">
          <Button
            size="small"
            onClick={() => editShapes(!editing)}
            icon={editing ? <SaveOutlined /> : <EditOutlined />}
            disabled={!shape.current}
          />
        </Tooltip>
        <Tooltip title={t('delete')} placement="left">
          <Button
            size="small"
            onClick={removeShapes}
            icon={<DeleteOutlined />}
            disabled={!shape.current}
          />
        </Tooltip>
      </div>
    </div>
  );
};
