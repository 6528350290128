import React, {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'antd';
import {useParams} from 'react-router-dom';

import {makeSelectGetSingleMap} from 'store/selectors/svgMap';
import {CoordinatesForm} from '../CoordinatesForm/CoordinatesForm';
import { useTranslation } from 'react-i18next';

export const SideBar = ({
  boundsCoords,
  boundsEditingMode,
  imageFile,
  newMap,
  onSubmit,
  onSubmitCoordinates,
  setBoundsEditingMode,
  setImageFile,
  setSegmentLength,
  submitRef
}) => {
  const imageInput = useRef(null);
  const submitBtnRef = useRef(null);
  const {t} = useTranslation()

  const {mapId} = useParams();

  const stateMapData = useSelector(makeSelectGetSingleMap);
  const singleMap = useMemo(() => newMap ? null : stateMapData, [newMap, stateMapData]);

  const clickImageInput = () => {
    imageInput.current.click();
  };

  const handleSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleImageLoad = (e) => {
    const [file] = e.target.files;
    if (file) setImageFile(file);
  };

  return (
    <div className="sidebar">
      <Form hidden={true} onFinish={onSubmit}>
        <input
          ref={submitRef}
          type="submit"
          value="submit"
          className="d-none"
        />
      </Form>

      <input
        ref={imageInput}
        className="d-none"
        type="file"
        name="image"
        onChange={handleImageLoad}
      />
      <CoordinatesForm
        boundsCoords={boundsCoords}
        boundsEditingMode={boundsEditingMode}
        clickImageInput={clickImageInput}
        onSubmit={onSubmitCoordinates}
        setBoundsEditingMode={setBoundsEditingMode}
        setSegmentLength={setSegmentLength}
        singleMap={singleMap}
        submitBtnRef={submitBtnRef}
      />
      <button className="btn" onClick={handleSubmit}>
        {!!mapId ? t('edit') : t('create')}
      </button>
    </div>
  );
};
