export const STATE_KEY = '@MARKER_CONFIG';

export const GET_LIST_REQUEST = `${STATE_KEY}_GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${STATE_KEY}_GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${STATE_KEY}_GET_LIST_FAILURE`;

export const CREATE_REQUEST = `${STATE_KEY}_CREATE_REQUEST`;
export const CREATE_SUCCESS = `${STATE_KEY}_CREATE_SUCCESS`;
export const CREATE_FAILURE = `${STATE_KEY}_CREATE_FAILURE`;

export const UPDATE_REQUEST = `${STATE_KEY}_UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${STATE_KEY}_UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${STATE_KEY}_UPDATE_FAILURE`;

export const SET_IS_CREATE_FORM_OPEN = `${STATE_KEY}_SET_IS_CREATE_FORM_OPEN`;
export const SET_IS_EDIT_FORM_OPEN = `${STATE_KEY}_SET_IS_EDIT_FORM_OPEN`;

export const ASK_TO_DELETE = `${STATE_KEY}_ASK_TO_DELETE`;
export const DELETE_REQUEST = `${STATE_KEY}_DELETE_REQUEST`;
export const DELETE_SUCCESS = `${STATE_KEY}_DELETE_SUCCESS`;
export const DELETE_FAILURE = `${STATE_KEY}_DELETE_FAILURE`;


export const getListRequest = payload => ({
  type: GET_LIST_REQUEST,
  payload,
});
export const getListSuccess = payload => ({
  type: GET_LIST_SUCCESS,
  payload,
});
export const getListFailure = payload => ({
  type: GET_LIST_FAILURE,
  payload,
});

export const setIsCreateFormOpen = payload => ({
  type: SET_IS_CREATE_FORM_OPEN,
  payload,
});
export const setIsEditFormOpen = payload => ({
  type: SET_IS_EDIT_FORM_OPEN,
  payload,
});

export const createRequest = payload => ({
  type: CREATE_REQUEST,
  payload,
});
export const createSuccess = payload => ({
  type: CREATE_SUCCESS,
  payload,
});
export const createFailure = payload => ({
  type: CREATE_FAILURE,
  payload,
});

export const updateRequest = payload => ({
  type: UPDATE_REQUEST,
  payload,
});
export const updateSuccess = payload => ({
  type: UPDATE_SUCCESS,
  payload,
});
export const updateFailure = payload => ({
  type: UPDATE_FAILURE,
  payload,
});

export const askToDelete = payload => ({
  type: ASK_TO_DELETE,
  payload,
});

export const deleteRequest = payload => ({
  type: DELETE_REQUEST,
  payload,
});
export const deleteSuccess = payload => ({
  type: DELETE_SUCCESS,
  payload,
});
export const deleteFailure = payload => ({
  type: DELETE_FAILURE,
  payload,
});
