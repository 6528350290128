import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const ChartsSOSCount = () => {
  const [eventCount, setEventCount] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    axios
      .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/dashboards/sos_counts')
      .then((response) => {
        setEventCount(response.data.today);
      });
  }, []);

  return (
    <div className="char-component-wrapper">
      <div className="char-component-title">
        <span>
          {t('dashboard.sosEventPerDay', {
            val: eventCount,
          })}
        </span>
      </div>
      <div className="char-component">
        <div className="char-wrapper">
          <div className="content">
            <div
              className="char-pie-count"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('reports?tab=events&event_type=BUTTON_PRESSED&page=1&for_today=true');
              }}
            >
              <span className="char-pie-text">{eventCount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
