import {useDispatch, useSelector} from 'react-redux';
import {makeSelectGetSingleMap} from 'store/selectors/svgMap';
import {useParams} from 'react-router-dom';
import React, {useEffect} from 'react';
import {getMapByIdRequest} from 'store/actions/svgMap';
import {MapArea} from './Map/MapArea';

export const Area = () => {
  const mapData = useSelector(makeSelectGetSingleMap);

  const dispatch = useDispatch();
  const {mapId} = useParams();

  useEffect(() => {
    dispatch(getMapByIdRequest(mapId));
  }, [mapId]);

  return !!mapData && <MapArea map={mapData}/>;
};
