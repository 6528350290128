import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

export const UserDrawerEmployeeMainStats = ({ keeper }) => {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const sensors = keeper?._embedded?.tag?.sensors;
  const { t } = useTranslation();

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  function employeesPosition() {
    if (!keeper || !keeper.employees) return '';
    if (keeper?.employees?.length === 1) {
      return keeper.employees[0].post;
    } else {
      return keeper.employees.map((employee) => employee.post).join(' / ');
    }
  }

  function employeesServiceNumber() {
    if (!keeper) return;
    if (!keeper.employees) {
    } else if (keeper?.employees?.length === 1) {
      return keeper.employees[0].service_number;
    } else {
      return keeper.employees
        .map((employee) => employee.service_number)
        .join(' / ');
    }
  }

  function employeesMark() {
    if (!keeper) return;
    if (!keeper.employees) {
    } else if (keeper?.employees?.length === 1) {
      return keeper.employees[0].mark;
    } else {
      return keeper.employees.map((employee) => employee.mark).join(' / ');
    }
  }

  // ------------------------------------------------------------------------------------------------------------
  return (
    <>
      <div className="d-flex-center-center">
        <Avatar size="large" icon={<UserOutlined />} />
      </div>
      <p className="stack-drawer-title mt-20">{keeper?.name}</p>
      <p className="d-flex-sb">
        <span>{t('labelNumber')}:</span>
        <span className="green">{employeesMark()}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('personnelNumber')}:</span>
        <span className="green">{employeesServiceNumber()}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('position')}:</span>
        <span className="green">{employeesPosition()}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('battery')}:</span>
        <span className="green">{sensors?.battery_state}</span>
      </p>
    </>
  );
};
