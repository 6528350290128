import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { t as tFunc } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { useQuery } from '../../../hooks/query';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import { OrganizationForm } from './form.component';

const OrganizationsConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { t } = useTranslation();
  const { query, setQuery } = useQuery();
  // const count = useSelector(selectConfigListCount);
  // const formOpener = useFormOpener();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const formOpener = useFormOpener();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => {
              handleClickEdit(instance);
            }}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={() => {
              formOpener.open({
                title: "Удалить организацию",
                submitText: t('delete'),
                component: "Вы действительно хотите удалить организацию?",
                onSubmit:  () => remove(instance.id),
                loading: deleteLoading,
              });
            }}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(() => {
    loadData();
  }, [query]);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  async function loadData() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/organizations/',
      {
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
      }
    );
    setCount(response.data.count);
    setData(response.data.results);
  }

  async function handleClickCreate() {
    formOpener.open({
      title: 'Добавить организацию',
      cancelText: tFunc('cancel'),
      submitText: tFunc('create'),
      component: (
        <OrganizationForm
          onSave={() => {
            loadData();
          }}
        />
      ),
    });
  }

  async function handleClickEdit(instance) {
    formOpener.open({
      title: 'Изменить организацию',
      cancelText: t('cancel'),
      submitText: t('edit'),
      component: (
        <OrganizationForm
          instance={instance}
          onSave={() => {
            loadData();
          }}
        />
      ),
    });
  }

  async function remove(id) {
    setDeleteLoading(true);
    await request.delete(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/organizations/' + id
    );
    setDeleteLoading(false);
    loadData();
    formOpener.close();
  }

  // ------------------------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-filter-wrap">
          <div></div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            Добавить организацию
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="employeesTable"
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={count}
          current={Number(query.page) || 1}
          pageSize={query.size || MIN_PAGINATION_ITEMS}
          onChange={(page, size) => setQuery({ ...query, page, size })}
          hideOnSinglePage={true}
          showSizeChanger={true}
          defaultCurrent={Number(query.page)}
        />
      </div>
    </SettingsLayout>
  );
};

export default OrganizationsConfiguration;
