const STATE_KEY = '@CAMERAS';

export const GET_CONFIG_REQUEST = `${STATE_KEY}_GET_CONFIG_REQUEST`;
export const GET_CONFIG_SUCCESS = `${STATE_KEY}_GET_CONFIG_SUCCESS`;
export const GET_CONFIG_FAILURE = `${STATE_KEY}_GET_CONFIG_FAILURE`;

export const UPDATE_CONFIG_REQUEST = `${STATE_KEY}_UPDATE_CONFIG_REQUEST`;
export const UPDATE_CONFIG_SUCCESS = `${STATE_KEY}_UPDATE_CONFIG_SUCCESS`;
export const UPDATE_CONFIG_FAILURE = `${STATE_KEY}_UPDATE_CONFIG_FAILURE`;
export const SET_IS_EDIT_FORM_OPEN = `${STATE_KEY}_SET_IS_EDIT_FORM_OPEN`;


export const getConfigRequest = payload => ({
  type: GET_CONFIG_REQUEST,
  payload,
});
export const getConfigSuccess = payload => ({
  type: GET_CONFIG_SUCCESS,
  payload,
});
export const getConfigFailure = payload => ({
  type: GET_CONFIG_FAILURE,
  payload,
});

export const updateConfigRequest = payload => ({
  type: UPDATE_CONFIG_REQUEST,
  payload,
});
export const updateConfigSuccess = payload => ({
  type: UPDATE_CONFIG_SUCCESS,
  payload,
});
export const updateConfigFailure = payload => ({
  type: UPDATE_CONFIG_FAILURE,
  payload,
});
export const setIsEditFormOpen = payload => ({
  type: SET_IS_EDIT_FORM_OPEN,
  payload,
});
