import { Button, Form, Input } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CategoryForm({
  submitRef,
  handleSubmit,
  category,
  loading,
}) {
  const imageInput = useRef(null);
  const [file, setFile] = useState();
  const [imgUrl, setImgUrl] = useState(category?.thumbnail);
  const { t } = useTranslation();
  const handleClickUpload = () => {
    imageInput.current.click();
  };

  const handleImageLoad = (e) => {
    const [file] = e.target.files;
    if (file) {
      setFile(file);
      setImgUrl(URL.createObjectURL(file));
    }
  };

  const handleClickSubmit = ({ name, order,...rest }) => {
    handleSubmit({ order:+order, name, thumbnail: file, id: category?.id });
  };

  return (
    <div className="categories">
      <div className="categories-form">
        <Form onFinish={handleClickSubmit}>
          <Form.Item
            name="name"
            label={t('pictureName')}
            initialValue={category?.name}
            rules={[{ required: true, message: t('enterText') }]}
          >
            <Input shape="round" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="order"
            label={t('sortOrder')}
            initialValue={category?.order}
            rules={[{ required: true, message: t('enterSortOrder') }]}
          >
            <Input min={0} type="number" shape="round" disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button
              className="form-btn"
              onClick={handleClickUpload}
              disabled={loading}
            >
              {t('picLoading')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              ref={submitRef}
              className="d-none"
              htmlType="submit"
              loading={loading}
            >
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="categories-image">
        <input
          ref={imageInput}
          className="d-none"
          type="file"
          name="image"
          onChange={handleImageLoad}
        />
        {imgUrl && <img src={imgUrl} alt="category img" />}
      </div>
    </div>
  );
}
