import {
  SET_UPLOAD_MAP_FETCHING,
  SET_UPLOAD_MAP_ERROR,
  SET_UPLOAD_MAP_DATA,
  SET_GET_SINGLE_MAP_FETCHING,
  SET_GET_SINGLE_MAP_FAILURE,
  SET_GET_SINGLE_MAP_DATA,
  SET_GET_MAPS_LIST_FETCHING,
  SET_GET_MAPS_LIST_FAILURE,
  SET_GET_MAPS_LIST_DATA,
  GET_MAIN_MAP_DATA,
  GET_MAIN_MAP_FAILURE,
  GET_MAIN_MAP_FETCHING,
  ADD_MAIN_MAP_FETCHING,
  ADD_MAIN_MAP_FAILURE,
  ADD_MAIN_MAP_DATA,
  EDIT_MAP_FETCHING,
  EDIT_MAP_FAILURE,
  EDIT_MAP_DATA
} from '../actions/svgMap';
import { successNotification } from '../../helpers/notificationHelpers';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  uploadMapState: reqState,
  singleMapState: reqState,
  mapsListState: {
    ...reqState,
    data: [],
    count: 0,
  },
  mainMapState: reqState,
  editMapState: reqState
};

export default function svgMap(state = initialState, { type, payload }) {
  switch (type) {
    case SET_UPLOAD_MAP_FETCHING:
      return {
        ...state,
        uploadMapState: { ...state.uploadMapState, fetching: payload },
      };
    case SET_UPLOAD_MAP_DATA:
      successNotification('Успешно загружено');
      return {
        ...state,
        uploadMapState: { ...state.uploadMapState, data: payload },
      };
    case SET_UPLOAD_MAP_ERROR:
      return {
        ...state,
        uploadMapState: { ...state.uploadMapState, error: payload },
      };

    case SET_GET_SINGLE_MAP_FETCHING:
      return {
        ...state,
        singleMapState: { ...state.singleMapState, fetching: payload },
      };
    case SET_GET_SINGLE_MAP_DATA:
      return {
        ...state,
        singleMapState: { ...state.singleMapState, data: payload },
      };
    case SET_GET_SINGLE_MAP_FAILURE:
      return {
        ...state,
        singleMapState: { ...state.singleMapState, error: payload },
      };

    case SET_GET_MAPS_LIST_FETCHING:
      return {
        ...state,
        mapsListState: { ...state.mapsListState, fetching: payload },
      };
    case SET_GET_MAPS_LIST_DATA:
      return {
        ...state,
        mapsListState: {
          ...state.mapsListState,
          data: payload.results,
          count: payload.count,
        },
      };
    case SET_GET_MAPS_LIST_FAILURE:
      return {
        ...state,
        mapsListState: { ...state.mapsListState, error: payload },
      };

    case GET_MAIN_MAP_FETCHING:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, fetching: true },
      };
    case GET_MAIN_MAP_DATA:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, data: payload, fetching: false },
      };
    case GET_MAIN_MAP_FAILURE:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, error: payload, data: null, fetching: false },
      };

    case ADD_MAIN_MAP_FETCHING:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, fetching: true },
      };
    case ADD_MAIN_MAP_DATA:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, data: payload, fetching: false },
      };
    case ADD_MAIN_MAP_FAILURE:
      return {
        ...state,
        mainMapState: { ...state.mainMapState, error: payload, fetching: false },
      };
    case EDIT_MAP_FETCHING:
      return {
        ...state,
        editMapState: { ...state.editMapState, fetching: true },
      };
    case EDIT_MAP_DATA:
      return {
        ...state,
        editMapState: { ...state.editMapState, data: payload, fetching: false },
        singleMapState: { ...state.singleMapState, data: payload }
      };
    case EDIT_MAP_FAILURE:
      return {
        ...state,
        editMapState: { ...state.editMapState, error: payload, fetching: false },
      };
    default:
      return state;
  }
}
