import { CloseOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, List, Modal, Select } from 'antd';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getBounds, safeGet } from '../../helpers/getters';
import { createImage } from '../../helpers/imageHelpers';
import {
  addGeoJSON, initMap, removeAllLayersAndResetImage,
  setDrawerToolbar
} from '../../helpers/mapHelpers';

import {
  clearAreas, clearForm, clearUpdating, deleteAreaRequest, getAreasRequest, nominateToDelete, setIsEditing, setPolygon
} from '../../store/actions/drawPolygons';
import {
  makeSelectAreas, makeSelectDeletingLoading,
  makeSelectIsEditing, makeSelectNominatedToDelete, makeSelectPolygon, makeSelectUpdatingData
} from '../../store/selectors/drawPolygonSelector';

import { useTranslation } from 'react-i18next';
import { getDevicesRequest } from '../../store/actions/devices';
import Create from './Create';
import Edit from './Edit';
import './index.scss';
import { drawMapHeaderFilterItems } from './mockedData';

const { Option } = Select;

const DrawMapPolygons = ({ image }) => {
  const mapId = 'map';
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [map, setMap] = useState();
  const [bounds, setBounds] = useState();
  // const [drawer, setDrawer] = useState();
  const [toolbarLayer, setToolbarLayer] = useState();
  const [selected, setSelected] = useState(null);
  const [isDraw, setIsDraw] = useState(false);
  const [isDrawFinished, setIsDrawFinished] = useState(false);
  const [filterItems, setFilterItems] = useState();

  const selectedLayer = useRef(null);
  const isDrawEventAdded = useRef(false);

  const polygon = useSelector(makeSelectPolygon);

  const areas = useSelector(makeSelectAreas);
  const nominatedToDelete = useSelector(makeSelectNominatedToDelete);
  const deletingLoading = useSelector(makeSelectDeletingLoading);
  const isEditing = useSelector(makeSelectIsEditing);
  const updatedData = useSelector(makeSelectUpdatingData);

  const _setIsEditing = (value) => {
    dispatch(setIsEditing(value));
  };

  const handleFilterChange = (e) => {
    setFilterItems(e);
  };

  const handleLoadImage = (img) => {
    setBounds(getBounds(img.width, img.height));
  };

  const initBounds = () => {
    createImage(image, handleLoadImage);
  };

  const handleCreateClick = () => {
    if (!map) return;
    removeSelectedLayer();
    _setDrawMode(true);
  };

  const _setDrawMode = useCallback(
    (value) => {
      setIsDraw(value);
    },
    [setIsDraw, isDraw, map, dispatch]
  );

  const handleNominateToDelete = (id) => () => {
    if (!id) return;
    dispatch(nominateToDelete(id));
  };

  const handleDeleteRequest = () => {
    if (!nominatedToDelete) return;
    if (selected && selected.id === nominatedToDelete) {
      setSelected(null);
      removeSelectedLayer();
    }

    dispatch(
      deleteAreaRequest({
        id: nominatedToDelete,
        layoutId: params.mapId,
      })
    );
  };

  const handleCreateFormClose = () => {
    _setDrawMode(false);
    if (map && polygon) map.removeLayer(polygon);
    dispatch(clearForm());
  };

  const handleEditArea = (item) => () => {
    if (selected && selected.id !== item.id) {
      setSelected(item);
      drawSelectedPolygon(item);
    }
    dispatch(setPolygon(selectedLayer.current));
    _setIsEditing(true);
  };

  const handleEditFormClose = () => {
    dispatch(clearForm());
    _setIsEditing(false);
    removeAllLayersAndResetImage({ image, bounds, map, setMap, mapId });
  };

  const handleEditPolygon = () => {
    removeSelectedLayer();

    _setDrawMode(true);
  };

  const handlePolygonAdd = useCallback(
    (data) => {
      const polygons = data.layer._latlngs;
      if (!polygons?.length) return;
      dispatch(setPolygon(data.layer));
      setIsDrawFinished(true);
    },
    [dispatch, setIsDrawFinished]
  );

  const removeSelectedLayer = useCallback(() => {
    if (selectedLayer.current) {
      map.removeLayer(selectedLayer.current);
      selectedLayer.current = null;
    }
  }, [map, selectedLayer]);

  const drawSelectedPolygon = useCallback(
    (geoJSON) => {
      if (selected) {
        removeSelectedLayer();
        selectedLayer.current = addGeoJSON({
          map,
          geoJSON,
          options: { color: safeGet(geoJSON, 'properties.color') },
        });
      }
    },
    [selectedLayer, map, selected, removeSelectedLayer]
  );

  useEffect(() => {
    initBounds();
    dispatch(getDevicesRequest());

    if (params.mapId) {
      dispatch(
        getAreasRequest({ layoutId: params.mapId, filter: filterItems })
      );
    }

    return () => {
      dispatch(clearAreas());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (bounds) {
      if (!map && bounds) setMap(initMap({ mapId, image, bounds }));
    }
  }, [bounds]); // eslint-disable-line

  // useEffect(() => {
  //   if (map) {
  //     setDrawer(initDrawer({map}));
  //   }
  // }, [map]);

  useEffect(() => {
    if (map && isDraw && !toolbarLayer) {
      setDrawerToolbar({
        map,
        removeSelectedLayer,
        handlePolygonAdd,
        setToolbarLayer,
        errMessage: `<strong>${t('sorry')}<strong> ${t('yCantDrawLikeThat')} `,
      });
    }
  }, [isDraw, map]);

  useEffect(() => {
    if (areas.length) {
      if (!selected) {
        if (updatedData) {
          const target = areas.find((item) => item.id === updatedData.id);
          setSelected(target || areas[0]);
          dispatch(clearUpdating());
        } else {
          setSelected(areas[0]);
        }
      }
    } else {
      if (!!selected) setSelected(null);
    }
  }, [areas, selected, updatedData, dispatch]);

  useEffect(() => {
    if (map && selected && !isDraw && !polygon) {
      drawSelectedPolygon(selected);
    }
  }, [
    selected,
    selectedLayer,
    map,
    isDraw,
    polygon,
    drawSelectedPolygon,
    isEditing,
    dispatch,
  ]);

  // useEffect(
  //   () => {
  //     if (drawer && !isDrawEventAdded.current) {
  //       isDrawEventAdded.current = true;
  //       drawer.on('layeradd', handlePolygonAdd);
  //     }
  //
  //     return () => {
  //       if (isDrawEventAdded.current) {
  //         drawer.off('layeradd');
  //         isDrawEventAdded.current = false;
  //       }
  //     };
  //   },
  //   [drawer, isDrawEventAdded, dispatch, handlePolygonAdd],
  // );
  //
  useEffect(() => {
    if (isDraw && isDrawFinished) {
      _setDrawMode(false);
      setIsDrawFinished(false);
    }
  }, [isDraw, _setDrawMode, isDrawFinished]);

  useEffect(() => {
    if (params.mapId) {
      dispatch(
        getAreasRequest({ layoutId: params.mapId, filter: filterItems })
      );
    }
  }, [filterItems, params.mapId]);

  const drawMapHeader = (
    <div className="draw-map-header">
      <h2>{t('equipmentList')}</h2>
      <Select
        style={{ width: '180px' }}
        defaultValue={drawMapHeaderFilterItems[0].key}
        onChange={handleFilterChange}
      >
        {drawMapHeaderFilterItems.map((item) => (
          <Option value={item.key} key={item.key}>
            {item.value}
          </Option>
        ))}
      </Select>
    </div>
  );

  const list = (
    <List
      size="large"
      bordered
      header={drawMapHeader}
      footer={
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          className="create-button"
          onClick={handleCreateClick}
        >
          {t('addArea')}
        </Button>
      }
      itemLayout="horizontal"
      dataSource={areas}
      renderItem={(item, idx) => (
        <List.Item
          key={idx}
          className={cn('area-list-item', {
            selected: safeGet(selected, 'id') === safeGet(item, 'id', -1),
          })}
          onClick={() => setSelected(item)}
        >
          <List.Item.Meta
            title={safeGet(item, 'properties.name')}
            description={
              <div className="color-wrapper">
                Цвет:{' '}
                <span
                  className="area-color"
                  style={{
                    background: safeGet(item, 'properties.color', '#ffffff'),
                    marginLeft: 16,
                  }}
                />
              </div>
            }
          />
          <div className="area-actions">
            <Button
              shape="circle"
              type="primary"
              icon={<EditFilled />}
              size="small"
              className="area-action"
              onClick={handleEditArea(item)}
            />
            <Button
              shape="circle"
              type="primary"
              danger
              icon={<CloseOutlined />}
              size="small"
              onClick={handleNominateToDelete(safeGet(item, 'id'))}
            />
          </div>
        </List.Item>
      )}
    />
  );

  return (
    <div className={cn('map-wrapper', { drawing: isDraw })}>
      <div
        className="areas-list-wrapper"
        style={{ display: isDraw ? 'none' : 'initial' }}
      >
        {!polygon ? (
          list
        ) : isEditing ? (
          <Edit
            layoutId={params.mapId}
            onClose={handleEditFormClose}
            initials={selected}
            handleEditPolygon={handleEditPolygon}
            map={map}
          />
        ) : (
          <Create
            map={map}
            layoutId={params.mapId}
            handleCreateFormClose={handleCreateFormClose}
            drawSelectedPolygon={drawSelectedPolygon}
          />
        )}
      </div>
      <div className="map" id={mapId} />

      <Modal
        visible={!!nominatedToDelete}
        onCancel={() => dispatch(nominateToDelete(null))}
        okText={t('delete')}
        okButtonProps={{
          danger: true,
          type: 'primary',
          loading: deletingLoading,
        }}
        cancelText={t('cancel')}
        width={340}
        title={t('confirmAction')}
        closable={!deletingLoading}
        keyboard={!deletingLoading}
        onOk={handleDeleteRequest}
      >
        <p>{t('confirmAreaDelete')}?</p>
      </Modal>
    </div>
  );
};

export default DrawMapPolygons;
