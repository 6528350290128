import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'components/Pagination/constants';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { useModal } from 'context/modal/modal.hook';
import { request } from 'helpers/client';
import { useQuery } from 'hooks/query';
import { MIN_PAGINATION_ITEMS } from 'pages/Settings/consts';
import SettingsLayout from 'pages/Settings/SettingsLayout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { initForm } from 'store/actions/forms';
import {
  askToDelete,
  getEventTypeRequest,
  setIsCreateFormOpen,
  setIsEditFormOpen,
} from 'store/actions/notificationConfigurations';
import useConfig from '../form/config';
import { CREATE_FORM, EDIT_FORM } from '../form/constants';
import { useEventTypeNameMapper } from '../utils';
import { ReactionsForm } from './reactions.form';

const NotificationConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const { query, setQuery } = useQuery();
  const { t } = useTranslation();
  const config = useConfig();
  const modal = useModal();
  const formOpener = useFormOpener();
  const eventTypeNameMapper = useEventTypeNameMapper();
  const columns = [
    // {
    //   title: 'Изображение',
    //   dataIndex: 'thumbnail',
    //   key: 'thumbnail',
    //   render: (thumbnail) => (!thumbnail ? null : <Avatar src={thumbnail} />),
    // },
    {
      title: t('eventType'),
      dataIndex: 'event_type',
      key: 'event_type',
      render: (type) => {
        if (eventTypeNameMapper[type]) {
          return eventTypeNameMapper[type];
        } else {
          return type;
        }
      },
    },
    // {
    //   title: 'Название',
    //   dataIndex: 'title',
    //   key: 'name',
    // },
    // {
    //   title: 'Описание',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: t('actions'),
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => {
              modal.open({
                title: t('editConfig'),
                component: (
                  <ReactionsForm
                    instance={instance}
                    existTypes={data.results.map((item) => item.event_type)}
                    onSubmit={() => {
                      loadData();
                    }}
                  />
                ),
                width: 'auto',
              });
            }}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={() => {
              formOpener.open({
                title: t('confirmAction'),
                submitText: t('delete'),
                component: <> {t('sureWantDelete')}</>,
                onSubmit: async () => {
                  await deleteData(instance.id);
                },
              });
            }}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  const [data, setData] = useState({
    results: [],
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(
    () => {
      dispatch(getEventTypeRequest());
      dispatch(initForm({ form: CREATE_FORM, config }));
      dispatch(initForm({ form: EDIT_FORM, config }));

      return () => {
        dispatch(setIsCreateFormOpen(false));
        dispatch(setIsEditFormOpen(false));
        dispatch(askToDelete(null));
      };
    },
    // Need to call this only once at render
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    loadData();
  }, [query]);
  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------
  async function loadData() {
    if (loading) return;
    setLoading(true);
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/events/`,
      {
        page: query.page || 1,
        size: query.size || DEFAULT_PAGE_SIZE,
        // search: query.search || '',
      }
    );
    setData({
      results: responce.data.results,
      count: responce.data.count,
    });

    setLoading(false);
  }

  async function deleteData(id) {
    setLoading(true);
    await request.delete(`/events/${id}`);
    loadData();
    setLoading(false);
    formOpener.close();
  }

  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="btn-create-wrapper">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            loading={loading}
            onClick={() => {
              modal.open({
                title: t('createConfig'),
                component: (
                  <ReactionsForm
                    existTypes={data.results.map((item) => item.event_type)}
                    onSubmit={() => {
                      loadData();
                    }}
                  />
                ),
                width: 'auto',
              });
            }}
          >
            {t('add')}
          </Button>
        </div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data.results}
          pagination={false}
        />
        <Pagination
          style={{ marginTop: '1rem' }}
          {...{
            total: data.count,
            current: query.page || 1,
            pageSize: query.size || MIN_PAGINATION_ITEMS,
            onChange: (page, size) => setQuery({ page, size }),
            hideOnSinglePage: true,
            showSizeChanger: true,
            position: ['bottomLeft'],
            defaultCurrent: query.page,
          }}
        />
      </div>
    </SettingsLayout>
  );
};

export default React.memo(NotificationConfiguration);
