import { Checkbox, Divider, Drawer } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as AnchorIcon } from 'assets/icons/anchor.svg';
import { ReactComponent as ElementsLogo } from '../../assets/icons/elements.svg';
import { ReactComponent as GridLogo } from '../../assets/icons/grid.svg';
import { ReactComponent as SettingWrenchLogo } from '../../assets/icons/interface-setting-wrench.svg';
import { ReactComponent as MapTypeLogo } from '../../assets/icons/map-type.svg';
import { ReactComponent as ObjectLogo } from '../../assets/icons/object.svg';
import { ReactComponent as ToolLogo } from '../../assets/icons/tool.svg';
import { ReactComponent as UserGrayLogo } from '../../assets/icons/user-gray.svg';

import ColorPicker from '../../components/ColorPicker';

import { getGridLayer } from '../../helpers/mapHelpers';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { mapStore } from '../../model/MapStore';
import { setUserColorRequest } from '../../store/actions/user';
import { makeSelectGetUserColor } from '../../store/selectors/user';
import MapTypes from './MapTypes';

// const elementsList = [
//   {
//     text: 'Сотрудники',
//     logo: <UserGrayLogo />,
//     name: 'users'
//   },
//   {
//     text: 'Подписи объектов',
//     logo: <ObjectLogo />,
//   },
//   {
//     text: 'Области',
//     logo: <MapLocationLogo />,
//     name: 'areas',
//   }
//   // {
//   //   text: 'Инфраструктура',
//   //   logo: <ShoppingBagLogo />,
//   // },
//   // {
//   //   text: 'Ярлыки',
//   //   logo: <PriceTagLogo />,
//   // },
// ];

function StackDrawer({ visible, setVisible, map }) {
  const { t } = useTranslation();

  const [isGridDisplayed, setIsGridDisplayed] = useState(false);
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [areaVisibilityState, setAreaVisibilityState] = useState(
    mapStore.layers.areas === 'all'
  );
  const [usersVisibilityState, setUsersVisibilityState] = useState(
    mapStore.layers.users
  );
  const [titleVisibilityState, setTitleVisibilityState] = useState(
    mapStore.layers.title
  );
  const [anchors, setAnchors] = useState(false);
  const [mapData, setMapData] = useState();
  const gridLayerRef = useRef(getGridLayer()).current;
  const userMapColor = useSelector(makeSelectGetUserColor);
  const { mapId } = useParams();

  useEffect(() => {
    if (!mapData) {
      setMapData(mapStore.maps[mapId]);
    }
    const subscription = mapStore.maps$.subscribe((maps) => {
      if (mapId !== mapData?.id && maps?.[mapId]) {
        setMapData(maps[mapId]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [mapData, mapId]);

  useEffect(() => {
    setAreaVisibilityState(mapStore.layers.areas === 'all');
    setUsersVisibilityState(mapStore?.layers.users);
    setTitleVisibilityState(mapStore?.layers.title);
    setAnchors(mapStore?.layers.anchors);
    const s = mapStore.layers$.subscribe((state) => {
      setAreaVisibilityState(state.areas === 'all');
      setUsersVisibilityState(!!state.users);
      setTitleVisibilityState(!!state.title);
      setAnchors(!!state.anchors);
    });
    return () => s?.unsubscribe();
  }, [mapData]);

  const setAreaVisibility = useCallback((e) => {
    mapStore.setLayerState('areas', e.target.checked ? 'all' : null);
    // mapData?.setAreaVisibility(e.target.checked ? 'all' : null);
  }, []);

  const dispatch = useDispatch();

  // const _setColorPickerAnchor = (node) => {
  //   if (node && !isEqual(node, colorPickerAnchor)) {
  //     setColorPickerAnchor(node);
  //   }
  // };

  const handleMapColorChange = (color) => {
    setVisible(false);
    dispatch(setUserColorRequest(color));
    // setTimeout(
    //   () => {
    //     setMapColor(color);
    //   },
    //   150,
    // )
  };

  const handleDrawGrid = () => {
    if (!map) return;

    if (isGridDisplayed) {
      setIsGridDisplayed(false);
      map.removeLayer(gridLayerRef);
    } else {
      setIsGridDisplayed(true);
      map.addLayer(gridLayerRef);
    }

    setVisible(false);
  };

  return (
    <React.Fragment>
      <Drawer
        placement="right"
        mask={false}
        closable={!isColorPickerOpen}
        keyboard={!isColorPickerOpen}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        width={350}
      >
        <div
          style={{
            marginTop: 36,
          }}
        >
          <p className="stack-drawer-title">{t('mapOptions')}</p>
          <p className="stack-drawer-sub-title">
            <SettingWrenchLogo />
            <span>{t('tools')}</span>
          </p>
          <div className="logos-wrapper">
            <ToolLogo className="stack-icon" />
            <GridLogo
              className={cn('stack-icon', { active: isGridDisplayed })}
              onClick={handleDrawGrid}
            />
            <div
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => mapStore.setLayerState('anchors', !anchors)}
            >
              <AnchorIcon
                style={{
                  color: mapStore.layers.anchors ? '#1890ff' : '#c4c4c4ff',
                }}
              />
            </div>
          </div>
          <Divider />
          <p className="stack-drawer-sub-title">
            <MapTypeLogo />
            <span>{t('mapType')}</span>
          </p>
          <MapTypes className="drawer-types" />
          <Divider />
          <p className="stack-drawer-sub-title">
            <ElementsLogo />
            <span>{t('elements')}</span>
          </p>
          <div className="element-wrapper">
            <UserGrayLogo />
            <span>{t('employees')}</span>
            <Checkbox
              defaultChecked={usersVisibilityState}
              onChange={(e) =>
                mapStore.setLayerState('users', e.target.checked)
              }
            />
          </div>
          <div className="element-wrapper">
            <ObjectLogo />
            <span>{t('objectLabels')}</span>
            <Checkbox
              defaultChecked={titleVisibilityState}
              onChange={(e) =>
                mapStore.setLayerState('title', e.target.checked)
              }
            />
          </div>
          <div className="element-wrapper">
            <ObjectLogo />
            <span>{t('areas')}</span>
            <Checkbox
              defaultChecked={areaVisibilityState}
              onChange={setAreaVisibility}
            />
          </div>

          {/* elementsList.map((item, idx) => {
            return (
              <div key={idx} className={'element-wrapper'}>
                {item.logo}
                <span>{item.text}</span>
                {item.name === 'areas' ? (
                  <Checkbox
                    defaultChecked={areaVisibilityState}
                    onChange={setAreaVisibility}
                  />
                ) : item.name === 'users' ? (
                  <Checkbox
                    defaultChecked={usersVisibilityState}
                    onChange={(e) => mapStore.setLayerState('users', e.target.checked)}
                  />
                ) : (
                  <Checkbox />
                )}
              </div>
            );
          }) */}
        </div>
      </Drawer>

      <ColorPicker
        color={userMapColor}
        onChange={handleMapColorChange}
        open={isColorPickerOpen}
        anchor={colorPickerAnchor}
        onClose={() => setIsColorPickerOpen(false)}
      />
    </React.Fragment>
  );
}

export default React.memo(StackDrawer);
