import { Button, DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/lib/time-picker/locale/ru_RU';
import axios from 'axios';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import client, { request } from 'helpers/client';
import { mapStore } from 'model/MapStore';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getGroups } from 'services/groups';
import { getUsers } from 'services/users';
import './index.scss';

export const AreaForm = ({ setFormValues, area }) => {
  const { t } = useTranslation();
  const areaTypes = [
    {
      name: 'none',
      label: t('noType'),
    },
    {
      name: 'device',
      label: t('equipment'),
    },
    {
      name: 'camera',
      label: t('camera'),
    },
    {
      name: 'danger_zone',
      label: t('dangerZone'),
    },
    {
      name: 'green_zone',
      label: t('greenZone'),
    },
    {
      name: 'forbidden_zone',
      label: t('forbiddenZone'),
    },
    // {
    //   name: 'temporary_zone',
    //   label: 'Temporary zone'
    // }
  ];
  const location = useLocation();
  const { mapId, areaId } = useParams();
  const formRef = useRef();
  const [groups, setGroups] = useState();
  const [users, setUsers] = useState();
  const [type, setType] = useState();
  const map = useMemo(
    () => mapId && mapStore?.maps?.[mapId],
    [mapId, location.pathname]
  );
  const loading = useRef({});
  const [cameras, setCameras] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedZoneType, setSelectedZoneType] = useState();
  const [form] = Form.useForm();

  const currentUser = useCurrentUser();

  const onValuesChange = (changed, values) => {
    const { type: nextType } = changed;
    if (nextType && type !== nextType) {
      setType(nextType);
    }
  };
  useEffect(() => {
    if (area) {
      setSelectedZoneType(area.type);
      const users_has_access = (users || [])
        .filter((user) => (area.users_has_access || []).includes(user.id))
        .map((user) => user.id);
      form.setFieldsValue({
        type: form.getFieldValue('type') || area.type,
        name: form.getFieldValue('name') || area.name,
        device_id: form.getFieldValue('device_id') || area.device_id,
        camera_id: form.getFieldValue('camera_id') || area.camera_id,
        users_has_access:
          form.getFieldValue('users_has_access') || users_has_access,
        startTime: form.getFieldValue('from_date') || moment(area.from_date),
        endTime: form.getFieldValue('to_date') || moment(area.to_date),
      });
    }
  }, [area, users]);

  useEffect(() => {
    if (area) {
      if (area.type) {
        setType(area.type);
      }
    }
  }, [area]);

  useEffect(() => {
    currentUser.updateUserData();
  }, []);

  useEffect(() => {
    if (currentUser.macroscop.url !== '') {
      loadCameras(currentUser.macroscop);
    }
  }, [currentUser.macroscop]);

  function loadCameras(macroscop) {
    const params = new URLSearchParams();
    params.append('login', macroscop.login);
    params.append('password', macroscop.password);
    params.append('responsetype', 'json');
    const { url } = macroscop;
    axios.get(url + `/configex?${params.toString()}`).then((res) => {
      const cameras = res?.data?.Channels || [];
      setCameras(cameras);
    });
  }

  useEffect(() => {
    if (mapStore.cameras.length) {
      setCameras(mapStore.cameras);
    }
    if (mapStore.devices.length) {
      setDevices(mapStore.devices);
    }

    const deviceSubscription = mapStore.devices$.subscribe((list) => {
      setDevices(list);
    });
    return () => {
      // cameraSubscription.unsubscribe();
      deviceSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!users && !loading.current?.users) {
      loading.current.users = true;
      getUsers().then((res) => {
        if (res) {
          setUsers(res);
        }
        loading.current.users = false;
      });
    }
  }, [users]);

  useEffect(() => {
    if (!groups && !loading.current?.groups) {
      loading.current.groups = true;
      getGroups().then((data) => {
        if (data?.results) {
          setGroups(data.results);
        }
        loading.current.groups = false;
      });
    }
  }, [groups]);

  const onFinish = (values) => {
    const { startTime, endTime, ...formData } = values;
    if (startTime) {
      formData.from_date = startTime.toISOString(true);
    }
    if (endTime) {
      formData.to_date = endTime.toISOString(true);
    }
    formData.users_has_access = formData.users_has_access || [];
    setFormValues(formData);
  };

  const showDatePickers = () => {
    const type = form.getFieldValue('type') || selectedZoneType;
    return type === 'danger_zone' || type === 'green_zone';
  };

  return (
    <div className="form-wrapper">
      <Form
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        ref={formRef}
        form={form}
      >
        <Form.Item
          name="name"
          label={t('title')}
          // initialValue={area.name}
          rules={[{ required: true, message: t('requireField') }]}
        >
          <Input
            className="input-max-width"
            shape="round"
            placeholder={t('zoneTitle')}
          />
        </Form.Item>

        <Form.Item
          label={t('zoneType')}
          name="type"
          rules={[{ required: true, message: t('requireField') }]}
        >
          <Select
            placeholder={t('selectZoneType')}
            onChange={setSelectedZoneType}
          >
            {areaTypes?.map(({ name, label }) => (
              <Select.Option key={name} value={name}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {type === 'camera' ? (
          <Form.Item
            name="camera_id"
            rules={[{ required: true, message: t('requireField') }]}
          >
            <Select placeholder="Выберите камеру">
              {cameras.map(({ Id, Name }) => (
                <Select.Option key={Id} value={Id}>
                  {Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        {type === 'device' ? (
          <Form.Item
            name="device_id"
            rules={[{ required: true, message: t('requireField') }]}
          >
            <Select placeholder={t('selectEquipment')}>
              {devices.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        {showDatePickers() && (
          <div className="starting-point">
            <div className="title">{t('zoneActiveTime')}</div>
            <div className="values">
              <Form.Item name="startTime">
                <DatePicker
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  locale={locale}
                />
              </Form.Item>
              <Form.Item name="endTime">
                <DatePicker
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  locale={locale}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {/* <Form.Item
          label="Доступ для групп"
          name="employees_has_access"
        >
          <Select placeholder="Выберите группы" mode="multiple">
            {(groups || []).map(({id, name}) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}

        {type === 'camera' && (
          <Form.Item label={t('usersAccess')} name="users_has_access">
            <Select placeholder={t('selectUsers')} mode="multiple">
              {(users || []).map(({ id, first_name, last_name, username }) => (
                <Select.Option key={id} value={id}>
                  {first_name || last_name
                    ? `${first_name} ${last_name}`
                    : username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Button htmlType="submit">
            {!!areaId ? t('edit') : t('create')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
