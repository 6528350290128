import {
  SET_NOTIFICATIONS_FETCHING,
  SET_NOTIFICATIONS_DATA,
  SET_NOTIFICATIONS_ERROR,
  DELETE_BLOCKING_NOTIFICATIONS,
  DELETE_BOTTOM_RIGHT_NOTIFICATIONS,
  ADD_BOTTOM_RIGHT_NOTIFICATION,
  DELETE_NOTIFICATIONS
} from '../actions/notifications';
import { filterBlockingNotifications, filterBottomRightNotifications } from '../../helpers/notificationHelpers'

const reqData = { fetching: false, data: null, error: null };

const initialState = {
  notificationsData: reqData,
  allNotifications: 0,
  blockingNotifications: [],
  bottomRightNotifications: [],
};

export default function notifications(state = initialState, { type, payload }) {
  switch (type) {
    case SET_NOTIFICATIONS_FETCHING:
      return {
        ...state,
        notificationsData: { ...state.notificationsData, fetching: payload },
      };
    case SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        allNotifications: payload
      };
    case SET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsData: { ...state.notificationsData, error: payload },
      };
    case DELETE_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.filter((_, index) => index !== payload)
      }
    case DELETE_BLOCKING_NOTIFICATIONS:
      return {
        ...state,
        blockingNotifications: state.blockingNotifications.filter((_, index) => index !== payload)
      }
    case DELETE_BOTTOM_RIGHT_NOTIFICATIONS:
      return {
        ...state,
        bottomRightNotifications: state.blockingNotifications.filter((_, index) => index !== payload)
      }
    case ADD_BOTTOM_RIGHT_NOTIFICATION:
      return {
        ...state,
        bottomRightNotifications: payload
      }
    default:
      return state;
  }
}
