import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import { getLanguage } from './localization.utils';

function SwtichGroup({ list }) {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState('ru');

  useEffect(() => {

    setLang(i18n.language)
  },[i18n.language])

  function updateLanguage(lang) {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('language', JSON.stringify(lang));
  }

  return (
    <>
      <div className="switch-group">
        {list.map((item) => {
          return (
            <a
              onClick={() => {
                updateLanguage(item.title.toLocaleLowerCase());
              }}
              className={
                item.title.toLocaleLowerCase() === lang.toLocaleLowerCase()
                  ? 'active'
                  : ''
              }
              key={item.id}
            >
              {item.title}
            </a>
          );
        })}
      </div>
    </>
  );
}

export default SwtichGroup;
