import { request } from 'helpers/client';
import { useEffect, useState } from 'react';

export const useIsBackendWorking = () => {
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  const [isBackendWorking, setIsBackendWorking] = useState(true);
  const refreshDelay = 10000;

  useEffect(() => {
    const interval = setInterval(async () => {
      const pingData = await requestPing();
      if (!pingData) {
        setLastUpdate(lastUpdate + 1);
        return;
      }
      setLastUpdate(pingData);
    }, refreshDelay);
    return () => clearInterval(interval);
  }, [refreshDelay, lastUpdate]);

  useEffect(() => {
    const now = new Date().getTime();
    const isOldData = lastUpdate && (lastUpdate - now) * -1 > refreshDelay;
    setIsBackendWorking(!isOldData);
  }, [lastUpdate]);

  async function requestPing() {
    try {
      await request.get(`${process.env.REACT_APP_BACK_END_URL}/api/ping`);
      return new Date().getTime();
    } catch {
      return undefined;
    }
  }

  return isBackendWorking;
};
