import { TextField } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReportFilterParams } from '../report-page.hook';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportSelectSimple } from '../ui-components/report-filter/report-select-simple';
import { ReportTable } from '../ui-components/reports-table';
import './../index.scss';

export function ReportsUserActivity({ showFilter, reports, loading }) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useReportFilterParams();

  const reportsEventColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('report.user_activity.created_at'),
      width: '15%',
      render: (created_at) => moment(created_at).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'login',
      dataIndex: 'login',
      title: t('report.user_activity.login'),
    },
    {
      key: 'ip_address',
      dataIndex: 'ip_address',
      title: t('report.user_activity.ipAddress'),
    },

    {
      key: 'http_method',
      dataIndex: 'http_method',
      title: t('report.user_activity.http_method'),
    },
    {
      key: 'hostname',
      dataIndex: 'hostname',
      title: t('report.user_activity.apiMethod'),
    },
  ];

  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div className="reports-filter-container">
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            <div className="filter-params">
              <TextField
                id="standard-basic"
                label={t('report.user_activity.login')}
                variant="standard"
                value={filterParams ? filterParams.login : ''}
                onChange={(e) => {
                  setFilterParams({
                    ...filterParams,
                    login: e.target.value,
                  });
                }}
                disabled={loading}
              />

              <TextField
                id="standard-basic"
                label={t('report.user_activity.hostName')}
                variant="standard"
                value={filterParams.hostname}
                onChange={(e) => {
                  setFilterParams({
                    ...filterParams,
                    hostname: e.target.value,
                  });
                }}
                disabled={loading}
              />

              <TextField
                id="standard-basic"
                label={t('report.user_activity.ipAddress')}
                variant="standard"
                value={filterParams ? filterParams.ip_address : ''}
                onChange={(e) => {
                  setFilterParams({
                    ...filterParams,
                    ip_address: e.target.value,
                  });
                }}
                disabled={loading}
              />

              <ReportSelectSimple
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                paramName={'http_method'}
                variants={['GET', 'POST', 'PUT', 'DELETE']}
                placeholder={t('report.user_activity.http_method')}
              />
            </div>
          </div>

          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}

      <ReportTable
        columns={reportsEventColumns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
}
