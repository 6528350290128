const STATE_KEY = '@DRAW_POLYGONS';

export const SET_POLYGON = `${STATE_KEY}_SET_POLYGON`;
export const SET_COLOR = `${STATE_KEY}_SET_COLOR`;
export const SET_NAME = `${STATE_KEY}_SET_NAME`;
export const SET_POINT_CAMERA = `${STATE_KEY}_SET_POINT_CAMERA`;
export const SET_POINT_DEVICE = `${STATE_KEY}_SET_POINT_DEVICE`;
export const ON_CHANGE = `${STATE_KEY}_ON_CHANGE`;
export const CLEAR_FORM = `${STATE_KEY}_CLEAR_FORM`;
export const NOMINATE_TO_DELETE = `${STATE_KEY}_NOMINATE_TO_DELETE`;

export const GET_AREAS_REQUEST = `${STATE_KEY}_GET_AREAS_REQUEST`;
export const GET_AREAS_SUCCESS = `${STATE_KEY}_GET_AREAS_SUCCESS`;
export const GET_AREAS_FAILURE = `${STATE_KEY}_GET_AREAS_FAILURE`;
export const CLEAR_AREAS = `${STATE_KEY}_CLEAR_AREAS`;

export const CREATE_AREA_REQUEST = `${STATE_KEY}_CREATE_AREA_REQUEST`;
export const CREATE_AREA_SUCCESS = `${STATE_KEY}_CREATE_AREA_SUCCESS`;
export const CREATE_AREA_FAILURE = `${STATE_KEY}_CREATE_AREA_FAILURE`;

export const UPDATE_AREA_REQUEST = `${STATE_KEY}_UPDATE_AREA_REQUEST`;
export const UPDATE_AREA_SUCCESS = `${STATE_KEY}_UPDATE_AREA_SUCCESS`;
export const UPDATE_AREA_FAILURE = `${STATE_KEY}_UPDATE_AREA_FAILURE`;
export const SET_IS_EDITING = `${STATE_KEY}_SET_IS_EDITING`;
export const CLEAR_UPDATING = `${STATE_KEY}_CLEAR_UPDATING`;

export const DELETE_AREA_REQUEST = `${STATE_KEY}_DELETE_AREA_REQUEST`;
export const DELETE_AREA_SUCCESS = `${STATE_KEY}_DELETE_AREA_SUCCESS`;
export const DELETE_AREA_FAILURE = `${STATE_KEY}_DELETE_AREA_FAILURE`;

export const GET_CAMERA_LIST_REQUEST = `${STATE_KEY}_GET_CAMERA_LIST_REQUEST`;
export const GET_CAMERA_LIST_SUCCESS = `${STATE_KEY}_GET_CAMERA_LIST_SUCCESS`;
export const GET_CAMERA_LIST_FAILURE = `${STATE_KEY}_GET_CAMERA_LIST_FAILURE`;

export const setPolygon = payload => ({
  type: SET_POLYGON,
  payload: payload || [],
});
export const setColor = payload => ({
  type: SET_COLOR,
  payload: payload || null,
});
export const setName = payload => ({ type: SET_NAME, payload });
export const setPointCamera = payload => ({ type: SET_POINT_CAMERA, payload });
export const setPointDevice = payload => ({ type: SET_POINT_DEVICE, payload });
export const onChange = payload => ({ type: ON_CHANGE, payload });
export const clearForm = () => ({ type: CLEAR_FORM });
export const nominateToDelete = payload => ({ type: NOMINATE_TO_DELETE, payload });

export const getAreasRequest = payload => ({
  type: GET_AREAS_REQUEST,
  payload,
});
export const getAreasSuccess = payload => ({
  type: GET_AREAS_SUCCESS,
  payload: payload || [],
});
export const getAreasFailure = payload => ({
  type: GET_AREAS_FAILURE,
  payload,
});
export const clearAreas = () => ({ type: CLEAR_AREAS });

export const createAreaRequest = payload => ({
  type: CREATE_AREA_REQUEST,
  payload,
});
export const createAreaSuccess = payload => ({
  type: CREATE_AREA_SUCCESS,
  payload,
});
export const createAreaFailure = payload => ({
  type: CREATE_AREA_FAILURE,
  payload,
});

export const updateAreaRequest = payload => ({
  type: UPDATE_AREA_REQUEST,
  payload,
});
export const updateAreaSuccess = payload => ({
  type: UPDATE_AREA_SUCCESS,
  payload,
});
export const updateAreaFailure = payload => ({
  type: UPDATE_AREA_FAILURE,
  payload,
});
export const setIsEditing = payload => ({ type: SET_IS_EDITING, payload });
export const clearUpdating = () => ({ type: CLEAR_UPDATING });

export const deleteAreaRequest = payload => ({
  type: DELETE_AREA_REQUEST,
  payload,
});
export const deleteAreaSuccess = payload => ({
  type: DELETE_AREA_SUCCESS,
  payload,
});
export const deleteAreaFailure = payload => ({
  type: DELETE_AREA_FAILURE,
  payload,
});

export const getCameraListRequest = payload => ({
  type: GET_CAMERA_LIST_REQUEST,
  payload
})
export const getCameraListSuccess = payload => ({
  type: GET_CAMERA_LIST_SUCCESS,
  payload
})
export const getCameraListFailure = payload => ({
  type: GET_CAMERA_LIST_FAILURE,
  payload
})
