import { useTranslation } from 'react-i18next';
import {
  SETTINGS_CATEGORIES,
  SETTINGS_DEVICES,
  SETTINGS_EMPLOYESS,
  SETTINGS_GROUPS,
  SETTINGS_MACROSCOP, SETTINGS_MAPS,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_ORGANIZATIONS,
  SETTINGS_ROTATIONS, SETTINGS_SETTING, SETTINGS_SMPT, SETTINGS_TRANSPORTS, SETTINGS_USERS
} from '../../../consts/urls';

export const useMenuItems = () => {


  const { t } = useTranslation()


  return [
    {
      path: SETTINGS_CATEGORIES,
      label: t('categories'),
      key: 'categories',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_NOTIFICATIONS,
      label: t('reactions'),
      key: 'notifications',
      hasSubMenu: false,
    },
    // {
    // path: SETTINGS_MAP_MARKERS,
    //   label: 'Метки на карте',
    //   key: 'markers',
    //   hasSubMenu: false,
    // },
    {
      path: SETTINGS_MAPS,
      label: t('listOfMaps'),
      key: 'maps',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_EMPLOYESS,
      label: t('employees'),
      key: 'employees',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_ROTATIONS,
      label: t('shifts2'),
      key: 'rotations',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_GROUPS,
      label: t('groups'),
      key: 'groups',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_USERS,
      label: t('users'),
      key: 'users',
      hasSubMenu: false,
    },
    // ------------------------------------------------------
    {
      path: SETTINGS_ORGANIZATIONS,
      label: t('organizations'),
      key: 'organizations',
      hasSubMenu: false,
    },
    {
      path: SETTINGS_TRANSPORTS,
      label: t('transport'),
      key: 'transports',
      hasSubMenu: false,
    },
    // ------------------------------------------------------
    {
      label: t('devices'),
      key: 'devicesMenu',
      hasSubMenu: true,
      subItems: [
        {
          path: SETTINGS_MACROSCOP,
          label: 'Macroscop',
          key: 'macroscop',
        },
        {
          path: SETTINGS_SMPT,
          label: 'SMTP',
          key: 'smtp',
        },

        {
          path: SETTINGS_DEVICES,
          label: t('objects'),
          key: 'devices',
        },
      ],
    },
    {
      path: SETTINGS_SETTING,
      label: t('settings'),
      key: 'setting',
      hasSubMenu: false,
    },
  ];
}
