import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,

  SET_IS_CREATE_FORM_OPEN,
  SET_IS_EDIT_FORM_OPEN,

  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,

  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,

  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,

  ASK_TO_DELETE,
} from '../actions/markerConfigurations';

const processState = {
  fetching: false,
  error: null
};

const initialState = {
  list: {
    data: [],
    fetching: false,
    error: null,
    count: 0,
  },

  isCreateFormOpen: false,
  isEditFormOpen: false,

  creation: processState,
  editing: processState,
  deleting: {
    ...processState,
    selected: null,
  },
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true,
        }
      };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          data: action.payload.results,
          count: action.payload.count,
        }
      };
    case GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          error: action.payload
        }
      };

    case SET_IS_CREATE_FORM_OPEN:
      return {
        ...state,
        isCreateFormOpen: action.payload,
      };
    case SET_IS_EDIT_FORM_OPEN:
      return {
        ...state,
        isEditFormOpen: action.payload,
      };

    case CREATE_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: true,
          error: null
        }
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: false,
        }
      };
    case CREATE_FAILURE:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: false,
          error: action.payload,
        }
      };

    case UPDATE_REQUEST:
      return {
        ...state,
        editing: {
          ...state.editing,
          fetching: true,
          error: null,
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        editing: {
          ...state.editing,
          fetching: false
        }
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        editing: {
          ...state.editing,
          fetching: false,
          error: action.payload,
        }
      };

    case DELETE_REQUEST:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: true,
          error: null,
        }
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: true,
        }
      };
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: false,
          error: action.payload,
        }
      };
    case ASK_TO_DELETE:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          selected: action.payload,
        }
      };
    default:
      return state;
  }
};

export default reducer;
