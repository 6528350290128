import { all, takeLatest, put, call } from 'redux-saga/effects';

import { request } from '../../helpers/client';

import { clearForm } from '../actions/forms';

import {
  GET_CONFIG_REQUEST,
  UPDATE_CONFIG_REQUEST,

  getConfigSuccess,
  getConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  setIsEditFormOpen,
} from '../actions/cameras';


function* getConfig() {
  try {
    const response = yield call(request.get, '/common/config/macroscop/');

    yield put(getConfigSuccess(response.data));
  } catch (error) {
    yield put(getConfigFailure(error));
  }
}


function* updateConfig(action) {
  const { data } = action.payload;

  try {
    const response = yield call(
      request.post,
      '/common/config/macroscop/',
      data
    );

    yield put(updateConfigSuccess(response.data));
    yield put(setIsEditFormOpen(false));
    yield put(clearForm({ form: 'macroscop' }));
  } catch (error) {
    yield put(updateConfigFailure(error));
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_CONFIG_REQUEST, getConfig),
    takeLatest(UPDATE_CONFIG_REQUEST, updateConfig),
  ]);
}

export default Saga;
