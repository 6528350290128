import { Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { clearForm } from '../../store/actions/forms';

import { useTranslation } from 'react-i18next';
import Form from './form';

const Dialog = ({
  title,
  visible,
  onClose,
  buttonText,
  form,
  handleSubmit,
  loading,
  config,
  error,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    if (onClose) onClose();
    dispatch(clearForm({ form }));
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleClose}
      okText={buttonText}
      cancelText={t('cancel')}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      keyboard={!loading}
      cancelButtonProps={{ loading }}
      width="44rem"
    >
      <Form name={form} config={config} error={error} />
    </Modal>
  );
};

export default React.memo(Dialog);
