import {
  SET_CENTER_COORDINATES,
  CLEAR_CENTER_COORDINATES,
} from '../actions/crsMap';

const initialState = {
  centerCoordinates: null,
};

export default function crsMap(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CENTER_COORDINATES:
      return {
        ...state,
        centerCoordinates: payload,
      };
    case CLEAR_CENTER_COORDINATES:
      return {
        ...state,
        centerCoordinates: null,
      };

    default:
      return state;
  }
}
