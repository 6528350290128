import bluMarkerSvg from 'assets/icons/blue-marker.svg';
import redMarkerSvg from 'assets/icons/red-marker.svg';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-editable';
import 'leaflet-freehandshapes';
import 'leaflet-providers';
import 'leaflet-rotatedmarker';
import 'leaflet.animatedmarker/src/AnimatedMarker';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import countries from '../consts/countries.json';
import { mapTileAttribution, mapTileLayerUrl } from '../consts/map';

export const initGlobalMap = ({
  mapId,
  position = [52.9435, 62.1415],
  zoom = 11,
}) => {
  clearMap(mapId);

  const map = L.map(mapId, {
    maxZoom: zoom,
    attributeControl: false,
    zoomControl: false,
  }).setView(position, 17);

  map.off();

  L.tileLayer(mapTileLayerUrl, {
    maxZoom: zoom,
    attribution: mapTileAttribution,
    maxNativeZoom: 19,
  }).addTo(map);

  return map;
};

export const initMap = ({
  mapId,
  image,
  bounds,
  minZoom = 0,
  zoom = 0,
  maxZoom = 10,
  editable = false,
}) => {
  const map = L.map(mapId, {
    crs: L.CRS.Simple,
    zoomControl: false,
    minZoom,
    maxZoom,
    zoom,
    editable,
  });
  map.currentImageOverlay = L.imageOverlay(image, bounds).addTo(map);
  map.fitBounds(bounds);
  return map;
};

export const zoomInMap = ({ map }) => {
  map.zoomIn();
};

export const zoomOutMap = ({ map }) => {
  map.zoomOut();
};

export function clearMap(map = 'map') {
  const container = L.DomUtil.get(map);
  if (container != null) {
    container._leaflet_id = null;
  }
}

export const createMarker = ({
  latLng,
  draggable,
  setPosition,
  rotationAngle,
  icon,
}) => {
  const options = { draggable, rotationAngle };
  if (icon) {
    options.icon = icon;
  }
  const marker = L.marker(latLng, options);
  marker.on('dragend', () => {
    if (setPosition) setPosition(marker.getLatLng());
  });
  marker.on('move', () => {
    if (setPosition) setPosition(marker.getLatLng());
  });
  return marker;
};

export const createAnimatedMarker = ({
  latLng,
  latLng2,
  draggable,
  setPosition,
  rotationAngle,
  icon,
  onEnd,
}) => {
  const options = {
    draggable,
    rotationAngle,
    interval: 300,
  };
  if (icon) {
    options.icon = icon;
  }
  if (onEnd) {
    options.onEnd = onEnd;
  }
  const marker = L?.animatedMarker?.(
    [
      [latLng.lat, latLng.lng],
      [latLng2?.lat || latLng.lat, latLng2.lng || latLng.lng],
    ],
    options
  );
  marker.on('dragend', () => {
    if (setPosition) setPosition(marker.getLatLng());
  });
  marker.on('move', () => {
    if (setPosition) setPosition(marker.getLatLng());
  });
  return marker;
};

export const addMarker = ({
  map,
  latLng,
  draggable,
  setPosition,
  rotationAngle,
  icon,
  onClick,
}) => {
  const marker = createMarker({
    latLng,
    draggable,
    setPosition,
    rotationAngle,
    icon,
  });

  if (onClick) {
    marker.off('click', onClick(marker));
    marker.on('click', onClick(marker));
  }

  marker.addTo(map);
  return marker;
};

export const addPolyLine = ({ map, latLngs, options }) => {
  return L.polyline(latLngs, options).addTo(map);
};

export const addPolygon = ({
  map,
  coords,
  tooltip = {},
  options = {},
  handleShapeClick,
}) => {
  const polygon = L.polygon(coords, options);
  polygon.on('click', handleShapeClick);

  if (tooltip.text) {
    polygon.bindTooltip(
      tooltip.text,
      Object.assign({ sticky: true }, tooltip.options)
    );
  }

  polygon.addTo(map);
  return polygon;
};

export const addColorToLayer = ({ mapColor, colorLayer }) => {
  if (colorLayer) {
    colorLayer.setStyle({ fillColor: mapColor, fillOpacity: '0.2' });
  }
};

export const createColorLayer = ({ map, style, setColorLayer }) => {
  const geoJsonLayer = L.geoJson(countries.features, { style });
  geoJsonLayer.addTo(map);
  setColorLayer(geoJsonLayer);
};

export const addGeoJSON = ({ map, geoJSON, options }) => {
  const geoJSONLayer = L.geoJson(geoJSON, options);
  geoJSONLayer.addTo(map);
  return geoJSONLayer;
};

export const addImageOverlay = ({ map, image, bounds, options }) => {
  const overlay = L.imageOverlay(image, bounds, options);
  overlay.addTo(map);
  return overlay;
};

export const resetImageOverlay = ({ imageOverlay, image }) => {
  imageOverlay.setUrl(image);
};

export const addLayerGroup = ({ map, markers }) => {
  return L.layerGroup(markers).addTo(map);
};

export const onClickMarker = ({ marker, setVisible }) => {
  marker.on('click', () => setVisible());
};

export const convertCoordinatesToLatLng = (coordinate) => {
  const [x, y] = coordinate;
  return [y, x];
};

export const getMarkerByType = (type, config) => {
  if (!config) return null;

  const target = config.find((item) => item['marker_type'] === type);
  if (!target) return null;

  return L.icon({ iconUrl: target.icon, iconSize: [24, 24], className: '' });
};

export const getBlueMapMarkerIcon = () => {
  return L.icon({
    iconUrl: bluMarkerSvg,
    shadowUrl: markerShadow,
    iconSize: [25, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [36, 36],
  });
};

export const getRedMapMarkerIcon = () => {
  return L.icon({
    iconUrl: redMarkerSvg,
    shadowUrl: markerShadow,
    iconSize: [25, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [36, 36],
  });
};

export const getMarkerIcon = (iconUrl) => {
  return L.icon({
    iconUrl,
    shadowUrl: markerShadow,
    iconSize: [25, 35],
    iconAnchor: [12, 31],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [36, 36],
  });
};

export const initDrawer = ({ map }) => {
  const drawer = new L.FreeHandShapes({
    simplify_tolerance: 0.0001,
  });

  map.addLayer(drawer);
  return drawer;
};

export const setDrawMode = (drawer) => {
  drawer.setMode('add');
};

export const setDrawerToolbar = ({
  map,
  removeSelectedLayer,
  setToolbarLayer,
  errMessage,
}) => {
  removeSelectedLayer();

  const editableLayers = new L.FeatureGroup();
  map.addLayer(editableLayers);

  const drawPluginOptions = {
    position: 'topright',
    draw: {
      polygon: {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#e1e100', // Color the shape will turn when intersects
          message: errMessage, // Message that will show when intersect
        },
        shapeOptions: {
          color: '#97009c',
        },
      },
      // disable toolbar item by setting it to false
      polyline: false,
      circle: true, // Turns off this drawing tool
      rectangle: true,
      marker: false,
      circlemarker: false,
    },
    edit: {
      featureGroup: editableLayers,
      remove: true,
    },
  };

  const drawControl = new L.Control.Draw(drawPluginOptions);
  map.addControl(drawControl);

  setToolbarLayer(drawControl);

  map.on('draw:created', function (e) {
    map.removeLayer(e);
    editableLayers.addLayer(e.layer);
  });

  map.on('draw:drawstart', function () {
    editableLayers.clearLayers();
  });

  map.on('draw:edited', function (e) {
    const layers = e.layers;
    map.removeLayer(e);
    // editableLayers.clearLayers();
    layers.eachLayer(function (layerItem) {
      //do whatever you want; most likely save back to db
      editableLayers.addLayer(layerItem.layer);
    });
  });
};

export const deleteDrawerToolbar = ({ map }) => {
  map.removeLayer();
};

export const setViewMode = (drawer) => {
  drawer.setMode('view');
};

export const initAnchorPlacer = (map, handleMarkerAdded) => {
  const onMapClick = (e) => {
    const marker = addMarker({
      map,
      latLng: e.latlng,
      draggable: false,
      onClick: (marker) => () => {
        map.removeLayer(marker);
      },
    });

    if (handleMarkerAdded) {
      handleMarkerAdded(marker, e.latlng);
    }
  };

  if (map) {
    map.off('click', onMapClick);
    map.on('click', onMapClick);
  }
};

export const removeAnchorPlacer = (map) => {
  if (map) {
    map.off('click');
  }
};

export const removeAllLayersAndResetImage = ({ bounds, image, map }) => {
  // if(map){
  map.eachLayer((layer) => {
    if (map) {
      map.removeLayer(layer);
    }
  });

  addImageOverlay({ map, image, bounds });
  // if (!map && bounds) setMap(initMap({ mapId, image, bounds }));
  // }
};

export const getGridLayer = (opts = {}) => {
  let grid = '<ul class="tile-child-grid">';

  for (let i = 0; i < 5; ++i) {
    grid += '<li class="grid-row"><ul>';
    for (let k = 0; k < 5; ++k) {
      grid += '<li class="grid-column"></li>';
    }
    grid += '</ul></li>';
  }
  grid += '</ul>';

  const Klass = L.GridLayer.extend({
    createTile: function () {
      const tile = document.createElement('div');
      tile.style.outline = '1px solid rgba(0, 0, 0, 0.4)';
      tile.style.fontWeight = 'bold';
      tile.style.fontSize = '14pt';
      tile.innerHTML = grid;
      return tile;
    },
  });

  return new Klass(opts);
};

export const removeMap = ({ map }) => {
  map.remove();
};

export const convertFromMeters = (coords, map) => {
  const { x0, y0, x0m, y0m, ratio } = map || {};
  if (
    x0 !== undefined &&
    y0 !== undefined &&
    x0m !== undefined &&
    y0m !== undefined &&
    ratio !== undefined &&
    coords?.length
  ) {
    return coords.map(([lat, lng]) => [
      (lat - y0m) * ratio + y0,
      (lng - x0m) * ratio + x0,
    ]);
  }
  return [];
};
