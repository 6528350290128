import {Form, Input} from "antd";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {t} from "i18next";


export const ReportTemplateForm = () => {
    return (
        <Form.Item
            className="m-config-item"
            label={t('report.template.create.name')}
            rules={[
                {
                    required: true,
                    message: t('report.template.error.name'),
                },
            ]}
            name={'name'}
        >
            <Input
                type="text"
                name={'name'}
                className="m-config-value"
            />
        </Form.Item>
    )
}