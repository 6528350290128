import employeeDanger from 'assets/icons/default-markers/subject-danger.svg';
import employeeLowBatteryOnDanger from 'assets/icons/default-markers/subject-low-battery-on-danger.svg';
import employeeLowBatterySelected from 'assets/icons/default-markers/subject-low-battery-selected.svg';
import employeeLowBatteryViolator from 'assets/icons/default-markers/subject-low-battery-violator.svg';
import employeeLowBattery from 'assets/icons/default-markers/subject-low-battery.svg';
import employeeSelected from 'assets/icons/default-markers/subject-selected.svg';
import employeeViolator from 'assets/icons/default-markers/subject-violator.svg';
import employeeDefault from 'assets/icons/default-markers/subject.svg';

import vehicleDanger from 'assets/icons/default-markers/vehicle-danger.svg';
import vehicleLowBatteryOnDanger from 'assets/icons/default-markers/vehicle-low-battery-on-danger.svg';
import vehicleLowBatterySelected from 'assets/icons/default-markers/vehicle-low-battery-selected.svg';
import vehicleLowBatteryViolator from 'assets/icons/default-markers/vehicle-low-battery-violator.svg';
import vehicleLowBattery from 'assets/icons/default-markers/vehicle-low-battery.svg';
import vehicleSelected from 'assets/icons/default-markers/vehicle-selected.svg';
import vehicleViolator from 'assets/icons/default-markers/vehicle-violator.svg';
import vehicleDefault from 'assets/icons/default-markers/vehicle.svg';

import employeeFFIcon from 'assets/icons/default-markers/subject-selected-fast-forward.svg';
import employeePauseIcon from 'assets/icons/default-markers/subject-selected-pause.svg';
import employeePlayIcon from 'assets/icons/default-markers/subject-selected-play.svg';

import vehicleFFIcon from 'assets/icons/default-markers/vehicle-selected-fast-forward.svg';
import vehiclePauseIcon from 'assets/icons/default-markers/vehicle-selected-pause.svg';
import vehiclePlayIcon from 'assets/icons/default-markers/vehicle-selected-play.svg';

export class Keeper {
  id;
  name;

  // координаты в единицах измерения карты
  x;
  y;
  // координаты в метрах относительно главного нуля
  xM;
  yM;
  z;

  isMoved = false;

  icons = {
    EMPLOYEE: {
      history: {
        ff: employeeFFIcon,
        pause: employeePauseIcon,
        play: employeePlayIcon,
      },
      danger: employeeDanger,
      lowBatteryOnDanger: employeeLowBatteryOnDanger,
      lowBatterySelected: employeeLowBatterySelected,
      lowBatteryViolator: employeeLowBatteryViolator,
      lowBattery: employeeLowBattery,
      selected: employeeSelected,
      violator: employeeViolator,
      default: employeeDefault,
    },
    VEHICLE: {
      history: {
        ff: vehicleFFIcon,
        pause: vehiclePauseIcon,
        play: vehiclePlayIcon,
      },
      danger: vehicleDanger,
      lowBatteryOnDanger: vehicleLowBatteryOnDanger,
      lowBatterySelected: vehicleLowBatterySelected,
      lowBatteryViolator: vehicleLowBatteryViolator,
      lowBattery: vehicleLowBattery,
      selected: vehicleSelected,
      violator: vehicleViolator,
      default: vehicleDefault,
    },
  };

  status;
  icon = this.icons.EMPLOYEE.default;
  tracked = false;
  selected = false;
  sensors;
  data;
  type;

  constructor(data) {
    if (!data) {
      return;
    }
    this.type = data.type;
    this.update(data);
  }

  update(data) {
    this.id = data.id;
    this.name = data?.name || data?._embedded?.tag || 'Неизвестный';
    const { status, x, y, z } = data?._embedded?.tag || {};
    this.status = status;
    this.isMoved = this.xM !== x || this.yM !== y;
    this.xM = x;
    this.yM = y;
    this.z = z;
    this.data = data;
    this.sensors = data?._embedded?.tag?.sensors;

    if (this.name === 'Джанасбаев Д.') {
      this.setColor();
    }
  }

  track(isTracked) {
    this.tracked = isTracked;
    this._setKeeperIcon(isTracked);
  }

  select(isSelected) {
    this.selected = isSelected;
    this._setKeeperIcon(isSelected);
  }

  getHistoryIcon(state = 'play') {
    let icon = this.icons.EMPLOYEE.history.play;
    if (!this.data) {
      return icon;
    }
    const states = ['play', 'pause', 'ff'];
    if (!states.includes(state)) throw new Error(`Invalid state: ${state}`);
    icon = this.icons[this.type].history[state];
    return icon;
  }

  getKeeperIcon(cond) {
    let icon = this.icons.EMPLOYEE.default;

    if (this.sensors.battery_state === 'LOW') {
      if (cond) {
        icon = this.icons[this.type].lowBatterySelected;
      } else {
        if (this.data.coloring === null || this.data.coloring.color === null) {
          icon = this.icons[this.type].lowBattery;
        } else {
          if (this.data.coloring.color === 'red') {
            icon = this.icons[this.type].lowBatteryOnDanger;
          }
          if (this.data.coloring.color === 'yellow') {
            icon = this.icons[this.type].lowBatteryViolator;
          }
        }
      }
    } else {
      if (cond) {
        icon = this.icons[this.type].selected;
      } else {
        if (this.data.coloring === null || this.data.coloring.color === null) {
          icon = this.icons[this.type].default;
        } else {
          if (this.data.coloring.color === 'red') {
            icon = this.icons[this.type].danger;
          }
          if (this.data.coloring.color === 'yellow') {
            icon = this.icons[this.type].violator;
          }
        }
      }
    }
    return icon;
  }

  _setKeeperIcon(cond) {
    this.icon = this.getKeeperIcon(cond);
  }

  /**
   * @deprecated
   */
  setColor() {
    if (this.sensors.battery_state === 'LOW') {
      if (this.data.coloring === null || this.data.coloring.color === null) {
        this.icon = this.icons[this.data.type].lowBattery;
      } else {
        if (this.data.coloring.color === 'red') {
          this.icon = this.icons[this.data.type].lowBatteryOnDanger;
        }
        if (this.data.coloring.color === 'yellow') {
          this.icon = this.icons[this.data.type].lowBatteryViolator;
        }
      }
    } else {
      if (this.data.coloring === null || this.data.coloring.color === null) {
        this.icon = this.icons[this.data.type].default;
      } else {
        if (this.data.coloring.color === 'red') {
          this.icon = this.icons[this.data.type].danger;
        }
        if (this.data.coloring.color === 'yellow') {
          this.icon = this.icons[this.data.type].violator;
        }
      }
    }
  }
}
