import { Button, Form, Input, Select, Typography } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColorPicker from "../../components/ColorPicker";
import {
  clearForm,
  createAreaRequest,
  createAreaSuccess,
  onChange, setPointCamera, setPointDevice
} from "../../store/actions/drawPolygons";
import {
  makeSelectCameraId,
  makeSelectCameraPoint, makeSelectColor,
  makeSelectCreationData,
  makeSelectCreationLoading, makeSelectDeviceId, makeSelectDevicePoint, makeSelectName,
  makeSelectPolygon
} from "../../store/selectors/drawPolygonSelector";

import { EditFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { initAnchorPlacer, removeAnchorPlacer } from "../../helpers/mapHelpers";
import { makeSelectDevicesList } from "../../store/selectors/devices";
import { createTypesFilter, mockedVideoSelect } from "./mockedData";
import { handleRemoveAllMarkers } from "./utils";

const {Option} = Select


const Create = ({map, layoutId, handleCreateFormClose, drawSelectedPolygon}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorAnchor, setColorAnchor] = useState();
  const [isMarkerPlacing, setIsMarkerPlacing] = useState(false);
  const [createType, setCreateType] = useState();

  const name = useSelector(makeSelectName);
  const color = useSelector(makeSelectColor);
  const deviceId = useSelector(makeSelectDeviceId);
  const polygon = useSelector(makeSelectPolygon);
  const cameraId = useSelector(makeSelectCameraId)
  const cameraMarker = useSelector(makeSelectCameraPoint);
  const deviceMarker = useSelector(makeSelectDevicePoint)
  const deviceIds = useSelector(makeSelectDevicesList)

  const areaCreationLoading = useSelector(makeSelectCreationLoading);
  const areaCreationData = useSelector(makeSelectCreationData);


  const isCreateFormValid = useMemo(
    () => {
      if(createType === 'with-device'){
        return !!name && !!color && !!polygon && !!deviceId && !!deviceMarker
      } else if(createType === 'with-camera'){
        return !!name && !!color && !!polygon && !!cameraId && !!cameraMarker
      } else {
        return !!name && !!color && !!polygon
      }
    },
    [name, color, polygon, deviceId, cameraId, cameraMarker, deviceMarker, createType],
  );

  const _setColorAnchor = node => {
    if (node && !isEqual(colorAnchor, node)) {
      setColorAnchor(node);
    }
  };

  const setCameraMarker = (payload) => {
    dispatch(setPointCamera(payload))
  }

  const setDeviceMarker = (payload) => {
    dispatch(setPointDevice(payload))
  }

  const handleColorPickerOpen = () => {
    if (areaCreationLoading) return;
    setIsColorPickerOpen(true)
  };

  const handleSetMarkerMode = () => {
    setIsMarkerPlacing(true)
  }

  const handleChangeName = e => {
    dispatch(onChange({
      field: 'name', value: e.target.value
    }));
  };

  const handleChangeCreateType = e => {
    setCreateType(e)
  }

  const handleChangeDeviceId = e => {
    dispatch(onChange({
      field: 'deviceId', value: e
    }));
  };

  const handleChangeColor = color => {
    dispatch(onChange({
      field: 'color', value: color
    }));
  };

  const handleCreateRequest = () => {
    let form = {
      name,
      device_id: deviceId,
      color,
      region: polygon.toGeoJSON(),
    };
    if(createType === 'with-camera'){
      form = {
        ...form,
        camera: {
          point: cameraMarker.marker.toGeoJSON(),
          cameraId
        },
      }
    } else if(createType === 'with-device'){
      form = {
        ...form,
        device: {
          point: deviceMarker.marker.toGeoJSON(),
          device_id: deviceId,
        }
      }
    }

    dispatch(createAreaRequest({form, layoutId}));
  };

  const handleChangeSelect = (e) => {
    dispatch(onChange({
      field: 'cameraId',
      value: e
    }))
  }


  useEffect(
    () => {
      if (polygon && color) {
        polygon._path.style.fill = color;
        polygon._path.style.fillOpacity = 0.8;
      }
    },
    [color, polygon],
  );

  useEffect(() => {
    if (map && isMarkerPlacing) {
      initAnchorPlacer(
        map,
        (marker, latlng) => {
          handleRemoveAllMarkers({
            markerInstance: marker,
            position: latlng,
            setCameraMarker,
            setIsMarkerPlacing,
            setDeviceMarker,
            map,
            deviceMarker,
            cameraMarker,
            createType,
          })
        }
      );
    } else if (map && !isMarkerPlacing) {
      removeAnchorPlacer(map);
    }
  }, [map, isMarkerPlacing])

  useEffect(
    () => {
      if (areaCreationData && isCreateFormValid) {
        map.removeLayer(polygon);
        dispatch(createAreaSuccess(null));
        dispatch(clearForm());
        drawSelectedPolygon();
      }
    },
    [
      areaCreationData,
      isCreateFormValid,
      dispatch,
      polygon,
      map,
      drawSelectedPolygon,
    ],
  );

  return (
    <Form
      className="area-create-form"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
    >
      <Typography.Title level={3} style={{marginBottom: 24}}>{t('createVisibility')}</Typography.Title>
      <Form.Item
        label={t('title')}
        className="area-name-field"
      >
        <Input
          value={name}
          onChange={handleChangeName}
          disabled={areaCreationLoading}
        />
      </Form.Item>
      <Form.Item
        label={t('color')}
        className="color-wrapper"
      >
        <span
          className="area-color"
          style={{background: color || '#ffffff', cursor: 'pointer'}}
          ref={_setColorAnchor}
          onClick={handleColorPickerOpen}
        />
      </Form.Item>
      <Form.Item
        label={t('type')}
        className="select-wrapper"
      >
        <Select
          defaultValue={createTypesFilter[0].key}
          onChange={handleChangeCreateType}
        >
          {createTypesFilter.map(item => (
            <Option key={item.key} value={item.key}>{item.label}</Option>
          ))}
        </Select>
      </Form.Item>
      {
        createType === 'with-device' && (
          <>
            <Form.Item
              label={t('equipmentTag')}
              className="select-wrapper"
            >
              <Select
                defaultValue={deviceId}
                onChange={handleChangeDeviceId}
              >
                {deviceIds.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('marker')}
              className="color-wrapper"
            >
              <Button
                type="primary"
                disabled={isMarkerPlacing}
                icon={<EditFilled/>}
                onClick={handleSetMarkerMode}
              >
                {t('putMarker')}
              </Button>
            </Form.Item>
          </>
        )
      }
      {
        createType === 'with-camera' && (
          <>
            <Form.Item
              label={t('camera')}
              className="select-wrapper"
            >
              <Select
                defaultValue={2}
                onChange={handleChangeSelect}
              >
                {mockedVideoSelect.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('marker')}
              className="color-wrapper"
            >
              <Button
                type="primary"
                disabled={isMarkerPlacing}
                icon={<EditFilled/>}
                onClick={handleSetMarkerMode}
              >
                {t('putMarker')}
              </Button>
            </Form.Item>
          </>
        )
      }

      <div className="area-form-actions">
        <Button
          type="primary"
          disabled={!isCreateFormValid || areaCreationLoading}
          onClick={handleCreateRequest}
        >
          {areaCreationLoading ? `${t('loading')}...` : t('create')}
        </Button>
        <Button
          disabled={areaCreationLoading}
          onClick={handleCreateFormClose}
        >
          {t('close')}
        </Button>
      </div>

      <ColorPicker
        open={isColorPickerOpen}
        onClose={() => setIsColorPickerOpen(false)}
        anchor={colorAnchor}
        onChange={handleChangeColor}
      />
    </Form>
  );
};

export default Create;
