import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import client, { request } from '../../helpers/client';
import { eventTypeNameMapped } from '../../pages/Settings/Reactions/utils';

import {
  CREATION_REQUEST,
  DELETE_REQUEST,
  EDITING_REQUEST,
  GET_EVENT_TYPE_REQUEST,
  GET_LIST_REQUEST,
  OPEN_EDIT_FORM,
  askToDelete,
  createFailure,
  createSuccess,
  deleteFailure,
  deleteSuccess,
  editFailure,
  editSuccess,
  getEventTypeFailure,
  getEventTypeSuccess,
  getListFailure,
  getListRequest,
  getListSuccess,
  setIsCreateFormOpen,
  setIsEditFormOpen,
} from '../actions/employees';
import { clearForm, setFormInitialValues } from '../actions/forms';

import { t } from 'i18next';
import { DEFAULT_PAGE_SIZE } from '../../components/Pagination/constants';
import {
  CREATE_FORM,
  EDIT_FORM,
} from '../../pages/Settings/Employees/form/constants';

function* fetchEmployeesList(action) {
  const {
    page = 1,
    size = DEFAULT_PAGE_SIZE,
    search,
    isActive,
  } = action.payload;
  try {
    const response = yield call(
      request.get,
      '/employees/?' +
        `${!!search ? `search=${search}&` : ''}` +
        `${String(isActive) === 'true' ? 'is_active=true&' : ''}`,
      {
        params: { page, size },
      }
    );
    yield put(getListSuccess(response.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

function* fetchEventTypes() {
  try {
    const response = yield call(
      client.get,
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/dicts/EVENT_TYPE'
    );
    const types = eventTypeNameMapped(t);
    const data = response.data.items.map((item) => ({
      name: item.name,
      label: types[item.name] ? types[item.name] : item.name,
    }));

    yield put(getEventTypeSuccess(data));
  } catch (error) {
    yield put(getEventTypeFailure(error));
  }
}

function* create(action) {
  const { data } = action.payload;
  try {
    const response = yield call(request.post, '/employees/', data);
    yield put(createSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsCreateFormOpen(false));
    yield put(clearForm({ form: CREATE_FORM }));
  } catch (error) {
    yield put(createFailure(error));
  }
}

function* update(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(request.put, `/employees/${id}/`, data);

    yield put(editSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsEditFormOpen(false));
  } catch (error) {
    yield put(editFailure(error));
  }
}

function* openEditForm(action) {
  const { instance } = action.payload;
  yield put(setFormInitialValues({ form: EDIT_FORM, instance }));
  yield delay(300);
  yield put(setIsEditFormOpen(true));
}

function* deleteRequest(action) {
  const { id } = action.payload;

  try {
    const response = yield call(request.delete, `/employees/${id}/`);
    yield put(deleteSuccess(response.data));
    yield put(getListRequest());
    yield put(askToDelete(null));
  } catch (error) {
    yield put(deleteFailure(error));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_LIST_REQUEST, fetchEmployeesList),
    takeLatest(GET_EVENT_TYPE_REQUEST, fetchEventTypes),
    takeLatest(CREATION_REQUEST, create),
    takeLatest(EDITING_REQUEST, update),
    takeLatest(OPEN_EDIT_FORM, openEditForm),
    takeLatest(DELETE_REQUEST, deleteRequest),
  ]);
}

export default Saga;
