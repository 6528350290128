export const UPLOAD_MAP_REQUEST = 'UPLOAD_MAP_REQUEST';
export const SET_UPLOAD_MAP_DATA = 'SET_UPLOAD_MAP_DATA';
export const SET_UPLOAD_MAP_FETCHING = 'SET_UPLOAD_MAP_FETCHING';
export const SET_UPLOAD_MAP_ERROR = 'SET_UPLOAD_MAP_ERROR';

export const GET_SINGLE_MAP_REQUEST = 'GET_SINGLE_MAP_REQUEST';
export const SET_GET_SINGLE_MAP_DATA = 'SET_GET_SINGLE_MAP_DATA';
export const SET_GET_SINGLE_MAP_FAILURE = 'SET_GET_SINGLE_MAP_FAILURE';
export const SET_GET_SINGLE_MAP_FETCHING = 'SET_GET_SINGLE_MAP_FETCHING';

export const GET_MAPS_LIST_REQUEST = 'GET_MAPS_LIST_REQUEST';
export const SET_GET_MAPS_LIST_DATA = 'SET_GET_MAPS_LIST_DATA';
export const SET_GET_MAPS_LIST_FAILURE = 'SET_GET_MAPS_LIST_FAILURE';
export const SET_GET_MAPS_LIST_FETCHING = 'SET_GET_MAPS_LIST_FETCHING';

export const GET_MAIN_MAP_DATA = 'GET_MAIN_MAP_DATA';
export const GET_MAIN_MAP_FAILURE = 'GET_MAIN_MAP_FAILURE';
export const GET_MAIN_MAP_FETCHING = 'GET_MAIN_MAP_FETCHING';

export const ADD_MAIN_MAP_DATA = 'ADD_MAIN_MAP_DATA';
export const ADD_MAIN_MAP_FAILURE = 'ADD_MAIN_MAP_FAILURE';
export const ADD_MAIN_MAP_FETCHING = 'ADD_MAIN_MAP_FETCHING';

export const EDIT_MAP_DATA = 'EDIT_MAP_DATA';
export const EDIT_MAP_FAILURE = 'EDIT_MAP_FAILURE';
export const EDIT_MAP_FETCHING = 'EDIT_MAP_FETCHING';

export const NEXT_FLOOR = 'NEXT_FLOOR';
export const NEXT_FLOOR_ERROR = 'NEXT_FLOOR_ERROR';

export const uploadMapRequest = (payload) => ({
  type: UPLOAD_MAP_REQUEST,
  payload,
});
export const setUploadMapLoading = (payload) => ({
  type: SET_UPLOAD_MAP_FETCHING,
  payload,
});
export const setUploadMapData = (payload) => ({
  type: SET_UPLOAD_MAP_DATA,
  payload,
});
export const setUploadMapError = (payload) => ({
  type: SET_UPLOAD_MAP_ERROR,
  payload,
});

export const getMapByIdRequest = (payload) => ({
  type: GET_SINGLE_MAP_REQUEST,
  payload,
});
export const setGetSingleMapLoading = (payload) => ({
  type: SET_GET_SINGLE_MAP_FAILURE,
  payload,
});
export const setGetSingleMapData = (payload) => ({
  type: SET_GET_SINGLE_MAP_DATA,
  payload,
});
export const setGetSingleMapError = (payload) => ({
  type: SET_GET_SINGLE_MAP_FETCHING,
  payload,
});

export const getMapsListRequest = (payload) => ({
  type: GET_MAPS_LIST_REQUEST,
  payload,
});
export const setGetMapsListLoading = (payload) => ({
  type: SET_GET_MAPS_LIST_FAILURE,
  payload,
});
export const setGetMapsListData = (payload) => ({
  type: SET_GET_MAPS_LIST_DATA,
  payload,
});
export const setGetMapsListError = (payload) => ({
  type: SET_GET_MAPS_LIST_FETCHING,
  payload,
});

export const getMainMapFetching = () => ({
  type: GET_MAIN_MAP_FETCHING
});

export const getMainMapData = (payload) => ({
  type: GET_MAIN_MAP_DATA,
  payload,
});

export const getMainMapError = (payload) => ({
  type: GET_MAIN_MAP_FAILURE,
  payload,
});

export const addMainMapFetching = (payload) => ({
  type: ADD_MAIN_MAP_FETCHING,
  payload
});

export const addMainMapData = (payload) => ({
  type: ADD_MAIN_MAP_DATA,
  payload,
});

export const addMainMapError = (payload) => ({
  type: ADD_MAIN_MAP_FAILURE,
  payload,
});

export const editMapRequest = (payload) => ({
  type: EDIT_MAP_FETCHING,
  payload
});

export const editMapData = (payload) => ({
  type: EDIT_MAP_DATA,
  payload,
});

export const editMapError = (payload) => ({
  type: EDIT_MAP_FAILURE,
  payload,
});

export const mapNextFloor = (payload) => ({
  type: NEXT_FLOOR,
  payload
})

export const errorNextFloor = (payload) => ({
  type: NEXT_FLOOR_ERROR,
  payload
})

