import { all, fork } from 'redux-saga/effects';

import userSaga from './user';
import authSaga from './auth';
import categoriesSaga from './categories';
import svgMapSaga from './svgMap';
import search from './search';
import ows from './ows';
import reports from './reports';
import notificationConfigurations from './notificationConfigurations';
import groups from './groups';
import groupsStaffs from './groups-staffs';
import users from './users';
import employees from './employees';
import rotations from './rotations';
import markersConfigurations from './markersConfigurations';
import drawPolygon from './drawPolygon';
import devices from './devices';
import cameras from './cameras';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(authSaga),
    fork(categoriesSaga),
    fork(svgMapSaga),
    fork(search),
    fork(ows),
    fork(reports),
    fork(notificationConfigurations),
    fork(groups),
    fork(groupsStaffs),
    fork(employees),
    fork(rotations),
    fork(markersConfigurations),
    fork(drawPolygon),
    fork(devices),
    fork(cameras),
    fork(users),
  ]);
}
