import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import { getQueryParams } from 'hooks/query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportFilterBoolean({
  setTempFilterParams,
  tempFilterParams,
  loading,
  propKey,
  localizationKey,
}) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (!tempFilterParams) return false;
    const queryParams = getQueryParams();
    if (tempFilterParams[propKey]) {
      return tempFilterParams[propKey]
        ? String(tempFilterParams[propKey]) === 'true'
        : false;
    } else {
      return queryParams[propKey]
        ? String(queryParams[propKey]) === 'true'
        : false;
    }
  }, [tempFilterParams, propKey]);

  // ----------------------------------------------------------------------------------------
  // Functions
  // ----------------------------------------------------------------------------------------

  function onChange(v) {
    setTempFilterParams({
      ...tempFilterParams,
      [propKey]: String(v),
    });
  }

  // ----------------------------------------------------------------------------------------

  return (
    <FormControl component="fieldset" disabled={loading}>
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={value}
            onChange={(_, checked) => onChange(checked)}
          />
        }
        label={t(localizationKey)}
        labelPlacement="start"
      />
    </FormControl>
  );
}

export function ReportFilterInDayRange({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const value = useMemo(() => {
    if (!tempFilterParams) return false;
    const queryParams = getQueryParams();
    if (tempFilterParams.for_today) {
      return tempFilterParams.for_today
        ? String(tempFilterParams.for_today) === 'true'
        : false;
    } else {
      return queryParams.for_today
        ? String(queryParams.for_today) === 'true'
        : false;
    }
  }, [tempFilterParams]);

  // ----------------------------------------------------------------------------------------
  // Functions
  // ----------------------------------------------------------------------------------------

  function onChange(v) {
    setTempFilterParams({
      ...tempFilterParams,
      for_today: String(v),
    });
  }

  // ----------------------------------------------------------------------------------------

  return (
    <FormControl component="fieldset" disabled={loading}>
      <FormControlLabel
        value="start"
        control={
          <Checkbox
            checked={value}
            onChange={(_, checked) => onChange(checked)}
          />
        }
        label={t('report.filter.forToday')}
        labelPlacement="start"
      />
    </FormControl>
  );
}
