/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import {
  categoriesRequest,
  categoryByIdRequest,
  clearSingleCategoryState,
  createCategoryRequest,
  deleteCategoryRequest,
  openConfirmModal,
  openCreateModal,
  updateCategoryRequest,
} from '../../../store/actions/categories';
import {
  makeSelectCategoriesCount,
  makeSelectCategoryFetching,
  makeSelectCategoryTableData,
  makeSelectConfirmModalOpen,
  makeSelectCreateModalOpen,
} from '../../../store/selectors/categories';

import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { getQueryParams } from '../../../hooks/query';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';
import FormModal from './FormModal';

const CategoriesPage = ({
  history,
  dispatch,
  singleCategory,
  createCategory,
  updateProcessing,
}) => {
  const [deletedId, setDeletedId] = useState();
  const [page, setPage] = useState(getQueryParams().page || 1);
  const [size, setSize] = useState(getQueryParams().size || DEFAULT_PAGE_SIZE);
  const { t } = useTranslation();
  const loading = useSelector(makeSelectCategoryFetching);
  const count = useSelector(makeSelectCategoriesCount);
  const isConfirmModalOpen = useSelector(makeSelectConfirmModalOpen);
  const isCreateFormModalOpen = useSelector(makeSelectCreateModalOpen);

  const closeConfirmModal = () => {
    dispatch(openConfirmModal(false));
  };

  const handleClickDelete = (id) => {
    setDeletedId(id);
    dispatch(openConfirmModal(true));
  };

  const handleClickEdit = (category) => {
    dispatch(categoryByIdRequest({ id: category.id }));
  };

  const closeFormModal = () => {
    dispatch(clearSingleCategoryState());
    dispatch(openCreateModal(false));
  };

  const handleClickCreate = () => {
    dispatch(openCreateModal(true));
  };

  const handleEditSubmit = ({ id, name, order, thumbnail }) => {
    const formData = new FormData();
    try {
      if (name !== singleCategory?.name) {
        formData.append('name', name);
      }
      if (order !== singleCategory?.order) {
        formData.append('order', order);
      }

      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      if (Array.from(formData.keys()).length) {
        dispatch(updateCategoryRequest({ formData, id }));
      }
    } catch (error) {
      console.log('errorCATEGORYEDIT', error);
    }
  };

  const handleCreateSubmit = ({ name, order, thumbnail }) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('order', order);

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }
    if (Array.from(formData.keys()).length) {
      dispatch(createCategoryRequest({ formData }));
    }
  };

  const handleConfirm = () => {
    dispatch(deleteCategoryRequest({ id: deletedId }));
  };

  useEffect(
    () => {
      dispatch(categoriesRequest({ page, size }));
    },
    // eslint-disable-next-line
    []
  );

  const handlePaginationChange = (page, size) => {
    setPage(page);
    setSize(size);
  };

  useEffect(
    () => {
      dispatch(categoriesRequest({ page, size }));
    },
    [page, size]
  );

  const columns = [
    {
      title: t('title'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('image'),
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (thumbnail) => (thumbnail ? <Avatar src={thumbnail} /> : null),
    },
    {
      title: t('actions'),
      dataIndex: 'instance',
      key: 'actions',
      render: (instance) => (
        <>
          <EditFilled
            className="table-action edit"
            onClick={() => handleClickEdit(instance)}
            style={{ fontSize: 25 }}
          />
          <DeleteFilled
            className="table-action"
            onClick={() => handleClickDelete(instance.id)}
            style={{ fontSize: 25 }}
          />
        </>
      ),
    },
  ];

  const data = useSelector(makeSelectCategoryTableData(columns));

  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="btn-create-wrapper">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleClickCreate}
          >
            {t('add')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={
            data.length < MIN_PAGINATION_ITEMS
              ? false
              : {
                  total: count,
                  current: page,
                  pageSize: size,
                  onChange: handlePaginationChange,
                  hideOnSinglePage: true,
                  showSizeChanger: true,
                  position: ['bottomLeft'],
                }
          }
        />
      </div>
      <ConfirmModal
        visible={isConfirmModalOpen}
        title={t('delete') + '!!!'}
        message={t('sureWantDelete')}
        handleConfirm={handleConfirm}
        handleCancel={closeConfirmModal}
      />
      {isCreateFormModalOpen && (
        <FormModal
          category={createCategory}
          handleOk={handleCreateSubmit}
          handleCancel={closeFormModal}
        />
      )}
      {singleCategory && (
        <FormModal
          category={singleCategory}
          handleOk={handleEditSubmit}
          handleCancel={closeFormModal}
          loading={updateProcessing}
        />
      )}
    </SettingsLayout>
  );
};

const mapStateToProps = (state) => ({
  singleCategory: state.categories.singleCategory.data,
  updateProcessing: state.categories.categoryUpdateState.fetching,
});

export default connect(mapStateToProps)(CategoriesPage);
