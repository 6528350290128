import { SearchOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { useState } from 'react';

import SearchPopoverContent from './SearchPopoverContent';

import { useTranslation } from 'react-i18next';
import '../index.css';

function SearchPopover() {
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation()
  return (
    <Popover
      placement="bottomRight"
      content={<SearchPopoverContent setVisible={setVisible} visible={visible}/>}
      trigger="click"
      overlayClassName="search-header__popover notifications-header__popover"
      visible={visible}
      onVisibleChange={() => {
        setVisible(!visible);
      }}
    >
      <Tooltip title={t('search')}>
        <SearchOutlined
          style={{
            color: '#fff',
            fontSize: 28,
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    </Popover>
  );
}

export default SearchPopover;
