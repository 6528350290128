import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { getMapByIdRequest } from '../../store/actions/svgMap';
import Map from "../../components/Map";
import { makeSelectGetSingleMap } from "../../store/selectors/svgMap";

const EditMap = () => {
  const singleMap = useSelector(makeSelectGetSingleMap)

  const dispatch = useDispatch()
  const { mapId } = useParams();

  useEffect(() => {
    dispatch(getMapByIdRequest(mapId));
  }, [mapId]);

  return !!singleMap &&  <Map singleMap={singleMap}/>
}

export default EditMap
