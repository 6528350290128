import { request } from 'helpers/client';
import { errorNotification } from 'helpers/notificationHelpers';
import { t } from 'i18next';
import { mapStore } from 'model/MapStore';
import { useContext, useEffect, useState } from 'react';
import { UserDrawerContext } from './user-drawer.context';

export function useUserDrawer() {
  return useContext(UserDrawerContext);
}
export function useCurrentKeeper({
  keeperInstance = undefined,
  holder = undefined,
}) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const [keeper, setKeeper] = useState(keeperInstance);
  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (!keeperInstance) return;
    setKeeper(keeperInstance);
  }, [keeperInstance]);

  useEffect(() => {
    if (!keeperInstance) return;
    const subscription = mapStore.keepersByMap$.subscribe((allKeepers) => {
      const keepers = [];
      if (!allKeepers) return;
      Object.keys(allKeepers).forEach((mapId) => {
        for (let keeperItem in allKeepers[mapId]) {
          keepers.push(allKeepers[mapId][keeperItem]);
        }
      });
      const currentKeeper = keepers.find((item) => item.id === keeper.id);
      if (!currentKeeper) return;
      setKeeper(currentKeeper.data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [keeper, keeperInstance]);

  useEffect(() => {
    if (!holder) return;
    loadKeeperByHolder(holder);
    const timeout = setTimeout(() => {
      loadKeeperByHolder(holder);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [holder]);


  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------
  async function loadKeeperByHolder(holder) {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL +
        `/keepers/by_holder/?type=${holder.type}&id=${holder.id}`
        
    );

    if (!responce.data && responce.data === '') {
      errorNotification(t('keeperDataNotFound'));
    }
    setKeeper(responce.data);
  }

  const clearKeeperData = () => setKeeper(undefined);
  return { keeper, clearKeeperData };
}
