import { MenuOutlined } from '@ant-design/icons';
import { Layout, PageHeader, Space, Tooltip } from 'antd';

import BellPopover from './BellPopover/BellPopover';
import FavoriteMaps from './FavoriteMaps';
import SearchPopover from './SearchPopover/SearchPopover';

import { MENU } from '../../consts/tooltip';

import { useLocation } from 'react-router-dom';
import MapTypes from '../MapButtons/MapTypes';
import './index.css';
import { SettingsPopover } from './settings-popover/settings-popover';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;

export function HeaderNav({ onOpen }) {
  const location = useLocation()
  const {t} = useTranslation()
  return (
    <>
      <Header>
        <PageHeader
          className="site-page-header"
          title={
            <div style={{ display: 'flex' }}>
              <Tooltip title={t('menu')}>
                <div
                  className="header-menu-wrapper"
                  onClick={() => {
                    onOpen();
                  }}
                >
                  <MenuOutlined
                    style={{
                      color: '#fff',
                    }}
                  />
                </div>
              </Tooltip>
              <MapTypes className="header-types" />
            </div>
          }
          extra={
            // <MapTypes />,
            <div key="1">
              <Space size={48}>
                {location.pathname !== "/statistics" && location.pathname !== "/reports" && (
                  <>
                    <SearchPopover />
                    <FavoriteMaps />
                  </>
                )}

                <BellPopover />
                <SettingsPopover />
              </Space>
            </div>
          }
        />
      </Header>
    </>
  );
}
