const setCookie = (obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    //clear cookie
    const clearedCookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${
      value.path || '/'
    }`;
    document.cookie = clearedCookie;

    //set cookie
    const updatedCookie = `${key}=${value.value}; path=${value.path || '/'}`;
    document.cookie = updatedCookie;
  });
};

export { setCookie };
