import { Modal } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { ModalContext } from './modal.context';

export function ModalProvider(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const defaultOptions = useRef({
    component: <></>,
    onClose: () => {},
    width: '600px',
  });
  const [isRendered, setRendered] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [options, setOptions] = useState(defaultOptions.current);

  // ---------------------------------------------------------------------------
  // memo callbacks
  // ---------------------------------------------------------------------------

  const openFn = useCallback((options) => {
    setRendered(true);
    setTimeout(() => {
      setOpened(true);
      if (options)
        setOptions((prev) => {
          return { ...prev, ...options };
        });
    });
  }, []);

  const closeFn = useCallback(() => {
    setOpened(false);

    options.onClose();
    setTimeout(() => {
      setRendered(false);
    }, 300);
    setOptions(defaultOptions.current);
  }, [options, defaultOptions]);

  const updateOptions = useCallback((options) => {
    setOptions((prev) => {
      return { ...prev, ...options };
    });
  }, []);

  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [value] = useState({
    open: openFn,
    close: closeFn,
    updateOptions,
  });

  // ---------------------------------------------------------------------------
  return (
    <ModalContext.Provider value={value}>
      {isRendered && isOpened && (
        <Modal
          centered
          footer={null}
          visible={isOpened}
          title={options.title}
          width={options.width}
          onOk={() => {
            options.onOk();
            setOpened(false);
          }}
          okText={options.okText}
          cancelText={options.cancelText}
          onCancel={() => {
            setOpened(false);
            // setEdit(false);
          }}
        >
          {options.component}
        </Modal>
      )}

      {props.children}
    </ModalContext.Provider>
  );
}
