import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

function ConfirmModal({
  title,
  message,
  handleConfirm,
  handleCancel,
  visible,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onOk={handleConfirm}
      onCancel={handleCancel}
    >
      <p className="sos-modal-title">{title}</p>
      <p className="sos-modal-text">{message}</p>
      <div className="d-flex-sb">
        <Button className="sos-modal-send-btn" onClick={handleConfirm}>
          {t('yes')}
        </Button>
        <Button className="sos-modal-cancel-btn" onClick={handleCancel}>
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
