import { DeleteOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
/*import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';*/
import { styled } from '@mui/material/styles';
/*import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';*/
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Button, Pagination, Popconfirm, Switch, Table, Tooltip } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { ExpandIcon } from '../../../components/Icons';
import { DEFAULT_PAGE_SIZE } from '../../../components/Pagination/constants';
import { getMapAreasPath, toEditMapId } from '../../../consts/routes';
import { UPLOAD_MAP } from '../../../consts/urls';
import {
  errorNotification,
  successNotification,
} from '../../../helpers/notificationHelpers';
import { getQueryParams, useQuery } from '../../../hooks/query';
import { deleteArea } from '../../../services/areas';
import { deleteMap, getMapZones } from '../../../services/maps';
import {
  editMapRequest,
  getMapsListRequest,
} from '../../../store/actions/svgMap';
import {
  makeSelectGetMainMap,
  makeSelectGetMainMapFetching,
  makeSelectMapListCount,
  makeSelectMapsListFetching,
  makeSelectTableData,
} from '../../../store/selectors/svgMap';
import SettingsLayout from '../SettingsLayout';
import { MIN_PAGINATION_ITEMS } from '../consts';

const MapsConfiguration = ({ history }) => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const { t } = useTranslation();
  const count = useSelector(makeSelectMapListCount);
  const { query, setQuery } = useQuery();

  const loading = useSelector(makeSelectMapsListFetching);
  const main = useSelector(makeSelectGetMainMap);
  const isMainLoading = useSelector(makeSelectGetMainMapFetching);
  const params = useParams();

  const dispatch = useDispatch();

  const columns = [
    {
      title: t('title'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('categoryCrushing'),
      dataIndex: 'category.name',
      key: 'category',
    },
    {
      title: t('floor'),
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: t('mainFloor'),
      dataIndex: 'instance',
      key: 'is_main',
      render: (instance) => (
        <Switch
          checked={instance.id === main?.id}
          onChange={(checked) => handleSwitch(checked, instance.id)}
          disabled={
            (instance.id === main?.id ? false : Boolean(main)) || isMainLoading
          }
        />
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <div className="actions-wrapper">
          <Tooltip title={t('editMap')}>
            <NavLink to={toEditMapId(id)} className="demo-list__link" exact>
              <EditFilled style={{ fontSize: 25 }} />
            </NavLink>
          </Tooltip>
          <Tooltip title={t('editZones')}>
            <NavLink to={getMapAreasPath(id)} className="demo-list__link" exact>
              <ExpandIcon />
            </NavLink>
          </Tooltip>
          {/* <Tooltip title={EDIT_ANCHORS}>
            <NavLink to={toPutAnchors(id)} className="demo-list__link" exact>
              <Anchors />
            </NavLink>
          </Tooltip> */}
          <Tooltip title={t('deleteMap')}>
            <Popconfirm
              title={t('sureWantDelete')}
              placement={'left'}
              okText={'Да'}
              cancelText={'Нет'}
              onConfirm={() => remove(id)}
            >
              <DeleteOutlined style={{ fontSize: 20 }} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];
  const data = useSelector(makeSelectTableData(columns));

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [params]
  );

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  function loadData() {
    const queryParams = getQueryParams();
    dispatch(
      getMapsListRequest({
        page: queryParams.page || 1,
        size: queryParams.size || DEFAULT_PAGE_SIZE,
      })
    );
  }
  const remove = useCallback(
    async (id) => {
      try {
        const zones = await getMapZones(id);
        for (let { id } of zones) {
          await deleteArea(id);
        }
        await deleteMap(id);
        successNotification(t('mapSuccessDel'));
        loadData();
      } catch (e) {
        errorNotification(t('errorOnMapDelete'));
        throw e;
      }
    },
    [query]
  );

  const handleSwitch = (checked, id) => {
    let formData = new FormData();
    formData.append('is_main', checked);
    dispatch(editMapRequest({ formData, id }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="btn-create-wrapper">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push(UPLOAD_MAP)}
          >
            {t('addMap')}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={count}
          current={Number(query.page) || 1}
          pageSize={query.size || MIN_PAGINATION_ITEMS}
          onChange={(page, size) =>
            setQuery({ ...getQueryParams(), page, size })
          }
          hideOnSinglePage={true}
          showSizeChanger={true}
          defaultCurrent={Number(query.page)}
        />
      </div>
    </SettingsLayout>
  );
};

export default MapsConfiguration;
