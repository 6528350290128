import { Select } from 'antd';
import { getQueryParams } from 'hooks/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectSimple({
  setTempFilterParams,
  tempFilterParams,
  loading,
  paramName,
  variants,
  placeholder,
  renderItem = undefined,
}) {
  const { t } = useTranslation();

  const value = useCallback(() => {
    const queryParams = getQueryParams();
    let currentValue = null;
    if (!tempFilterParams) return;
    if (tempFilterParams[paramName]) {
      currentValue = variants.find(
        (type) => type === tempFilterParams[paramName]
      );
    } else {
      currentValue = variants.find((type) => type === queryParams[paramName]);
    }

    if (!currentValue) return null;

    return renderItem ? renderItem(currentValue) : currentValue;
  }, [variants, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={placeholder}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={value()}
      onChange={(value) => {
        const tempParam = { ...tempFilterParams };
        tempParam[paramName] = value;
        setTempFilterParams(tempParam);
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {['_none', ...variants].map((value, index) => (
        <Select.Option value={value} key={index}>
          {value === '_none'
            ? t('notSpecified')
            : renderItem
            ? renderItem(value)
            : value}
        </Select.Option>
      ))}
    </Select>
  );
}
