import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Card, Modal } from 'antd';

import SwtichGroup from '../../components/SwitchGroup';
import { signInRequest } from '../../store/actions/auth';

import './index.css';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useTranslation } from 'react-i18next';

function Authorization({ isAuthenticated, history, signIn, signInError }) {
  const [visible, setVisible] = useState(false);
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const onFinish = ({ login, password }) => {
    const reqBody = {
      username: login,
      password,
    };

    signIn({ payload: reqBody, history });
  };
  useEffect(() => {
    currentUser.updateUserData();
  }, [isAuthenticated]);

  return (
    <div className="auth-container">
      <Modal
        centered
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        className={'auth-container--modal'}
      >
        <p className="modal-title">{t('accessRecovery')}</p>
        <p>{t('accessRecoveryDesc')}</p>
        <Button
          className="auth-container__btn"
          onClick={() => {
            setVisible(false);
          }}
        >
          Ок
        </Button>
      </Modal>
      <Card
        bordered={false}
        style={{ width: 500, padding: '0' }}
        className="auth-container__form-wrapper"
      >
        <div className="auth-container__logo"></div>
        <p className="auth-container__title">{t('systemAuth')}</p>
        {!!signInError && (
          <p className="auth-container__error-text">{t('wrongCredentials')}</p>
        )}
        <Form
          layout={'vertical'}
          name="normal_login"
          className="login-form mt-24"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="login"
            label={t('login')}
            rules={[{ required: true, message: t('enterLogin') }]}
          >
            <Input shape="round" className="auth-container__input" />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('password') + "*"}
            rules={[{ required: true, message: t('enterPassword') }]}
          >
            <Input className="auth-container__input" type="password" />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" noStyle>
              <SwtichGroup
                list={[
                  { id: 0, title: 'KZ' },
                  { id: 1, title: 'RU' },
                  { id: 2, title: 'EN' },
                ]}
              />
            </Form.Item>
            <a
              style={{ float: 'right', textDecoration: 'underline' }}
              onClick={() => {
                setVisible(true);
              }}
            >
              {t('forgetPassword')}
            </a>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="auth-container__btn">
              {t('logIn')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    signInError: state.auth.signInState.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: (reqBody) => dispatch(signInRequest(reqBody)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
