import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

import { getBounds } from '../../helpers/getters';
import { createImage } from '../../helpers/imageHelpers';
import { initAnchorPlacer, initMap } from '../../helpers/mapHelpers';

import { useTranslation } from 'react-i18next';
import './index.scss';

const Map = ({ image, id }) => {
  const [map, setMap] = useState();
  const [bounds, setBounds] = useState();
  const [markerList, setMarkerList] = useState([]);
const {t} = useTranslation()
  const handleLoadImage = (img) => {
    setBounds(getBounds(img.width, img.height));
  };

  const initBounds = () => {
    createImage(image, handleLoadImage);
  };

  useEffect(() => {
    initBounds();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (bounds) {
      if (!map && bounds) setMap(initMap({ mapId: id, image, bounds }));
    }
  }, [bounds]); // eslint-disable-line

  useEffect(
    () => {
      initAnchorPlacer(map, (marker, latlng) => {
        setMarkerList((value) => [...value, { marker, latlng }]);
      });
    },
    // eslint-disable-next-line
    [map]
  );

  const handleRemoveAllMarkers = () => {
    if (!map) return;

    for (const instance of markerList) {
      map.removeLayer(instance.marker);
    }

    setMarkerList([]);
  };

  return (
    <div className="map-wrapper">
      <Button
        color="secondary"
        onClick={handleRemoveAllMarkers}
        className="remove-marker-button"
        disabled={!markerList.length}
      >
        {t('removeAllMarks')}
      </Button>
      <div id={id} className="map" />
    </div>
  );
};

export default React.memo(Map);
