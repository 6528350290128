import { ContentLayout } from 'hoc/content-layout/content-layout.component';
import moment from 'moment';
import 'moment/locale/ru';
import { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import { ROUTES } from './consts/routes';
import { mapStore } from './model/MapStore';
import { getLabels } from './services/labels';
import { getListRequest } from './store/actions/notificationConfigurations';
import { getMainMapFetching } from './store/actions/svgMap';
import { getUserColorRequest } from './store/actions/user';
import { selectConfigList } from './store/selectors/notificationConfigSelectors';

import initI18n from 'components/SwitchGroup/localization';
import { getLanguage } from 'components/SwitchGroup/localization.utils';
import i18n from 'i18next';

moment.locale('ru');

function App({ isAuthenticated }) {
  const configList = useSelector(selectConfigList);

  const dispatch = useDispatch();

  initI18n().then(() => {
    const language = getLanguage().toLocaleLowerCase();
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      getLabels();
    }
  }, [isAuthenticated, configList]); // eslint-disable-line

  useEffect(() => {
    if (isAuthenticated) {
      mapStore.setUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getListRequest());
    dispatch(getMainMapFetching());
    dispatch(getUserColorRequest());
  }, []); // eslint-disable-line


  const routerParams = useParams()
  const keeperHistory = useMemo(
    () => mapStore.keeperHistoryInstance,
    [routerParams]
  );


  return (
    <ContentLayout>
      <Switch>
        {ROUTES.map(({ isPrivate, ...rest }, idx) => {
          return isPrivate ? (
            <PrivateRoute {...rest} key={`POSITION_ROUTE_${idx}`} />
          ) : (
            <Route {...rest} key={`POSITION_ROUTE_${idx}`} />
          );
        })}
      </Switch>
    </ContentLayout>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps)(App);
