import { Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { clearForm } from '../../store/actions/forms';

import { useTranslation } from 'react-i18next';
import Form from './form';

const Dialog = ({
  title,
  visible,
  onClose,
  buttonText,
  form,
  handleSubmit,
  loading,
  config,
  error,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = React.createRef();

  const handleClose = () => {
    if (onClose) onClose();
    dispatch(clearForm({ form }));
  };

  async function onSubmit() {
    await formRef.current.validateFields();
    handleSubmit();
  }

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={handleClose}
      okText={buttonText}
      cancelText={t('cancel')}
      onOk={onSubmit}
      okButtonProps={{ loading }}
      keyboard={!loading}
      cancelButtonProps={{ loading }}
      width="600px"
    >
      <Form
        formRef={formRef}
        loading={loading}
        name={form}
        config={config}
        error={error}
      />
    </Modal>
  );
};

export default React.memo(Dialog);
