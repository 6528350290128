import { Button, Form, Input } from 'antd';
import { useModal } from 'context/modal/modal.hook';
import { request } from 'helpers/client';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

export const SmtpForm = ({ loading, setLoading, disabled = false }) => {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const modal = useModal();
  const [data, setData] = useState({
    username: '',
    password: '',
    smtp_server_address: '',
    smtp_server_port: '',
    ssl_tls_address: '',
    ssl_tls_port: '',
  });

  const fields = [
    'username',
    'password',
    'smtp_server_address',
    'smtp_server_port',
    'ssl_tls_address',
    'ssl_tls_port',
  ];

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    getSmtpData();
  }, []);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  async function getSmtpData() {
    setLoading(true);
    const response = await request.get('/smtp_settings');
    const { data } = response;
    console.log(data);
    setData(data);
    setLoading(false);
  }

  async function setSmtpData(data) {
    setLoading(true);
    await request.put('/smtp_settings/', data);
    setLoading(false);
    modal.close();
  }

  // ---------------------------------------------------------------------------
  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 20 }}
      onFinish={(e) => {
        setSmtpData(data);
      }}
      className="m-config-wrapper"
    >
      {fields.map((field) => (
        <Form.Item
          initialValue={data[field]}
          label={t(`smtp.${field}`)}
          style={{ flex: '0 0 10%', width: '40rem' }}
          rules={[{ required: true, message: t('enterText') }]}
          className="form-row"
          required
        >
          <Input
            type={
              field === 'smtp_server_port' || field === 'ssl_tls_port'
                ? 'number'
                : 'text'
            }
            value={data[field]}
            name={field}
            onChange={(e) => {
              let resData = {};
              for (let key in data) {
                if (key === field) {
                  resData[key] = e.target.value;
                } else {
                  resData[key] = data[key];
                }
              }
              setData(resData);
            }}
            disabled={disabled || loading}
            required
          />
        </Form.Item>
      ))}
      {!disabled && (
        <Button type="primary" loading={loading} htmlType="submit">
          {t('save')}
        </Button>
      )}
    </Form>
  );
};
