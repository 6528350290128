import {normalizeInstance} from "../../helpers/table";

const getState = state => state.categories

export const makeSelectCategoryList = state => getState(state).categoriesState?.data?.results;
export const makeSelectCategoryTableData = (columns = []) => state => {
  const data = makeSelectCategoryList(state) || [];
  return data.map(normalizeInstance(columns));
};
export const makeSelectCategoriesCount = state => getState(state).categoriesState?.data?.count || 0;
export const makeSelectCategoryFetching = state => getState(state).categoriesState.fetching;
export const makeSelectCreateModalOpen = state => getState(state).createModalOpen
export const makeSelectConfirmModalOpen = state => getState(state).confirmModalOpen
