import KeeperCounter from 'components/Map/components/KeeperCounter';
import { mapStore } from 'model/MapStore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactScrollableFeed from 'react-scrollable-feed';
import { categoriesRequest } from 'store/actions/categories';
import { getMapsListRequest } from 'store/actions/svgMap';
import { makeSelectCategoryList } from 'store/selectors/categories';
import { makeSelectGetMapsList } from 'store/selectors/svgMap';
import { SandwichColumn } from './SandwichColumn';
import { groupMapsByCategories, sortSandwichesByOrder } from './utils';

export const SandwichPage = () => {
  // ------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [sandwichList, setSandwichList] = useState([]);
  const categoriesList = useSelector(makeSelectCategoryList);
  const mapsList = useSelector(makeSelectGetMapsList);
  const [countById, setCountById] = useState();

  const dispatch = useDispatch();

  // ------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(categoriesRequest());
    dispatch(getMapsListRequest({ size: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    if (categoriesList && mapsList) {
      const list = groupMapsByCategories(mapsList, categoriesList);
      const sorted = sortSandwichesByOrder(list);
      setSandwichList(sorted);
    }
  }, [categoriesList, mapsList]);

  useEffect(() => {
    const subscription = mapStore.keepersByMap$.subscribe((keepersByMap) => {
      if (keepersByMap) {
        const countByMap = {};
        Object.keys(keepersByMap).forEach((mapId) => {
          countByMap[mapId] = Object.keys(keepersByMap[mapId]).length;
        });
        setCountById(countByMap);
      }
    });

    return () => subscription.unsubscribe();
  }, [mapStore]);

  // ------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------

  function getAllKeepersCount() {
    if (!countById) return 0;
    const sandwichIds = [];
    let keepersCount = 0;
    for (let sandwichListColumnItem of sandwichList) {
      for (let item of sandwichListColumnItem.list) {
        sandwichIds.push(item.id);
      }
    }
    for (let sandwichId of sandwichIds) {
      if (!countById[sandwichId]) continue;

      keepersCount += countById[sandwichId];
    }
    return keepersCount;
  }

  // ------------------------------------------------------------------------------------------------------
  return (
    <div className="content-block block-gallery-sandwich">
      <ReactScrollableFeed
        style={{
          width: `100%`,
        }}
      >
        <div className="block-gallery-wrapper">
          {getAllKeepersCount() !== 0 && (
            <KeeperCounter
              title={t('activeUsersCount')}
              amount={getAllKeepersCount()}
            />
          )}
          <div className="block-gallery-inner-wrapper">
            {sandwichList?.map((item, index) => (
              <SandwichColumn key={index} data={item} />
            ))}
          </div>
        </div>
      </ReactScrollableFeed>
    </div>
  );
};
