import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectTransport({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [variants, setVariants] = useState([]);

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------
  useEffect(() => {
    loadVariants();
  }, []);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------
  async function loadVariants() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/transports?size=1000`
    );
    if (!responce || !responce.data) return;
    setVariants(responce.data.results);
  }

  const value = useCallback(() => {
    if (!variants || !tempFilterParams) return null;
    const queryParams = getQueryParams();
    let currentValue = null;
    if (tempFilterParams.transport_id) {
      currentValue = variants.find(
        (v) => v.id === +tempFilterParams.transport_id
      );
    } else {
      currentValue = variants.find((v) => v.id === +queryParams.transport_id);
    }

    if (!currentValue) return null;
    return currentValue.onboard_number;
  }, [variants, tempFilterParams]);

  // ----------------------------------------------------------------------------------------
  if (variants.length === 0) return <></>;
  return (
    <Select
      showSearch={true}
      placeholder={t('transport')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={value()}
      onChange={(transport_id) =>
        setTempFilterParams({
          ...tempFilterParams,
          transport_id,
        })
      }
      onFocus={(e) =>
        (e.currentTarget.style.borderBottom = '2px solid #1976d2')
      }
      onBlur={(e) => (e.currentTarget.style.borderBottom = '2px solid #c2c2c2')}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {variants &&
        [{ id: '_none', onboard_number: t('notSpecified') }, ...variants].map(
          (v, i) => (
            <Select.Option value={v.id} key={i}>
              {v.onboard_number}
            </Select.Option>
          )
        )}
    </Select>
  );
}
