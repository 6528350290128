export const UPLOAD_MAP = '/upload/map';
export const SHOW_MAP = '/show/map/';
export const EDIT_MAP = '/edit/map/';
export const SHOW_MAP_ID = SHOW_MAP + ':mapId';
export const SHOW_MAP_ID_POINT = SHOW_MAP + ':mapId/point/:x/:y/:z';
export const SHOW_MAP_ID_NOTIFICATION =
  SHOW_MAP + ':mapId/point/:x/:y/:z/:notificationId';
export const SHOW_MAP_ID_REPORT =
  SHOW_MAP + ':mapId/point/:x/:y/:z/report/:reportType/:reportId';

export const EDIT_MAP_ID = EDIT_MAP + ':mapId';
export const DRAW_POLYGONS = '/draw-polygons/';
export const DRAW_POLYGONS_MAP_ID = DRAW_POLYGONS + ':mapId';
export const LOGIN = '/login';
export const REPORTS = '/reports';
export const MAIN = '/';
export const SANDWICH = '/sandwich';
export const SETTINGS = '/settings';
export const SETTINGS_CATEGORIES = SETTINGS + '/categories';
export const SETTINGS_MAPS = SETTINGS + '/maps';
export const SETTINGS_EMPLOYESS = SETTINGS + '/employees';
export const SETTINGS_ROTATIONS = SETTINGS + '/rotations';
export const SETTINGS_GROUPS = SETTINGS + '/groups';
export const SETTINGS_SETTING = SETTINGS + '/setting';
export const SETTINGS_USERS = SETTINGS + '/users';
export const SETTINGS_ORGANIZATIONS = SETTINGS + '/organizations';
export const SETTINGS_TRANSPORTS = SETTINGS + '/transports';

export const SETTINGS_NOTIFICATIONS = `${SETTINGS}/notifications`;
export const SETTINGS_MAP_MARKERS = `${SETTINGS}/markers`;
export const PUT_ANCHORS = '/put-anchor';
export const PUT_ANCHORS_URL = `${PUT_ANCHORS}/:mapId`;
export const STATISTICS = '/statistics';
export const SETTINGS_DEVICES = `${SETTINGS}/devices`;
export const SETTINGS_MACROSCOP = `${SETTINGS}/macroscop`;
export const SETTINGS_SMPT = `${SETTINGS}/smpt`;

export const SETTINGS_MAP_AREA = `${SETTINGS}/map/:mapId/areas`;
export const SETTINGS_MAP_AREA_CREATE = `${SETTINGS}/map/:mapId/areas/create`;
export const SETTINGS_MAP_AREA_EDIT = `${SETTINGS}/map/:mapId/areas/:areaId/edit`;
