import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { floorOptions } from 'consts/options';
import { useTranslation } from 'react-i18next';
import { makeSelectCategoryList } from 'store/selectors/categories';
import {
  makeSelectGetMainMap,
  makeSelectGetMainMapFetching,
} from 'store/selectors/svgMap';
import './index.scss';

export const CoordinatesForm = ({
  boundsCoords,
  boundsEditingMode,
  clickImageInput,
  onSubmit,
  setBoundsEditingMode,
  setSegmentLength,
  singleMap,
  submitBtnRef,
}) => {
  const { t } = useTranslation();

  const { mapId } = useParams();
  const isMain = useSelector(makeSelectGetMainMap);
  const isMainLoading = useSelector(makeSelectGetMainMapFetching);
  const categories = useSelector(makeSelectCategoryList);
  const [form] = Form.useForm();
  const [boundsCoordsError, setBoundsCoordsError] = useState(false);

  const [isMainChecked, setIsMainChecked] = useState(mapId === isMain?.id);

  const handleChangeMain = () => {
    setIsMainChecked((prevState) => !prevState);
  };

  useEffect(() => {
    if (singleMap) {
      const {
        x = null,
        y = null,
        remote_id = '',
        name = '',
        min_z = null,
        max_z = null,
        segment_length = null,
      } = singleMap;
      form.setFieldsValue({
        x,
        y,
        remote_id,
        name,
        min_z,
        max_z,
        segment_length,
      });
    }
  }, [singleMap]);

  useEffect(() => {
    setIsMainChecked(mapId === isMain?.id);
  }, [mapId, isMain]);

  const submitHandler = (values) => {
    if (boundsCoords) {
      onSubmit({ ...values, is_main: isMainChecked });
    } else {
      setBoundsCoordsError(true);
    }
  };

  const onValuesChange = (value) => {
    if (+value?.segment_length) {
      setSegmentLength(+value.segment_length);
    }
  };

  return (
    <div className="form-wrapper">
      <Form
        layout="vertical"
        onFinish={submitHandler}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Form.Item>
          <Tooltip title={t('clickToSelectAnImageWithFloorPlan')}>
            <Button style={{ margin: 0 }} onClick={clickImageInput}>
              {t('chooseFloorPlan')}
            </Button>
          </Tooltip>
        </Form.Item>
        <Form.Item
          name="remote_id"
          label={t('rtlsMapId')}
          rules={[{ required: false, message: t('enterRtlsMapId') }]}
          required
        >
          <Input
            className="input-max-width"
            shape="round"
            placeholder={t('rtlsMapId')}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('name')}
          rules={[{ required: false, message: t('enterMapName') }]}
          required
        >
          <Input
            className="input-max-width"
            shape="round"
            placeholder={t('name')}
          />
        </Form.Item>
        <Form.Item
          name="min_z"
          label="Min Z"
          rules={[{ required: true, message: t('requireField') }]}
          required
        >
          <Input shape="round" type="number" />
        </Form.Item>
        <Form.Item
          name="max_z"
          label="Max Z"
          rules={[{ required: true, message: t('requireField') }]}
          required
        >
          <Input shape="round" type="number" />
        </Form.Item>
        <Form.Item
          name="segment_length"
          label={t('cutLength')}
          rules={[{ required: true, message: t('requireField') }]}
          required
        >
          <Input shape="round" type="number" />
        </Form.Item>

        <div className="starting-point">
          <Tooltip title={t('rpcrttmzim')}>
            <div className="title">{'*' + t('calculationPoint')}</div>
          </Tooltip>
          <div className="values">
            <Form.Item
              name="x"
              rules={[{ required: true, message: t('requireField') }]}
            >
              <Input shape="round" type="number" title="x, м" />
            </Form.Item>
            <Form.Item
              name="y"
              rules={[{ required: true, message: t('requireField') }]}
            >
              <Input shape="round" type="number" title="y, м" />
            </Form.Item>
          </div>
          
        </div>

        <Tooltip title={t('specRoomBounds')} visible={boundsCoordsError}>
          <Button
            onClick={() => {
              setBoundsCoordsError(false);
              setBoundsEditingMode(!boundsEditingMode);
            }}
            block
          >
            {boundsEditingMode
              ? t('save')
              : boundsCoords?.length
              ? t('edit')
              : t('create')}
            {' ' + t('roomBoundaries')}
          </Button>
        </Tooltip>

        {!isMainLoading && (
          <Form.Item
            label={t('main')}
            name="is_main"
            initialValue={isMainChecked}
            shouldUpdate
            className="form-item-horizontal"
          >
            <Switch
              defaultChecked={mapId === isMain?.id}
              checked={isMainChecked}
              onChange={handleChangeMain}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={!isMain || mapId !== isMain?.id}
            />
          </Form.Item>
        )}
        <Form.Item
          label={t('floor')}
          name="floor"
          initialValue={singleMap?.floor || floorOptions?.[0]?.id}
          required
        >
          <Input shape="round" type="number" />
        </Form.Item>
        <Form.Item
          label={t('category')}
          name="category"
          initialValue={singleMap?.category?.id}
          required
        >
          <Select>
            {categories?.map(({ id, name }) => {
              return (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item hidden={true}>
          <Button hidden={true} ref={submitBtnRef} htmlType="submit">
            {!!mapId ? t('edit') : t('create')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
