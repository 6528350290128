import { Drawer } from 'antd';
import { connect } from 'react-redux';

import { useQuery } from 'hooks/query';
import { mapStore } from 'model/MapStore';
import { useState } from 'react';
import { setCenterCoordinates } from 'store/actions/crsMap';
import { UserDrawerBody } from './common/user-drawer-body';

function UserDrawerSandwich({
  visible,
  onClose,
  keeper: keeperInstance,
  onSelectKeeperToTrack,
}) {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const { setQuery } = useQuery();
  const [isHistoryVisible, setHistoryVisible] = useState(false);

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------
  // function
  // ------------------------------------------------------------------------------------------------------------

  const close = () => {
    onClose();
    setQuery({ showKeeperCamera: null });
    setHistoryVisible(false);
    mapStore.keeperHistoryInstance.clear();
  };

  // ------------------------------------------------------------------------------------------------------------
  return (
    <>
      <Drawer
        className={"map-drawer"}
        placement="left"
        closable={true}
        onClose={close}
        open={visible}
        width={350}
      >
        <UserDrawerBody
          keeperInstance={keeperInstance}
          onSelectKeeperToTrack={onSelectKeeperToTrack}
          isHistoryVisible={isHistoryVisible}
          setHistoryVisible={setHistoryVisible}
        />
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setKeeperCoordinates: (coordinates) =>
      dispatch(setCenterCoordinates(coordinates)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawerSandwich);
