import {
  SET_POLYGON,
  SET_COLOR,
  SET_NAME,
  ON_CHANGE,
  CLEAR_FORM,
  NOMINATE_TO_DELETE,
  GET_AREAS_REQUEST,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  CREATE_AREA_REQUEST,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAILURE,
  UPDATE_AREA_REQUEST,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAILURE,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_REQUEST,
  DELETE_AREA_FAILURE,
  CLEAR_AREAS, SET_IS_EDITING, CLEAR_UPDATING, SET_POINT, SET_POINT_CAMERA, SET_POINT_DEVICE,
} from '../actions/drawPolygons';

const formInitial = {
  name: null,
  polygon: null,
  color: null,
  deviceId: null,
  cameraId: null,
  cameraPoint: null,
  devicePoint: null
};

const initialState = {
  form: formInitial,
  areas: {
    data: [],
    fetching: false,
    error: null,
  },
  creation: {
    fetching: false,
    data: null,
    error: null,
  },
  updating: {
    isUpdating: false,
    data: null,
    fetching: false,
    error: null,
  },
  deleting: {
    selected: null,
    fetching: false,
    data: null,
    error: null,
  },
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POLYGON: return {
      ...state,
      form: {
        ...state.form,
        polygon: action.payload,
      }
    };
    case SET_COLOR: return {
      ...state,
      form: {
        ...state.form,
        color: action.payload,
      }
    };
    case SET_NAME: return {
      ...state,
      form: {
        ...state.form,
        name: action.payload,
      }
    };
    case SET_POINT_CAMERA: return {
      ...state,
      form: {
        ...state.form,
        cameraPoint: action.payload,
      }
    };
    case SET_POINT_DEVICE: return {
      ...state,
      form: {
        ...state.form,
        devicePoint: action.payload,
      }
    };
    case ON_CHANGE:{
      const { field, value } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          [field]: value,
        }
      };
    }
    case CLEAR_FORM: return {
      ...state,
      form: formInitial,
    };
    case NOMINATE_TO_DELETE: return {
      ...state,
      deleting: {
        ...state.deleting,
        selected: action.payload,
      }
    };
    case GET_AREAS_REQUEST: return {
      ...state,
      areas: {
        ...state.areas,
        fetching: true,
        data: [],
      }
    };
    case GET_AREAS_SUCCESS: return {
      ...state,
      areas: {
        ...state.areas,
        fetching: false,
        data: action.payload,
      }
    };
    case GET_AREAS_FAILURE: return {
      ...state,
      areas: {
        ...state.areas,
        fetching: false,
        error: action.payload,
      }
    };
    case CLEAR_AREAS: return {
      ...state,
      areas: {
        ...state.areas,
        data: [],
        fetching: false,
        error: null,
      },
    };
    case CREATE_AREA_REQUEST: return {
      ...state,
      creation: {
        ...state.creation,
        fetching: true,
        data: null,
        error: null,
      },
    };
    case CREATE_AREA_SUCCESS: return {
      ...state,
      creation: {
        ...state.creation,
        fetching: false,
        data: action.payload
      }
    };
    case CREATE_AREA_FAILURE: return {
      ...state,
      creation: {
        ...state.creation,
        fetching: false,
        error: action.payload,
      }
    };
    case UPDATE_AREA_REQUEST: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: true,
        data: null,
        error: null,
      },
    };
    case UPDATE_AREA_SUCCESS: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: false,
        data: action.payload
      }
    };
    case UPDATE_AREA_FAILURE: return {
      ...state,
      updating: {
        ...state.updating,
        fetching: false,
        error: action.payload,
      }
    };
    case SET_IS_EDITING: return {
      ...state,
      updating: {
        ...state.updating,
        isUpdating: action.payload,
      }
    };
    case CLEAR_UPDATING: return {
      ...state,
      updating: initialState.updating,
    };
    case DELETE_AREA_REQUEST: return {
      ...state,
      deleting: {
        ...state.deleting,
        fetching: true,
        data: null,
        error: null,
      }
    };
    case DELETE_AREA_SUCCESS: return {
      ...state,
      deleting: {
        ...state.deleting,
        fetching: false,
        data: action.payload,
      }
    };
    case DELETE_AREA_FAILURE: return {
      ...state,
      deleting: {
        ...state.deleting,
        fetching: false,
        error: action.payload,
      }
    };
    default: return state;
  }
};

export default reducer;
