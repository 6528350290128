import client from '../helpers/client';
import store from '../store';
import {
    setKeepersData,
    setKeepersError,
    setKeepersLoading,
} from '../store/actions/keepers';

export const getKeepers = (headers) => {
    store.dispatch(setKeepersLoading(true));
    return client
        .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/keepers/', {
            params: headers,
        })
        .then((res) => {
            store.dispatch(setKeepersData(res.data));
            return res;
        })
        .catch((err) => {
            store.dispatch(setKeepersError(err));
        })
        .finally(() => {
            store.dispatch(setKeepersLoading(false));
        });
};

export const fetchKeepers = () =>
    client
        .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/keepers/', {
            params: {
                embed: 'tag',
                filter: '[deleted==false;name=@;type==EMPLOYEE]',
                sort: 'name',
            },
        })
        .then((res) => res?.data?.items || [])
        .catch((e) => {
            console.error(e);
            return [];
        });

export const getKeeperHistory = (id, start_time, end_time) =>
    client
        .get(
            process.env.REACT_APP_LOCAL_API_BASE_URL +
            // `/keepers/${id}/movement_history`,
            `/keeper_movement_history_records/`,
            {
                params: {
                    keeper_id: id,
                    created_at__range: `${start_time},${end_time}`
                }
            }
            // { params: { start_time, end_time } }
        )
        .then((res) => {
            return res?.data || []
        })
        .catch((e) => {
            console.error(e);
            return [];
        });
