export const STATE_KEY = '@FORMS';

export const INIT_FORM = `${STATE_KEY}_INIT_FORM`;
export const SET_FORM_VALUE = `${STATE_KEY}_SET_FORM_VALUE`;
export const CLEAR_FORM = `${STATE_KEY}_CLEAR_FORM`;
export const SET_FORM_INITIAL_VALUES = `${STATE_KEY}_SET_FORM_INITIAL_VALUES`;
export const REMOVE_FORM = `${STATE_KEY}_REMOVE_FORM`;


export const initForm = payload => ({
  type: INIT_FORM,
  payload,
});
export const setFormValue = payload => ({
  type: SET_FORM_VALUE,
  payload,
});
export const clearForm = payload => ({
  type: CLEAR_FORM,
  payload,
});
export const setFormInitialValues = payload => ({
  type: SET_FORM_INITIAL_VALUES,
  payload
});
export const removeForm = payload => ({
  type: REMOVE_FORM,
  payload,
});
