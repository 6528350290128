import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getMapByIdRequest } from '../../store/actions/svgMap';
import { makeSelectGetSingleMap } from '../../store/selectors/svgMap';
import DrawMapPolygons from './DrawMapPolygons';

const DrawPolygons = () => {
  const singleMap = useSelector(makeSelectGetSingleMap);

  const dispatch = useDispatch();
  const { mapId } = useParams();

  useEffect(() => {
    dispatch(getMapByIdRequest(mapId));
  }, [mapId]);

  return <>{!!singleMap && <DrawMapPolygons image={singleMap.file} />}</>;
};

export default DrawPolygons;
