import {
    GET_LIST_SUCCESS,
    GET_LIST_REQUEST,
    GET_LIST_FAILURE,
    GET_EVENT_TYPE_REQUEST,
    GET_EVENT_TYPE_SUCCESS,
    GET_EVENT_TYPE_FAILURE,
    SET_IS_CREATE_FORM_OPEN,
    SET_IS_EDIT_FORM_OPEN,
    CREATION_REQUEST,
    CREATION_SUCCESS,
    CREATION_FAILURE,
    EDITING_REQUEST,
    EDITING_SUCCESS,
    EDITING_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    ASK_TO_DELETE,
  } from '../actions/settings';
  
  const initialState = {
    list: {
      data: [],
      fetching: false,
      error: null,
      count: 0,
    },
    eventTypes: {
      data: [],
      fetching: false,
      error: null,
    },
  
    isCreateFormOpen: false,
    isEditFormOpen: false,
  
    creation: {
      fetching: false,
      error: null,
    },
    editing: {
      fetching: false,
      error: null,
    },
    deleting: {
      selected: null,
      fetching: false,
      error: null,
    },
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LIST_REQUEST:
        return {
          ...state,
          list: {
            ...state.list,
            fetching: true,
          },
        };
      case GET_LIST_SUCCESS:
        return {
          ...state,
          list: {
            fetching: false,
            data: action.payload.results,
            count: action.payload.count,
          },
        };
      case GET_LIST_FAILURE:
        return {
          ...state,
          list: {
            ...state.list,
            fetching: false,
            error: action.payload,
          },
        };
  
      case GET_EVENT_TYPE_REQUEST:
        return {
          ...state,
          eventTypes: {
            ...state.eventTypes,
            fetching: false,
          },
        };
      case GET_EVENT_TYPE_SUCCESS:
        return {
          ...state,
          eventTypes: {
            ...state.eventTypes,
            fetching: false,
            data: action.payload,
          },
        };
      case GET_EVENT_TYPE_FAILURE:
        return {
          ...state,
          eventTypes: {
            ...state.eventTypes,
            fetching: false,
            error: action.payload,
          },
        };
  
      case SET_IS_CREATE_FORM_OPEN:
        return {
          ...state,
          isCreateFormOpen: action.payload,
        };
      case SET_IS_EDIT_FORM_OPEN:
        return {
          ...state,
          isEditFormOpen: action.payload,
        };
  
      case CREATION_REQUEST:
        return {
          ...state,
          creation: {
            ...state.creation,
            fetching: true,
            error: null,
          },
        };
      case CREATION_SUCCESS:
        return {
          ...state,
          creation: {
            ...state.creation,
            fetching: false,
          },
        };
      case CREATION_FAILURE:
        return {
          ...state,
          creation: {
            ...state.creation,
            fetching: false,
            error: action.payload,
          },
        };
      case EDITING_REQUEST:
        return {
          ...state,
          editing: {
            ...state.editing,
            fetching: true,
            error: null,
          },
        };
      case EDITING_SUCCESS:
        return {
          ...state,
          editing: {
            ...state.editing,
            fetching: false,
          },
        };
      case EDITING_FAILURE:
        return {
          ...state,
          editing: {
            ...state.editing,
            fetching: false,
            error: action.payload,
          },
        };
  
      case ASK_TO_DELETE:
        return {
          ...state,
          deleting: {
            ...state.deleting,
            selected: action.payload,
          },
        };
      case DELETE_REQUEST:
        return {
          ...state,
          deleting: {
            ...state.deleting,
            fetching: true,
            error: null,
          },
        };
      case DELETE_SUCCESS:
        return {
          ...state,
          deleting: {
            ...state.deleting,
            fetching: false,
          },
        };
      case DELETE_FAILURE:
        return {
          ...state,
          deleting: {
            ...state.deleting,
            fetching: false,
            error: action.payload,
          },
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  