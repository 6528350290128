import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as AnchorLogo } from 'assets/icons/anchor-icon.svg';
import { ReactComponent as AnchorRedLogo } from 'assets/icons/anchor-red-icon.svg';
import { ReactComponent as AreaLogo } from 'assets/icons/area-icon.svg';
import { ReactComponent as DisabledGoalIcon } from 'assets/icons/goal-disabled.svg';
import { ReactComponent as GoalLogo } from 'assets/icons/goal.svg';
import { ReactComponent as ObjectLogo } from 'assets/icons/object-icon.svg';
import { ReactComponent as SosLogo } from 'assets/icons/sos.svg';
import { ReactComponent as TransportNotActiveLogo } from 'assets/icons/transport-not-active.svg';
import { ReactComponent as TransportLogo } from 'assets/icons/transport.svg';
import { ReactComponent as UserLogoNotActive } from 'assets/icons/user-icon-not-active.svg';
import { ReactComponent as UserLogo } from 'assets/icons/user-icon.svg';

import { Tooltip } from 'antd';
import {
  ANCHORS,
  AREAS,
  EMPLOYEES,
  ITEMS,
  TRANSPORT,
  USERS,
} from 'consts/options';
import { toShowMapId } from 'consts/routes';
import { useUserDrawer } from 'context/user-drawer/user-drawer.hook';
import {
  errorNotification,
  successNotification,
} from 'helpers/notificationHelpers';
import { mapStore } from 'model/MapStore';
import { useTranslation } from 'react-i18next';
import { sendSos } from 'services/sos';
import {
  makeSelectSearchData,
  makeSelectSearchDataLoading,
} from 'store/selectors/search';
import Spinner from '../../Spinner';
import '../index.css';

function SearchResult({ objectType, visible }) {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const tempDataList = useSelector(makeSelectSearchData);
  const loading = useSelector(makeSelectSearchDataLoading);
  const history = useHistory();
  const userDrawer = useUserDrawer();
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);

  // ------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------

  useEffect(() => {
    setDataList(tempDataList);
  }, [tempDataList]);

  useEffect(() => {
    if (!visible || objectType !== EMPLOYEES) return;

    const subscription = mapStore.keepersByMap$.subscribe((allKeepers) => {
      if (!visible || objectType !== EMPLOYEES) return;

      const employeesArr = [];
      Object.keys(allKeepers).forEach((mapId) => {
        for (let keeperItem in allKeepers[mapId]) {
          const keeper = allKeepers[mapId][keeperItem];
          const employees = keeper.data.employees;
          employeesArr.push(...employees);
        }
      });
      //
      const resEmployeeArr = [...(dataList ?? [])];
      for (const employee of employeesArr) {
        const existEmployee = resEmployeeArr.find(
          (dataItem) => dataItem.id === employee.id
        );
        if (!existEmployee) continue;
        const isStateUpdated = employee.is_active !== existEmployee.is_active;

        if (isStateUpdated) {
          const index = resEmployeeArr.indexOf(existEmployee);
          resEmployeeArr[index] = employee;
          setDataList(resEmployeeArr);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [objectType, visible, dataList]);
  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------

  // async function onClickCenterEmployeeOnMap(item) {
  //   const responce = await request.get(
  //     `/employees/${item.id}/get_located_layouts`
  //   );
  //   const data = responce.data;
  //   if (data.length === 0) {
  //     errorNotification('На картах не найден');
  //     return;
  //   }
  //   const firstMap = data[0];
  //   history.push(toShowMapId(firstMap.id));
  // }

  const handleClickSendSos = (/** @type {string} */ id) => {
    sendSos(id)
      .then(() => {
        successNotification(t('signalSent'));
      })
      .catch((err) => {
        errorNotification(t('signalNotSendRepaid'));
        throw err;
      });
  };

  const list = useMemo(() => {
    if (objectType === ANCHORS) {
      return mapStore.setAnchors(dataList);
    }
    if (objectType === EMPLOYEES) {
      const list = [];
      const index = {};
      dataList?.forEach((e) => {
        e.userName = `${e.surname || ''} ${e.name || ''}`.trim();
        const mark = e.mark;
        if (mark) {
          const keeper = mapStore.keeperByMark[mark];
          if (keeper?.id) {
            if (!index[keeper.id]) {
              index[keeper.id] = 1;
            }
            const maps = mapStore.mapsByKeeper[keeper.id];
            e.mapId = Object.values(maps || {})[0]?.id;
          }
        }
        list.push(e);
      });

      return list.sort((a, b) => {
        if (a.userName > b.userName) return 1;
        if (a.userName < b.userName) return -1;
        return 0;
      });
    }
    if (objectType === AREAS) {
      return dataList;
    }

    if (objectType === TRANSPORT) {
      const list = [];
      const index = {};
      dataList?.forEach((e) => {
        const mark = e.transport_mark;
        if (mark) {
          const keeper = mapStore.keeperByMark[mark];
          if (keeper?.id) {
            if (!index[keeper.id]) {
              index[keeper.id] = 1;
            }
            const maps = mapStore.mapsByKeeper[keeper.id];
            e.mapId = Object.values(maps || {})[0]?.id;
            e.keeper = keeper;
          }
        }
        list.push(e);
      });

      return list.sort((a, b) => {
        if (a.userName > b.userName) return 1;
        if (a.userName < b.userName) return -1;
        return 0;
      });
    }
    return dataList;
  }, [dataList, objectType]);

  // ------------------------------------------------------------------------------------
  return !loading ? (
    <>
      {!!list?.length ? (
        list.map((item, idx) => {
          return (
            <div key={idx} className="search-result__content">
              <div className="user-logo">
                {objectType === ITEMS && (
                  <ObjectLogo
                    style={{
                      flex: '1 0 0%',
                    }}
                  />
                )}

                {objectType === TRANSPORT && (
                  <>
                    {item.is_active ? (
                      <TransportLogo
                        onClick={() => {
                          userDrawer.open({
                            keeper: {
                              instance: item?.keeper,
                            },
                          });
                        }}
                        style={{
                          flex: '1 0 0%',
                          cursor: 'pointer',
                        }}
                      />
                    ) : (
                      <Tooltip title="Транспорт не активен">
                        <TransportNotActiveLogo
                          onClick={() => {
                            userDrawer.open({
                              keeper: {
                                holder: {
                                  id: item.id,
                                  type: 'VEHICLE',
                                },
                              },
                            });
                          }}
                          style={{
                            flex: '1 0 0%',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}

                {(objectType === EMPLOYEES || objectType === USERS) && (
                  <>
                    {item.is_active ? (
                      <UserLogo
                        onClick={() => {
                          if (objectType === EMPLOYEES) {
                            userDrawer.open({
                              keeper: {
                                holder: {
                                  id: item.id,
                                  type: 'EMPLOYEE',
                                },
                              },
                            });
                          }
                        }}
                        style={{
                          flex: '1 0 0%',
                          cursor: 'pointer',
                        }}
                      />
                    ) : (
                      <Tooltip title="Сотрудник не активен">
                        <UserLogoNotActive
                          onClick={() => {
                            if (objectType === EMPLOYEES) {
                              userDrawer.open({
                                keeper: {
                                  holder: {
                                    id: item.id,
                                    type: 'EMPLOYEE',
                                  },
                                },
                              });
                            }
                          }}
                          style={{
                            flex: '1 0 0%',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
                {objectType === ANCHORS && (
                  <>
                    {item.status === 'UP' ? (
                      <AnchorLogo
                        style={{
                          flex: '1 0 0%',
                        }}
                      />
                    ) : (
                      <AnchorRedLogo
                        style={{
                          flex: '1 0 0%',
                        }}
                      />
                    )}
                  </>
                )}
                {objectType === AREAS && (
                  <AreaLogo
                    style={{
                      flex: '1 0 0%',
                    }}
                  />
                )}
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                    // lightHeight: '16px',
                    flex: '1 0 55%',
                  }}
                >
                  {objectType === EMPLOYEES && (
                    <Fragment>{item.userName}</Fragment>
                  )}
                  {objectType === USERS && (
                    <Fragment>
                      {item.last_name} {item.first_name}
                    </Fragment>
                  )}
                  {objectType === ITEMS && (
                    <Fragment>{item._links?.self?.label}</Fragment>
                  )}
                  {objectType === ANCHORS && <Fragment>{item.sn}</Fragment>}
                  {objectType === AREAS && <Fragment>{item.name}</Fragment>}
                  {objectType === TRANSPORT && (
                    <Fragment>{item.description}</Fragment>
                  )}
                </p>
              </div>
              <div className="goal-sos-logo">
                {/*{objectType !== USERS && <GoalLogo />}*/}
                {objectType === ANCHORS &&
                  (item.mapId ? (
                    <GoalLogo
                      onClick={() => {
                        mapStore.setSelectedAnchor(item);
                        history.push(toShowMapId(item.mapId));
                      }}
                    />
                  ) : (
                    <Tooltip title={t('notFoundOnMaps')}>
                      <DisabledGoalIcon />
                    </Tooltip>
                  ))}
                {objectType === AREAS &&
                  (item.layout ? (
                    <GoalLogo
                      onClick={() => {
                        mapStore.layers.areas = 'all';
                        history.push(toShowMapId(item.layout));
                      }}
                    />
                  ) : (
                    <Tooltip title={t('notFoundOnMaps')}>
                      <DisabledGoalIcon />
                    </Tooltip>
                  ))}
                {objectType === EMPLOYEES && (
                  <>
                    {item.is_active && (
                      <SosLogo onClick={() => handleClickSendSos(item.id)} />
                    )}

                    {item?.mapId ? (
                      <GoalLogo
                        onClick={() => history.push(toShowMapId(item.mapId))}
                      />
                    ) : (
                      <Tooltip title={t('notFoundOnMaps')}>
                        <DisabledGoalIcon />
                      </Tooltip>
                    )}
                  </>
                )}

                {objectType === TRANSPORT && (
                  <>
                    {item?.mapId ? (
                      <GoalLogo
                        onClick={() => history.push(toShowMapId(item.mapId))}
                      />
                    ) : (
                      <Tooltip title={t('notFoundOnMaps')}>
                        <DisabledGoalIcon />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p className="empty-text">{t('listIsEmpty')}</p>
      )}
    </>
  ) : (
    <Spinner />
  );
}

export default SearchResult;
