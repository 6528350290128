import { BellOutlined } from '@ant-design/icons';
import { Badge, Popover, Tooltip } from 'antd';
import { useNotifications } from 'context/notification/notification-hooks/notification.hook';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../index.css';
import BellPopoverContent from './BellPopoverContent';

const BellPopover = () => {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const notification = useNotifications();

  // ------------------------------------------------------------------------------------
  return (
    <Popover
      placement="bottomRight"
      overlayClassName="notifications-header__popover"
      onVisibleChange={setShow}
      content={<BellPopoverContent show={show} />}
      trigger="click"
    >
      <Badge size="small" count={notification.notReadedCount}>
        <Tooltip title={t('notifications')}>
          <BellOutlined
            style={{
              color: '#fff',
              fontSize: 28,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </Badge>
    </Popover>
  );
};

export default BellPopover;
