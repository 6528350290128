import L from 'leaflet';

L.MovingMarker = L.Marker.extend({
  options: {
    autoStart: true,
    onEnd: () => {},
    animationDistance: 10000,
    speed: 1
  },

  polylinePoints: [],

  initialize: function(latLngs, options) {
    Object.assign(this.options, options);
    this.setLine(latLngs);
    L.Marker.prototype.initialize.call(this, latLngs[0], options);
  },

  setLine: function(latLngs) {
    if (latLngs instanceof Array) {
      this.pointIndex = 0;
      this.polylinePoints = [];
      latLngs.forEach(([lat1, lng1, time1], i) => {
        if (latLngs[i + 1]) {
          const [lat2, lng2, time2] = latLngs[i + 1];
          const distance = new L.LatLng(lat1, lng1).distanceTo(new L.LatLng(lat2, lng2));
          if (distance) {
            let amount = distance / this.options.animationDistance;
            const interval = (time2 - time1) / amount;
            const distancePart = this.options.animationDistance / distance;
            let pointTime = time1;
            let progress = 0;
            for(let i = 0; i < amount; i++) {
              this.polylinePoints.push([
                lat1 + progress * (lat2 - lat1),
                lng1 + progress * (lng2 - lng1),
                pointTime
              ]);
              pointTime += interval;
              progress += distancePart;
            }
          }
        } else {
          this.polylinePoints.push([lat1, lng1, time1]);
        }
      });
    }
  },

  onAdd: function(map) {
    L.Marker.prototype.onAdd.call(this, map);
    if (this.options.autoStart) {
      this.start();
    }
  },

  pointIndex: 0,
  timeoutId: [],

  animate() {
    if (this.polylinePoints[this.pointIndex] && this.polylinePoints[this.pointIndex + 1]) {
      const time1 = this.polylinePoints[this.pointIndex][2];
      const [lat2, lng2, time2] = this.polylinePoints[this.pointIndex + 1];
      this.timeoutId.push(setTimeout(() => {
        this.pointIndex += 1;
        this.setLatLng(new L.LatLng(lat2, lng2));
        this.animate();
      }, (time2 - time1) / this.options.speed));
    } else {
      this.pointIndex = 0;
      this.onEnd?.();
    }
  },

  start(speed) {
    if (speed) {
      this.options.speed = speed;
    }
    this.animate();
  },

  pause() {
    for (let i = 0; i < this.timeoutId.length; i++) {
      clearTimeout(this.timeoutId[i]);
    }
    this.timeoutId = [];
    return this.polylinePoints[this.pointIndex];
  },

  stop() {
    this.pointIndex = 0;
    this.remove();
    if (this.polylinePoints[0]) {
      this.setLatLng(this.polylinePoints[0]);
    }
    for (let i = 0; i < this.timeoutId.length; i++) {
      clearTimeout(this.timeoutId[i]);
    }
    this.timeoutId = [];
  },

  moveToTime(time) {
    const index = this.polylinePoints.findIndex((point) => point[2] >= time);
    if (index !== -1) {
      this.pointIndex = index;
      const [lat, lng, time] = this.polylinePoints[index];
      this.setLatLng([lat, lng]);
      return time;
    }
  }

});

L.movingMarker = function(latLngs, options) {
  return new L.MovingMarker(latLngs, options);
};
