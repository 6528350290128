const submit = (formValues, instance) => {
  const formData = new FormData();

  const isViolator = formValues['is_violator']
  const violatorTerm = formValues['violator_term']
  if (isViolator && !violatorTerm) {
    formData.append('violator_term', null);
  }

  Object.entries(formValues).forEach(([key, value]) => {
    if (!value || value === '' || value === 'null' || value === null) return;
    formData.append(key, value);
  });

  return formData;
};

export default submit;
