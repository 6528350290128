import {
  SET_LABELS_FETCHING,
  SET_LABELS_DATA,
  SET_LABELS_ERROR,
  SET_IS_USER_DRAWER_VISIBLE,
  SET_FAVORITE_DRAWER,
} from '../actions/labels';

const reqData = { fetching: false, data: null, error: null };

const initialState = {
  labelsData: reqData,
  isUserDrawerVisible: false,
  isFavoriteDrawerVisible: false,
};

export default function labels(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LABELS_FETCHING:
      return {
        ...state,
        labelsData: { ...state.labelsData, fetching: payload },
      };
    case SET_LABELS_DATA:
      return {
        ...state,
        labelsData: { ...state.labelsData, data: payload },
      };
    case SET_FAVORITE_DRAWER:
      return {
        ...state,
        isFavoriteDrawerVisible: !state.isFavoriteDrawerVisible,
      };
    case SET_LABELS_ERROR:
      return {
        ...state,
        labelsData: { ...state.labelsData, error: payload },
      };
    case SET_IS_USER_DRAWER_VISIBLE:
      return {
        ...state,
        isUserDrawerVisible: payload,
      }
    default:
      return state;
  }
}
