import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';

export const ContentLayoutLink = ({ to, onClick, logo, text, divider }) => {
  return (
    <>
      <Link to={to} onClick={onClick}>
        <p className="d-flex-center-center auth-container__link-wrapper">
          {logo}
          <span>{text}</span>
        </p>
      </Link>
      {divider && <Divider />}
    </>
  );
};
