import { DEFAULT_PAGE_SIZE } from 'components/Pagination/constants';
import { getQueryParams } from 'hooks/query';
import { ParamsHelper } from './params';

export const ReportHelper = {
  sortByDate: (date) =>
    date.sort((a, b) => a.created_at.localeCompare(b.created_at)),
  isDateRangeNotDefined: (
    /** @type {{ fromDate?: string; toDate: string; }} */ queryParams
  ) => {
    return (
      typeof queryParams.fromDate === 'undefined' ||
      queryParams.fromDate === '0' ||
      typeof queryParams.toDate === 'undefined' ||
      queryParams.toDate === '0'
    );
  },

  isCoordinatesExist: (record) => {
    if (!record || !record.coordinates || !record.layout.id) return false;
    const { x, y, z } = record.coordinates;
    return (
      x &&
      typeof x === 'number' &&
      y &&
      typeof y === 'number' &&
      z &&
      typeof z === 'number'
    );
  },
  /**
   * @param query {{[key in string] : string}}
   * @param mode {"load" | "export" | undefined}
   */
  params: (query, mode) => {
    const queryParams = getQueryParams();

    let filterParams;
    if (queryParams.id) {
      const fromDate = new Date(Number(queryParams.fromDate)).toISOString();
      const toDate = new Date(Number(queryParams.toDate)).toISOString();

      filterParams = ParamsHelper.exclude(
        {
          created_at__range: `${fromDate},${toDate}`,
          ...queryParams,
          employees: queryParams.id,
        },
        ['tab', 'fromDate', 'toDate', 'id']
      );
    } else if (ReportHelper.isDateRangeNotDefined(query)) {
      filterParams = ParamsHelper.exclude(queryParams, [
        'tab',
        'fromDate',
        'toDate',
        'id',
      ]);
    } else {
      const fromDate = new Date(Number(queryParams.fromDate)).toISOString();
      const toDate = new Date(Number(queryParams.toDate)).toISOString();

      filterParams = ParamsHelper.exclude(
        {
          created_at__range: `${fromDate},${toDate}`,
          ...queryParams,
        },
        ['tab', 'fromDate', 'toDate', 'id']
      );
    }
    filterParams = ParamsHelper.excludeWithValues(filterParams, ['_none']);
    const params = {
      page: queryParams.page ?? null,
      size: queryParams.size ?? null,
      ...filterParams,
    };

    if (params.page === 1 || mode === 'export') {
      params.page = null;
    }

    if (params.size === DEFAULT_PAGE_SIZE || mode === 'export') {
      params.size = null;
    }

    return params;
  },
};
