import { useTranslation } from 'react-i18next';

export const useEventTypeNameMapper = () => {
  const { t } = useTranslation();
  return eventTypeNameMapped(t);
};

export const eventTypeNameMapped = (t) => {
  return {
    PARKING_START: t('parkingStart'),
    PARKING_END: t('parkingEnd'),
    DANGER_ZONE_ENTER: t('dangerZoneEntr'),
    DANGER_ZONE_EXIT: t('dangerZoneExit'),
    danger_zone_exit: t('dangerZoneExit'),
    danger_zone_entrance: t('dangerZoneEntr'),
    DANGER_ZONE_EMPTY: t('dangerZoneEmpty'),
    DANGER_ZONE_NONEMPTY: t('dangerZoneNotEmpty'),
    BLINK: t('blink'),
    AREA_ENTER: t('areasEnter'),
    AREA_EXIT: t('areasExit'),
    SPEED_EXCEED: t('overSpeed'),
    UNIDENTIFIED_ENTER: t('unknownObjAccEnt'),
    ITV_COUNTER: t('ITVCounter'),
    EXPORT: t('reportFileCreated'),
    WRONG_ORIENTATION: t('wrongPoss'),
    WRONG_ORIENTATION_CANCEL: t('wrongPossCancel'),
    MAN_DOWN: t('manWithoutMove'),
    MAN_DOWN_CANCEL: t('manWithoutMoveCancel'),
    BATTERY_LOW: t('batteryLow'),
    NO_SIGNAL: t('noSignal'),
    FREE_FALL: t('theFall'),
    IMPACT: t('impact'),
    IN_DIG_ON: t('digitInOn'),
    IN_DIG_OFF: t('digitInOff'),
    BUTTON_PRESSED: t('btnPressed'),
    PAIRING_START: t('pairingStart'),
    PAIRING_END: t('pairingEnd'),
    TAG_SIGNAL_REQUEST: t('reqSignalToTag'),
    SITE_ENTER: t('siteEnter'),
    SITE_EXIT: t('siteExit'),
    LE_UP: t('leUp'),
    LE_DOWN: t('leDown'),
    ANCHOR_UP: t('anchorUp'),
    ANCHOR_DOWN: t('anchorDown'),
    level_entrance: t('levelEntr'),
    level_exit: t('levelExit'),
    areas_exit: t('areasExit'),
    areas_entrance: t('areasEnter'),
    REPORT_EXPORT_DONE: t('reportExportDone'),
    REPORT_EXPORT_IN_PROCESS: t('reportExportInProcess'),
    REPORT_EXPORT_CANCEL: t('reportExportCancel'),
    HIGH_HEART_RATE: t("reportHighHeartRate"),
    LOW_HEART_RATE: t("reportLowHeartRate"),
    ANCHORT_STATE_UP: t("anchortStateUp"),
    ANCHORT_STATE_DOWN: t("anchortStateDown")
  };
};

export const normalizeFields = (config) =>
  config.reduce((memo, curr) => {
    if (curr.type === 'file') {
      return Object.assign(memo, { [curr.name]: { file: null, url: null } });
    }
    if (curr.type === 'boolean') {
      return Object.assign(memo, { [curr.name]: Boolean(curr.defaultChecked) });
    }
    return Object.assign(memo, { [curr.name]: '' });
  }, {});
