import { BehaviorSubject, Subject } from 'rxjs';
import { getAnchors } from '../services/anchors';
import { getAreas } from '../services/areas';
import { getDevices } from '../services/devices';
import { fetchKeepers } from '../services/keepers';
import { fetchIcons, fetchMaps } from '../services/maps';
import { Area } from './Area';
import { Keeper } from './Keeper';
import { KeeperHistory } from './KeeperHistory';
import { MapData } from './MapData';

export class MapStore {
  userId;
  userId$ = new Subject('');

  maps = {};
  maps$ = new Subject();
  mapList = [];

  keepers = {};

  mapsByKeeper = {};
  keepersByMap = {};
  keepersByMap$ = new Subject();

  keeperByMark = {};
  keeperByMark$ = new Subject();

  keepersTimeout;

  currentMapId;

  currentMapAreas;
  currentMapAreas$ = new Subject();

  currentMapKeeperList = [];
  mapKeeperList$ = new Subject();

  cameras = [];
  cameras$ = new Subject();

  currentCameraId;
  currentCameraId$ = new Subject();

  devices = [];
  devices$ = new Subject();

  trackedKeeper = {};

  selectedKeeperId = null;
  selectedKeeperId$ = new Subject();

  selectedKeeperInCameraArea;
  selectedKeeperInCameraArea$ = new Subject();
  keeperCameraAreas;

  keeperHistory;
  keeperHistory$ = new Subject(); // history index
  currentHistoryIndex;

  playKeeperHistory$ = new Subject(); // {mapId, time}
  keeperHistoryByMap = {}; // {[mapId]: {[timeStart]: point[]}}
  historyPoint;

  keeperHistoryInstance;

  anchors = {};
  anchorList = [];
  anchorList$ = new Subject();
  filteredAnchors = {};
  filteredAnchorList = [];
  selectedAnchor;
  selectedAnchor$ = new BehaviorSubject(null);

  layers = {
    areas: '',
    anchors: false,
    users: true,
    title: true,
  };
  layers$ = new Subject();

  center$ = new Subject(); // center coordinates

  setLayerState(name, value) {
    this.layers = {
      ...this.layers,
      [name]: value,
    };
    this.layers$.next(this.layers);
  }

  constructor() {
    this.fetchMaps()
      .then(() => fetchIcons())
      .then(() => this.getDevices())
      // .then(() => this.getCameras())
      .then(() => this.fetchAreas())
      .then(() => this.fetchKeepers())
      .then(() => this.fetchAnchors())
      .then(() => this.setTimeout());
    this.keeperHistoryInstance = new KeeperHistory(this);
  }

  setMapId(mapId) {
    if (mapId) {
      this.currentMapId = mapId;
      this.currentMapKeeperList = Object.values(
        this.keepersByMap[this.currentMapId] || {}
      );
      this.mapKeeperList$.next(this.currentMapKeeperList);

      if (this.maps[mapId]) {
        const map = this.maps[mapId];
        if (Object.keys(map.areas).length) {
          this.currentMapAreas$.next(map.areas);
        }
      }
    }
  }

  center([lat, lng]) {
    this.center$.next([lat, lng]);
  }

  playHistory(play) {
    this.playKeeperHistory$.next(play);
  }

  setKeeperHistory(history) {
    const mapHistory = [
      // {
      //   mapId: '',
      //   points: [],
      //   startTime: '2022-04-07T00:05:07.775+06:00',
      //   endTime: '2022-04-07T00:05:07.775+06:00'
      // }
    ];
    let currentMap;
    let lastCurrentMapPoint;
    let coords;
    let currentMapHistoryIndex;

    const getMap = (point) => {
      let k = 0;
      while (
        k < this.mapList.length &&
        this.mapList[k].id !== currentMap?.mapId
      ) {
        const map = this.mapList[k];
        if (map.contains(coords)) {
          point.x = coords.x;
          point.y = coords.y;
          return map;
        }
        k++;
      }
    };

    let lastIndex;
    history?.forEach((point, i) => {
      coords = {
        xM: point.params.x,
        yM: point.params.y,
        z: point.params.z,
      };

      let map;
      if (currentMap?.mapId) {
        map = this.maps[currentMap.mapId];
        if (map?.contains(coords)) {
          point.x = coords.x;
          point.y = coords.y;
          currentMap.points.push(point);
          lastCurrentMapPoint = point;
        } else {
          map = getMap(point);
          if (map) {
            if (
              currentMap &&
              currentMap.mapId !== map.id &&
              lastCurrentMapPoint?.time
            ) {
              currentMap.endTime = lastCurrentMapPoint.time;
            }
            currentMap = {
              mapId: map.id,
              startTime: point.time,
              points: [point],
            };
            mapHistory.push(currentMap);
            lastCurrentMapPoint = point;
          }
        }
      } else {
        map = getMap(point);
        if (map) {
          currentMap = {
            mapId: map.id,
            startTime: point.time,
            points: [point],
          };
          lastIndex = mapHistory.push(currentMap) - 1;
          lastCurrentMapPoint = point;
        }
      }
      if (
        this.currentHistoryIndex === undefined &&
        map?.id === this.currentMapId
      ) {
        this.currentHistoryIndex = lastIndex;
      }
    });

    this.keeperHistory = mapHistory;
    this.keeperHistory$.next(this.currentHistoryIndex || 0);
  }

  enableHistory() {
    this.keeperHistoryInstance.enable(true);
  }

  setSelectedKeeperId(id) {
    this.setKeeperInCameraArea(id);
    this.selectedKeeperId = id;
    this.selectedKeeperId$.next(id);
    this.keeperHistoryInstance.clear();
  }

  setKeeperInCameraArea(keeperId) {
    this.selectedKeeperInCameraArea = false;
    this.keeperCameraAreas = [];
    if (keeperId && this.currentMapId) {
      const map = this.mapsByKeeper[keeperId]?.[this.currentMapId];
      if (map) {
        const cameraAreas = Object.values(map.areas).filter(
          (area) => area.type === 'camera'
        );
        const keeper = this.keepers[keeperId];
        if (keeper && cameraAreas.length) {
          const keeperCameraAreas = cameraAreas.filter((area) =>
            area.contains(keeper)
          );
          if (keeperCameraAreas.length) {
            this.selectedKeeperInCameraArea = true;
            this.keeperCameraAreas = keeperCameraAreas;
          }
        }
      }
    }
    this.selectedKeeperInCameraArea$.next(this.selectedKeeperInCameraArea);
  }

  // getCameras() {
  // return getCameras().then((cameras) => {
  //   this.cameras = cameras;
  //   this.cameras$.next(cameras);
  // });
  // }

  openCamera() {
    if (this.keeperCameraAreas[0]?.camera_id) {
      this.currentCameraId$.next(this.keeperCameraAreas[0].camera_id);
    }
  }

  getDevices() {
    return getDevices().then((devices) => {
      this.devices = devices;
      this.devices$.next(devices);
    });
  }

  fetchKeepers() {
    return fetchKeepers().then((data) => {
      this.updateKeepers(data);
      this.keepersByMap$.next(this.keepersByMap);
      this.mapKeeperList$.next(this.currentMapKeeperList);
      this.keeperByMark$.next(this.keeperByMark);
    });
  }

  fetchMaps() {
    return fetchMaps().then((data) => {
      this.updateMaps(data);
      this.maps$.next(this.maps);
    });
  }

  fetchAreas() {
    return getAreas().then((areas) => {
      if (areas?.length) {
        if (!this.areas) {
          this.areas = {};
        }
        areas.forEach((data) => {
          if (data.layout && this.maps[data.layout]) {
            const map = this.maps[data.layout];
            this.areas[data.id] = new Area(map, data);
            map.updateArea(data);
          }
        });
        if (this.currentMapId && this.maps[this.currentMapId]) {
          const map = this.maps[this.currentMapId];
          if (Object.keys(map.areas).length) {
            this.currentMapAreas$.next(map.areas);
          }
        }
      }
    });
  }

  fetchAnchors() {
    return getAnchors().then((anchors) => this.updateAnchors(anchors));
  }

  setTimeout() {
    if (!this.keepersTimeout) {
      this.keepersTimeout = setTimeout(() => {
        this.fetchKeepers()
          .then(() => this.fetchAnchors())
          .then(() => {
            this.keepersTimeout = null;
            this.setTimeout();
          });
      }, 2000);
    }
  }

  updateMap(data) {
    const { bounds, point1, x, y, min_z, max_z, id } = data || {};
    if (
      id &&
      // bounds?.length &&
      point1?.lng !== undefined &&
      point1?.lat !== undefined &&
      x !== undefined &&
      y !== undefined &&
      min_z !== undefined &&
      max_z !== undefined
    ) {
      if (this.maps[id]) {
        this.maps[id].update(data);
      } else {
        this.maps[id] = new MapData(data);
        this.mapList.push(this.maps[id]);
      }
    }
  }

  updateMaps(list) {
    if (Array.isArray(list) && list.length) {
      list.forEach((map) => {
        this.updateMap(map);
      });
    }
  }

  getMapByPoint(x, y, z) {
    if (x !== undefined && y !== undefined && z !== undefined) {
      return;
    }
    return null;
  }

  updateKeeper(keeper) {
    const { status, x, y, z, id } = keeper?._embedded?.tag || {};
    if (
      keeper.id &&
      status !== 'NO_SIGNAL' &&
      x !== undefined &&
      y !== undefined &&
      z !== undefined
    ) {
      if (this.keepers[keeper?.id]) {
        this.keepers[keeper.id].update(keeper);
      } else {
        this.keepers[keeper.id] = new Keeper(keeper);
      }
      if (id) {
        this.keeperByMark[id] = this.keepers[keeper.id];
      }
      // this.keepers[keeper.id].update(keeper);

      return this.keepers[keeper?.id];
    }
    return null;
  }

  updateKeepers(keepers) {
    if (keepers?.length) {
      let k = 0;
      let keeperList = keepers;
      this.keepersByMap = {};
      this.keeperByMark = {};
      // this.testMapChanged = false;
      while (k < keeperList.length) {
        let m = 0;
        const keeper = this.updateKeeper(keeperList[k]);
        if (keeper) {
          this.mapsByKeeper[keeper.id] = {};
          while (m < this.mapList.length) {
            const map = this.mapList[m];
            if (map.contains(keeper)) {
              this.mapsByKeeper[keeper.id][map.id] = map;
              if (!this.keepersByMap[map.id]) {
                this.keepersByMap[map.id] = {};
              }
              this.keepersByMap[map.id][keeper.id] = keeper;
            }
            m++;
          }
        }
        k++;
      }
      if (this.currentMapId) {
        this.currentMapKeeperList = Object.values(
          this.keepersByMap[this.currentMapId] ?? {}
        );
      }
    }
    if (this.selectedKeeperId) {
      this.setKeeperInCameraArea(this.selectedKeeperId);
    }
  }

  setTrackedKeeper(id) {
    if (id) {
      if (this.trackedKeeper[id]) {
        this.trackedKeeper = {};
      } else if (this.mapsByKeeper[id]) {
        this.trackedKeeper = { [id]: { ...this.mapsByKeeper[id] } };
      } else {
        this.trackedKeeper = {};
      }
    } else {
      this.trackedKeeper = {};
    }
  }

  setUser() {
    const jwt = JSON.parse(localStorage.getItem('token'))?.access;
    if (jwt) {
      const token = jwt.split('.')[1];
      if (token) {
        const userId = JSON.parse(window.atob(token))?.user_id;
        if (userId) {
          this.userId = userId;
          this.userId$.next(userId);
        }
      }
    }
  }

  // {
  //   "id": 7816442388941245,
  //   "ip_address": "172.16.35.71",
  //   "sn": 2493,
  //   "status": "UP",
  //   "_links": {
  //     "self": {
  //       "id": 7816442388941245,
  //       "label": "2493",
  //       "type": "anchors",
  //       "href": "/anchors/7816442388941245"
  //     }
  //   },
  //   "z": 3.8,
  //   "disabled": false,
  //   "y": -104.9,
  //   "x": 207.7
  // }

  setAnchors(data) {
    this.filteredAnchors = {};
    this.filteredAnchorList = [];
    (data || []).forEach((anchor) => {
      this.filteredAnchors[anchor.id] = anchor;
      this.filteredAnchorList.push(anchor);
      anchor.xM = anchor.x;
      anchor.yM = anchor.y;
      let i = 0;
      while (i < this.mapList.length) {
        const map = this.mapList[i];
        if (map.contains(anchor)) {
          anchor.mapId = map.id;
          break;
        }
        i++;
      }
    });
    return this.filteredAnchorList;
  }

  updateAnchors(data) {
    this.anchors = {};
    this.anchorList = [];
    (data || []).forEach((anchor) => {
      this.anchors[anchor.id] = anchor;
      this.anchorList.push(anchor);
      anchor.xM = anchor.x;
      anchor.yM = anchor.y;
      let i = 0;
      while (i < this.mapList.length) {
        const map = this.mapList[i];
        if (map.contains(anchor)) {
          anchor.mapId = map.id;
          break;
        }
        i++;
      }
    });
    this.anchorList$.next(this.anchorList);
    return this.anchorList;
  }

  setSelectedAnchor(anchor) {
    this.selectedAnchor = anchor;
    this.selectedAnchor$.next(anchor);
  }
}

export const mapStore = new MapStore();
