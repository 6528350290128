import { useTranslation } from 'react-i18next';
import { ReactComponent as PlanLogo } from '../../../../assets/icons/plane.svg';
import { ReactComponent as TemperatureLogo } from '../../../../assets/icons/temperature.svg';

export const UserDrawerVehicleStats = ({ keeper }) => {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------
  const sensors = keeper?._embedded?.tag?.sensors;
  const { t } = useTranslation();

  const elements = [
    {
      text: t('movement'),
      logo: <PlanLogo />,
      value: getMotionDesc(),
    },
    {
      text: t('temperature'),
      logo: <TemperatureLogo />,
      value: sensors?.temperature,
    },
  ];

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  function getMotionDesc() {
    let motionDesc;
    if (sensors?.motion === 'STILL') {
      motionDesc = 'Стоит';
    } else if (sensors?.motion === 'MOTION') {
      motionDesc = 'В движении';
    }
    return motionDesc;
  }

  // ------------------------------------------------------------------------------------------------------------
  if (!keeper?.is_active) return <></>;
  return (
    <>
      {elements.map((item, idx) => {
        return (
          item.value && (
            <div key={idx} className={'data-wrapper'}>
              {item.logo}
              <span className="data-text" style={{ color: '#373737' }}>
                {item.text}
              </span>
              <span className="data-value green">{item.value}</span>
            </div>
          )
        );
      })}
    </>
  );
};
