import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input } from 'antd';
import { useCurrentUser } from 'context/current-user/current-user.hook';
import { useModal } from 'context/modal/modal.hook';
import { request } from 'helpers/client';
import { errorNotification } from 'helpers/notificationHelpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SettingsPopoverUserData = () => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const currentUser = useCurrentUser();
  const [mode, setMode] = useState('read');
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const modal = useModal();
  const { t } = useTranslation();
  const [userAdditionalMetadata, setUserAdditionalMetadata] = useState({
    email: '',
    phone: '',
    role: '',
  });
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    loadUserAdditionalMetadata();
  }, [currentUser.user]);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------

  async function loadUserAdditionalMetadata() {
    const res = await request.get('/base_users/' + currentUser.user.id);
    setUserAdditionalMetadata({
      email: res.data.email,
      phone: res.data.phone ?? '',
      role: res.data.role ?? '',
    });
  }

  async function onSubmitForm() {
    if (!formRef.current) return;
    await formRef.current.validateFields();
    const values = formRef.current.getFieldsValue();
    try {
      await request.post(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/base_users/change_password/${currentUser.user.username}`,
        values
      );
    } catch (error) {
      errorNotification(t('someErrorOccured'));
      return;
    }
    modal.close();
  }

  // ----------------------------------------------------------------------------------------
  return (
    <>
      {mode === 'edit-password' ? (
        <>
          <p
            style={{
              fontWeight: 900,
              fontSize: 18,
              textAlign: 'center',
              color: '#00A2E8',
            }}
          >
            {t('changePassword')}
          </p>
          <Form layout={'vertical'} ref={formRef}>
            <Form.Item
              label={t('currentPassword')}
              name="current_password"
              className="bottom20"
              rules={[
                {
                  required: true,
                  message: t('pInputPass'),
                },
              ]}
            >
              <Input.Password value={'password'} />
            </Form.Item>
            <Form.Item
              name="new_password"
              label={t('newPassword')}
              className="bottom20"
              rules={[{ required: true }]}
            >
              <Input.Password value={'password'} />
            </Form.Item>
            <Form.Item
              name="repeat_new_password"
              label={t('confirmNewPassword')}
            >
              <Input.Password value={'password'} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                onClick={() => onSubmitForm()}
              >
                {t('confirm')}
              </Button>
              <Button
                htmlType="button"
                loading={loading}
                style={{ marginLeft: 16 }}
                onClick={() => {
                  setMode('read');
                }}
              >
                {t('cancel')}
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <Avatar
            size={72}
            icon={<UserOutlined />}
            style={{ display: 'block', margin: '24px auto' }}
          />
          <p
            style={{
              fontWeight: 900,
              fontSize: 18,
              textAlign: 'center',
              color: '#00A2E8',
            }}
          >
            {currentUser.user.username}
          </p>
          <Form layout={'vertical'}>
            <Form.Item label={t('position')}>
              <Input value={`${t(currentUser.user.role)}`} disabled />
            </Form.Item>
            <Form.Item label={t('telephone')}>
              <Input value={userAdditionalMetadata.phone} disabled />
            </Form.Item>
            <Form.Item label="Email">
              <Input value={userAdditionalMetadata.email} disabled />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
              }}
            >
              <Form.Item label={t('password')} style={{ flex: '1 0 0' }}>
                <Input.Password value={'very_difficult_password'} disabled />
              </Form.Item>
              <EditOutlined
                onClick={() => {
                  setMode('edit-password');
                }}
                style={{
                  cursor: 'pointer',
                  marginLeft: 16,
                  marginBottom: 20,
                }}
              />
            </div>
          </Form>
        </>
      )}
    </>
  );
};
