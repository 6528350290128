import { Col, DatePicker, Form, notification, Row } from 'antd';
import { mapStore } from 'model/MapStore';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DateForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const keeperHistory = useMemo(() => mapStore.keeperHistoryInstance, []);
  const { startTime, endTime } = keeperHistory;

  const incorrectInterval = t('incorrectInterval');
  const format = 'DD.MM.YYYY HH:mm';
  useEffect(() => {
    keeperHistory.getHistory();
  }, [keeperHistory]);

  const onValuesChange = (changed, values) => {
    const current = moment();
    let start = null;
    let end = null;
    let message = '';
    if (changed.start) {
      start = changed.start;
      end = values.end;
      if (changed.start > current) {
        message = incorrectInterval;
        start = null;
      } else if (end) {
        if (changed.start > end) {
          message = incorrectInterval;
          start = null;
        } else if (end.diff(changed.start, 'days', true) > 1) {
          end = changed.start.add(1, 'days');
          if (end > current) end = current;
        }
      }
    } else if (changed.end) {
      start = values.start;
      end = changed.end;
      if (changed.end > current) {
        message = incorrectInterval;
        end = null;
      } else if (start) {
        if (changed.end < start) {
          message = incorrectInterval;
          end = null;
        } else if (changed.end.diff(start, 'days', true) > 1) {
          start = changed.end.subtract(1, 'days');
        }
      }
    }
    if (message) {
      notification.warning({ message });
    }
    form.setFieldsValue({ start, end });
    if (start && end) {
      keeperHistory.getHistory({ start, end });
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={onValuesChange}
      initialValues={{ start: startTime, end: endTime }}
    >
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item name="start">
            <DatePicker
              placeholder={t('intervalStart')}
              showTime
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item name="end">
            <DatePicker
              placeholder={t('intervalEnd')}
              showTime
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
