// @ts-nocheck
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import './index.css';
const { useTranslation } = require('react-i18next');

export const ReportDateRangePicker = ({
  tempFilterParams,
  setTempFilterParams,
  loading,
}) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------

  const { t } = useTranslation();

  // ----------------------------------------------------------------------------------------
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h4 className={'dateH'}>{t('report.filter.period')}</h4>
        <DesktopDateTimePicker
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
          label={t('report.filter.from')}
          value={tempFilterParams ? tempFilterParams.fromDate : null}
          onChange={(fromDate) => setTempFilterParams({
            ...tempFilterParams,
            fromDate,
          })}
          disabled={loading}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDateTimePicker
          ampm={false}
          label={t('report.filter.by')}
          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
          value={tempFilterParams ? tempFilterParams.toDate : null}
          onChange={(toDate) => setTempFilterParams({
            ...tempFilterParams,
            toDate,
          })}
          disabled={loading}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    </LocalizationProvider>
  );
};
