import {
  CREATION_FAILURE,
  CREATION_REQUEST,
  CREATION_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
} from '../actions/groups-staffs';

const initialState = {
  list: {
    data: [],
    fetching: false,
    error: null,
    count: 0,
  },

  creation: {
    fetching: false,
    error: null,
  },
  deleting: {
    selected: null,
    fetching: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true,
        },
      };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state,
          fetching: false,
          data: action.payload.results,
          count: action.payload.count,
        },
      };
    case GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          error: action.payload,
        },
      };

    case CREATION_REQUEST:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: true,
          error: null,
        },
      };
    case CREATION_SUCCESS:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: false,
        },
      };
    case CREATION_FAILURE:
      return {
        ...state,
        creation: {
          ...state.creation,
          fetching: false,
          error: action.payload,
        },
      };

    case DELETE_REQUEST:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: true,
          error: null,
        },
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: false,
        },
      };
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: {
          ...state.deleting,
          fetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
