import { useTranslation } from 'react-i18next';
import ReportsAnchor from './report-types/reports-anchor';
import { ReportsBiometric } from './report-types/reports-biometric';
import ReportsEmployeeInteraction from './report-types/reports-employee-interaction';
import { ReportsEmployeeTransportConvergence } from './report-types/reports-employee-transport-convergence';
import { ReportsEvent } from './report-types/reports-event';
import { ReportsKeeperBatteryLevel } from './report-types/reports-keeper-battery-level';
import { ReportsSos } from './report-types/reports-sos';
import { ReportsTransactionBetweenLevels } from './report-types/reports-transaction-between-levels';
import { ReportsUserActivity } from './report-types/reports-user-activity';
import { ReportsWorkingTime } from './report-types/reports-working-time';

export const reportEventEventsType = (t) => {
  return {
    _none: t('notSpecified'),
    HIGH_HEART_RATE: t("reportHighHeartRate"),
    LOW_HEART_RATE: t("reportLowHeartRate"),
        BATTERY_LOW: t('batteryLow'),
    BUTTON_PRESSED: t('buttonPressed'),
    FREE_FALL: t('theFall'),
    MAN_DOWN: t('immobility'),
    MAN_DOWN_CANCEL: t('immobilityCancel'),
    danger_zone_exit: t('dangerZoneExit'),
    danger_zone_entrance: t('dangerZoneEntr'),
  };
};

export const useReportPageRegistry = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('report.event'),
      component: ReportsEvent,
      key: 'events',
    },

    {
      label: t('report.interractionReport'),
      component: ReportsEmployeeInteraction,
      key: 'employee_interaction',
    },

    {
      label: t('report.anchorReport'),
      component: ReportsAnchor,
      key: 'anchor_events',
    },

    {
      label: t('report.transactionBetweenLevelsReport'),
      component: ReportsTransactionBetweenLevels,
      key: 'transaction_between_levels',
    },

    {
      label: t('report.workingTimeReport'),
      component: ReportsWorkingTime,
      key: 'working_time',
    },
    {
      label: t('report.userActivityReport'),
      component: ReportsUserActivity,
      key: 'user_activity',
    },

    {
      label: t('report.sosReport'),
      component: ReportsSos,
      key: 'sos',
    },
    {
      label: t('report.biometricReport'),
      component: ReportsBiometric,
      key: 'biometric',
    },
    {
      label: t('report.keeperBatteryLevel'),
      component: ReportsKeeperBatteryLevel,
      key: 'keeper_battery_level',
    },
    {
      label: t('report.transportEmployeeConvergence'),
      component: ReportsEmployeeTransportConvergence,
      key: 'employee_transport_convergence',
    },
  ];
};

export const reportPageConfig = (reportPageRegistry) => {
  return {
    excludeViolatorFilter: [
      'anchor_events',
      'user_activity',
      'sos',
      'biometric',
      'keeper_battery_level',
      'employee_transport_convergence',
    ],
    defaultPage: reportPageRegistry[0],
  };
};
