import { createContext } from 'react';

export const NotificationContext = createContext({
    loadReaded: ({fromStart = true}) => {
    },
    loadNotReaded: ({fromStart = true}) => {
    },
    load: (id) => {
        return {};
    },
    clear: () => {
    },
    read: (id) => {
    },
    notReaded: [],
    notReadedCount:0,
    readed: {
        results: [],
        nextPage: 1,
        count: 0,
    },
    readedLoading: false,
});

