import {
  SET_SEARCH_DATA,
  SET_SEARCH_FETCHING,
  SET_SEARCH_ERROR,
} from '../actions/search';

const reqState = { fetching: false, data: null, error: null };

const initialState = {
  searchState: reqState,
};

export default function search(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SEARCH_FETCHING:
      return {
        ...state,
        searchState: { ...state.searchState, fetching: payload },
      };
    case SET_SEARCH_DATA:
      return {
        ...state,
        searchState: { ...state.searchState, data: payload },
      };
    case SET_SEARCH_ERROR:
      return {
        ...state,
        searchState: { ...state.searchState, error: payload, data: null },
      };
    default:
      return state;
  }
}
