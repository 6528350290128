import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../../helpers/client';
import {
  ADD_FAVORITES_REQUEST,
  GET_FAVORITES_REQUEST,
  GET_FREQUENTLY_REQUEST,
  GET_HISTORY_REQUEST,
  GET_USER_COLOR_REQUEST,
  GET_USER_DATA_REQUEST,
  SET_USER_COLOR_REQUEST,
  addFavoritesData,
  addFavoritesError,
  getFavoritesData,
  getFavoritesError,
  getFavoritesRequest,
  getFrequentlyVisitedData,
  getFrequentlyVisitedError,
  getHistoryData,
  getHistoryError,
  setUserColorData,
  setUserColorError,
  setUserData,
} from '../actions/user';

function* getFavorites(action) {
  const { size } = action.payload;
  try {
    const response = yield call(request.get, '/auth/favorites/', {
      params: { size },
    });

    yield put(getFavoritesData(response.data));
  } catch (err) {
    yield put(getFavoritesError(err));
  }
}

function* addFavorites(action) {
  const { id } = action.payload;
  try {
    const response = yield call(request.post, `/auth/favorites/${id}/`);

    yield put(addFavoritesData(response.data, id));
    yield put(getFavoritesRequest());
  } catch (err) {
    yield put(addFavoritesError(err));
  }
}

function* getFrequentlyVisited(action) {
  const { size } = action.payload;
  try {
    const response = yield call(
      request.get,
      '/auth/layout/frequently-visited/',
      { params: { size } }
    );

    yield put(getFrequentlyVisitedData(response.data));
  } catch (err) {
    yield put(getFrequentlyVisitedError(err));
  }
}

function* getHistory(action) {
  const { size } = action.payload;
  try {
    const response = yield call(request.get, '/auth/layout/history/', {
      params: { size },
    });

    yield put(getHistoryData(response.data));
  } catch (err) {
    yield put(getHistoryError(err));
  }
}

function* setUserColor(action) {
  const map_color = action.payload;
  try {
    const response = yield call(request.post, '/auth/map-color/', {
      map_color,
    });

    yield put(setUserColorData(response.data?.map_color));
  } catch (err) {
    yield put(setUserColorError(err));
  }
}

function* getUserColor() {
  try {
    const response = yield call(request.get, '/auth/me/');

    yield put(setUserColorData(response.data.map_color));
  } catch (err) {
    yield put(setUserColorError(err));
  }
}

function* getUserData() {
  try {
    const response = yield call(request.get, '/auth/me/');
    console.log({ response });
    yield put(setUserData(response.data));
  } catch (err) {
    console.log({ err });
    yield put(setUserColorError(err));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_FAVORITES_REQUEST, getFavorites),
    takeLatest(ADD_FAVORITES_REQUEST, addFavorites),
    takeLatest(GET_FREQUENTLY_REQUEST, getFrequentlyVisited),
    takeLatest(GET_HISTORY_REQUEST, getHistory),
    takeLatest(SET_USER_COLOR_REQUEST, setUserColor),
    takeLatest(GET_USER_COLOR_REQUEST, getUserColor),
    takeLatest(GET_USER_DATA_REQUEST, getUserData),
  ]);
}

export default Saga;
