import L from 'leaflet';
import moment from 'moment';
import { Subject } from 'rxjs';
import { getKeeperHistory } from '../services/keepers';

export class KeeperHistory {
  enabled = false;

  startTime = moment().subtract(1, 'days');
  endTime = moment();

  list = null;
  list$ = new Subject();

  currentHistoryIndex = null;
  currentHistoryIndex$ = new Subject();
  currentPointIndex = null;
  currentSegmentIndex = 0;
  currentTime; // number

  progress = 0;
  progress$ = new Subject();

  speed = 1;
  speed$ = new Subject();

  play = 'stop';
  play$ = new Subject(); // play, pause, stop

  store;

  constructor(store) {
    this.store = store;
    store.selectedKeeperId$.subscribe((id) => {
      if (id) {
        if (this.enabled) {
          this.getHistory({ start: this.startTime, end: this.endTime });
        }
        // if (this.list) {
        //   this.list$.next(this.list);
        // } else {
        //   this.getHistory({start: this.startTime, end: this.endTime});
        // }
      } else {
        this.clear();
      }
    });
  }

  enable(value) {
    this.enabled = !!value;
    if (this.enabled) {
      if (this.list) {
        this.currentHistoryIndex$.next(this.currentHistoryIndex);
      } else {
        this.getHistory();
      }
    }
  }

  getHistory({ start, end } = {}) {
    if (start) {
      this.startTime = start;
    } else {
      start = this.startTime;
    }
    if (end) {
      this.endTime = end;
    } else {
      end = this.endTime;
    }
    const id = this.store.selectedKeeperId;
    // if (id && start && end) {
    if (id) {
      getKeeperHistory(id, start.toISOString(), end.toISOString()).then(
        (res) => {
          this.processHistory(res);
        }
      );
    }
  }

  /**
   * @description: Method draw movement points history
   */
  processHistory(history) {
    const mapHistory = [
      // {
      //   mapId: '',
      //   points: [],
      //   segments: [[{x, y, time}, {x, y, time}]],
      //   startTime: '2022-04-07T00:05:07.775+06:00',
      //   endTime: '2022-04-07T00:05:07.775+06:00'
      // }
    ];

    let currentMap;
    let coords;
    const { currentMapId, maps, mapList } = this.store;

    const getMap = (point) => {
      let k = 0;
      while (k < mapList.length && mapList[k].id !== currentMap?.mapId) {
        const map = mapList[k];
        if (map.contains(coords)) {
          point.x = coords.x;
          point.y = coords.y;
          return map;
        }
        k++;
      }
    };

    let startPoint;
    const tempPoints = {};

    const setCurrentMap = (map, point) => {
      currentMap = {
        mapId: map.id,
        startTime: point.time,
        points: [point],
        segments: [],
      };
      startPoint = point;
      mapHistory.push(currentMap);
    };

    history?.forEach((point, i) => {
      if (
        point.created_at &&
        typeof point.x === 'number' &&
        typeof point.y === 'number' &&
        typeof point.z === 'number'
      ) {
        point.time = point.created_at;
        point.params = {};
        point.params.x = point.x;
        point.params.y = point.y;
        point.params.z = point.z;
        const key = `${point.time}${point.params.x}${point.params.y}${point.params.z}`;
        if (!tempPoints[key]) {
          tempPoints[key] = 1;
          coords = {
            xM: point.params.x,
            yM: point.params.y,
            z: point.params.z,
          };

          let map;
          if (currentMap?.mapId) {
            map = maps[currentMap.mapId];
            if (map?.contains(coords)) {
              point.x = coords.x;
              point.y = coords.y;
              currentMap.points.push(point);
              const endPoint = {
                time: point.time,
                x: point.x,
                y: point.y,
              };
              if (startPoint) {
                currentMap.segments.push([startPoint, endPoint]);
              }
              startPoint = endPoint;
            } else {
              map = getMap(point);
              if (map) {
                setCurrentMap(map, point);
              }
            }
          } else {
            map = getMap(point);
            if (map) {
              setCurrentMap(map, point);
            }
          }
        }
      }
    });
    this.setHistory(
      mapHistory.map((h) => {
        if (h.points.length) {
          h.endTime = h.points[h.points.length - 1]?.time;
        }
        return h;
      })
    );
    // this.setHistory(mapHistory.filter((history) => {
    //   if (history.points.length > 1) {
    //     const startTime = history.points[0].time;
    //     const endTime = history.points[history.points.length - 1].time;
    //     if (+moment(endTime) - +moment(startTime) > 10000) {
    //       history.startTime = startTime;
    //       history.endTime = endTime;
    //       return true;
    //     }
    //   }
    //   return false;
    // }));
    const currentMapHistoryIndex = this.list?.findIndex(
      (h) => h.mapId === currentMapId
    );
    this.setCurrentHistoryIndex(
      currentMapHistoryIndex === -1 ? 0 : currentMapHistoryIndex
    );
  }

  setPlay(play) {
    this.play = play;
    this.play$.next(play);
  }

  setSpeed() {
    if (this.play !== 'pause') {
      this.setPlay('pause');
    }
    this.speed = this.speed === 32 ? 1 : this.speed * 2;
    this.speed$.next(this.speed);
  }

  setProgress(progress, final = false) {
    this.progress = progress;
    this.progress$.next({ progress, final });
  }

  setHistory(list) {
    this.list = list;
    this.list$.next(list);
  }

  setCurrentHistoryIndex(index) {
    this.setPlay('stop');
    this.currentHistoryIndex = index;
    this.currentHistoryIndex$.next(index);
  }

  getTimePoint() {
    const result = {};
    if (this.currentHistoryIndex !== null && this.currentTime) {
      const time = this.currentTime;
      const history = this.list?.[this.currentHistoryIndex];
      if (history) {
        const { segments = [] } = history;
        if (time) {
          let point1, time1, point2, time2;
          result.segmentIndex = segments.findIndex(([p1, p2]) => {
            const t1 = +moment(p1.time);
            const t2 = +moment(p2.time);
            if (time >= t1 && time <= t2) {
              point1 = p1;
              time1 = t1;
              point2 = p2;
              time2 = t2;
              return true;
            }
            return false;
          });
          if (point1) {
            const segmentProgress = (time - time1) / (time2 - time1);
            const latLng1 = new L.LatLng(point1.y, point1.x);
            const latLng2 = new L.LatLng(point2.y, point2.x);
            const distance = latLng1.distanceTo(latLng2);
            let latLng;
            if (distance) {
              const distanceProgress = (distance * segmentProgress) / distance;
              latLng = L.latLng(
                point1.y + distanceProgress * (point2.y - point1.y),
                point1.x + distanceProgress * (point2.x - point1.x)
              );
            } else {
              latLng = latLng1;
            }
            result.latLng = latLng;
            result.point = { x: latLng.lng, y: latLng.lat, time };
            result.segment = [result.point, point2];
          }
        }
      }
    }
    return result;
  }

  clear() {
    this.enabled = false;
    this.setHistory(null);
    this.setCurrentHistoryIndex(null);
    this.setPlay('stop');
    this.currentPointIndex = null;
  }
}
