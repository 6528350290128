import { CloseOutlined } from '@ant-design/icons';
import {
  Form as BaseForm,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFormValue } from '../../store/actions/forms';
import { selectFormValues } from '../../store/selectors/forms';

import { errorMessage } from '../../helpers/errorHandlers';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import store from '../../store';

const Form = ({ formRef, name, loading, config, error }) => {
  const dispatch = useDispatch();
  const formValues = useSelector(selectFormValues(name));
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleFieldUpdate = (field, value) => {
    dispatch(
      setFormValue({
        form: name,
        field,
        value: value,
      })
    );
  };

  const handleFileFieldChange = (field, type) => (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (type.startsWith('image')) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event) => {
        handleFieldUpdate(field, {
          file,
          url: event.target.result,
        });
      };
    } else {
      handleFieldUpdate(field, {
        file,
        url: file.name,
      });
    }
  };

  const getValueSetLoading = (item) => {
    if (item.selectType === 'raw') return false;
    return item.getValueSetLoading(store.getState());
  };

  const getOptions = (item) => {
    if (item.selectType === 'raw') return item.options;

    return [];
  };

  const { fields: errors } = errorMessage(error);

  return (
    <BaseForm
      onSubmit={handleSubmit}
      ref={formRef}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      {config.map((item, idx) => {
        const key = `FORM_${name}_${item.name}_FIELD_${idx}`;
        const value = formValues[item.name];

        if (item.type === 'file') {
          return (
            <BaseForm.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              key={key}
              required={item.required}
              name={item.name}
            >
              {value && value.url ? (
                (item.accept || '').startsWith('image') ? (
                  <div className="image-field-preview">
                    <CloseOutlined
                      className="remove"
                      onClick={() =>
                        handleFieldUpdate(item.name, { file: null, url: null })
                      }
                    />
                    <img src={value.url} alt={item.name} />
                  </div>
                ) : (
                  <div className="audio-field-preview">
                    <p>{t('selected')}:</p>
                    <span className="link-chip">
                      <span className="link-el">{value.url}</span>
                      <CloseOutlined
                        className="close"
                        onClick={() =>
                          handleFieldUpdate(item.name, {
                            file: null,
                            url: null,
                          })
                        }
                      />
                    </span>
                  </div>
                )
              ) : (
                <Input
                  type="file"
                  shape="rounded"
                  onChange={handleFileFieldChange(item.name, item.accept)}
                  accept={item.accept}
                  disabled={loading}
                  required={item.required}
                />
              )}
            </BaseForm.Item>
          );
        }

        if (item.type === 'boolean') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
            >
              <Switch
                checked={value}
                onChange={(checked) => handleFieldUpdate(item.name, checked)}
                loading={loading}
              />
            </BaseForm.Item>
          );
        }

        if (item.type === 'date-picker') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              // rules={[
              //   { required: Boolean(item.required), message: t('enterText') },
              // ]}
              getValueProps={() => ({
                value: value ? moment(value, 'YYYY-MM-DD') : null,
              })}
            >
              <DatePicker
                allowClear
                disabled={loading}
                disabledDate={(current) =>
                  moment(current).isBefore(moment().subtract('1', 'day')) ||
                  (item.name === 'start_date'
                    ? moment(current).isAfter(moment(formValues.end_date))
                    : item.name === 'end_date'
                    ? moment(current).isBefore(moment(formValues.start_date))
                    : false)
                }
                defaultValue={value ? moment(value, 'YYYY-MM-DD') : null}
                style={{ width: '100%' }}
                onChange={(m) =>
                  handleFieldUpdate(item.name, m?.format('YYYY-MM-DD'))
                }
              />
            </BaseForm.Item>
          );
        }

        if (item.type === 'time-picker') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              // rules={[
              //   { required: Boolean(item.required), message: t('enterText') },
              // ]}
            >
              <TimePicker
                allowClear
                disabled={loading}
                style={{ width: '100%' }}
                defaultValue={value ? moment(value, 'HH:mm') : null}
                format={'HH:mm'}
                showSecond={false}
                onChange={(moment) =>
                  handleFieldUpdate(item.name, moment?.format('HH:mm'))
                }
              />
            </BaseForm.Item>
          );
        }

        if (item.type === 'valueSet') {
          return (
            <BaseForm.Item
              key={key}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label={item.label}
              initialValue={
                getOptions(item).find(({ name }) => name === value)?.label
              }
              className="form-row"
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
              rules={[
                { required: Boolean(item.required), message: t('enterText') },
              ]}
            >
              <Select
                backfill={false}
                onChange={(selected) => {
                  if (item.name === 'cyclicality') {
                    handleFieldUpdate('value_cycle', []);
                  }

                  handleFieldUpdate(item.name, selected);
                }}
                value={value}
                loading={getValueSetLoading(item) || loading}
              >
                {getOptions(item).map((option, index) => (
                  <Select.Option
                    key={`FORM_${name}_SELECT_OPTION_${index}`}
                    value={option.name}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </BaseForm.Item>
          );
        }

        if (item.type === 'value_cycle') {
          const cyclicality = formValues.cyclicality;

          if (!cyclicality) return;

          return cyclicality === 'working_days' ? (
            <div className="value-cycle-container">
              {[
                ...moment.weekdaysShort().slice(1),
                moment.weekdaysShort()[0],
              ].map((day, index) => (
                <div
                  key={index}
                  className={
                    value.includes(`${index + 1}`)
                      ? 'value-cycle value-cycle-selected'
                      : 'value-cycle'
                  }
                  onClick={() => {
                    if (!value.includes(`${index + 1}`)) {
                      handleFieldUpdate(item.name, [...value, `${index + 1}`]);
                      return;
                    }

                    handleFieldUpdate(
                      item.name,
                      value.filter((v) => v !== `${index + 1}`)
                    );
                  }}
                >
                  {day.toUpperCase()}
                </div>
              ))}
            </div>
          ) : cyclicality === 'days_of_the_month' ? (
            <div className="value-cycle-container">
              {[...Array(31).keys()].map((day, index) => (
                <div
                  key={index}
                  className={
                    value.includes(`${index + 1}`)
                      ? 'value-cycle value-cycle-selected'
                      : 'value-cycle'
                  }
                  onClick={() => {
                    if (!value.includes(`${index + 1}`)) {
                      handleFieldUpdate(item.name, [...value, `${index + 1}`]);
                      return;
                    }

                    handleFieldUpdate(
                      item.name,
                      value.filter((v) => v !== `${index + 1}`)
                    );
                  }}
                >
                  {day + 1}
                </div>
              ))}
            </div>
          ) : cyclicality === 'from_date_to_date' ? (
            <BaseForm.Item
              label={t('choosePeriod')}
              rules={[
                { required: Boolean(item.required), message: t('enterText') },
              ]}
              className="form-row"
              key={key}
              initialValue={value}
              validateStatus={errors[item.name] ? 'error' : ''}
              help={errors[item.name]}
              required={item.required}
              name={item.name}
            >
              <DatePicker
                allowClear
                disabled={loading}
                style={{ marginLeft: '6%', width: '40%' }}
                value={value?.[0] ? moment(value[0], 'DD-MM-YYYY') : null}
                disabledDate={(current) =>
                  moment(current).isBefore(moment().subtract('1', 'day')) ||
                  (value?.[1]
                    ? moment(current).isAfter(moment(value?.[1], 'DD-MM-YYYY'))
                    : false)
                }
                onChange={(moment) => {
                  handleFieldUpdate(item.name, [
                    moment?.format('DD-MM-YYYY'),
                    value?.[1],
                  ]);
                }}
              />
              <DatePicker
                allowClear
                disabled={loading}
                style={{ marginLeft: '8%', marginRight: '6%', width: '40%' }}
                value={value?.[1] ? moment(value[1], 'DD-MM-YYYY') : null}
                disabledDate={(current) =>
                  moment(current).isBefore(moment().subtract('1', 'day')) ||
                  (value?.[0]
                    ? moment(current).isBefore(moment(value?.[0], 'DD-MM-YYYY'))
                    : false)
                }
                onChange={(moment) => {
                  handleFieldUpdate(item.name, [
                    value?.[0],
                    moment?.format('DD-MM-YYYY'),
                  ]);
                }}
              />
            </BaseForm.Item>
          ) : cyclicality === 'x_through_y' ? (
            <Input.Group size="large">
              <BaseForm.Item
                label={`X (${t('workingDays')})`}
                required={item.required}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  value={value[0]}
                  min={1}
                  onChange={(val) => {
                    const newValue = [...value];
                    newValue[0] = val;
                    handleFieldUpdate(item.name, [...newValue]);
                  }}
                />
              </BaseForm.Item>
              <BaseForm.Item label={`Y (${t('daysOff')})`}>
                <InputNumber
                  style={{ width: '100%' }}
                  value={value[1]}
                  min={1}
                  required={item.required}
                  onChange={(val) => {
                    const newValue = [...value];
                    newValue[1] = val;
                    handleFieldUpdate(item.name, [...newValue]);
                  }}
                />
              </BaseForm.Item>
            </Input.Group>
          ) : (
            ''
          );
        }

        return (
          <BaseForm.Item
            label={item.label}
            rules={[
              { required: Boolean(item.required), message: t('enterText') },
            ]}
            className="form-row"
            key={key}
            initialValue={value}
            validateStatus={errors[item.name] ? 'error' : ''}
            help={errors[item.name]}
            required={item.required}
            name={item.name}
          >
            {item.type === 'textarea' ? (
              <Input.TextArea
                shape="round"
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                value={value}
                disabled={loading}
              />
            ) : (
              <Input
                shape="round"
                onChange={(e) => handleFieldUpdate(item.name, e.target.value)}
                value={value}
                disabled={loading}
              />
            )}
          </BaseForm.Item>
        );
      })}
    </BaseForm>
  );
};

export default React.memo(Form);
