import { safeGet } from "../../helpers/getters";
import { normalizeInstance } from '../../helpers/table';


const selectState = state => state.devices;

export const makeSelectDevicesList = state => safeGet(selectState(state), 'list.data', []);
export const makeSelectDevicesTableData = columns => state => {
  const data = makeSelectDevicesList(state);
  return data.map(normalizeInstance(columns));
};
export const makeSelectDevicesCount = state => safeGet(selectState(state), 'list.count', 0);
export const makeSelectDevicesLoading = state => safeGet(selectState(state), 'list.fetching', false);

export const makeSelectCreatingModalOpen = state => safeGet(selectState(state), 'creating.isOpen', false);
export const makeSelectCreatingLoading = state => safeGet(selectState(state), 'creating.fetching', false);

export const makeSelectUpdatingModalOpen = state => safeGet(selectState(state), 'updating.isOpen', false);
export const makeSelectUpdatingLoading = state => safeGet(selectState(state), 'updating.fetching', false);

export const makeSelectNominatedToDelete = state => safeGet(selectState(state), 'deleting.selected', null);
export const makeSelectDeletingLoading = state => safeGet(selectState(state), 'deleting.fetching', false);
