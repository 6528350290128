import { Form, Modal } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useRef, useState } from 'react';
import { FormContext as FormOpenerContext } from './form-opener.context';

export function FormOpenerProvider(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  // const { t } = useTranslation()
  const defaultOptions = useRef({
    cancelText: t('cancel'),
    submitText: "Ok",
    title: '____',
    component: <></>,
    onCancel: () => { },
    onSubmit: (isValid, value) => { },
    submitButtonProps: {},
    cancelButtonProps: {},
    keyboard: undefined,
    width: '600px',
    loading: false
  });
  const formRef = React.createRef();
  const [isRendered, setRendered] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [options, setOptions] = useState(defaultOptions.current);

  // ---------------------------------------------------------------------------
  // memo callbacks
  // ---------------------------------------------------------------------------

  const openFn = useCallback((options) => {
    setRendered(true);
    setTimeout(() => {
      setOpened(true);
      if (options)
        setOptions((prev) => {
          return { ...prev, ...options };
        });
    });
  }, []);

  const closeFn = useCallback(() => {
    setOpened(false);

    options.onCancel();
    setTimeout(() => {
      setRendered(false);
    }, 300);
    setOptions(defaultOptions.current);
  }, [options, defaultOptions]);

  const updateOptions = useCallback((options) => {
    setOptions((prev) => {
      return { ...prev, ...options };
    });
  }, []);

  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [value] = useState({
    open: openFn,
    close: closeFn,
    updateOptions,
  });

  // ---------------------------------------------------------------------------
  return (
    <FormOpenerContext.Provider value={value}>
      {isRendered && (
        <Modal
          title={options.title}
          visible={isOpened}
          onCancel={() => {
            setOpened(false);
            closeFn();
          }}
          okText={options.submitText}
          cancelText={options.cancelText}
          confirmLoading={options.loading}
          onOk={() => {
            formRef.current
              ?.validateFields()
              .then(() => {
                options.onSubmit(true, formRef.current.getFieldsValue());
              })
              .catch(() => {
                options.onSubmit(false);
              });
          }}
          okButtonProps={options.submitButtonProps}
          keyboard={options.keyboard}
          cancelButtonProps={options.cancelButtonProps}
          width={options.width}
        >
          <Form
            ref={formRef}
            name="control-ref"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            {options.component}
          </Form>
        </Modal>
      )}
      {props.children}
    </FormOpenerContext.Provider>
  );
}
