export const resources = {
  en: {
    translation: {
      report: {
        report: 'Report',
        anchorReport: 'Anchor report',
        anchors: 'Anchor report',
        interractionReport: 'Interaction report',
        interactions: 'Interaction report',
        event: 'Event report',
        events: 'Event report',
        transactionBetweenLevelsReport: 'Maps report',
        workingTimeReport: 'Working time report',
        biometricReport: 'Biometrics Report',
        keeperBatteryLevel: 'Tag battery charge report',
        transportEmployeeConvergence : "Getting closer to transport",
        employeeWorkingTime: 'Working time report',
        userActivityReport: 'User activity report',
        sosReport: 'SOS report',
        noCoordinateOrMap: 'No coordinates or no map defined',
        template: {
          title: 'Templates',
          search: 'Search by template name',
          create: {
            title: 'Create new template',
            submit: 'Create',
            name: 'Template name',
          },
          params: {
            violator: 'Only violators',
            post: 'Occupation: {{val}}',
            employees: 'Tag owner: {{val}}',
            zone: 'Zone: {{val}}',
            hostname: 'Host name: {{val}}',
            sos_type: 'Sos type: {{val}}',
            position: 'Occupation: {{val}}',
            login: 'Login: {{val}}',
            status: 'Status: {{val}}',
            anchor_id: 'Anchor: {{val}}',
            shift: 'Shift: {{val}}',
            layout: 'Map: {{val}}',
            group: 'Group',
            individual: 'Individual',

            event_type: 'Event type: {{val}}',
            BATTERY_LOW: 'Battery low',
            BUTTON_PRESSED: 'Button pressed',
            FREE_FALL: 'The fall',
            MAN_DOWN: 'Immobility',
            MAN_DOWN_CANCEL: 'Immobility (cancel)',
            danger_zone_exit: 'Dangerous zone (exit)',
            danger_zone_entrance: 'Danger zone (entrance)',

            from_user: 'From user: {{val}}',
            to_employee: 'To employee: {{val}}',
            group_id: 'Group: {{val}}',

            http_method: 'Http method: {{val}}',
            ip_address: 'IP address: {{val}}',
          },
          expand: 'Expand',
          collapse: 'Collapse',
          use: 'Use',
          error: {
            name: 'Name required',
          },
        },
        filter: {
          sender: 'Sender',
          receiver: 'Receiver',
          tagOwner: 'Tag owner',
          apply: 'Apply',
          reset: 'Reset',
          anchor: 'Anchor',
          period: 'Period',
          from: 'From',
          by: 'By',
          onlyViolators: 'Only violators',
          forToday: 'For last 24 hours',
          forTodayBiometrics: 'By biometrics in 24 hours',
          sosType: 'SOS type',
          group: 'Group',
          individual: 'Individual',
          totalTime: 'Total time',
          keeper: 'Keeper',
          tag: 'Tag',
          battery_state: 'Battery state',
        },
        user_activity: {
          created_at: 'Time',
          login: 'Login',
          hostname: 'Hostname',
          http_method: 'Http method',
          host: 'Host',
          apiMethod: 'API method',
          hostName: 'Host name',
          ipAddress: 'IP address',
        },
      },
      dashboard: {
        violator_count: 'Violator count: {{val}}',
        medium: 'Medium',
        discharged: 'Discharged',
        compleate: 'Compleate',
        activeStaffsPointsChargeLevel: 'Tags battery level',
        fallEvents: 'Fall events',
        fallEventsPerDay: 'Free fall event per day: {{val}}',
        employeesOnObject: 'Employees on object: {{val}}',
        employeesOnObjectByOrganization: "On-site employees by contractors",
        employeesByMap:"Employees by map",
        employeesOnObjectByPosition: "By positions",
        sosEventPerDay: 'SOS events per day: {{val}}',
        countOfHealthEvent:"Number of health events",
        healthEventByMark:"Health events by marks",
        healthEventByPosition: "Number of health events by position",
        showHideAll: 'Show (hide) all',
        currentConfiguration: 'Current configuration',
        notAvailable: 'Not available',
        notConfigured: 'Not configured',
        switchedOff: 'Switched off',
        infrastructureDevices: 'Anchors count: {{val}}',
        mapsCount: `Maps: {{val}}`,
        eventsInTheRegions: 'Events in the regions',
        active: 'Active',
        ok: 'Ок',
        notIssued: 'Not issued',
        tagsCountDevices: 'Tags: {{val}} devices',
        immobilizationEvents: 'Immobilization Events',
        employees: 'Staff',
        violator: 'Violator',
      },
      smtp: {
        title: 'SMTP server configuration',
        username: 'Username',
        password: 'Password',
        smtp_server_address: 'Smtp server address',
        smtp_server_port: 'Smtp server port',
        ssl_tls_address: 'Ssl tls address',
        ssl_tls_port: 'Ssl tls port',
      },
      reaction: {
        event_type: 'Event type',
        mode: 'Reaction type',
        audio: 'Audio',
        to_email: 'To email',
        regular: 'Popup notification',
        danger: 'Blocking notification',
        none: 'No notification',
      },
      notification: {
        popup: 'Popup notification',
        blocking: 'Blocking notification',
        none: 'No notification',
      },
      outOfCameraZone: 'The object is outside the camera zones',
      userWithoutAccess: 'You do not have access to view this camera',
      notOnMaps: 'Not found on maps',
      home: 'Home',
      sandwich: 'Sandwich',
      mainMap: 'Main',
      accessRecovery: 'Access recovery',
      accessRecoveryDesc:
        'To recover the access please refer to the system administrator',
      systemAuth: 'System authorization',
      wrongCredentials: 'Login error, wrong username or password', //todo
      enterLogin: 'Enter login', //todo
      enterPassword: 'Enter password',
      login: 'Login',
      password: 'Password',
      forgetPassword: 'Forget password?',
      logIn: 'Log in',
      personnelNumber: 'Personnel number',
      position: 'Position',
      compleate: 'Compleate',
      employees: 'Staff',
      objects: 'Objects',
      transport: 'Transport',
      labels: 'Labels',
      areas: 'Areas',
      maps: 'Maps',
      active: 'Active',

      ok: 'Ок',

      medium: 'Medium',
      discharged: 'Discharged',
      offShort: 'Off',
      noData: 'No data',
      error: 'Error',
      cancel: 'Cancel',
      unreaded: 'Unread',
      readed: 'Read',
      loading: 'Loading',
      loadMore: 'Load more...',

      interactionReport: 'Interaction Report',
      time: 'Time',
      cancelAnErrorOccurred: 'Cancel : an error occurred',
      fullName: 'Fullname',
      map: 'Map',
      zone: 'Zone',
      linkToFile: 'Link to file',
      excelReportRows: `The uploaded Report contains: {{val}} rows in .xlsx format`,
      bookmarks: 'Bookmarks',
      recentZones: 'Recent Zones',
      frequentZones: 'Frequent zones',
      favorites: 'Favorites',
      objectType: 'Object type',
      objectName: 'Object name',
      enterToSearch: 'Enter to search',
      notFoundOnMaps: 'Not found on maps',
      listIsEmpty: 'List is empty',
      administrator: 'System Administrator',
      manager: 'Manager',
      operator: 'Operator',
      someErrorOccured: 'Some error occured',
      currentPassword: 'Current password',
      pInputPass: 'Please input your password!',
      telephone: 'Phone',
      settings: 'Settings',
      exit: 'Exit',
      clickToSelectAnImageWithFloorPlan:
        'Click to select an image with floor plan',
      chooseFloorPlan: 'Choose floor plan',
      enterRTLSCardId: 'Enter RTLS card id',
      attention: 'Attention',
      newPassword: 'New password',
      changePassword: 'Change password',
      confirmNewPassword: 'Confirm new password',
      confirm: 'Confirm',
      enterRtlsMapId: 'Enter RTLS map id',
      rtlsMapId: 'RTLS map id',
      enterMapName: 'Enter map name',
      name: 'Name',
      requireField: 'Require field',
      cutLength: 'Cut length',
      rpcrttmzim:
        'Reference point coordinates relative to the main zero in meters',
      calculationPoint: 'Calculation point',
      specRoomBounds: 'Specify the boundaries of the room',
      save: 'Save',
      edit: 'Edit',
      create: 'Create',
      roomBoundaries: 'room boundaries',
      main: 'Main',
      category: 'Category',
      siftplfcsc:
        'Select image from the previews list for coordinate system configuration',
      sosSignal: 'Sos signal',
      send: 'Send',
      startTyping: 'Start typing',
      groups: 'Groups',
      noCameras: 'No cameras',
      mapOptions: 'Map options',
      yes: 'Yes',
      selected: 'Selected',
      choosePeriod: 'Choose period',
      enterText: 'Enter text',
      //   workingDays: 'working days',
      daysOff: 'days off',
      popupNotification: 'Popup notification',
      blockingNotification: 'Blocking notification',
      notSpecified: 'Not specified',
      allowEmailDistr: 'Allow email distribution',
      email: 'Email',
      phoneNumber: 'Phone number',
      yCantDelLastEntry: 'You cannot delete the last entry',
      deleteEntry: 'Delete entry',
      addEmail: 'Add email',
      allReadyRegistered: 'A user with this name is already registered.',
      inValidRoleSelect: 'Wrong choice of role.',
      undefinedError: 'Undefined error',
      userName: 'Username',
      enterName: 'Enter name',
      surname: 'Surname',
      enterEmail: 'Enter email',
      role: 'Role',
      intervalStart: 'Date and time of the beginning of the interval',
      intervalEnd: 'Date and time of the end of the interval',
      movementHistory: 'Movement history',
      personalNum: 'Personnel Number',
      violator: 'Violator',
      violatorDetected: 'The violator was found',
      danger: 'Danger',
      employeeOnDanger: 'Employee on danger',
      movement: 'Movement',
      temperature: 'Temperature',
      dropImpact: 'Drop/Impact',
      regulation: 'Position',
      barometer: 'Barometer',
      barometerTemp: 'Barometer temperature',
      pulceBracelet: 'Pulse (bracelet)',
      pedometBracelet: 'Steps (bracelet)',
      batteryBracelet: 'Battery (bracelet)',
      signalSent: 'The signal on the label is sent!',
      signalNotSendRepaid: 'Signal not delivered, please resend!',
      labelNumber: 'Label number',
      battery: 'Battery',
      anchors: 'Anchors',
      notifications: 'Notifications',
      camera: 'Camera',
      menu: 'Menu',
      search: 'Search',
      profile: 'Profile',
      sendSos: 'Send SOS',
      zoomIn: 'Zoom In',
      zoomOut: 'Zoom Out',
      upper: 'Upper',
      lower: 'Lower',
      mapSettings: 'Map settings',
      totalNumberOfActiveUsers: 'Total number of active employees',
      editMap: 'Edit Map',
      deleteMap: 'Delete Map',
      editPolygons: 'Edit Polygons',
      editZones: 'Edit Zones',
      editZone: 'Edit Zone',
      deleteZone: 'Delete Zone',
      deleteAnchor: 'Delete Anchor',
      editEmployee: 'Edit Employee',
      mapsFavorites: 'Favorites',
      notificationCleared: 'Notification cleared',
      incorrectInterval: 'Incorrect interval',
      back: 'Back',
      keeperDataNotFound: 'Keer data not found',
      sorry: 'Sorry!',
      yCantDrawLikeThat: "You can't draw like that!",
      dashboards: 'Dashboards',
      reports: 'Reports',
      configuration: 'Configuration',
      noType: 'Without type',
      equipment: 'Equipment',
      dangerZone: 'Danger zone',
      greenZone: 'Green zone',
      forbiddenZone: 'Forbidden zone',
      title: 'Name',
      zoneTitle: 'Zone name',
      zoneType: 'Zone type',
      selectZoneType: 'Select zone type',
      selectEquipment: 'Select equipment',
      zoneActiveTime: 'Zone active time',
      usersAccess: 'Users access',
      selectUsers: 'Select users',
      arbitraryArea: 'Arbitrary area',
      square: 'Square',
      delete: 'Delete',
      succesUpdate: 'Successfully updated',
      succesCreated: 'Successfully created',
      zoneCreationError: 'Zone creation error',
      actions: 'Actions',
      addZone: 'Add zone',
      createVisibility: 'Creating Visibility',
      color: 'Color',
      equipmentTag: 'Equipment tag',
      marker: 'Marker',
      putMarker: 'Put a marker',
      close: 'Close',
      equipmentList: 'Equipment list',
      addArea: 'Add an area',
      confirmAction: 'Confirm action',
      confirmAreaDelete: 'You really want to delete this area',
      region: 'Region',
      reDrawRegion: 'Redraw area',
      track: 'Track',
      onMap: 'On map',
      urgently: 'Urgently',
      videoMonitor: 'VideoCam',
      outOfCameraArea: 'Outside areas with cameras',
      dinningRoom: 'Dining room',

      mapNotFound: 'Map not found ',
      removeAllMarks: 'Remove all marks',
      excelFileCreating: 'Excel file : {{val}} is processing',
      excelFileReady: 'Excel file: {{val}} is created',
      excelFileRowsC:
        'The Report to be uploaded contains: {{val}} rows in .xlsx format',
      errorOnFileCreation: 'Error creating file',
      reportType: 'Report type',
      batteryLow: 'Battery low',
      buttonPressed: 'Button pressed',
      theFall: 'The fall',
      immobility: 'Immobility',
      immobilityCancel: 'Immobility (cancel)',
      dangerZoneExit: 'Dangerous zone (exit)',
      dangerZoneEntr: 'Danger zone (entrance)',
      type: 'Type',
      birth_date: 'Birth date',
      status: 'Status',
      eventType: 'Event type',
      sex: 'Gender',
      min_rate: 'Lower limit of heart rate',
      max_rate: 'Upper limit of heart rate',
      shift: 'Shift',
      shiftNotSpecified: 'Shift not specified',
      zoneNotSpecified: 'Zone not specified',
      mapNotSpecified: 'Map not specified',
      information: 'Information',
      has_biometry: 'Has biometry',
      has_special_values_of_biometrics: 'Has special values of biometrics',
      notificationType: 'Notification type',
      interractTime: 'Interraction time',
      toEmployee: 'Interract with',
      groupName: 'Group name',
      receiver: 'Receiver',
      receiverNotSpecified: 'Receiver not specified',
      startTime: 'Start time',
      endTime: 'End time',
      duration: 'Duration',
      host: 'Host',
      apiMethod: 'API method',
      hostName: 'Host name',
      ipAddress: 'IP address',
      activeTime: 'Active time',
      inActiveTime: 'Inactive time',
      unreliableCoordinatesTime: 'Unreliable coordinates time',
      activeUsersCount: 'Number of active employees',
      mapsNotYetAddToCateg: 'Until maps are added to this category',
      pictureName: 'Picture name',
      sortOrder: 'The sort order',
      enterSortOrder: 'Enter the sort order',
      picLoading: 'Loading a picture',
      add: 'Add',
      image: 'Image',
      sureWantDelete: 'Do you really want to delete?',
      pathToData: 'Path to data',
      interval: 'Interval',
      editSettings: 'Edit Settings',
      patronymic: 'Patronymic',
      mark: 'Mark',
      label: 'Label',
      violatorTerm: 'Violator term',
      searchByColumns: 'Search by columns',
      onlyActivePosts: 'Only active posts',
      addEmployee: 'Add employee',
      createEmpl: 'Create an employee',
      editEmpL: 'Edit employee',
      sureWantToDeleteEmpl: 'Are you sure you want to delete the employee?',
      description: 'Description',
      responsible: 'Responsible',
      enterTitle: 'Enter title',
      enterDesc: 'Enter a description ',
      editGroup: 'Edit group',
      addGroup: 'Add group',
      sureWantToDeleteGroup: 'Do you really want to delete the group?',
      sureWantToDeleteTemplate: 'Do you really want to delete the template?',
      not: 'Not',
      width: 'Width',
      height: 'Height',
      macrosConnSett: 'Macroscop connection settings',
      categoryCrushing: 'Category (crushing)',
      floor: 'Floor',
      mainFloor: 'Main',
      mapSuccessDel: 'Map deleted successfully',
      errorOnMapDelete: 'Error when deleting a map',
      addMap: 'Add map',
      icon: 'Icon',
      markerType: 'Marker type',
      addConfig: 'Add configuration',
      editConfig: 'Edit Configuration',
      createConfig: 'Create a configuration',

      sureWantToDeleteConfig:
        'Are you sure you want to delete the configuration?',
      employee: 'Employee',
      employeeFullName: 'Full name of the employee',
      hr: 'Heart rate',
      pedometer: 'Pedometer',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      object: 'Object',
      vehicle: 'Vehicle',
      organization: 'Organization',
      age: 'Age',
      dedicEmployee: 'Dedicated employee',
      selectedObject: 'Selected object',
      dedicVehicle: 'Selected vehicle',
      disChargEmployee: 'Discharged employee',
      disChargObject: 'Discharged object',
      disChargedVehicle: 'Discharged vehicle',
      eventWithEmployee: 'Event with an employee',
      eventWithObject: 'Event with an object',
      eventWithVehicle: 'Event with the vehicle',
      violatorEmployee: 'Violator (employee)',
      violatorObject: 'Violator (object)',
      violatorVehicle: 'Violator (vehicle)',
      sound: 'Sound',
      parkingStart: 'Parking (Start)',
      parkingEnd: 'Parking (end)',
      dangerZoneEmpty: 'Danger zone (empty)',
      dangerZoneNotEmpty: 'Danger zone (not empty)',
      blink: 'Blink',
      areasEnter: 'Areas (enter)',
      areasExit: 'Areas (exit)',
      overSpeed: 'Over speed',
      unknownObjAccEnt: 'Unknown object access (login)',
      ITVCounter: 'ITV counter',
      reportFileCreated: 'Report file created',
      wrongPoss: 'Wrong position',
      wrongPossCancel: 'Wrong position (cancel)',
      manWithoutMove: 'Man without movement',
      manWithoutMoveCancel: 'Man without movement (cancel)',
      reportHighHeartRate: 'Heart rate over the upper limit',
      reportLowHeartRate: 'Heart rate going beyond the lower limit',
      anchortStateDown:'Anchor condition DOWN',
      anchortStateUp:"Anchor condition UP",
      noSignal: 'No signal',
      impact: 'Impact',
      digitInOn: 'Digit. In (on)',
      digitInOff: 'Digit. In (off)',
      btnPressed: 'Button pressed',
      pairingStart: 'Pairing (start)',
      pairingEnd: 'Pairing (end)',
      reqSignalToTag: 'Request to send a signal to a tag',
      siteEnter: 'Enabling the module for calculating coordinates',
      siteExit: 'Turn off the module for calculating coordinates',
      leUp: 'Enabling the module for calculating coordinates',
      anchorUp: 'Turning on the anchor',
      anchorDown: 'Turning of the anchor',
      levelEntr: 'Level (entrance)',
      levelExit: 'Level (exit)',
      reportExportDone: 'Uploading a report',
      reportExportCancel: 'Unloading an error report',
      workingDays: 'Working days',
      daysOfTheMonth: 'Days of the month',
      fromDateToDate: 'From number to number',
      wDAYD: 'X (working days) and Y (weekends)',
      shiftName: 'Shift name',
      dayOfBegin: 'The date of the beginning',
      expireDate: 'Expiration date',
      cyclicality: 'Cyclicity',
      addShifts: 'Add shifts',
      createShift: 'Create shift',
      sureWantToDelShift: 'Are you sure you want to delete a shift?',
      interractDistM: 'Interaction distance, m',
      dspw: 'Duration of staining Person without movement (cancel), sec',
      stainiDurFallS: 'Staining duration Fall, sec',
      interractTimeS: 'Interaction time, sec',
      stainingDurDangerZ: 'Duration of staining Danger zone (exit), sec',
      stainingDurBtnPress: 'Duration of staining Button pressed, sec',
      reactions: 'Reactions',
      listOfMaps: 'List of maps',
      shifts2: 'Shifts and shifts',
      users: 'Users',
      devices: 'Devices',
      organizations: 'Organizations',
      deleteUser: 'Delete user',
      sureWantDeleteUser: 'Are you sure you want to delete the user?',
      addingUser: 'Adding a user',
      editUser: 'User change',
      addUser: 'Add user',
      permissions: 'Permissions',
      interraction: 'Interraction',
      notEnoughtPermiss: 'Insufficient rights to view',
      abandondMaps: 'Leaved maps',
      jump: 'Jump',
      passingThrouthArea: 'Go through the area',
      state: 'State',
      categories: 'Categories',
      participant: 'Participant',

      tools: 'Tools',
      mapType: 'Map type',
      elements: 'Elements',
      objectLabels: 'Object labels',
      sureWantDeleteZone: 'Are you sure you want to delete the zone?',
      zoneDeleted: 'Zone deleted',
      onboard_number: "Onboard number",
      transport_mark: "Transport mark",
      state_number: "State number",
      driver: "Driver"
    },
  },


















  ru: {
    translation: {
      report: {
        
        report: 'Отчет',
        anchorReport: 'Отчет по анкерам',
        anchors: 'Отчет по анкерам',
        interractionReport: 'Отчет по взаимодействиям ',
        interactions: 'Отчет по взаимодействиям',
        event: 'Отчет по событиям',
        events: 'Отчет по событиям',
        transactionBetweenLevelsReport: 'Отчет по нахождению на картах',
        userActivityReport: 'Отчет об активности пользователей',
        sosReport: 'Отчет по SOS',
        biometricReport: 'Отчет по Биометрии',
        keeperBatteryLevel: 'Отчет по заряду батареи метки',
        transportEmployeeConvergence : "Отчет по сближению с транспортом",
        workingTimeReport: 'Отчет о рабочем времени',
        employeeWorkingTime: 'Отчет о рабочем времени',
        template: {
          title: 'Шаблоны',
          search: 'Поиск по названию шаблона',
          create: {
            title: 'Создание нового отчета',
            submit: 'Создать',
            name: 'Название шаблона',
          },
          params: {
            violator: 'Только нарушитель',
            post: 'Должность: {{val}}',
            employees: 'Владелец метки: {{val}}',
            zone: 'Область: {{val}}',
            hostname: 'Название хоста: {{val}}',
            sos_type: 'Тип сос: {{val}}',
            position: 'Должность: {{val}}',
            login: 'Логин: {{val}}',
            status: 'Статус: {{val}}',
            anchor_id: 'Анкер: {{val}}',
            group: 'Группа',
            individual: 'Индивидуально',
            shift: 'Смена: {{val}}',
            layout: 'Карта: {{val}}',

            event_type: 'Тип уведомления: {{val}}',
            BATTERY_LOW: 'Батарея разряжена',
            BUTTON_PRESSED: 'Нажата кнопка',
            FREE_FALL: 'Падение',
            MAN_DOWN: 'Обездвиженность',
            MAN_DOWN_CANCEL: 'Обездвиженность (отмена)',
            danger_zone_exit: 'Опасная зона (выход)',
            danger_zone_entrance: 'Опасная зона (вход)',

            from_user: 'От пользователя: {{val}}',
            to_employee: 'К работнику: {{val}}',
            group_id: 'Группа: {{val}}',

            http_method: 'Http метод: {{val}}',
            ip_address: 'IP адрес: {{val}}',
          },
          expand: 'Развернуть',
          collapse: 'Свернуть',
          use: 'Использовать',
          error: {
            name: 'Название обязательно',
          },
        },
        filter: {
          sender: 'Отправитель',
          tagOwner: 'Владелец метки',
          receiver: 'Получатель',
          apply: 'Применить',
          reset: 'Сбросить',
          anchor: 'Анкер',
          period: 'Период',
          from: 'C',
          by: 'По',
          onlyViolators: 'Только нарушитель',
          forToday: 'За 24 часа',
          forTodayBiometrics: 'По биометрии за 24 часа',
          sosType: 'Тип SOS',
          group: 'Группа',
          individual: 'Индивидуально',
          totalTime: 'Общее время',
          keeper: 'Кипер',
          tag: 'Метка',
          battery_state: 'Статус батареи',
        },
        user_activity: {
          created_at: 'Время',
          login: 'Логин',
          hostName: 'Название хоста',
          http_method: 'Http метод',
          host: 'Хост',
          apiMethod: 'API метод',
          ipAddress: 'IP адрес',
        },
        noCoordinateOrMap: 'Нет координат или не определена карта',
        group: 'Группе',
      },
      dashboard: {
        violator_count: 'Kоличество нарушителей: {{val}}',
        medium: 'Средняя',
        discharged: 'Разряжена',
        compleate: 'Полная',
        activeStaffsPointsChargeLevel: 'Уровень заряда устройств',
        fallEvents: 'События падения',
        employeesOnObject: 'Сейчас на объекте: {{val}} сотрудника',
        employeesOnObjectByOrganization: "Сотрудники на обьекте по подрядным организациям",
        employeesByMap:"Сотрудники по картам",
        employeesOnObjectByPosition: "По позициям (Должностям)",
        sosEventPerDay: 'Количество событий SOS за сутки: {{val}}',
        countOfHealthEvent:"Кол-во событий по здоровью",
        healthEventByMark: 'События здоровья по отметкам',
        healthEventByPosition: "Кол-во событий здоровья по должностям",
        fallEventsPerDay: 'Количество событий падения за сутки: {{val}}',
        showHideAll: 'Показать (скрыть) все',
        currentConfiguration: 'Текущая конфигурация',
        active: 'Активен',
        notAvailable: 'Недоступен',
        notConfigured: 'Не сконфигурирован',
        switchedOff: 'Выключен',
        infrastructureDevices: `Количество анкеров: {{val}}`,
        mapsCount: `Карты: {{val}}`,
        eventsInTheRegions: 'События в областях',
        ok: 'Ок',
        noSignal: 'Нет сигнала',
        notIssued: 'Не выдана',
        tagsCountDevices: 'Количество меток: {{val}}',
        immobilizationEvents: 'События обездвиженности',
        employees: 'Сотрудники',
        violator: 'Нарушители',
      },
      notification: {
        popup: 'Всплывающее уведомления',
        blocking: 'Блокируюшее уведомление',
        none: 'Без уведомления',
      },
      smtp: {
        title: 'Конфигурация SMTP-сервера',
        username: 'Пользователь',
        password: 'Пароль',
        smtp_server_address: 'Адрес smtp-сервера',
        smtp_server_port: 'Порт smtp-сервера',
        ssl_tls_address: 'SSL-TLS-адрес',
        ssl_tls_port: 'SSL tls-порт',
      },
      reaction: {
        event_type: 'Тип уведомления',
        mode: 'Тип реакции',
        audio: 'Аудио',
        email_to: 'На почту',
        regular: 'Всплывающее уведомления',
        danger: 'Блокируюшее уведомление',
        none: 'Без уведомления',
      },

      outOfCameraZone: 'Объект находится за пределами зоны действия камер',
      userWithoutAccess: 'У вас нет доступа для просмотра этой камеры',

      notOnMaps: 'На картах не найден',
      home: 'Общий вид',
      sandwich: 'Сэндвич',
      mainMap: 'Главная карта',
      accessRecovery: 'Восстановление доступа',
      accessRecoveryDesc:
        'Для восстановления доступа к аккаунту, обратитесь к администратору системы?',
      systemAuth: 'Авторизация в системе',
      wrongCredentials: 'Ошибка входа, неправильный логин или пароль', //todo
      enterLogin: 'Введите логин', //todo
      enterPassword: 'Введите Пароль',
      login: 'Логин', //todo

      password: 'Пароль',
      forgetPassword: 'Забыли пароль?',
      logIn: 'Войти',
      personnelNumber: 'Табельный номер',
      position: 'Должность',

      compleate: 'Полная',
      employees: 'Сотрудники',
      objects: 'Объекты',
      transport: 'Транспорт',
      labels: 'Ярлыки',
      areas: 'Области',
      maps: 'Карты',

      active: 'Активен',
      ok: 'Ок',
      medium: 'Средний',
      discharged: 'Разряжена',
      offShort: 'Выкл',
      noData: 'Нет данных',
      error: 'Ошибка',
      cancel: 'Отмена',
      unreaded: 'Непрочитанные',
      readed: 'Прочитанные',
      loading: 'Загрузка',
      loadMore: 'Загрузить еще...',

      interactionReport: 'Отчет по взаимодействиям',
      time: 'Время',
      cancelAnErrorOccurred: 'Отмена : произошла ошибка',
      fullName: 'ФИО',
      map: 'Карта',
      zone: 'Зона',
      linkToFile: 'Ссылка к файлу',
      excelReportRows: `Выгружаемый Отчет содержит : {{val}} cтрок в формате .xlsx`,
      bookmarks: 'Закладки',
      recentZones: 'Недавние зоны',
      frequentZones: 'Частые зоны',
      favorites: 'Избранное',
      objectType: 'Тип объекта',
      objectName: 'Имя объекта',
      enterToSearch: 'Введите для поиска',
      notFoundOnMaps: 'Не найден на картах',
      listIsEmpty: 'Список пуст',
      administrator: 'Системный администратор',
      manager: 'Менеджер',
      operator: 'Оператор',
      someErrorOccured: 'Произошла какая-то ошибка',
      currentPassword: 'Действующий пароль',
      pInputPass: 'Пожалуйста, введите ваш пароль!',
      telephone: 'Телефон',
      settings: 'Настройки',
      exit: 'Выйти',
      clickToSelectAnImageWithFloorPlan:
        'Нажмите чтобы выбрать изображение с планом помещения',
      chooseFloorPlan: 'Выбрать план помещения',
      enterRTLSCardId: 'Введите RTLS id карты',
      attention: 'Внимание',
      newPassword: 'Новый пароль',
      changePassword: 'Смена пароля',
      confirmNewPassword: 'Подвердите новый пароль',
      confirm: 'Подтвердить',
      enterRtlsMapId: 'Введите RTLS id карты',
      rtlsMapId: 'id карты RTLS',
      enterMapName: 'Введите название карты',
      name: 'Имя',
      requireField: 'Обязательное поле',
      cutLength: 'Длина отрезка',
      rpcrttmzim:
        'Координаты точки отсчета относительно главного нуля в метрах',
      calculationPoint: 'Точка отсчета',
      specRoomBounds: 'Укажите границы помещения',
      save: 'Сохранить',
      edit: 'Редактировать',
      create: 'Создать',
      roomBoundaries: 'границы помещения',
      main: 'Главный',
      floor: 'Этаж',
      category: 'Категория',
      siftplfcsc:
        'Выберите изображение из списка превью для системы координат конфигурация',
      sosSignal: 'Сигнал СОС',
      send: 'Отправить',
      sendSosQ: 'Вы действительно хотите отправить сигнал SOS ?',
      startTyping: 'Начните вводить',
      groups: 'Группы',
      noCameras: 'Нет камер',
      mapOptions: 'Параметры карты',
      yes: 'Да',
      selected: 'Выбрано',
      choosePeriod: 'Выберете период',
      enterText: 'Введите значение',
      daysOff: 'выходные дни',
      popupNotification: 'Всплывающее уведомления',
      blockingNotification: 'Блокируюшее уведомление',
      notSpecified: 'Не указано',
      allowEmailDistr: 'Разрешить рассылку по Электронной почте',
      email: 'Электронная почта',
      phoneNumber: 'Номер телефона',
      yCantDelLastEntry: 'Вы не можете удалить последнюю запись',
      deleteEntry: 'Удалить запись',
      addEmail: 'Добавить Электронную почту',
      allReadyRegistered: 'Пользователь с таким именем уже зарегистрирован.',
      inValidRoleSelect: 'Не правильный выбор роли.',
      undefinedError: 'Неопределенная ошибка',
      userName: 'Имя пользователя',
      enterName: 'Введите имя',
      surname: 'Фамилия',
      enterEmail: 'Введите Адрес электронной почты',
      role: 'Роль',
      intervalStart: 'Дата и время начала интервала',
      intervalEnd: 'Дата и время конца интервала',
      movementHistory: 'История перемещений',
      personalNum: 'Табельный номер',
      violator: 'Нарушитель',
      violatorDetected: 'Нарушитель выявлен',
      danger: 'Опасности',
      employeeOnDanger: 'Работник в опасности',
      movement: 'Движение',
      temperature: 'Температура',
      dropImpact: 'Падение/удар',
      regulation: 'Положение',
      barometer: 'Барометр',
      barometerTemp: 'Температура барометра',
      pulceBracelet: 'Пульс (браслет)',
      pedometBracelet: 'Шагомер (браслет)',
      batteryBracelet: 'Батарея (браслет)',
      signalSent: 'Сигнал на метку отправлен!',
      signalNotSendRepaid: 'Сигнал не доставлен, повторите отправку!',
      labelNumber: 'Номер метки',
      battery: 'Батарея',
      anchors: 'Анкеры',
      notifications: 'Уведомления',
      camera: 'Камера',
      menu: 'Меню',
      search: 'Поиск',
      profile: 'Профиль',
      sendSos: 'Отправить SOS',
      zoomIn: 'Увеличить',
      zoomOut: 'Уменьшить',
      upper: 'Выше',
      lower: 'Ниже',
      mapSettings: 'Параметры карты',
      totalNumberOfActiveUsers: 'Общее количество активных сотрудников',
      editMap: 'Редактировать карту',
      deleteMap: 'Удалить карту',
      editPolygons: 'Редактировать полигоны',
      editZones: 'Редактировать зоны',
      editZone: 'Редактировать зону',
      deleteZone: 'Удалить зону',
      deleteAnchor: 'Редактировать анкеры',
      editEmployee: 'Редактировать сотрудника',
      mapsFavorites: 'Избранные карты',
      notificationCleared: 'Уведомления очищены',
      incorrectInterval: 'Некорректный интервал!',
      back: 'Назад',
      keeperDataNotFound: 'Данные кипера не найдены',
      sorry: 'Извините!',
      yCantDrawLikeThat: 'Вы не можете нарисовать так!',
      dashboards: 'Дэшборды',
      reports: 'Отчеты',

      configuration: 'Конфигурация',
      noType: 'Без типа',
      equipment: 'Оборудование',
      dangerZone: 'Опасная зона',
      greenZone: 'Зеленая зона',
      forbiddenZone: 'Запретная зона',
      title: 'Название',
      zoneTitle: 'Название зоны',
      zoneType: 'Тип зоны',
      selectZoneType: 'Выберите тип зоны',
      selectEquipment: 'Выберите оборудование',
      zoneActiveTime: 'Время активности зоны',
      usersAccess: 'Доступ для пользователей',
      selectUsers: 'Выберите пользователей',
      arbitraryArea: 'Произвольная область',
      square: 'Квадрат',
      delete: 'Удалить',
      succesUpdate: 'Успешно обновлено',
      succesCreated: 'Успешно создано',
      zoneCreationError: 'Ошибка создания зоны',
      type: 'Тип',
      actions: 'Действия',
      addZone: 'Добавить зону',
      createVisibility: 'Создание видимости',
      color: 'Цвет',
      equipmentTag: 'Тег оборудования',
      marker: 'Маркер',
      putMarker: 'Поставить маркер',
      close: 'Закрыть',
      equipmentList: 'Список оборудования',
      addArea: 'Добавить область',
      confirmAction: 'Подтвердите действие',
      confirmAreaDelete: 'Вы действительно хотите удалить эту область',
      region: 'Область',
      reDrawRegion: 'Нарисовать область заного',
      track: 'Отслеживать',
      onMap: 'На карте',
      urgently: 'Срочно',
      videoMonitor: 'Камера видеонаблюдения',
      outOfCameraArea: 'Вне зон с камерами',
      dinningRoom: 'Столовая',
      mapNotFound: 'Карта не найдена',
      removeAllMarks: 'Убрать все метки',
      excelFileCreating: 'Excel файл : {{val}} создается',
      excelFileReady: 'Excel файл : {{val}} готов',
      excelFileRowsC:
        'Выгружаемый Отчет содержит : {{val}} cтрок в формате .xlsx',
      errorOnFileCreation: 'Ошибка при создании файла',
      reportType: 'Тип отчета',
      batteryLow: 'Батарея разряжена',
      buttonPressed: 'Нажата кнопка',
      theFall: 'Падение',
      immobility: 'Обездвиженность',
      immobilityCancel: 'Обездвиженность (отмена)',
      dangerZoneExit: 'Опасная зона (выход)',
      dangerZoneEntr: 'Опасная зона (вход)',

      status: 'Статус',
      eventType: 'Тип события',
      sex: 'Пол',
      min_rate: 'Нижняя граница ЧСС',
      max_rate: 'Верхняя граница ЧСС',
      birth_date: 'Дата рождения',
      shift: 'Cмена/вахта',
      shiftNotSpecified: 'Смена/вахта не указана',
      zoneNotSpecified: 'Зона не указана',
      mapNotSpecified: 'Карта не указана',

      information: 'Информация',
      has_biometry: 'Имеет биометрию',
      has_special_values_of_biometrics: 'Имеет особые значения биометрии',
      notificationType: 'Тип уведомления',
      interractTime: 'Время взаимодейтвия',
      toEmployee: 'C кем взаимодействовал',
      groupName: 'Имя группы',
      receiver: 'Получатель',
      receiverNotSpecified: 'Получатель не указан',
      startTime: 'Время начала',
      endTime: 'Время окончания',
      duration: 'Продолжительность',
      host: 'Хост',
      apiMethod: 'Метод апи',
      hostName: 'Имя хоста',
      ipAddress: 'Адрес IP',
      activeTime: 'Активное время',
      inActiveTime: 'Время неактивности',
      unreliableCoordinatesTime: 'Время в недостоверных координатах',
      activeUsersCount: 'Количество активных сотрудников',
      mapsNotYetAddToCateg: 'Пока карты не добавлены в эту категорию',
      pictureName: 'Название картинки',
      sortOrder: 'Порядок сортировки',
      enterSortOrder: 'Введите порядок сортировки',
      picLoading: 'Загрузка картинки',
      add: 'Добавить',
      image: 'Изображение',
      sureWantDelete: 'Вы действительно хотите удалить?',

      pathToData: 'Путь к данным',
      interval: 'Интервал',
      editSettings: 'Редактировать настройки',
      patronymic: 'Отчество',
      mark: 'Метка',
      label: 'Ярлык',
      violatorTerm: 'Срок нарушителя',
      searchByColumns: 'Поиск по колонкам',
      onlyActivePosts: 'Только активные записи',
      addEmployee: 'Добавить сотрудника',
      createEmpl: 'Создание сотрудника',
      editEmpL: 'Редактировать сотрудника',
      sureWantToDeleteEmpl: 'Вы действительно хотите прекратить действие?',
      description: 'Описание',
      responsible: 'Ответственный',
      enterTitle: 'Введите название',
      enterDesc: 'Введите описание ',
      editGroup: 'Редактировать группу',
      addGroup: 'Добавить группу',
      sureWantToDeleteGroup: 'Вы действительно хотите удалить группу?',
      sureWantToDeleteTemplate: 'Вы действительно хотите удалить шаблон?',
      not: 'Нет',
      width: 'Ширина',
      height: 'Высота',
      macrosConnSett: 'Настройки подключения Macroscop',
      categoryCrushing: 'Категория (дробление)',
      mainFloor: 'Главная карта',
      mapSuccessDel: 'Карта успешно удалена',
      errorOnMapDelete: 'Ошибка при удалении карты',
      addMap: 'Добавить Карту',
      icon: 'Иконка',
      markerType: 'Тип маркера',
      addConfig: 'Добавить конфигурацию',
      editConfig: 'Редактировать конфигурацию',
      createConfig: 'Создание конфигурации',

      sureWantToDeleteConfig: 'Вы действительно хотите удалить конфигурацию?',
      employee: 'Сотрудник',
      employeeFullName: 'ФИО сотрудника',
      hr: 'ЧСС',
      pedometer: 'Шагомер',
      gender: 'Пол',
      male: 'Мужской',
      female: 'Женский',
      object: 'Объект',
      age: 'Возраст',
      vehicle: 'Транспортное средство',
      organization: 'Организация',
      dedicEmployee: 'Выделенный сотрудник',
      selectedObject: 'Выделенный объект',
      dedicVehicle: 'Выделенное транспортное средство',
      disChargEmployee: 'Разряженный сотрудник',
      disChargObject: 'Разряженный объект',
      disChargedVehicle: 'Разряженное транспортное средство',
      eventWithEmployee: 'Событие с сотрудником',
      eventWithObject: 'Событие с объектом',
      eventWithVehicle: 'Событие с транспортным средством',
      violatorEmployee: 'Нарушитель (сотрудник)',
      violatorObject: 'Нарушитель (объект)',
      violatorVehicle: 'Зиянкес (Нарушитель (транспортное средство))',
      sound: 'Звук',
      parkingStart: 'Парковка (начало)',
      parkingEnd: 'Парковка (конец)',
      dangerZoneEmpty: 'Опасная зона (пустая)',
      dangerZoneNotEmpty: 'Опасная зона (не пустая)',
      blink: 'Сигнал от метки',
      areasEnter: 'Области (вход)',
      areasExit: 'Области (выход)',
      overSpeed: 'Превышение скорости',
      unknownObjAccEnt: 'Доступ неизвестного объекта (вход)',
      ITVCounter: 'ITV счетчик',
      reportFileCreated: 'Файл отчета создан',
      wrongPoss: 'Неправильное положение',
      wrongPossCancel: 'Неправильное положение (отмена)',
      manWithoutMove: 'Человек без движения',
      manWithoutMoveCancel: 'Человек без движения (отмена)',
      reportHighHeartRate: 'Выход ЧСС за верхнюю границу',
      reportLowHeartRate: 'Выход ЧСС за нижнюю границу',
      anchortStateUp: "Анкер состояние UP",
      anchortStateDown:'Анкер состояние DOWN',
      impact: 'Удар',
      digitInOn: 'Цифр. Вх (вкл)',
      digitInOff: 'Цифр. Вх (выкл)',
      btnPressed: 'Кнопка нажата',
      pairingStart: 'Сопряжение (начало)',
      pairingEnd: 'Сопряжение (конец)',
      reqSignalToTag: 'Запрос на отправку сигнала на метку',
      siteEnter: 'Включение модуля расчета координат',
      siteExit: 'Выключение модуля расчета координат',
      leUp: 'Включение модуля расчета координат',
      anchorUp: 'Включение анкера',
      anchorDown: 'Выключение анкера',
      levelEntr: 'Уровень (вход)',
      levelExit: 'Уровень (выход)',
      reportExportDone: 'Выгрузка отчета',
      reportExportCancel: 'Выгрузка отчета ошибка',
      reportExportInProcess: 'Выгрузка отчета в процессе',
      workingDays: 'Рабочие дни',
      daysOfTheMonth: 'Дни месяца',
      fromDateToDate: 'С числа по число',
      wDAYD: 'X (рабочих дней) и Y (выходные дни)',
      shiftName: 'Имя смены',
      dayOfBegin: 'Дата начала',
      expireDate: 'Дата окончания',
      cyclicality: 'Цикличность',
      addShifts: 'Добавить Смены',
      createShift: 'Создание смены',
      sureWantToDelShift: 'Вы действительно хотите удалить смену?',
      interractDistM: 'Расстояние взаимодействия, м',
      dspw: 'Длительность окрашивания Человек без движения (отмена), сек',
      stainiDurFallS: 'Длительность окрашивания Падение, сек',
      interractTimeS: 'Время взаимодействия, сек',
      stainingDurDangerZ: 'Длительность окрашивания Опасная зона (выход), сек',
      stainingDurBtnPress: 'Длительность окрашивания Кнопка нажата, сек',
      reactions: 'Реакции',
      listOfMaps: 'Список карт',
      shifts2: 'Смены и Вахты',
      users: 'Пользователи',
      devices: 'Устройства',
      organizations: 'Организации',
      deleteUser: 'Удалить пользователя',
      sureWantDeleteUser: 'Вы действительно хотите удалить пользователя?',
      addingUser: 'Добавление пользователя',
      editUser: 'Изменение пользователя',
      addUser: 'Добавить пользователя',
      permissions: 'Доступы',
      interraction: 'Взаимодействие',
      notEnoughtPermiss: 'Недостаточно прав для просмотра',
      abandondMaps: 'Покинутые карты',
      jump: 'перейти',
      passingThrouthArea: 'Пройти через область',
      state: 'Состояние',
      categories: 'Категории',
      participant: 'Участник',
      tools: 'Инструменты',
      mapType: 'Тип карты',
      elements: 'Элементы',
      objectLabels: 'Подписи объектов',
      sureWantDeleteZone: 'Вы действительно хотите удалить зону?',
      zoneDeleted: 'Зона удалена',
      onboard_number: "Бортовой номер",
      transport_mark: "Транспортная метка",
      state_number: "Госс. номер",
      driver: "Водитель"
    },
  },














  kz: {
    translation: {
      report: {
        report: 'Есеп беру',
        anchorReport: 'Анкерлік есеп',
        anchors: 'Анкерлік есеп',
        interractionReport: 'Өзара әрекеттесу туралы есеп',
        interactions: 'Өзара әрекеттесу туралы есеп',
        event: 'Оқиға туралы есеп',
        events: 'Оқиға туралы есеп',
        transactionBetweenLevelsReport: 'Карталар туралы есеп',
        userActivityReport: 'Пайдаланушы әрекетінің есебі',
        workingTimeReport: 'Жұмыс уақыты туралы есеп',
        biometricReport: 'Биометриялық есеп',
        keeperBatteryLevel: 'Батарея зарядының есебін белгілеңіз',
        transportEmployeeConvergence : "Көлікке жақындау",
        employeeWorkingTime: 'Жұмыс уақыты туралы есеп',
        sosReport: 'SOS бойынша отчет',
        noCoordinateOrMap: 'Координаттар немесе карта анықталмаған',
        filter: {
          sender: 'Жіберуші',
          receiver: 'Алушы',
          tagOwner: 'Тег иесі',
          apply: 'Қолдану',
          reset: 'Қалпына келтіру',
          anchor: 'Анкер',
          period: 'Кезең',
          from: 'C',
          by: 'По',
          onlyViolators: 'Тек бұзушы',
          forToday: '24 сағатта',
          forTodayBiometrics: 'Биометрия бойынша 24 сағатта',
          sosType: 'SOS түрі',
          group: 'Топ',
          individual: 'Жеке',
          totalTime: 'Жалпы уақыт',
          keeper: 'Кипер',
          tag: 'Метка',
          battery_state: 'Батарея күйі',
        },
        template: {
          title: 'Үлгілер',
          search: 'Үлгі атауы бойынша іздеу',
          create: {
            title: 'Жаңа үлгі жасаңыз',
            submit: 'Жасау',
            name: 'Үлгі атауы',
          },
          params: {
            violator: 'Тек бұзушылар',
            post: 'Мамандығы: {{val}}',
            employees: 'Тег иесі: {{val}}',
            zone: 'Аймақ: {{val}}',
            hostname: 'Хост атауы: {{val}}',
            sos_type: 'Sos түрі: {{val}}',
            position: 'Мамандығы: {{val}}',
            login: 'Кіру: {{val}}',
            status: 'Күй: {{val}}',
            anchor_id: 'Якорь: {{val}}',
            shift: 'Ауысым: {{val}}',
            layout: 'Карта: {{val}}',
            group: 'Топ',
            individual: 'Жеке',

            event_type: 'Оқиға түрі: {{val}}',
            BATTERY_LOW: 'Батарея заряды төмен',
            BUTTON_PRESSED: 'Түйме басылды',
            FREE_FALL: 'Күз',
            MAN_DOWN: 'Қозғалыссыздық',
            MAN_DOWN_CANCEL: 'Қозғалмауы (болдырмау)',
            danger_zone_exit: 'Қауіпті аймақ (шығу)',
            danger_zone_entrance: 'Қауіпті аймақ (кіреберіс)',

            from_user: 'Пайдаланушыдан: {{val}}',
            to_employee: 'Жұмысшыға: {{val}}',
            group_id: 'Топ: {{val}}',

            http_method: 'Http әдісі: {{val}}',
            ip_address: 'IP мекенжайы: {{val}}',
          },
          expand: 'Кеңейту',
          collapse: 'Жыйрату',
          use: 'Қолдану',
          error: {
            name: 'Аты қажет',
          },
        },
        user_activity: {
          created_at: 'Уақыт',
          login: 'Логин',
          hostname: 'Хост аты',
          http_method: 'Http әдісі',
          host: 'Хост',
          apiMethod: 'API әдісі',
          ipAddress: 'IP мекенжайы',
        },
      },
      dashboard: {
        violator_count: 'Бұзушылар саны: {{val}}',
        medium: 'Орташа',
        discharged: 'Босатылды',
        compleate: 'Аяқталды',
        activeStaffsPointsChargeLevel: 'Тегтер батарея деңгейі',
        employeesOnObject: 'Қазіргі уақытта сайтта: {{val}} қызметкер',
        employeesOnObjectByOrganization: "Мердігерлердің жұмыс орнындағы қызметкерлері",
        employeesByMap:"Карта қызметкерлері",
        employeesOnObjectByPosition: "Лауазымы бойынша (позициясы)",
        countOfHealthEvent:"Денсаулық сақтау іс-шараларының саны",
        healthEventByMark:"Белгілері бойынша денсаулық оқиғалары",
        healthEventByPosition: "Позиция бойынша денсаулық оқиғаларының саны",
        fallEvents: 'Күз оқиғалары',
        fallEventsPerDay: 'Күніне күзгі оқиға: {{val}}',
        sosEventPerDay: 'Күніне SOS оқиғалары: {{val}}',
        showHideAll: 'Барлығын көрсету (жасыру).',
        currentConfiguration: 'Ағымдағы конфигурация',
        active: 'Белсенді',
        notAvailable: 'Жоқ',
        notConfigured: 'Конфигурацияланбаған',
        switchedOff: 'Өшірілген',
        infrastructureDevices: 'Анкерлер саны: {{val}}',
        mapsCount: `Карталар: {{val}}`,
        eventsInTheRegions: 'Өңірлердегі іс-шаралар',
        ok: 'Ок',
        noSignal: 'Сигнал жоқ',
        notIssued: 'Шығарылған жоқ',
        tagsCountDevices: 'Тегтер: {{val}} құрылғылар',
        immobilizationEvents: 'Иммобилизация оқиғалары',
        employees: 'Қызметкерлер',
        violator: 'Бұзушы',
      },
      smtp: {
        title: 'SMTP сервер конфигурациясы',
        username: 'Пайдаланушы',
        password: 'Құпия сөз',
        smtp_server_address: 'Smtp сервер мекенжайы',
        smtp_server_port: 'Smtp сервер порты',
        ssl_tls_address: 'SSL-TLS мекенжайы',
        ssl_tls_port: 'SSL tls порты',
      },
      notification: {
        popup: 'Қалқымалы хабарландыру',
        blocking: 'Блоктау хабарландыруы',
        none: 'Ескертусіз',
      },
      reaction: {
        event_type: 'Хабарландыру түрі',
        mode: 'Реакция түрі',
        audio: 'Аудио',
        email_to: 'To mail',
        regular: 'Қалқымалы хабарландыру',
        danger: 'Блоктау хабарландыруы',
        none: 'Ескертусіз',
      },
      outOfCameraZone: 'Бұл нысан камералар аймақта ішінде емес',
      userWithoutAccess: 'Бұл камераны көруге рұқсатыңыз жоқ',
      notOnMaps: 'Карталардан табылмады',
      home: 'Үй',
      sandwich: 'Сэндвич',
      mainMap: 'Негізгі',
      accessRecovery: '«Қалпына келтіруге қол жеткізу',
      accessRecoveryDesc:
        'Тіркелгіңізге кіру рұқсатын қалпына келтіру үшін жүйе әкімшісіне хабарласыңыз ба?',
      systemAuth: 'Жүйені авторизациялау',
      wrongCredentials:
        'Жүйеге кіру қатесі, қате пайдаланушы аты немесе құпия сөз', //todo
      login: 'Кіру',
      password: 'Құпия сөз',
      enterPassword: 'Құпия сөзді енгізіңіз',
      forgetPassword: 'Құпия сөзді ұмыттыңыз ба?',
      logIn: 'Кіру үшін',
      personnelNumber: 'Персонал саны',
      position: 'Позиция',

      compleate: 'Аяқталды',
      employees: 'Қызметкерлер',
      objects: 'Объектілер',
      transport: 'Көлік',
      labels: 'Жапсырмалар',
      areas: 'Аудандар',
      maps: 'Карталар',

      active: 'Белсенді',

      ok: 'Ок',
      medium: 'Орташа',
      discharged: 'Босатылды',
      offShort: 'Өшірулі',
      noData: 'Мәлімет жоқ',
      error: 'Қате',
      cancel: 'Болдырмау',
      unreaded: 'Оқылмаған',
      readed: 'Оқыңыз',
      loading: 'Жүктелуде',
      loadMore: 'Қосымша жүктеу...',

      interactionReport: 'Өзара әрекеттесу туралы есеп',
      time: 'Уақыт',
      cancelAnErrorOccurred: 'Болдырмау: қате орын алды',
      fullName: 'Толық аты',
      map: 'Карта',
      zone: 'Аймақ',
      linkToFile: 'Файлға сілтеме',
      excelReportRows: `Жүктеп салынған есепте мыналар бар:
      .xlsx пішіміндегі {{val}} жол`,
      bookmarks: 'Бетбелгілер',
      recentZones: 'Соңғы аймақтар',
      frequentZones: 'Жиі аймақтар',
      favorites: 'Таңдаулылар',
      objectType: 'Объект түрі',
      objectName: 'Нысан атауы',
      enterToSearch: 'Іздеу үшін енгізіңіз',
      notFoundOnMaps: 'Карталардан табылмады',
      listIsEmpty: 'тізім бос',
      administrator: 'Жүйелік администратор',
      manager: 'Менеджер',
      operator: 'Оператор',
      someErrorOccured: 'Кейбір қате орын алды',
      currentPassword: 'Ағымдағы Құпия сөз',
      pInputPass: 'Құпия сөзіңізді енгізіңіз!',
      telephone: 'Телефон',
      settings: 'параметрлері',
      exit: 'Шығу',
      clickToSelectAnImageWithFloorPlan:
        'Еден жоспары бар кескінді таңдау үшін басыңыз',
      chooseFloorPlan: 'Еден жоспарын таңдаңыз',
      enterRTLSCardId: 'RTLS картасының идентификаторын енгізіңіз',
      attention: 'Назар аударыңыз',
      newPassword: 'Жаңа пароль',
      changePassword: 'Құпия сөзді өзгерту',
      confirmNewPassword: 'Жаңа құпиясөзді растаңыз',
      confirm: 'Растау',
      enterRtlsMapId: 'RTLS картасының идентификаторын енгізіңіз',
      rtlsMapId: 'RTLS картасының идентификаторы',
      enterMapName: 'Карта атауын енгізіңіз',
      name: 'Аты',
      requireField: 'Міндетті өріс',
      cutLength: 'Ұзындығын кесіңіз',
      rpcrttmzim:
        'Анықтамалық нүкте координаттары метрдегі негізгі нөлге қатысты',
      calculationPoint: 'Жоғалу нүктесі',
      specRoomBounds: 'Бөлменің шекарасын көрсетіңіз',
      save: 'Сақтау',
      edit: 'Өңдеу',
      create: 'Жасау',
      roomBoundaries: 'бөлме шекаралары',
      main: 'Негізгі',
      floor: 'Еден',
      category: 'Санат',
      siftplfcsc:
        'Координаттар жүйесі үшін алдын ала қарау тізімінен суретті таңдаңыз конфигурация',
      sosSignal: 'SOS сигналы',
      send: 'Жіберу',
      sendSosQ: 'SOS сигналы қалай жұмыс істейді?',
      startTyping: 'Теруді бастаңыз',
      groups: 'Топтар',
      noCameras: 'Камералар жоқ',
      mapOptions: 'Карта опциялары',
      yes: 'Иә',
      selected: 'Таңдалған',
      choosePeriod: 'Кезеңді таңдаңыз',
      enterText: 'Кезеңді таңдаңыз',
      workingDays: 'Жұмыс күндері',
      daysOff: 'Демалыс күндері',
      popupNotification: 'Қалқымалы хабарландыру',
      blockingNotification: 'Блокируюшее уведомление',
      notSpecified: 'Көрсетілмеген',
      allowEmailDistr: 'Разрешить рассылку по Электронной почте',
      email: 'Электрондық пошта',
      phoneNumber: 'Телефон нөмірі',
      yCantDelLastEntry: 'Соңғы жазбаны жою мүмкін емес',
      deleteEntry: 'Жазбаны жою',
      addEmail: 'Электрондық поштаны қосу',
      allReadyRegistered: 'Бұл аты бар пайдаланушы әлдеқашан тіркелген.',
      inValidRoleSelect: 'Рөлді қате таңдау.',
      undefinedError: 'Анықталмаған қате',
      userName: 'Қолданушының аты',
      enterName: 'Атыңызды енгізіңіз',
      surname: 'Тегі',
      enterEmail: 'Электрондық пошта мекенжайыңызды енгізіңіз',
      role: 'Роль',
      intervalStart: 'Аралықтың басталу күні мен уақыты',
      intervalEnd: 'Аралықтың аяқталу күні мен уақыты',
      movementHistory: 'Қозғалыс тарихы',
      personalNum: 'Персонал саны',
      violator: 'Бұзушы',
      violatorDetected: 'Зиянкес анықталды',
      danger: 'қауіптер',
      employeeOnDanger: 'Қауіпті жұмысшы',
      movement: 'Трафик',
      temperature: 'Температура',
      dropImpact: 'Құлау/әсер ету',
      regulation: 'Положение',
      barometer: 'Барометр',
      barometerTemp: 'Барометр температурасы',
      pulceBracelet: 'Импульс (білезік)',
      pedometBracelet: 'Педометр (білезік)',
      batteryBracelet: 'Батарея (білезік)',
      signalSent: 'Тег жіберілді!',
      signalNotSendRepaid: 'Сигнал жеткізілмеді, қайта жіберіңіз!',
      labelNumber: 'Белгі нөмірі',
      battery: 'Battery',
      notifications: 'Хабарландырулар',
      camera: 'Камерасы',
      menu: 'Мәзір',
      search: 'Іздеу',
      profile: 'Профиль',
      sendSos: 'SOS жіберу',
      zoomIn: 'үлкейту',
      zoomOut: 'кішірейту',
      upper: 'Жоғарыда',
      lower: 'Төменде',
      mapSettings: 'Карта параметрлері',
      totalNumberOfActiveUsers: 'Белсенді қызметкерлердің жалпы саны',
      editMap: 'Картаны өңдеу',
      deleteMap: 'Картаны жою',
      editPolygons: 'Көпбұрыштарды өңдеу',
      editZones: 'Аймақтарды өңдеу',
      editZone: 'Аймақты өңдеу',
      deleteZone: 'Аймақты жою',
      deleteAnchor: 'Анкерді жою',
      deleteEmployee: 'Қызметкерді өңдеу',
      mapsFavorites: 'таңдаулы карталар',
      notificationCleared: 'Хабарландырулар өшірілді',
      incorrectInterval: 'Қате аралық!',
      back: 'Артқа',
      keeperDataNotFound: 'Keeper деректері табылмады',
      sorry: 'Кешіріңіз!',
      yCantDrawLikeThat: 'Сіз бұлай сурет сала алмайсыз!',
      dashboards: 'Бақылау тақталары',
      reports: 'Eсеп береді',
      configuration: 'Конфигурация',
      noType: 'Түрі жоқ',
      equipment: 'Жабдық',
      dangerZone: 'Қауіпті аймақ',
      greenZone: 'Жасыл аймақ',
      forbiddenZone: 'Щектеулі аймақ',
      title: 'Аты',
      zoneTitle: 'Аймақ атауы',
      zoneType: 'Аймақ түрі',
      selectZoneType: 'Аймақ түрін таңдаңыз',
      selectEquipment: 'Жабдықты таңдаңыз',
      zoneActiveTime: 'Белсенді аймақ уақыты',
      usersAccess: 'Белсенді аймақ уақыты',
      selectUsers: 'Пайдаланушыларды таңдаңыз',
      arbitraryArea: 'Арнайы аумақ',
      square: 'Шаршы',
      delete: 'Жою',
      succesUpdate: 'Сәтті жаңартылды',
      succesCreated: 'Сәтті құрылды',
      zoneCreationError: 'Аймақ құру қатесі',
      type: 'Түрі',
      actions: 'Әрекеттер',
      addZone: 'Аймақ қосу',
      createVisibility: 'Көрінуді құру',
      color: 'Түс',
      equipmentTag: 'Жабдық белгісі',
      marker: 'Маркер',
      putMarker: 'Маркер қойыңыз',
      close: 'жабық',
      equipmentList: 'Жабдықтардың тізімі',
      addArea: 'Аймақты қосыңыз',
      confirmAction: 'Әрекетті растау',
      confirmAreaDelete: 'Бұл аймақты шынымен жойғыңыз келе ме?',
      region: 'Аймақ',
      reDrawRegion: 'Ауданды сызыңыз',
      track: 'Трек',
      onMap: 'Картада',
      urgently: 'Шұғыл',
      videoMonitor: 'Бейнебақылау камерасы',
      outOfCameraArea: 'Камералары бар сыртқы аймақтар',
      dinningRoom: 'Асхана',

      mapNotFound: 'Карта табылмады',
      removeAllMarks: 'Барлық белгілерді алып тастаңыз',
      excelFileCreating: 'Excel файлы: {{val}} жасалды',
      excelFileReady: 'Excel файлы: {{val}} жасалды',
      excelFileRowsC:
        'Жүктеп салынатын есепте: .xlsx пішіміндегі {{val}} жолдар',
      errorOnFileCreation: 'Файлды жасау қатесі',
      reportType: 'Есеп түрі',
      batteryLow: 'Батарея заряды төмен',
      buttonPressed: 'Түймесі басылды',
      theFall: 'Күз',
      immobility: 'қозғалыссыздық',
      immobilityCancel: 'Қозғалмаушылық (жоқтау)',
      dangerZoneExit: 'Қауіпті аймақ (шығу)',
      dangerZoneEntr: 'Қауіпті аймақ (кіреберіс)',

      status: 'Күй',
      eventType: 'Оқиға түрі',
      sex: 'Пол',
      min_rate: 'Жүрек соғу жиілігінің төменгі шегі',
      max_rate: 'Жүрек соғу жиілігінің жоғарғы шегі',
      birth_date: 'Туған күні',
      shift: 'Ауысым/сағат',
      shiftNotSpecified: 'Смена/вахта не указана',
      zoneNotSpecified: 'Аймақ көрсетілмеген',
      mapNotSpecified: 'Карта көрсетілмеген',

      information: 'Мәліметтер',
      has_biometry: 'Биометриясы бар',
      has_special_values_of_biometrics: 'Арнайы биометриялық мәндерге ие',
      notificationType: 'Хабарландыру түрі',
      interractTime: 'Өзара әрекеттесу уақыты',
      toEmployee: 'Сіз кіммен араластыңыз?',
      groupName: 'Топ аты',
      receiver: 'Алушы',
      receiverNotSpecified: 'Қабылдаушы көрсетілмеген',
      startTime: 'Басталу уақыты',
      endTime: 'Аяқталу уақыты',
      duration: 'Ұзақтығы',
      host: 'Хост',
      apiMethod: 'API әдісі',
      hostName: 'Хост атауы',
      ipAddress: 'IP адресі',
      activeTime: 'Белсенді уақыт',
      inActiveTime: 'Әрекетсіздік уақыты',
      unreliableCoordinatesTime: 'Жарамсыз координаттардағы уақыт',
      activeUsersCount: 'Белсенді қызметкерлер саны',
      mapsNotYetAddToCateg: 'Карталар осы санатқа қосылғанша',
      pictureName: 'Суреттің аты',
      sortOrder: 'Сұрыптау тәртібі',
      enterSortOrder: 'Сұрыптау ретін енгізіңіз',
      picLoading: 'Сурет жүктелуде',
      add: 'қосу',
      image: 'Кескін',
      sureWantDelete: 'Жойғыңыз келетініне сенімдісіз бе?',
      interval: 'Интервал',
      pathToData: 'Деректер жолы',
      editSettings: 'Параметрлерді өңдеу',

      patronymic: 'әкесінің аты',
      mark: 'Заттаңба',
      violatorTerm: 'Тәртіп бұзушының мерзімі',
      searchByColumns: 'Бағандар бойынша іздеу',
      onlyActivePosts: 'Тек белсенді посттар',
      addEmployee: 'Қызметкерді қосыңыз',
      createEmpl: 'Қызметкерді жасаңыз',
      editEmpL: 'Қызметкерді өңдеу',
      sureWantToDeleteEmpl: 'Сіз шынымен қызметкерді жойғыңыз келе ме?',
      description: 'Сипаттама',
      responsible: 'Жауапты',
      enterTitle: 'Тақырыпты енгізіңіз',
      enterDesc: 'Сипаттама енгізіңіз ',
      editGroup: 'Топты өңдеу',
      addGroup: 'Топ қосу',
      sureWantToDeleteGroup: 'Топты шынымен жойғыңыз келе ме?',
      sureWantToDeleteTemplate: 'Үлгіні шынымен жойғыңыз келе ме?',
      not: 'Жоқ',
      width: 'Ені',
      height: 'Биіктігі',
      macrosConnSett: 'Макроскоп қосылым параметрлері',
      categoryCrushing: 'Санат (ұсақтау)',
      mainFloor: 'Негізгі',
      mapSuccessDel: 'Карта сәтті жойылды',
      errorOnMapDelete: 'Картаны жою кезіндегі қате',
      addMap: 'Картаны қосу',
      icon: 'Белгіше',
      markerType: 'маркер түрі',
      addConfig: 'Конфигурация қосу',
      editConfig: 'Конфигурацияны өңдеу',
      createConfig: 'Конфигурация жасаңыз',

      sureWantToDeleteConfig:
        'Конфигурацияны жойғыңыз келетініне сенімдісіз бе?',
      employee: 'Қызметкер',
      employeeFullName: 'Қызметкердің толық аты-жөні',
      hr: 'Жүрек соғу жиілігі',
      pedometer: 'Педометр',
      object: 'Объект',
      vehicle: 'Көлік',
      organization: 'Организация',
      gender: 'Жыныс',
      male: 'Еркек',
      female: 'Әйел',
      age: 'Жасы',
      dedicEmployee: 'Арнайы қызметкер',
      selectedObject: 'Таңдалған нысан',
      dedicVehicle: 'Dedicated vehicle',
      disChargEmployee: 'Жұмыстан босатылған қызметкер',
      disChargObject: 'Зарядталған объект',
      disChargedVehicle: 'Босатылған көлік',
      eventWithEmployee: 'Қызметкермен болған оқиға',
      eventWithObject: 'Объектісі бар оқиға',
      eventWithVehicle: 'Көлік оқиғасы',
      violatorEmployee: 'Тәртіп бұзушы (қызметкер)',
      violatorObject: 'Зиянкес (нысан)',
      violatorVehicle: 'Зиянкес (көлік)',
      sound: 'Дыбыс',
      parkingStart: 'Тұрақ (бастау)',
      parkingEnd: 'Тұрақ (соңы)',
      dangerZoneEmpty: 'Қауіпті аймақ (бос)',
      dangerZoneNotEmpty: 'Қауіпті аймақ (бос емес)',
      blink: 'Қауіпті аймақ (бос емес)',
      areasEnter: 'Аймақтар (енгізу)',
      areasExit: 'Аймақтар (шығу)',
      overSpeed: 'Жылдамдықты асыру',
      unknownObjAccEnt: 'Белгісіз нысанға кіру (логин)',
      ITVCounter: 'ITV есептегіші',
      reportFileCreated: 'Есеп файлы жасалды',
      wrongPoss: 'Қате позиция',
      wrongPossCancel: 'Қате орын (болдырмау)',
      manWithoutMove: 'Қозғалысы жоқ адам',
      manWithoutMoveCancel: 'Қозғалыссыз адам (болдырмау)',
      reportHighHeartRate: 'Жүрек соғу жиілігі жоғарғы шектен жоғары',
      reportLowHeartRate: 'Жүрек соғу жиілігі төменгі шектен асып кетеді',
      anchortStateDown:'Зәкір күйі ТӨМЕН',
      anchortStateUp:"Зәкір шарты ЖОҒАРЫ",
      impact: 'Әсер',
      digitInOn: 'Сан. Ішінде үстінде)',
      digitInOff: 'Сан. Кіру (өшіру)',
      btnPressed: 'Түйме басылды',
      pairingStart: 'Жұптау (бастау)',
      pairingEnd: 'Жұптау (соңы)',
      reqSignalToTag: 'Тегке сигнал жіберуді сұрау',
      siteEnter: 'Координаталарды есептеу модулін қосу',
      siteExit: 'Координаталарды есептеу модулін өшіріңіз',
      leUp: 'Координаталарды есептеу модулін қосу',
      leDown: 'Координаталарды есептеу модулін өшіріңіз',
      anchorUp: 'Зәкірді қосу',
      anchorDown: 'Зәкірді өшіру',
      levelEntr: 'Деңгей (енгізу)',
      levelExit: 'Деңгей (шығыс)',
      reportExportDone: 'Есепті жүктеп салу',
      reportExportCancel: 'Қате туралы есеп жүктелуде',
      daysOfTheMonth: 'Ай күндері',
      fromDateToDate: 'Саннан санға',
      wDAYD: 'X (жұмыс күндері) және Y (демалыс күндері)',
      shiftName: 'Ауысым атауы',
      dayOfBegin: 'Басталу күні',
      expireDate: 'Мерзімнің өту күні',
      addShifts: 'Ауысымдарды қосыңыз',
      createShift: 'Ауысым жасау',
      sureWantToDelShift: 'Жылдамдықты шынымен жойғыңыз келе ме?',
      interractDistM: 'Өзара әрекеттесу қашықтығы, м',
      dspw: 'Бояу ұзақтығы Қозғалыссыз адам (жою), сек',
      stainiDurFallS: 'Бояу ұзақтығы Күз, сек',
      interractTimeS: 'Өзара әрекеттесу уақыты, сек',
      stainingDurDangerZ: 'Бояу ұзақтығы Қауіпті аймақ (шығу), сек',
      stainingDurBtnPress: 'Бояу ұзақтығы түйме басылды, сек',
      reactions: 'Реакциялар',
      listOfMaps: 'Карталар тізімі',
      shifts2: 'Ауысымдар мен ауысымдар',
      users: 'Пайдаланушылар',
      devices: 'Құрылғылар',
      organizations: 'Ұйымдар',
      deleteUser: 'Пайдаланушыны жою',
      sureWantDeleteUser: 'Пайдаланушыны жойғыңыз келетініне сенімдісіз бе?',
      addingUser: 'Пайдаланушы қосу',
      editUser: 'Пайдаланушыны өзгерту',
      addUser: 'Пайдаланушы қосу',
      permissions: 'Қол жеткізу',
      interraction: 'Өзара әрекеттесу',
      notEnoughtPermiss: 'Көру құқығы жеткіліксіз',
      abandondMaps: 'Қалдырылған карталар',
      jump: 'бару',
      passingThrouthArea: 'Аудан арқылы өту',
      state: 'Мемлекет',
      categories: 'Санаттар',
      participant: 'Қатысушы',

      tools: 'Құралдар',
      mapType: 'Карта түрі',
      elements: 'Элементтер',
      objectLabels: 'Нысан белгілері',
      sureWantDeleteZone: 'Аймақты шынымен жойғыңыз келе ме?',
      zoneDeleted: 'Аймақ жойылды',
      onboard_number: "Борттық нөмір",
      transport_mark: "Tасымалдау белгісі",
      state_number: "Мемлекеттік нөмірі",
      driver: "Жүргізуші"
    },
  },
};
