import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectOrganization({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const [variants, setVariants] = useState([]);
  const { t } = useTranslation();
  
  useEffect(() => {
    loadData();
  }, []);

  const value = useCallback(() => {
    const queryParams = getQueryParams();
    if (!variants || !tempFilterParams) return null;
    let currentValue = null;
    if (tempFilterParams.organization_id) {
      currentValue = variants.find(
        (v) => v.id === Number(tempFilterParams.organization_id)
      );
    } else {
      currentValue = variants.find(
        (v) => v.id === Number(queryParams.organization_id)
      );
    }

    if (!currentValue) return null;
    return currentValue.title;
  }, [variants, tempFilterParams]);

  async function loadData() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/organizations/?size=1000`
    );

    if (!responce || !responce.data) return;
    setVariants(responce.data.results);
  }
  
  return (
    <Select
      showSearch={true}
      placeholder={t('organization')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={value()}
      onChange={(value) => {
        setTempFilterParams({
          ...tempFilterParams,
          organization_id: value,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {variants &&
        [{ id: '_none', title: t('notSpecified') }, ...variants].map(
          (item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.title}
            </Select.Option>
          )
        )}
    </Select>
  );
}
