import { Select } from 'antd';
import { request } from 'helpers/client';
import { getQueryParams } from 'hooks/query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ReportSelectZone({
  setTempFilterParams,
  tempFilterParams,
  loading,
}) {
  const [zones, setZones] = useState([]);

  useEffect(() => {
    loadZones();
  }, []);

  async function loadZones() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/layouts/zones?size=1000`
    );
    if (!responce || !responce.data) return;
    setZones(responce.data);
  }

  const { t } = useTranslation();
   
  const zoneValue = useCallback(() => {
    if (!zones || !tempFilterParams) return null;
    const queryParams = getQueryParams();
    let currentZone = null;
    if (tempFilterParams.zone) {
      currentZone = zones.find((zone) => zone.id === tempFilterParams.zone);
    } else {
      currentZone = zones.find((zone) => zone.id === queryParams.zone);
    }

    if (!currentZone) return null;
    return currentZone.name;
  }, [zones, tempFilterParams]);

  return (
    <Select
      showSearch={true}
      placeholder={t('zone')}
      optionFilterProp="children"
      bordered={false}
      style={{
        background: 'transparent',
        width: '12rem',
        borderBottom: '2px solid #c2c2c2',
      }}
      value={zoneValue()}
      onChange={(value) => {
        setTempFilterParams({
          ...tempFilterParams,
          zone: value,
        });
      }}
      onFocus={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #1976d2';
      }}
      onBlur={(e) => {
        e.currentTarget.style.borderBottom = '2px solid #c2c2c2';
      }}
      filterOption={(input, option) =>
        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {zones &&
        [{ id: '_none', name: t('notSpecified') }, ...zones].map(
          (zone, index) => (
            <Select.Option value={zone.id} key={index}>
              {zone.name}
            </Select.Option>
          )
        )}
    </Select>
  );
}
