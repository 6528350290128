import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

export const getQueryParams = () => {
  const url = new URL(document.location.href);
  const result = {};
  for (const key of Array.from(url.searchParams.keys())) {
    result[key] = url.searchParams.get(key);
  }
  return result;
};

export const useQuery = () => {
  // ------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------
  const [query, setQuery] = useState({});
  const history = useHistory();
  const routerParams = useParams();

  // ------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------
  useEffect(() => {
    setQuery(getQueryParams());
  }, [routerParams]);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  function _setQuery(/**@type {{[key: string] : string}} */ args) {
    const params = new URLSearchParams();

    for (let key in args) {
      if (
        typeof args[key] === 'undefined' ||
        args[key] === null ||
        args[key].toString() === '' ||
        args[key].toString() === '_none'
      )
        continue;
      params.append(key, args[key].toString());
    }
    history.push({ search: `?${params.toString()}` });
  }

  function reloadQuery() {
    _setQuery(query);
  }

  function filterQueryParams(filter) {
    return Object.keys(query)
      .filter(filter)
      .reduce((obj, key) => {
        obj[key] = query[key];
        return obj;
      }, {});
  }

  return {
    /**@type {{[key: string] : any}} */ query,
    setQuery: _setQuery,
    reloadQuery,
    filterQueryParams,
  };
};
