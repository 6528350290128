import * as React from 'react';

function LongArrowAltUp({
  width = 30,
  height = 30,
  color = 'currentColor',
  className,
  ...restProps
}) {
  return (
    <svg
      {...restProps}
      aria-hidden="true"
      data-prefix="fas"
      data-icon="long-arrow-alt-up"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      className={className}
      width={width}
      height={height}
    >
      <path
        fill={color}
        d="M88 166.059V468c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12V166.059h46.059c21.382 0 32.09-25.851 16.971-40.971l-86.059-86.059c-9.373-9.373-24.569-9.373-33.941 0l-86.059 86.059c-15.119 15.119-4.411 40.971 16.971 40.971H88z"
      />
    </svg>
  );
}

export default LongArrowAltUp;
