import { Button, Space, Typography } from 'antd';
import { getPointOnMapPathNotification } from 'consts/routes';
import { useModal } from 'context/modal/modal.hook';
import { t } from 'i18next';
import moment from 'moment';
import { useEventTypeNameMapper } from 'pages/Settings/Reactions/utils';

export const SosModal = ({ item, onOkClick }) => {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const modal = useModal();
  const mathCoordinate = (coordinate) => Math.floor(coordinate * 100) / 100;
  const eventTypeNameMapper = useEventTypeNameMapper()

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div
      style={{
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h2
        className="block-text"
        style={{
          marginBottom: '1rem',
          textAlign: 'center',
          alignSelf: 'center',
          color: 'red',
          width: '100%',
        }}
      >
       {item.text}
        {/* {t('attention')} */}
      </h2>

      <Space direction={'vertical'} style={{ padding: 5 }}>
        <Typography.Paragraph
          style={{
            fontSize: '1.5em',
          }}
        >
          {eventTypeNameMapper[item.type]}
        </Typography.Paragraph>
        {item.time && (
          <Typography.Text>
            {t('time')}: {moment(item.time).format('DD.MM.YYYY HH:mm')}
          </Typography.Text>
        )}
        {item.owner && (
          <Typography.Text>
            {t('fullName')}: {item.owner}
          </Typography.Text>
        )}
        {item.links?.map && (
          <Typography.Text>
            {t('map')}: {item.links?.map?.label}
          </Typography.Text>
        )}
        {item.params?.area_name && (
          <Typography.Text>
            {t('zone')}: {item.params?.area_name}
          </Typography.Text>
        )}
        {item.params?.x && item.params?.y && item.params?.z && (
          <Typography.Text>
            x:&nbsp;{mathCoordinate(item.params?.x)} y:&nbsp;
            {mathCoordinate(item.params?.y)} z:&nbsp;
            {mathCoordinate(item.params?.z)}
          </Typography.Text>
        )}
        {item.associated_layouts && item.associated_layouts.length !== 0 && (
          <Typography.Text
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
              const path = getPointOnMapPathNotification(
                item.associated_layouts[0].id,
                item.params,
                item.id
              );
              if (path) {
                window.open(path, '_blank');
              }
            }}
          >
            {t('map')}:&nbsp;{item.associated_layouts[0].name}
          </Typography.Text>
        )}
      </Space>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'right',
        }}
      >
        <Button
          color="success"
          style={{
            color: '#000',
            backgroundColor: '#fff',
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontWeight: 500,
            fontSize: '0.875rem',
            border: 'unset',
          }}
          onClick={() => {
            onOkClick();
            modal.close();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};
