import moment from 'moment';
import pointInPolygon from 'point-in-polygon';

import {mapStore} from './MapStore';

export class Area {

  id;
  layout;
  type; // none, device, camera
  name;
  color;
  coordinates;
  figure_type; // polygon, circle, square
  from_date;
  to_date;

  device_id;
  camera_id;

  hasAccess = false;

  map; // MapData instance

  constructor(map, params) {
    this.map = map;
    this.update(params);
  }

  update(params) {
    Object.entries(params).forEach(([key, value]) => {
      if (
        key === 'users_has_access' &&
        mapStore.userId &&
        value?.includes(mapStore.userId)
      ) {
        this.hasAccess = true;
      }
      this[key] = value;
    });
  }

  updateByForm(params) {
    Object.entries(params).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  contains(keeper) {
    if (keeper) {
      const {xM, yM} = keeper;
      if (this.map && this.coordinates?.length) {
        const bounds = this.coordinates.map(([y, x]) => [x, y]);
        if (pointInPolygon([xM, yM], bounds)) {
          return true;
        }
      }
    }
    return false;
  }

  getParams() {
    const {map, ...params} = this;
    return params;
  }

  getFormValues() {
    const {map, from_date, to_date, ...params} = this;
    params.from_date = moment(from_date).toISOString(true);
    params.to_date = moment(to_date).toISOString(true);
    return params;
  }

}
