import { EditFilled } from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from 'antd';
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColorPicker from "../../components/ColorPicker";

import {
  onChange, setPointCamera, setPointDevice,
  updateAreaRequest
} from '../../store/actions/drawPolygons';
import {
  makeSelectCameraId, makeSelectCameraPoint, makeSelectColor, makeSelectDeviceId, makeSelectDevicePoint, makeSelectName, makeSelectPolygon,
  makeSelectUpdatingLoading
} from '../../store/selectors/drawPolygonSelector';

import { safeGet } from '../../helpers/getters';

import { useTranslation } from 'react-i18next';
import { initAnchorPlacer, removeAnchorPlacer } from "../../helpers/mapHelpers";
import { makeSelectDevicesList } from "../../store/selectors/devices";
import { createTypesFilter, mockedVideoSelect } from "./mockedData";
import { handleRemoveAllMarkers } from "./utils";

const { Option } = Select


const Edit = ({ handleEditPolygon, initials, onClose, layoutId, map }) => {
  const { t } = useTranslation()
  const initialData = useMemo(
    () => ({
      name: safeGet(initials, 'properties.name'),
      deviceId: safeGet(initials, 'properties.device_id', safeGet(initials, 'properties.deviceId')),
      color: safeGet(initials, 'properties.color'),
      cameraId: safeGet(initials, 'properties.camera'),
    }),
    // Memoize first data of selected area
    // eslint-disable-next-line
    [],
  );

  const dispatch = useDispatch();

  const name = useSelector(makeSelectName);
  const deviceId = useSelector(makeSelectDeviceId);
  const color = useSelector(makeSelectColor);
  const polygon = useSelector(makeSelectPolygon);
  const cameraId = useSelector(makeSelectCameraId);
  const cameraMarker = useSelector(makeSelectCameraPoint);
  const deviceMarker = useSelector(makeSelectDevicePoint);
  const deviceIds = useSelector(makeSelectDevicesList)

  const isEditing = useSelector(makeSelectUpdatingLoading);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorAnchor, setColorAnchor] = useState();
  const [isMarkerPlacing, setIsMarkerPlacing] = useState(false);
  const [createType, setCreateType] = useState();

  const setCameraMarker = (payload) => {
    dispatch(setPointCamera(payload))
  }

  const setDeviceMarker = (payload) => {
    dispatch(setPointDevice(payload))
  }

  const _setColorAnchor = node => {
    if (node && !isEqual(colorAnchor, node)) {
      setColorAnchor(node);
    }
  };

  const handleChangeField = field => e => {
    dispatch(onChange({ field, value: e.target.value }));
  };

  const handleChangeDeviceId = e => {
    dispatch(onChange({
      field: 'deviceId', value: e
    }));
  };

  const handleChangeColor = color => {
    dispatch(onChange({ field: 'color', value: color }));
  };

  const handleChangeCreateType = e => {
    setCreateType(e)
  }

  const handleColorPickerOpen = () => {
    setIsColorPickerOpen(true);
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleChangeSelect = (e) => {
    dispatch(onChange({ field: 'cameraId', value: e }))
  }

  const handleSetMarkerMode = () => {
    setIsMarkerPlacing(true)
  }

  const isFormValid = useMemo(
    () => {
      const data = { name, deviceId, color, cameraId, cameraMarker, deviceMarker };

      const layers = safeGet(polygon, '_layers', { 0: polygon });
      const layer = Object.values(layers)[0];

      const initialGeometry = safeGet(initials, 'geometry');
      const currentGeometry = safeGet(layer.toGeoJSON(), 'geometry');

      return Object.values(data).every(Boolean) && (
        !isEqual(data, initialData) || !isEqual(initialGeometry, currentGeometry)
      );
    },
    [name, deviceId, color, initialData, initials, polygon, cameraId, cameraMarker, deviceMarker],
  );

  const handleSubmit = () => {
    let form = {
      name,
      device_id: deviceId,
      color,
      region: polygon.toGeoJSON(),
      id: safeGet(initials, 'id'),
      camera: undefined,
      device: undefined
    };
    if (createType === 'with-camera') {
      form = {
        ...form,
        camera: {
          point: cameraMarker.marker.toGeoJSON(),
          cameraId
        },
      }
    } else if (createType === 'with-device') {
      form = {
        ...form,
        device: {
          point: deviceMarker.marker.toGeoJSON(),
          device_id: deviceId,
        }
      }
    }
    dispatch(updateAreaRequest({ form, layoutId }));
  };

  useEffect(() => {
    setCreateType(createTypesFilter[0].key)
  }, [])

  useEffect(
    () => {
      if (polygon && color) {
        polygon.setStyle({ color });
      }
    },
    [color, polygon],
  );

  useEffect(() => {
    if (map && isMarkerPlacing) {
      initAnchorPlacer(
        map,
        (marker, latlng) => {
          handleRemoveAllMarkers({
            markerInstance: marker,
            position: latlng,
            map,
            deviceMarker,
            cameraMarker,
            setIsMarkerPlacing,
            setDeviceMarker,
            setCameraMarker,
            createType,
          })
        }
      );
    } else if (map && !isMarkerPlacing) {
      removeAnchorPlacer(map);
    }
  }, [map, isMarkerPlacing])


  useEffect(
    () => {
      Object.entries(initialData).forEach(([field, value]) => {
        dispatch(onChange({ field, value }));
      })
    },
    [initials, dispatch, initialData],
  );

  return (
    <Form
      className="area-create-form"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Typography.Title level={3} style={{ marginBottom: 24 }}>Редактирование области</Typography.Title>
      <Form.Item
        label={t('title')}
        className="area-name-field"
      >
        <Input
          value={name}
          onChange={handleChangeField('name')}
          disabled={isEditing}
        />
      </Form.Item>
      <Form.Item
        label={t('color')}
        className="color-wrapper"
      >
        <span
          className="area-color"
          style={{ background: color || '#ffffff', cursor: 'pointer' }}
          ref={_setColorAnchor}
          onClick={handleColorPickerOpen}
        />
      </Form.Item>
      <Form.Item
        label={'region'}
        className="color-wrapper"
      >
        <Button
          type="primary"
          color="default"
          icon={<EditFilled />}
          onClick={handleEditPolygon}
        >
          {t('reDrawRegion')}
        </Button>
      </Form.Item>
      <Form.Item
        label={t('type')}
        className="select-wrapper"
      >
        <Select
          defaultValue={createTypesFilter[0].key}
          onChange={handleChangeCreateType}
        >
          {createTypesFilter.map(item => (
            <Option key={item.key} value={item.key}>{item.label}</Option>
          ))}
        </Select>
      </Form.Item>
      {
        createType === 'with-device' && (
          <>
            <Form.Item
              label={t('equipmentTag')}
              className="select-wrapper"
            >
              <Select
                defaultValue={deviceId}
                onChange={handleChangeDeviceId}
              >
                {deviceIds.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('marker')}
              className="color-wrapper"
            >
              <Button
                type="primary"
                disabled={isMarkerPlacing}
                icon={<EditFilled />}
                onClick={handleSetMarkerMode}
              >
                Поставить маркер
              </Button>
            </Form.Item>
          </>
        )
      }
      {
        createType === 'with-camera' && (
          <>
            <Form.Item
              label={t('camera')}
              className="select-wrapper"
            >
              <Select
                defaultValue={2}
                onChange={handleChangeSelect}
              >
                {mockedVideoSelect.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('marker')}
              className="color-wrapper"
            >
              <Button
                type="primary"
                disabled={isMarkerPlacing}
                icon={<EditFilled />}
                onClick={handleSetMarkerMode}
              >
                {t('putMarker')}

              </Button>
            </Form.Item>
          </>
        )
      }
      <div className="area-form-actions">
        <Button
          type="primary"
          // disabled={!isFormValid || isEditing}
          onClick={handleSubmit}
        >
          {t('save')}
        </Button>
        <Button
          onClick={handleClose}
          disabled={isEditing}
        >
          {t('close')}
        </Button>
      </div>

      <ColorPicker
        open={isColorPickerOpen}
        onClose={() => setIsColorPickerOpen(false)}
        anchor={colorAnchor}
        onChange={handleChangeColor}
      />
    </Form>
  );
};

export default Edit;
