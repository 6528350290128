import store from '../store';
import { removeStorageItem } from './storageHelpers';
import { TOKEN } from '../consts/storageKeys';
import { signOut } from '../store/actions/auth';

function clearAuth() {
  removeStorageItem(TOKEN);
  store.dispatch(signOut());
}

export default clearAuth;
