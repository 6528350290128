import axios from 'axios';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const ChartsTagsCharger = () => {
  const [post, setPost] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          '/dashboards/battery_charge_level'
      )
      .then((response) => {
        const values = Object.keys(response.data).map((key) => {
          const count = response.data[key];
          let value;
          if (key === 'GOOD') {
            value = {
              name: t('dashboard.compleate'),
              count,
              color: '#67C587',
            };
          }

          if (key === 'MID') {
            value = {
              name: t('dashboard.medium'),
              count,
              color: '#25ACE3',
            };
          }

          if (key === 'LOW') {
            value = {
              name: t('dashboard.discharged'),
              count,
              color: '#E7AB51',
            };
          }

          return value;
        });

        setPost(values);
      });
  }, []);

  return (
    <div className="char-component-wrapper">
      <div className="char-component-title">
        <span>{t('dashboard.activeStaffsPointsChargeLevel')}</span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ padding: 20 }}>
          {post.map((r) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <div
                  style={{
                    background: r.color,
                    height: '10px',
                    width: '10px',
                  }}
                ></div>
              </div>
              <div style={{ flexGrow: 1 }}>{r.name}</div>
              <div style={{ fontWeight: 'bold' }}>{r.count}</div>
            </div>
          ))}
        </div>
        <div
          className="char-component"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push('reports?tab=keeper_battery_level');
          }}
        >
          <Pie
            data={{
              datasets: [
                {
                  label: '',
                  data: post.map((r) => r.count),
                  backgroundColor: post.map((r) => r.color),
                  borderColor: post.map((r) => r.color),
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
