import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { useReportFilterParams } from '../report-page.hook';
import { ReportButtonSet } from '../ui-components/report-button-set.component';
import { ReportDateRangePicker } from '../ui-components/report-filter/report-date-range-picker/report-date-range-picker';
import { ReportSelectEmployeePosts } from "../ui-components/report-filter/report-select-employee-posts.jsx";
import { ReportSelectEmployees } from '../ui-components/report-filter/report-select-employees';
import { ReportSelectGender } from "../ui-components/report-filter/report-select-gender.jsx";
import { ReportSelectOrganization } from "../ui-components/report-filter/report-select-organization.jsx";
import { ReportSelectShift } from '../ui-components/report-filter/report-select-shift.jsx';
import { ReportTable } from '../ui-components/reports-table';

export const ReportsBiometric = ({ showFilter, reports, loading }) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useReportFilterParams();
  const columns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('time'),
      width: '15%',
      render: (created_at) => moment(created_at).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'employee',
      dataIndex: 'employee',
      title: t('employeeFullName'),
    },
    {
      key: 'post',
      dataIndex: 'post',
      title: t('position'),
    },
    {
      key: 'heart_rate',
      dataIndex: 'heart_rate',
      title: t('hr'),
    },
    {
      key: 'pedometer',
      dataIndex: 'pedometer',
      title: t('pedometer'),
    },
    {
      key: 'battery_charge_level',
      dataIndex: 'battery_charge_level',
      title: t('battery'),
      render: (v) => v + '%',
    },
    {
      key: 'shift',
      dataIndex: 'shift',
      title: t('shift'),
    },
    {
      key: 'sex',
      dataIndex: 'sex',
      title: t('sex'),
      render: (v) => (!v ? t('notSpecified') : t(v)),
    },
    {
      key: 'birth_date',
      dataIndex: 'birth_date',
      title: t('birth_date'),
      render: (v) =>
        !v ? t('notSpecified') : moment(v).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      key: 'temperature',
      dataIndex: 'temperature',
      title: t('temperature'),
      render: (v) => (!v ? t('notSpecified') : v),
    },
    {
      key: 'organization_title',
      dataIndex: 'organization_title',
      title: t('organization'),
      render: (v) => (!v ? t('notSpecified') : v),
    },
  ];
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
  }, []);
  // -----------------------------------------------------------------------------
  return (
    <>
      {showFilter && (
        <>
          <div
            className="reports-filter-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReportDateRangePicker
              tempFilterParams={filterParams}
              setTempFilterParams={setFilterParams}
              loading={loading}
            />
            <div className="filter-params">
              <ReportSelectEmployees
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                placeholder={t('employee')}
                paramName={'employee_id'}
              />

              <ReportSelectEmployeePosts
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
                propKey={'post'}
              />

              <ReportSelectShift
                setTempFilterParams={setFilterParams}
                tempFilterParams={filterParams}
                loading={loading}
              />

              <ReportSelectGender
               setTempFilterParams={setFilterParams}
               tempFilterParams={filterParams}
               loading={loading}
             />

             <ReportSelectOrganization 
              setTempFilterParams={setFilterParams}
              tempFilterParams={filterParams}
              loading={loading}
             />
            </div>
          </div>
          <ReportButtonSet
            loading={loading}
            filterParams={filterParams}
            onResetFilters={() => setFilterParams({})}
          />
        </>
      )}
      <ReportTable
        columns={columns}
        items={reports.results}
        total={reports.count}
        loading={loading}
      />
    </>
  );
};
