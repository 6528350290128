import { combineReducers } from 'redux';

import user from './user';
import users from './users';
import auth from './auth';
import loader from './loader';
import mapType from './mapType';
import keepers from './keepers';
import notifications from './notifications';
import labels from './labels';
import categories from './categories';
import svgMap from './svgMap';
import search from './search';
import ows from './ows';
import reports from './reports';
import notificationConfigurations from './notificationConfigurations';
import groups from './groups';
import groupsStaffs from './groups-staff';
import employees from './employees';
import rotations from './rotations';
import forms from './forms';
import markerConfigurations from './markerConfigurations';
import crsMap from './crsMap';
import drawPolygon from './drawPolygon';
import devices from './devices';
import cameras from './cameras';
import settings from './settings';

const rootReducer = combineReducers({
  user,
  auth,
  loader,
  mapType,
  keepers,
  notifications,
  labels,
  categories,
  svgMap,
  search,
  ows,
  reports,
  notificationConfigurations,
  groups,
  groupsStaffs,
  employees,
  rotations,
  forms,
  markerConfigurations,
  crsMap,
  polygons: drawPolygon,
  devices,
  cameras,
  users,
  settings,
});

export default rootReducer;
