import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Form, Input } = require('antd');

export function ScadaSettingsForm({
  scadaSettings,
  onSaveScadaSettings = undefined,
  isPreviewMode = true,
}) {
  // ------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------
  const {t} = useTranslation()
  const formOpener = useFormOpener();
  const [isRequesting, setIsRequesting] = useState(false);

  // ------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    formOpener.updateOptions({
      onSubmit: onSubmit,
      submitButtonProps: { loading: isRequesting },
    });
  }, [formOpener, isRequesting]);

  // ------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------
  async function onSubmit(isValid, value) {
    if (!isValid) return;
    setIsRequesting(true);
    const response = await request.put(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/scada_settings/',
      value
    );
    setIsRequesting(false);
    onSaveScadaSettings(response.data);
    formOpener.close();
  }

  // ------------------------------------------------------------------------------------
  return (
    <>
      <Form.Item
        className="m-config-item"
        label={t('hostName')}
        name={!isPreviewMode ? 'hostname' : undefined}
        initialValue={scadaSettings.hostname}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={scadaSettings.hostname}
        />
      </Form.Item>
      <Form.Item
        className="m-config-item"
        label={t('userName')}
        name={!isPreviewMode ? 'username' : undefined}
        initialValue={scadaSettings.username}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={scadaSettings.username}
          disabled={!scadaSettings}
        />
      </Form.Item>
      <Form.Item
        className="m-config-item"
        label={t('password')}
        name={!isPreviewMode ? 'password' : undefined}
        initialValue={scadaSettings.password}
      >
        <Input
          type="password"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={scadaSettings.password}
          disabled={!scadaSettings}
        />
      </Form.Item>
      <Form.Item
        className="m-config-item"
        label={t('pathToData')}
        name={!isPreviewMode ? 'path_to_data' : undefined}
        initialValue={scadaSettings.path_to_data}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={scadaSettings.path_to_data}
          disabled={!scadaSettings}
        />
      </Form.Item>
      <Form.Item
        className="m-config-item"
        label={t('interval')}
        name={!isPreviewMode ? 'interval' : undefined}
        initialValue={scadaSettings.interval}
      >
        <Input
          type="text"
          readOnly={isPreviewMode}
          className="m-config-value"
          value={scadaSettings.interval}
          disabled={!scadaSettings}
        />
      </Form.Item>
    </>
  );
}
