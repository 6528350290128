export const SET_LABELS_DATA = 'SET_LABELS_DATA';
export const SET_LABELS_FETCHING = 'SET_LABELS_FETCHING';
export const SET_LABELS_ERROR = 'SET_LABELS_ERROR';
export const SET_IS_USER_DRAWER_VISIBLE = "SET_IS_USER_DRAWER_VISIBLE";
export const SET_FAVORITE_DRAWER = 'SET_FAVORITE_DRAWER';

export const setLabelsLoading = (payload) => ({
  type: SET_LABELS_FETCHING,
  payload,
});
export const setLabelsData = (payload) => {
  return {
    type: SET_LABELS_DATA,
    payload,
  };
};
export const setLabelsError = (payload) => ({
  type: SET_LABELS_ERROR,
  payload,
});
export const setIsUserDrawerVisible = (payload) => ({
  type: SET_IS_USER_DRAWER_VISIBLE,
  payload
})
export const setFavoriteDrawerVisible = () => ({
  type: SET_FAVORITE_DRAWER
})
