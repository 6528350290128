import { Form, Input } from 'antd';
import { t } from 'i18next';

export const FormProps = ({
  setMacroscop,
  macroscop,
  isFormOpened = false,
}) => {
  return (
    <>
      <InputForm
        isForm={isFormOpened}
        label="Url"
        name={'url'}
        initialValue={macroscop ? macroscop.url : ''}
      >
        <Input
          type="text"
          readOnly={!isFormOpened}
          className="m-config-value"
          value={macroscop ? macroscop.url : ''}
          onChange={(val) => setMacroscop({ ...macroscop, url: val })}
          disabled={macroscop.loading}
        />
      </InputForm>

      <InputForm
        isForm={isFormOpened}
        label={t('login')}
        name={'login'}
        initialValue={macroscop ? macroscop.login : ''}
      >
        <Input
          type="text"
          readOnly={!isFormOpened}
          className="m-config-value"
          value={macroscop ? macroscop.login : ''}
          onChange={(val) => setMacroscop({ ...macroscop, url: val })}
          disabled={macroscop.loading}
        />
      </InputForm>
      <InputForm
        isForm={isFormOpened}
        label={t('password')}
        name={'password'}
        initialValue={macroscop ? macroscop.password : ''}
      >
        <Input
          type="password"
          readOnly={!isFormOpened}
          className="m-config-value"
          value={macroscop ? macroscop.password : ''}
          onChange={(val) => setMacroscop({ ...macroscop, url: val })}
          disabled={macroscop.loading}
        />
      </InputForm>
      <InputForm
        isForm={isFormOpened}
        label={t('width')}
        name={'width'}
        initialValue={macroscop ? macroscop.width : ''}
      >
        <Input
          type="text"
          readOnly={!isFormOpened}
          className="m-config-value"
          value={macroscop ? macroscop.width : ''}
          onChange={(val) => setMacroscop({ ...macroscop, url: val })}
          disabled={macroscop.loading}
        />
      </InputForm>
      <InputForm
        isForm={isFormOpened}
        label={t('height')}
        name={'height'}
        initialValue={macroscop ? macroscop.height : ''}
      >
        <Input
          type="text"
          readOnly={!isFormOpened}
          className="m-config-value"
          value={macroscop ? macroscop.height : ''}
          onChange={(val) => setMacroscop({ ...macroscop, url: val })}
          disabled={macroscop.loading}
        />
      </InputForm>
    </>
  );
};

// TODO: RE DOOOO
const InputForm = ({ isForm, label, name, initialValue, children }) => {
  if (isForm) {
    return (
      <Form.Item
        className="m-config-item"
        label={label}
        name={name}
        initialValue={initialValue}
      >
        {children}
      </Form.Item>
    );
  } else {
    return (
      <Form.Item className="m-config-item" label={label}>
        {children}
      </Form.Item>
    );
  }
};
