import client, { request } from 'helpers/client';

export const getDevices = () =>
  request
    .get(process.env.REACT_APP_LOCAL_API_BASE_URL + '/layouts/devices/')
    .then((res) => res?.data?.results || [])
    .catch((e) => {
      console.error(e);
      return [];
    });
