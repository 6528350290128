import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '../../helpers/client';

import {
  createFailure,
  createSuccess,
  CREATION_REQUEST,
  DELETE_REQUEST,
  deleteFailure,
  deleteSuccess,
  GET_LIST_REQUEST,
  getListFailure,
  getListRequest,
  getListSuccess,
} from '../actions/groups-staffs';

function* fetchConfigList(action) {
  try {
    const { payload } = action;

    const response = yield call(
      request.get,
      '/group_staffs/?' + `${payload?.group ? `group=${payload?.group}` : ''}`
    );

    yield put(getListSuccess(response.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

function* create(action) {
  const { data, callback } = action.payload;
  try {
    const response = yield call(request.post, '/group_staffs/', data);

    yield put(createSuccess(response.data));
    yield put(getListRequest({ staff: data.group }));
    yield call(callback);
  } catch (error) {
    yield put(createFailure(error));
  }
}

function* deleteRequest(action) {
  const { id, group } = action.payload;

  try {
    const response = yield call(request.delete, `/group_staffs/${id}/`);
    yield put(deleteSuccess(response.data));
    yield put(getListRequest({ group }));
  } catch (error) {
    yield put(deleteFailure(error));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_LIST_REQUEST, fetchConfigList),
    takeLatest(CREATION_REQUEST, create),
    takeLatest(DELETE_REQUEST, deleteRequest),
  ]);
}

export default Saga;
