import { normalizeInstance } from '../../helpers/table';
import { safeGet } from '../../helpers/getters';

const getState = (state) => safeGet(state, 'rotations', {});

export const selectConfigList = (state) =>
  safeGet(getState(state), 'list.data', []);
export const selectConfigTableData =
  (columns = []) =>
  (state) => {
    const data =
      selectConfigList(state).map(({ value_cycle, ...rest }) => ({
        ...rest,
        value_cycle: value_cycle?.[0]?.split(',') || [],
      })) || [];
    return data.map(normalizeInstance(columns));
  };
export const selectConfigListFetching = (state) =>
  safeGet(getState(state), 'list.fetching');
export const selectConfigListCount = (state) =>
  safeGet(getState(state), 'list.count', 0);

export const selectIsCreateFormOpen = (state) =>
  safeGet(getState(state), 'isCreateFormOpen');
export const selectIsEditFormOpen = (state) =>
  safeGet(getState(state), 'isEditFormOpen');

export const selectEventTypes = (state) =>
  safeGet(getState(state), 'eventTypes.data', []);
export const selectEventTypesFetching = (state) =>
  safeGet(getState(state), 'eventTypes.fetching');

export const selectCreationFetching = (state) =>
  safeGet(getState(state), 'creation.fetching');
export const selectCreationError = (state) =>
  safeGet(getState(state), 'creation.error');

export const selectEditingFetching = (state) =>
  safeGet(getState(state), 'editing.fetching');
export const selectEditingError = (state) =>
  safeGet(getState(state), 'editing.error');

export const selectNominatedToRemoveItem = (state) =>
  safeGet(getState(state), 'deleting.selected');
export const selectDeletingFetching = (state) =>
  safeGet(getState(state), 'deleting.fetching');
