import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getListRequest as getEmployeeListRequest } from 'store/actions/employees';
import { selectConfigList as selectEmployees } from 'store/selectors/employees';

export const UserDrawerVehicleMainStats = ({ keeper }) => {
  // ------------------------------------------------------------------------------------------------------------
  // variables
  // ------------------------------------------------------------------------------------------------------------

  const { t } = useTranslation();
  const employees = useSelector(selectEmployees);
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);

  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        size: 1000,
      })
    );
    loadOrganizations();
  }, []);

  // ------------------------------------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------------------------------------

  async function loadOrganizations() {
    const responce = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + `/organizations/?size=1000`
    );

    if (!responce || !responce.data) return;
    setOrganizations(responce.data.results);
  }

  function employeesServiceNumber() {
    if (!employees) return '';
    if (employees.length === 0) return '';
    let drivers = [];
    for (const key in keeper.transport_info) {
      if (!key.startsWith('driver')) continue;
      const driverId = keeper.transport_info[key];
      if (!driverId) continue;
      const driver = employees.find((e) => e.id === driverId);
      drivers.push(driver.service_number);
    }
    return drivers.join(' / ');
  }

  function providerOrganization() {
    if (!keeper.transport_info.organization) return '';
    if (!organizations) return '';
    if (organizations.length === 0) return '';
    const organization = organizations.find(
      (o) => o.id === keeper.transport_info.organization
    );
    return organization.title;
  }

  // ------------------------------------------------------------------------------------------------------------
  return (
    <>
      <p className="stack-drawer-title mt-20">{keeper.name}</p>
      <p className="d-flex-sb">
        <span>{t('transport_mark')}:</span>
        <span className="green">{keeper._embedded.tag.id}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('personnelNumber')}:</span>
        <span className="green">{employeesServiceNumber()}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('position')}:</span>
        <span className="green">{keeper.post}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('onboard_number')}:</span>
        <span className="green">{keeper?.transport_info.onboard_number}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('state_number')}:</span>
        <span className="green">{keeper?.transport_info.state_number}</span>
      </p>
      <p className="d-flex-sb">
        <span>{t('description')}:</span>
        <span className="green">{keeper?.transport_info.description}</span>
      </p>

      <p className="d-flex-sb">
        <span>{t('organization')}:</span>
        <span className="green">{providerOrganization()}</span>
      </p>
    </>
  );
};
