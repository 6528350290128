import { all, takeLatest, call, put } from 'redux-saga/effects';

import { request } from '../../helpers/client';

import {
  GET_LIST_REQUEST,
  CREATE_REQUEST,
  UPDATE_REQUEST,
  DELETE_REQUEST,
  getListRequest,
  getListSuccess,
  getListFailure,
  createSuccess,
  createFailure,
  setIsCreateFormOpen,
  updateSuccess,
  updateFailure,
  setIsEditFormOpen,
  deleteSuccess,
  deleteFailure,
  askToDelete,
} from '../actions/markerConfigurations';

function* getList() {
  try {
    const response = yield call(request.get, '/common/config/markers/');

    yield put(getListSuccess(response.data));
  } catch (error) {
    yield put(getListFailure(error));
  }
}

function* create(action) {
  const { data } = action.payload;

  try {
    const response = yield call(request.post, '/common/config/markers/', data);

    yield put(createSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsCreateFormOpen(false));
  } catch (error) {
    yield put(createFailure(error));
  }
}

function* update(action) {
  const { data, id } = action.payload;
  try {
    const response = yield call(
      request.put,
      `/common/config/markers/${id}/`,
      data
    );

    yield put(updateSuccess(response.data));
    yield put(getListRequest());
    yield put(setIsEditFormOpen(false));
  } catch (error) {
    yield put(updateFailure(error));
  }
}

function* remove(action) {
  const { id } = action.payload;

  try {
    const response = yield call(
      request.delete,
      `/common/config/markers/${id}/`
    );

    yield put(deleteSuccess(response.data));
    yield put(getListRequest());
    yield put(askToDelete(null));
  } catch (error) {
    yield put(deleteFailure(error));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_LIST_REQUEST, getList),
    takeLatest(CREATE_REQUEST, create),
    takeLatest(UPDATE_REQUEST, update),
    takeLatest(DELETE_REQUEST, remove),
  ]);
}

export default Saga;
