import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { List } from 'antd';
import { useEffect, useState } from 'react';
import { getQueryParams } from 'hooks/query';
import { ReportTemplateItem } from './report-template-item';
import styles from './report-template.module.scss';
import { useParams } from 'react-router-dom';
import { ReportTemplateRequest } from './report-template.request';
import { ParamsHelper } from '../../../../helpers/params';
import { useFormOpener } from '../../../../context/form-opener/form-opener.hook';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/save.svg';
import { ReportTemplateForm } from './report-template-form';

export const ReportTemplate = ({ closePopup, onSetTemplate }) => {
  // ----------------------------------------------------------------------------------------
  // variables
  // ----------------------------------------------------------------------------------------
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const routerParams = useParams();
  const formOpener = useFormOpener();

  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    setTemplates([]);
  }, [routerParams]);

  useEffect(async () => {
    const queryParams = getQueryParams();
    await loadTemplate({
      search: keyword,
      report_type: queryParams.tab ? queryParams.tab : 'events',
    });
  }, [keyword, routerParams]);

  // ----------------------------------------------------------------------------------------
  // functions
  // ----------------------------------------------------------------------------------------
  async function loadTemplate(params) {
    const response = await ReportTemplateRequest.get(params);
    if (!response) return;
    setTemplates(response);
  }

  async function saveTemplate({ name }) {
    const queryParams = getQueryParams();
    const filterParams = ParamsHelper.exclude(queryParams, [
      'toDate',
      'fromDate',
      'tab',
      'id',
      'page',
      'size',
    ]);
    const newRecord = await ReportTemplateRequest.post({
      name: name,
      report_type: queryParams.tab ?? 'events',
      params: filterParams,
    });
    setTemplates((prev) => [newRecord, ...prev]);
  }

  function onSaveTemplateClick() {
    closePopup();
    formOpener.open({
      title: t('report.template.create.title'),
      submitText: t('report.template.create.submit'),
      component: ReportTemplateForm,
      onCancel: () => formOpener.close(),
      onSubmit: async (_, { name }) => {
        await saveTemplate({ name });
        formOpener.close();
      },
    });
  }

  // ----------------------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.search}>
          <input
            placeholder={t('report.template.search')}
            onChange={(event) => setKeyword(event.target.value)}
          />
          <SearchIcon />
        </div>
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          style={{ marginLeft: '0.2rem' }}
          aria-label="save template"
          onClick={() => onSaveTemplateClick()}
        >
          <SaveIcon />
        </IconButton>
      </div>

      <List
        itemLayout="vertical"
        className={styles.content}
        dataSource={templates}
        renderItem={(item) => (
          <ReportTemplateItem
            item={item}
            onDelete={() =>
              setTemplates((prev) => prev.filter((prev) => prev.id !== item.id))
            }
            closePopup={closePopup}
          />
        )}
      />
    </div>
  );
};
