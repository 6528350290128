import { safeGet } from "../../helpers/getters";

import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,

  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,
  SET_IS_CREATE_MODAL_OPEN,

  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  SET_IS_UPDATE_MODAL_OPEN,

  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  NOMINATE_TO_DELETE,
} from '../actions/devices';


const reqState = {
  data: null,
  fetching: false,
  error: null,
};

const initialState = {
  list: {
    data: [],
    count: 0,
    fetching: false,
    error: null,
  },
  creating: {
    ...reqState,
    isOpen: false,
  },
  updating: {
    ...reqState,
    isOpen: false,
  },
  deleting: {
    ...reqState,
    selected: null,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_REQUEST: return {
      ...state,
      list: {
        ...state.list,
        fetching: true,
        data: [],
      }
    };
    case GET_DEVICES_SUCCESS: return {
      ...state,
      list: {
        ...state.list,
        fetching: false,
        data: safeGet(action, 'payload.results', []),
        count: safeGet(action, 'payload.count', 0),
      },
    };
    case GET_DEVICES_FAILURE: return {
      ...state,
      list: {
        ...state.list,
        fetching: false,
        error: action.payload,
      }
    };

    case CREATE_DEVICE_REQUEST: return {
      ...state,
      creating: {
        ...state.creating,
        fetching: true,
        data: null,
        error: null,
      },
    };
    case CREATE_DEVICE_SUCCESS: return {
      ...state,
      creating: {
        ...state.creating,
        fetching: false,
        data: action.payload,
      }
    };
    case CREATE_DEVICE_FAILURE: return {
      ...state,
      creating: {
        ...state.creating,
        fetching: false,
        error: action.payload,
      }
    };
    case SET_IS_CREATE_MODAL_OPEN: return {
      ...state,
      creating: {
        ...state.creating,
        isOpen: action.payload,
      }
    };

    case UPDATE_DEVICE_REQUEST: return {
      ...state,
      updating: {
        ...state.creating,
        fetching: true,
        data: null,
        error: null,
      },
    };
    case UPDATE_DEVICE_SUCCESS: return {
      ...state,
      updating: {
        ...state.creating,
        fetching: false,
        data: action.payload,
      }
    };
    case UPDATE_DEVICE_FAILURE: return {
      ...state,
      updating: {
        ...state.creating,
        fetching: false,
        error: action.payload,
      }
    };
    case SET_IS_UPDATE_MODAL_OPEN: return {
      ...state,
      updating: {
        ...state.updating,
        isOpen: action.payload,
      }
    };

    case DELETE_DEVICE_REQUEST: return {
      ...state,
      deleting: {
        ...state.creating,
        fetching: true,
        data: null,
        error: null,
      },
    };
    case DELETE_DEVICE_SUCCESS: return {
      ...state,
      deleting: {
        ...state.creating,
        fetching: false,
        data: action.payload,
      }
    };
    case DELETE_DEVICE_FAILURE: return {
      ...state,
      deleting: {
        ...state.creating,
        fetching: false,
        error: action.payload,
      }
    };
    case NOMINATE_TO_DELETE: return {
      ...state,
      deleting: {
        selected: action.payload,
      }
    };
    default: return state;
  }
};

export default reducer;
