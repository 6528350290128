import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getMapByIdRequest } from '../../store/actions/svgMap';
import { makeSelectGetSingleMap, makeSelectSingleMapFetching } from '../../store/selectors/svgMap';

import Spinner from '../../components/Spinner';

import { useTranslation } from 'react-i18next';
import Map from './Map';


const PutAnchors = () => {
  const { mapId } = useParams();
  const mapElementId = `map-${mapId}`;
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const singleMap = useSelector(makeSelectGetSingleMap);
  const loading = useSelector(makeSelectSingleMapFetching);

  useEffect(
    () => {
      if (mapId) {
        dispatch(getMapByIdRequest(mapId));
      }
    },
    // eslint-disable-next-line
    [mapId],
  );

  if (!singleMap) {
    return loading ? <Spinner /> : <h2>{t('mapNotFound')}</h2>;
  }

  return <Map id={mapElementId} image={singleMap.file} />;
};

export default React.memo(PutAnchors);
