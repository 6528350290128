import {
  CLEAR_FORM,
  INIT_FORM,
  SET_FORM_INITIAL_VALUES,
  SET_FORM_VALUE,
  REMOVE_FORM,
} from '../actions/forms';
import {
  normalizeFields
} from '../../pages/Settings/Reactions/utils';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FORM: {
      const { form, config } = action.payload;
      const fields = normalizeFields(config);

      return {
        ...state,
        [form]: {
          _META: { config },
          ...fields
        },
      };
    }
    case SET_FORM_VALUE: {
      const { form, field, value } = action.payload;

      const _form = state[form];
      if (!_form) return state;

      return {
        ...state,
        [form]: {
          ...state[form],
          [field]: value
        },
      };
    }
    case CLEAR_FORM: {
      const { form } = action.payload;
      const _form = state[form];
      if (!_form) return state;
      const { config } = _form._META;

      return {
        ...state,
        [form]: {
          _META: { ..._form._META },
          ...normalizeFields(config),
        }
      }
    }
    case REMOVE_FORM: {
      const { form } = action.payload;
      if (!state[form]) return state;

      return Object.keys(state).reduce(
        (memo, curr) => {
          if (curr === form) return memo;
          return Object.assign(memo, { [curr]: state[curr] })
        },
        {},
      );
    }
    case SET_FORM_INITIAL_VALUES: {
      const { form, instance } = action.payload;

      const _form = state[form];
      if (!_form) return state;

      const { config } = _form._META;

      const data = config.reduce(
        (memo, curr) => {
          const value = instance[curr.name];
          if (!value) return memo;

          return {
            ...memo,
            [curr.name]: curr.type !== 'file'
              ? value
              : Object.assign({}, _form[curr.name], { url: value })
          }
        },
        {}
      );

      return {
        ...state,
        [form]: {
          ...data,
          id: instance.id,
          _META: _form._META,
        },
      }
    }
    default:
      return state;
  }
};

export default reducer;
