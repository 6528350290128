import { Tooltip } from 'antd';

export const EllipsisText = ({ children, width = '300px' }) => {
  return (
    <Tooltip title={children}>
      <div
        style={{
          width,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
