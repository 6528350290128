import { EditFilled } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import { useFormOpener } from 'context/form-opener/form-opener.hook';
import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsLayout from '../SettingsLayout';
import { SettingsForm } from './form/settings-form';

const SettingsConfiguration = ({ history }) => {
  // ---------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------
  const [generalSettings, setGeneralSettings] = useState({});
  const formOpener = useFormOpener();
  const { t } = useTranslation()
  // ---------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------

  useEffect(() => {
    loadGeneralSettings();
  }, []);
  // ---------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------
  async function loadGeneralSettings() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/settings/'
    );
    setGeneralSettings(response.data);
  }

  function onEditSettingsClick() {
    formOpener.open({
      title: t('editSettings'),
      submitText: t('edit'),
      width:"800px",
      component: (
        <SettingsForm
          generalSettings={generalSettings}
          onSaveGeneralSettings={setGeneralSettings}
          isPreviewMode={false}
        />
      ),
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
  };

  // ---------------------------------------------------------------
  return (
    <SettingsLayout history={history}>
      <div className="table-wrapper">
        <div className="table-actions">
          <Typography.Title>{t('settings')}</Typography.Title>
        </div>
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 20 }}
          className="m-config-wrapper"
          {...formItemLayout}
        >
          <SettingsForm generalSettings={generalSettings} />
          <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
            <Button
              type="primary"
              icon={<EditFilled />}
              disabled={Object.keys(generalSettings).length === 0}
              onClick={onEditSettingsClick}
            >
              {Object.keys(generalSettings).length === 0
                ? t('loading') + '...'
                : t('edit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </SettingsLayout>
  );
};

export default SettingsConfiguration;
