import { request } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getListRequest as getRotationsList } from 'store/actions/rotations';
import {
  selectConfigList,
  selectEventTypesFetching,
} from 'store/selectors/rotations';

const useConfig = () => {
  const { t } = useTranslation();
  const list = useSelector(selectConfigList);
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    dispatch(getRotationsList());
  }, []);

  useEffect(() => {
    loadOrganizations();
  }, []);

  // ------------------------------------------------------------------------------
  // functions
  // ------------------------------------------------------------------------------

  async function loadOrganizations() {
    const response = await request.get(
      process.env.REACT_APP_LOCAL_API_BASE_URL + '/organizations/',
      {
        page: 1,
        size: 1000,
      }
    );
    setOrganizations(
      response.data.results.map((r) => ({ name: r.id, label: r.title }))
    );
  }

  return [
    {
      type: 'text',
      name: 'surname',
      label: t('surname'),
      required: true,
    },
    {
      type: 'text',
      name: 'name',
      label: t('name'),
      required: true,
    },
    {
      type: 'text',
      name: 'patronymic',
      label: t('patronymic'),
      required: true,
    },
    {
      type: 'date-picker',
      name: 'birth_date',
      label: t('birth_date'),
      required: true,
    },
    {
      type: 'text',
      name: 'service_number',
      label: t('personnelNumber'),
      required: true,

    },
    {
      type: 'number',
      name: 'min_rate',
      label: t('min_rate'),
      required: true,
    },
    {
      type: 'number',
      name: 'max_rate',
      label: t('max_rate'),
      required: true,
    },
    {
      type: 'valueSet',
      name: 'sex',
      label: t('sex'),
      required: true,

      selectType: 'raw',
      options: [
        { label: 'Мужской', name: 'male' },
        { label: 'Женский', name: 'female' },
      ],
    },
    {
      type: 'text',
      placeholder: "Заполните это поле в формате '2000XXXX'",
      name: 'mark',
      label: t('mark'),
      required: true,

    },
    {
      type: 'boolean',
      name: 'is_active',
      label: t('active'),
      required: false,
    },
    {
      type: 'valueSet',
      name: 'organization',
      label: t('organization'),
      options: organizations,
      required: true,
      getValueSetLoading: () => {},
    },
    {
      type: 'text',
      name: 'post',
      label: t('position'),
      required: true,
    },
    {
      type: 'valueSet',
      name: 'shift',
      label: t('shift'),
      options: (list ?? []).map((v) => ({ name: v.id, label: v.shift_name })),
      getValueSetLoading: selectEventTypesFetching,
      required: true,
    },
    {
      type: 'valueSet',
      name: 'shift_second',
      label: t('shift') + ' 2',
      options: (list ?? []).map((v) => ({ name: v.id, label: v.shift_name })),
      getValueSetLoading: selectEventTypesFetching,
    },

    {
      type: 'boolean',
      name: 'is_violator',
      label: t('violator'),
      required: false,
    },
    {
      type: 'boolean',
      name: 'has_biometry',
      label: t('has_biometry'),
    },
    {
      type: 'boolean',
      name: 'has_special_values_of_biometrics',
      label: t('has_special_values_of_biometrics'),
    },
    {
      type: 'date-time-picker',
      name: 'violator_term',
      label: t('violatorTerm'),
    },
  ];
};

export default useConfig;
