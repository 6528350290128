export const ADD_FAVORITES_REQUEST = 'ADD_FAVORITES_REQUEST';
export const ADD_FAVORITES_DATA = 'ADD_FAVORITES_DATA';
export const ADD_FAVORITES_ERROR = 'ADD_FAVORITES_ERROR';
export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_DATA = 'GET_FAVORITES_DATA';
export const GET_FAVORITES_ERROR = 'GET_FAVORITES_ERROR';
export const GET_FREQUENTLY_REQUEST = 'GET_FREQUENTLY_REQUEST';
export const GET_FREQUENTLY_DATA = 'GET_FREQUENTLY_DATA';
export const GET_FREQUENTLY_ERROR = 'GET_FREQUENTLY_ERROR';
export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_DATA = 'GET_HISTORY_DATA';
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR';
export const SET_USER_COLOR_DATA = 'SET_USER_COLOR';
export const SET_USER_COLOR_REQUEST = 'SET_USER_COLOR_REQUEST';
export const SET_USER_COLOR_ERROR = 'SET_USER_COLOR_ERROR';
export const GET_USER_COLOR_REQUEST = 'GET_USER_COLOR_REQUEST';
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const SET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';

export const addFavoritesRequest = (payload) => ({
  type: ADD_FAVORITES_REQUEST,
  payload,
});

export const addFavoritesData = (payload, id) => ({
  type: ADD_FAVORITES_DATA,
  payload,
  id,
});

export const addFavoritesError = (payload) => ({
  type: ADD_FAVORITES_ERROR,
  payload,
});

export const getFavoritesRequest = (payload = {}) => ({
  type: GET_FAVORITES_REQUEST,
  payload,
});

export const getFavoritesData = (payload) => ({
  type: GET_FAVORITES_DATA,
  payload,
});

export const getFavoritesError = (payload) => ({
  type: GET_FAVORITES_ERROR,
  payload,
});

export const getFrequentlyVisitedRequest = (payload) => ({
  type: GET_FREQUENTLY_REQUEST,
  payload,
});

export const getFrequentlyVisitedData = (payload) => ({
  type: GET_FREQUENTLY_DATA,
  payload,
});

export const getFrequentlyVisitedError = (payload) => ({
  type: GET_FREQUENTLY_ERROR,
  payload,
});

export const getHistoryRequest = (payload) => ({
  type: GET_HISTORY_REQUEST,
  payload,
});

export const getHistoryData = (payload) => ({
  type: GET_HISTORY_DATA,
  payload,
});

export const getHistoryError = (payload) => ({
  type: GET_HISTORY_ERROR,
  payload,
});

export const setUserColorData = (payload) => ({
  type: SET_USER_COLOR_DATA,
  payload,
});

export const setUserColorError = (payload) => ({
  type: SET_USER_COLOR_ERROR,
  payload,
});

export const setUserColorRequest = (payload) => ({
  type: SET_USER_COLOR_REQUEST,
  payload,
});

export const getUserColorRequest = () => ({
  type: GET_USER_COLOR_REQUEST,
});

export const getUserDataRequest = () => ({
  type: GET_USER_DATA_REQUEST,
});

export const setUserData = (payload) => ({
  type: SET_USER_DATA_REQUEST,
  payload,
});
