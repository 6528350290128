import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';

import { EMPLOYEES, keysCollections, NOT_DELETED, USERS } from '../consts/options';

export const getBounds = (width, height) => {
  const x0 = 0;
  const y0 = 0;
  const x1 = 100;
  const y1 = (height * x1) / width;
  return [
    {lat: y0, lng: x0},
    {lat: y1, lng: x1},
  ];
};

const getRatio = ({segment_length, pointL0, pointL1}) => {
  const {lat: ly0, lng: lx0} = pointL0;
  const {lat: ly1, lng: lx1} = pointL1;

  const lw = Math.abs(Number(lx1 - lx0));
  // const lh = Math.abs(Number(ly1 - ly0));
  // sgm = 4.71 = 100
  // 4.71 = 100
  // 2 = x
  // x = 100 * 2 / 4.71
  // const hypo = (lw ** 2 + lh ** 2) ** 0.5;
  //TODO Adyl 09/12/21
  // return segment_length / hypo;

  return segment_length / lw
};

export const getFinalBounds = ({
                                 segment_length,
                                 x,
                                 y,
                                 pointA,
                                 pointL0,
                                 pointL1,
                                 height,
                                 width,
                               }) => {
  const ratio = getRatio({segment_length, pointL0, pointL1});

  const realWidth = width * ratio;
  const realHeight = height * ratio;

  const {lat: movedY, lng: movedX} = pointA;

  const minX = x - movedX * ratio;
  const minY = y - movedY * ratio;
  const maxX = minX + realWidth;
  const maxY = minY + realHeight;
  return {min_x: minX, min_y: minY, max_x: maxX, max_y: maxY, x, y, segment_length};
};

export const getSort = (type) => {
  if (type === EMPLOYEES) return 'lastname';
  if (type === USERS) return 'login';
  return 'name';
};

export const reducedFilter = (type, search) => {
  const keysList = keysCollections[type];
  const length = keysList.length;
  let resp = '['.repeat(length);
  resp += `${keysList[0]}=@${search}`;
  for (let i = 1; i < length; i++) {
    resp += `,${keysList[i]}=@${search}]`;
  }
  resp += `;${NOT_DELETED}]`;
  return resp;
};

export const getFilter = (type, search) => {
  if (search) {
    return reducedFilter(type, search);
  }
  return NOT_DELETED;
};

/**
 * Safely retrieve data from an object by the path.
 * @param {Record<string, unknown>} object - Target object which used for pulling path value
 * @param {string|Array<string>} path - Path to be pulled
 * @param {*} [defaultValue? = undefined] - A default value to be returned if the path value is absent or null/undefined
 * @returns {*}
 */
export const safeGet = (object, path, defaultValue = undefined) => {
  const result = get(object, path, defaultValue);
  if (isBoolean(result) || result === 0) return result;
  return result || defaultValue;
};
