import { Modal, notification as antd_notification } from 'antd';
import { SosModal } from 'components/HeaderNav/BellPopover/sos-modal';
import { request } from 'helpers/client';
import {
  errorNotification,
  eventNotification,
  successNotification,
} from 'helpers/notificationHelpers';
import { t } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  XlsFileProcessingNotification,
  XlsFileReadyNotification,
} from 'ui-components/notification/notification-xls';
import { useNotificationListener } from './notification-hooks/notification-listener.hook';
import { NotificationContext } from './notification.context';

export function NotificationProvider(props) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [notReaded, setNotReaded] = useState({
    results: [],
    count: 0,
  });
  const [readed, setReaded] = useState({
    results: [],
    nextPage: 1,
    count: 0,
  });
  const [notReadedCount, setNotReadedCount] = useState(0);

  const [readedLoading, setReadedLoading] = useState(false);
  const audioPlayerRef = useRef(new Audio());
  const [alertModals, setAlertModals] = useState([]);

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useNotificationListener(
    ({ data }) => {
      const { type } = JSON.parse(data);
      let responseNotificationObject;
      if (type === 'last_notifications') {
        responseNotificationObject = {
          results: [...JSON.parse(data).items, ...notReaded.results],
          count: notReaded.count + JSON.parse(data).items.length,
        };
      } else {
        const { notification } = JSON.parse(data);
        showPopup(notification);
        const newArray = [notification, ...notReaded.results];
        responseNotificationObject = {
          results: newArray,
          count: notReaded.count + 1,
        };
      }
      setNotReaded(responseNotificationObject);
    },
    [notReaded]
  );

  useEffect(() => {
    refreshNotReadedCount();
  }, [notReaded, readed]);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  async function refreshNotReadedCount() {
    const count = await loadNotReadedCount();
    setNotReadedCount(count);
  }

  const loadNotificationsReaded = useCallback(
    async (props) => {
      if (readedLoading) return;
      setReadedLoading(true);
      const responce = await request.get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/custom/notification?page=${
            props && props.fromStart ? 1 : readed.nextPage ?? 1
          }&read_is=true`
      );

      const notifications =
        props && props.fromStart
          ? responce.data.results
          : [...readed.results, ...responce.data.results];

      setReaded({
        results: notifications,
        nextPage: responce.data.next,
        count: responce.data.count,
      });
      setReadedLoading(false);
    },
    [readedLoading, readed.results, readed.nextPage]
  );

  const loadNotificationsNotReaded = useCallback(
    async (props) => {
      const responce = await request.get(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/custom/notification?read_is=false&id__lt=${
            notReaded.results[notReaded.results.length - 1].id
          }`
      );

      const notifications =
        props && props.fromStart
          ? responce.data
          : [...notReaded.results, ...responce.data];

      setNotReaded({
        results: notifications,
        count: notReaded.count + responce.data.length,
      });
    },
    [notReaded.results]
  );

  async function loadNotification(id) {
    const notification = await request.get('/custom/notification/' + id);
    return notification.data;
  }

  async function loadNotReadedCount() {
    const response = await request.get('notifications/unread-count');
    return response.data.count;
  }

  const onClickSosModalOk = async (item) => {
    await readNotification(item.id);
    await loadNotificationsReaded({ fromStart: true });
    stopAudioPlayer();
  };

  const clearNotifications = useCallback(async () => {
    const sortedNotifications = notReaded.results.sort((a, b) => a.id - b.id);
    if (sortedNotifications.length === 0) return;
    try {
      await request.post(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/custom/notification/multiple_read/`,
        {
          notification_id:
            sortedNotifications[sortedNotifications.length - 1].id,
        }
      );
      setNotReaded({
        results: [],
        count: 0,
      });

      setReaded({
        results: [],
        nextPage: 1,
        count: 0,
      });
      successNotification(t('notificationCleared'));
    } catch {
      errorNotification(t('someErrorOccured'));
    }
  }, [notReaded]);

  const readNotification = useCallback(
    async (id) => {
      await request.patch(
        process.env.REACT_APP_LOCAL_API_BASE_URL +
          `/custom/notification/${id}/`,
        {
          read_is: true,
        }
      );
      setNotReaded({
        count: notReaded.count - 1,
        results: notReaded.results.filter((item) => item.id !== id),
      });
    },
    [notReaded]
  );

  async function showPopup(notification) {
    if (
      !notification.reaction ||
      !Object.keys(notification.reaction).length === 0
    )
      return;
    let mode = notification.mode;
    if (notification.reaction) {
      playAudio(
        process.env.REACT_APP_BASE_URL_MEDIA + notification.reaction.audio
      );
      if (notification.reaction.mode) {
        mode = notification.reaction.mode;
      }
    }

    if (notification.type.startsWith('REPORT_EXPORT_IN_PROCESS')) {
      antd_notification.open(
        XlsFileProcessingNotification(notification.params.queue, t)
      );
      return;
    }

    if (notification.type.startsWith('REPORT_EXPORT')) {
      antd_notification.open(
        XlsFileReadyNotification(notification.params.queue, t)
      );
      return;
    }

    if (mode === 'danger') {
      setAlertModals([
        ...alertModals,
        {
          record: notification,
          key: new Date().getTime() + Math.floor(Math.random() * 100),
        },
      ]);
    }

    if (mode === 'regular') {
      eventNotification({
        item: notification,
        onCloseClick: () => onClickCloseEventNotification(notification),
        t,
      });
    }
  }

  async function onClickCloseEventNotification(notification) {
    await readNotification(notification.id);
    await loadNotificationsReaded({ fromStart: true });
  }

  function stopAudioPlayer() {
    if (!audioPlayerRef.current) return;
    audioPlayerRef.current.pause();
    audioPlayerRef.current.currentTime = 0;
    audioPlayerRef.current.src = '';
  }

  function playAudio(url) {
    audioPlayerRef.current.src = url;
    audioPlayerRef.current.autoplay = true;
    audioPlayerRef.current.play();
  }

  // ---------------------------------------------------------------------------
  return (
    <>
      {alertModals.map((r, index) => (
        <Modal
          key={r.key}
          centered
          footer={null}
          open={true}
          mask={index === 0}
          onCancel={() => {
            setAlertModals(alertModals.filter((m) => m.key !== r.key));
          }}
        >
          <SosModal
            onOkClick={() => {
              onClickSosModalOk(r.record);
              setAlertModals(alertModals.filter((m) => m.key !== r.key));
            }}
            item={r.record}
          />
        </Modal>
      ))}

      <NotificationContext.Provider
        value={{
          notReaded: notReaded.results,
          notReadedCount,
          readed: readed,
          readedLoading,
          load: loadNotification,
          loadReaded: loadNotificationsReaded,
          loadNotReaded: loadNotificationsNotReaded,
          clear: clearNotifications,
          read: readNotification,
        }}
      >
        {props.children}
      </NotificationContext.Provider>
    </>
  );
}
